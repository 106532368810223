import React from "react";
import { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Context } from "../Context/Context";
import { Box } from "@mui/system";
import { ShopifyAppKey, AuthScopes } from "../ShopifyConstants";

export default function RefetchShopifyToken() {
    const { state, setState } = useContext(Context);
    const { isOpenRefetchTokenDialog, shopName } = state;
    return (
        <Dialog
            open={isOpenRefetchTokenDialog ? isOpenRefetchTokenDialog : false}
            onClose={() => {
                setState({
                    ...state,
                    isOpenRefetchTokenDialog: false
                });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                "& .MuiPaper-root": {
                    maxHeight: "none",
                    maxWidth: "none",
                    borderRadius: '10px',
                },
            }}
        >
            <DialogContent sx={{
                width: "463px",
                minHeight: "260px",
                padding: '10px 20px',
                fontSize: "14px",
                boxSizing: 'border-box',
            }}><Box>
                    <Box sx={{ height: "48px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "30px", fontWeight: "600", letterSpacing: "0.15px" }}>Welcome to Cartifind</Box>
                    <Box sx={{
                        fontSize: "14px",
                        overflow: "auto",
                        maxHeight: "350px",
                        height: "80px",
                        "& .radio_label_wrap": {
                            height: '22px',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: "14px",
                            letterSpacing: "0.17px"
                        }
                    }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", fontSize: "24px" }}>
                            <Box sx={{
                                "& .MuiInputBase-root": {
                                    borderRadius: "10px",
                                    fontSize: "20px",
                                },
                                "&>.MuiButtonBase-root": {
                                    borderRadius: "10px",
                                    fontSize: "24px",
                                    backgroundColor: "#c4c4c4",
                                    "&:hover": {
                                        backgroundColor: "#c4c4c4",
                                    },
                                },
                                "& .MuiFormLabel-root": {
                                    fontSize: "20px",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#c4c4c4",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    "&.Mui-focused": {
                                        color: "#c4c4c4",
                                    },
                                },
                            }}>
                                Refresh Shopify token to continue
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                        <Box sx={{
                            backgroundColor: "#61C454",
                            width: "245px",
                            height: "38px",
                            padding: '6px 16px',
                            borderRadius: '10px',
                            color: "#FFF",
                            fontSize: "20px",
                            fontWeight: "600",
                            letterSpacing: "0.4px",
                            textTransform: "uppercase",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }} onClick={() => {
                            if (shopName == null || shopName.length == 0) {
                                return;
                            }
                            localStorage.setItem("shopName", shopName);
                            // Need window href to reload cookie.
                            const URL = `https://${shopName}/admin/oauth/authorize?client_id=${ShopifyAppKey}&scope=${AuthScopes}&redirect_uri=https://${window.location.hostname}/shopifyAuth&state=43758342758d`;
                            // &grant_options%5B%5D=per-user
                            window.location = URL;
                        }}>REFRESH</Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}