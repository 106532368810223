import React, { useEffect } from "react";
import { useState, useContext, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Context } from "../Context/Context";
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Carousel, Radio } from 'antd';
import Typography from '@mui/material/Typography';

const ADD_PRODUCT_LIST_FROM_URL = gql`
  mutation Mutation($aliExpressProductURL: String) {
    addProductListFormURL(aliExpressProductURL: $aliExpressProductURL) {
        status
        message
    }
  }
`;

export default function ImportAliExpressURL() {
    const { state, setState } = useContext(Context);
    const { isOpenImportAliExpressURLDialog, handleRefetchMyProductsCount, sendLog, handleRefetchProductList } = state;
    const [isLoading, setIsLoading] = useState(false);
    const [add_product_list_from_url] = useMutation(ADD_PRODUCT_LIST_FROM_URL);
    const [inputURL, setInputURL] = useState('');
    const [isAddSuccess, setIsAddSuccess] = useState(false);
    const [isShowExampleURL, setIsShowExampleURL] = useState(false);
    const carouselComponent = useRef(null);
    const navigate = useNavigate();
    useEffect(() => {
        setInputURL('');
        setIsAddSuccess(false);
        setIsLoading(false);
    }, [isOpenImportAliExpressURLDialog]);
    return (
        <Dialog
            open={isOpenImportAliExpressURLDialog ? isOpenImportAliExpressURLDialog : false}
            onClose={() => {
                setState({
                    ...state,
                    isOpenImportAliExpressURLDialog: false
                });
                setIsShowExampleURL(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                "& .MuiPaper-root": {
                    maxHeight: "none",
                    maxWidth: "none",
                    borderRadius: '1rem',
                },
            }}
        >
            <DialogContent sx={{
                width: "80.3rem",
                height: "65.3rem",
                padding: '3.6rem 3.1rem 4rem',
                boxSizing: 'border-box',
            }} onClick={() => { setIsShowExampleURL(false); }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '2.6rem'
                }}>
                    <Box sx={{
                        fontWeight: '700',
                        height: '5.6rem',
                        lineHeight: '5.6rem',
                        fontSize: '3.4rem',
                        color: '#333333'
                    }}>Import By URL</Box>
                    {/* <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        cursor: 'pointer'
                    }} onClick={() => {
                        sendLog("DAILOG_IMPORTURL_CLICK_ALIEXPRESS_SKIP");
                        window.open('https://best.aliexpress.com/');
                    }}>
                        <Box sx={{ background: 'url("/img/ImportAliExpressURL_skip.png") no-repeat 0 0', backgroundSize: 'contain', width: '2.2rem', height: '2.3rem', marginRight: '1rem' }} />
                        <Box sx={{
                            color: '#FF1F11',
                            fontWeight: '400',
                            fontSize: '2.4rem',
                        }}>AliExpress</Box>
                    </Box> */}
                </Box>
                <Box sx={{
                    height: '13.8rem',
                    fontWeight: '400',
                    fontSize: '1.8rem',
                    lineHeight: '150%',
                    letterSpacing: '0.15px',
                    color: '#333333',
                    marginBottom: '3.2rem'
                }}>
                    <Box>When not find the desired product, you can import a product based on its URL</Box>
                    <Box>1. Click the button below to visit to the platform</Box>
                    <Box sx={{
                        display: 'flex',
                        position: 'relative'
                    }}>
                        2. Copy the product URL
                        <Box sx={{ background: 'url("/img/icon_arrow_solid_bottom.png") no-repeat 0 0', backgroundSize: 'contain', width: '2rem', height: '2rem', marginLeft: '1rem', marginTop: '0.4rem', cursor: 'pointer' }} onClick={(e) => {
                            e.stopPropagation();
                            setIsShowExampleURL(!isShowExampleURL);
                        }} />
                        {isShowExampleURL ? <Box sx={{
                            position: 'absolute',
                            top: '3rem',
                            left: "-1.6rem",
                            width: '77.6rem',
                            // height: '13.6rem',
                            height: '19.6rem',
                            background: "#FFF",
                            boxSizing: 'border-box',
                            padding: '1.7rem 2.2rem 1.4rem',
                            boxShadow: " 0px -1px 4px rgba(0, 0, 0, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.25)",
                            borderRadius: '1rem',
                            zIndex: '9',
                            "&>div": {
                                height: '3rem',
                                marginBottom: '0.3rem',
                                lineHeight: '3rem',
                                fontWeight: '400',
                                fontSize: '1.8rem',
                                letterSpacing: '0.15px',
                                color: '#333333',
                            }
                        }} onClick={(e) => { e.stopPropagation(); }}>
                            <Box>AliExpress Product URL: https://www.aliexpress.us/item/3256804886086905.html</Box>
                            <Box>Amazon Product URL: https://www.amazon.com/dp/B09MVMFCBV/ref</Box>
                            {/* <Box>Etsy Product URL: https://www.etsy.com/listing/1398335718</Box> */}
                            <Box>1688.com Product URL: https://detail.1688.com/offer/593316719690.html</Box>
                            <Box>Temu Product URL: https://www.temu.com/601099512342845.html</Box>
                        </Box> : null}
                    </Box>
                    <Box>3. Paste it into the input box below</Box>
                    <Box>4. Click "Import" to add the product to the "My Products" list</Box>
                </Box>
                <Box sx={{
                    height: '5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '2.2rem',
                    "&>div": {
                        width: '13rem',
                        height: '5rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0.5rem',
                        border: '1px solid #FF9711'
                    }
                }}>
                    <Box onClick={() => {
                        window.open("https://www.aliexpress.us/")
                    }}>
                        <Box sx={{ background: 'url("/img/importURL_logo_AliExpress.png") no-repeat 0 0', backgroundSize: 'contain', width: '12rem', height: '2.8rem', cursor: 'pointer' }} />
                    </Box>
                    {/* <Box onClick={() => {
                        window.open("https://www.etsy.com/")
                    }}>
                        <Box sx={{ background: 'url("/img/importURL_logo_Etsy.png") no-repeat 0 0', backgroundSize: 'contain', width: '6rem', height: '2.8rem', cursor: 'pointer' }} />
                    </Box> */}
                    <Box onClick={() => {
                        window.open("https://amazon.com/")
                    }}>
                        <Box sx={{ background: 'url("/img/importURL_logo_amazon.png") no-repeat 0 0', backgroundSize: 'contain', width: '8.8rem', height: '2.6rem', cursor: 'pointer' }} />
                    </Box>
                    <Box sx={{ width: '16.8rem!important' }} onClick={() => {
                        window.open("https://re.1688.com/")
                    }}>
                        <Box sx={{ background: 'url("/img/importURL_log_1688.png") no-repeat 0 0', backgroundSize: 'contain', width: '14.8rem', height: '2.8rem', cursor: 'pointer' }} />
                    </Box>
                    <Box onClick={() => {
                        window.open("https://www.temu.com/")
                    }}>
                        <Box sx={{ background: 'url("/img/importURL_logo_TEMU.png") no-repeat 0 0', backgroundSize: 'contain', width: '10.4rem', height: '2.6rem', cursor: 'pointer' }} />
                    </Box>
                </Box>
                <Box sx={{
                    height: '5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '3.2rem',
                    "&>div": {
                        width: '13rem',
                        height: '5rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0.5rem',
                        border: '1px solid #FF9711'
                    }
                }}>
                    <Box sx={{ overflow: 'hidden' }} onClick={() => {
                        window.open("https://www.shein.com/")
                    }}>
                        <Box sx={{ background: 'url("/img/importURL_logo_shein.jpg") no-repeat 0 0', backgroundSize: 'contain', width: '10rem', height: '8.8rem', cursor: 'pointer' }} />
                    </Box>
                </Box>
                <Box>
                    <TextField
                        id="outlined-basic"
                        sx={{
                            width: '74.1rem',
                            height: '5rem',
                            backgroundColor: '#F2F2F2',
                            borderRadius: '0.5rem',
                            color: 'rgba(153, 153, 153, 0.5)',
                            "& fieldset": {
                                border: '0',
                            },
                            "& .MuiInputBase-root input": {
                                fontSize: '1.8rem',
                                fontWeight: '400',
                                paddingTop: '0',
                                height: '5rem',
                                paddingBottom: '0',
                            },
                        }}
                        placeholder='Import Product By URL '
                        value={inputURL}
                        onChange={(e) => {
                            setInputURL(e.target.value);
                        }}
                    />
                </Box>
                {isLoading ? <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    "& .text": {
                        height: '3rem',
                        lineHeight: '3rem',
                        margin: '1.2rem 0',
                        color: '#666666',
                        fontWeight: '400',
                        fontSize: '2rem'
                    }
                }}>
                    <Box sx={{
                        flex: 1
                    }}>
                        <Carousel dotPosition={"left"} dots={false} ref={carouselComponent}>
                            <Box className="text">Please wait ...</Box>
                            <Box className="text">We found this product ...</Box>
                            <Box className="text">We have found this product image ...</Box>
                            <Box className="text">We have found the SKU for this product ...</Box>
                            <Box className="text">Importing ...</Box>
                        </Carousel>
                    </Box>
                </Box> : null}
                {isAddSuccess ? <Box sx={{ height: '3rem', lineHeight: '3rem', margin: '1.2rem 0', color: '#31A409', fontWeight: '700', fontSize: '2rem' }}>The product has been added to My Products tab.</Box> : <Box />}
                <Box sx={{ marginTop: isAddSuccess || isLoading ? '0' : '5.4rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{
                        width: '14.4rem',
                        height: '6.4rem',
                        borderRadius: '0.5rem',
                        backgroundColor: '#00DE9B',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        "@keyframes load": {
                            from: {
                                transform: "rotate(0deg)",
                            },
                            to: {
                                transform: "rotate(360deg)",
                            }
                        },
                    }} onClick={() => {
                        sendLog("DAILOG_IMPORTURL_CLICK_IMPORT_BUTTON");
                        if (isLoading) {
                            return;
                        }
                        if (inputURL.toLowerCase().includes('temu')) {
                            setState({
                                ...state,
                                openSnackbar: true,
                                isOpenImportAliExpressURLDialog: false,
                                snackbarText: <Box sx={{
                                    fontFamily: "Arial",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "150%",
                                    letterSpacing: "0.15px",
                                    color: "#333333",
                                }}>
                                    Attention: We are redirected to <Typography variant="span" sx={{ color: "#0072E5", cursor: "pointer", textDecoration: 'underline' }} onClick={() => {
                                        navigate("/extensionInstructions");
                                    }}>Chrome Extension</Typography> to import Temu products, because import Temu URL is temporarily suspended.
                                </Box>,
                                snackbarState: 'SUCCESS',
                            });
                            navigate("/extensionInstructions");
                            return;
                        }
                        setIsAddSuccess(false);
                        setIsLoading(true);
                        let carouselTimes = 0;
                        const times = setInterval(() => {
                            carouselTimes++;
                            if (carouselTimes === 5) {
                                clearInterval(times);
                            } else {
                                carouselComponent.current.next();
                            }
                        }, 3000);
                        add_product_list_from_url({
                            variables: {
                                aliExpressProductURL: inputURL
                            }
                        }).then((result) => {
                            if (result != null && result.data != null && result.data.addProductListFormURL != null) {
                                if (result.data.addProductListFormURL.status) {
                                    setIsAddSuccess(true);
                                    setIsLoading(false);
                                    clearInterval(times);
                                    setState({
                                        ...state,
                                        openSnackbar: true,
                                        snackbarText: 'The product is added to My Products tab.',
                                        snackbarState: 'SUCCESS',
                                    });
                                    if (handleRefetchMyProductsCount != null) {
                                        handleRefetchMyProductsCount();
                                    }
                                    if (handleRefetchProductList != null) {
                                        handleRefetchProductList();
                                    }
                                } else {
                                    clearInterval(times);
                                    if (result.data.addProductListFormURL.message === 'PLEASE_UPGRADE_PLAN') {
                                        setState({
                                            ...state,
                                            isOpenSelectPlanDialog: true,
                                            openSnackbar: false,
                                        });
                                    } else {
                                        setState({
                                            ...state,
                                            openSnackbar: true,
                                            snackbarText: inputURL.includes('temu') ? <Box sx={{
                                                fontFamily: "Arial",
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                                lineHeight: "150%",
                                                letterSpacing: "0.15px",
                                                color: "#333333",
                                            }}>
                                                Sorry, please use <Typography variant="span" sx={{ color: "#0072E5", cursor: "pointer", textDecoration: 'underline' }} onClick={() => {
                                                    navigate("/extensionInstructions");
                                                    setState({
                                                        ...state,
                                                        isOpenImportAliExpressURLDialog: false
                                                    })
                                                }}>Chrome Extension</Typography> to import Temu products.
                                            </Box> : (result.data.addProductListFormURL.message == "SOLD_OUT" ? "The product is sold out." : 'Sorry, we could not find the product based on this URL.'),
                                            snackbarState: 'FAILED',
                                        });
                                    }
                                    setIsLoading(false);
                                }
                            }
                        }).catch((e) => {
                            setIsLoading(false);
                            clearInterval(times);
                            setState({
                                ...state,
                                openSnackbar: true,
                                snackbarText: inputURL.includes('temu') ? <Box sx={{
                                    fontFamily: "Arial",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "150%",
                                    letterSpacing: "0.15px",
                                    color: "#333333",
                                }}>
                                    Sorry, please use <Typography variant="span" sx={{ color: "#0072E5", cursor: "pointer", textDecoration: 'underline' }} onClick={() => {
                                        navigate("/extensionInstructions");
                                        setState({
                                            ...state,
                                            isOpenImportAliExpressURLDialog: false
                                        })
                                    }}>Chrome Extension</Typography> to import Temu products.
                                </Box> : 'This item is time-out because of too many SKU. Try a different product.',
                                snackbarState: 'FAILED',
                            });
                        });
                    }}>
                        {isLoading ? <Box sx={{
                            animation: 'load 1.5s infinite',
                            width: '2.5rem',
                            height: '2.5rem'
                        }}>
                            <img src='/img/ImportAliExpressURL_loading.png' alt="loading" width={'100%'} height={"100%"} />
                        </Box> : <Box sx={{ fontWeight: '700', color: '#FFF', fontSize: '2rem' }} >
                            Import
                        </Box>}
                    </Box>

                    {isAddSuccess ? <Box sx={{
                        width: '11.0rem',
                        height: '6.4rem',
                        borderRadius: '0.5rem',
                        backgroundColor: '#FF9711',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: '700',
                        color: '#FFF',
                        fontSize: '2rem',
                        cursor: 'pointer'
                    }} onClick={() => {
                        sendLog("DAILOG_IMPORTURL_CLICK_DONE_BUTTON");
                        setState({
                            ...state,
                            isOpenImportAliExpressURLDialog: false
                        });
                        navigate("/myProducts");
                    }}>
                        Done
                    </Box> :
                        <Box sx={{
                            height: '4.8rem',
                            lineHeight: '4.8rem',
                            color: '#FF9711',
                            display: 'flex',
                            cursor: 'pointer',
                            fontWeight: '700',
                            fontSize: '3.2rem',
                        }} onClick={() => {
                            sendLog("IMPORTURL_VIDEO_CLICK");
                            setState({
                                ...state,
                                isExplanatoryVideoDialogOpen: true,
                                explanatoryVideoSrc: '/img/tutorial/part1.mp4'
                            })
                        }}>
                            <Box sx={{ background: 'url("/img/blank_page_player.png") no-repeat 0 0', backgroundSize: 'contain', width: '5rem', height: '5rem', marginRight: '1.5rem' }} />
                            <Box>How to add products</Box>
                        </Box>}
                </Box>
            </DialogContent>
        </Dialog>
    )
}