import React, { useEffect } from 'react';
import { gql, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const GET_SAVE_GOOGLE_ACCESS_TOKEN = gql`
query saveGoogleToken($code: String) {
    saveGoogleToken(code: $code)
}
`;
const queryParams = new URLSearchParams(window.location.search);
const code = queryParams.get('code');

export default function GoogleInstall() {
    const navigate = useNavigate();
    const [isSuccess, setIsSuccess] = React.useState(null);

    useEffect(() => {
        if (isSuccess === true) {
            navigate('/app?googleInstall=1');
        } else if (isSuccess === false) {
            navigate('/app');
        }
    }, [isSuccess]);

    if (code != null) {
        const { data } = useQuery(GET_SAVE_GOOGLE_ACCESS_TOKEN, {
            variables: {
                code: String(code),
            },
            notifyOnNetworkStatusChange: true,
        });

        if (data != null && data.saveGoogleToken != null) {
            if (data.saveGoogleToken === true && isSuccess == null) {
                setIsSuccess(true);
            } else if (isSuccess == null) {
                setIsSuccess(false);
            }
        }
    }

    return (
        <div>
            <Box sx={{
                height: "40rem",
                marginTop: 10,
                display: "flex",
                justifyContent: "center",
            }}
            >
                <CircularProgress />
            </Box>
        </div>
    );
}
