/** @format */

import React from "react";
import "../App.css";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { useState, lazy, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import {
  gradeSelectedArray,
  percentageSelectedArray,
  sortByList,
} from "../utils/CategoryList";
import CardList from "./CardList.react";
import { Helmet } from "react-helmet";
import { Context } from "../Context/Context";
import CategorySelector from "../CategorySelector.react";
import { gql, useQuery } from "@apollo/client";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import Drawer from '@mui/material/Drawer';
import { handleCheckNumber } from "../utils/HelperFunction.js";

const AppHeader = lazy(() => import("../AppHeader.react"));
const AppSidebar = lazy(() => import("../AppSidebar.react"));

const CustomSelected = lazy(() =>
  import("../CommonComponents/CustomSelected.react")
);


const GET_IS_SURVEY_COMPLETED = gql`
  query getIsSurveyCompleted {
    getIsSurveyCompleted
  }
`;

const GET_API_CURRENCIES = gql`
  query getAccountCurrencies {
    getAccountCurrencies
  }
`;
const ButtonTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement={placement ? placement : "bottom"} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
    fontSize: "20px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    color: "#FFF",
    maxWidth: '260px',
    fontSize: "12px",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 1px rgb(0 0 0 / 12%)",
  },
}));
const getButtonTooltipLayout = (title) => {
  return <React.Fragment>
    <Box sx={{
      color: '#FFF',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '150%'
    }}>
      {title}
    </Box>
  </React.Fragment>
}
export default function AppSection(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const subscriptionStatus = queryParams.get("subscriptionStatus");
  const searchTitle = queryParams.get("search");
  const pageIndex = queryParams.get("pageIndex") != null ? Number(queryParams.get("pageIndex")) : 1;
  const isSurveyCompleted = localStorage.getItem("isSurveyCompleted");
  // const [category, setCategory] = React.useState('');
  const [sort, setSort] = React.useState(queryParams.get("sortBy") != null ? queryParams.get("sortBy") : "default");
  const [isOpenFilter, setIsOpenFilter] = React.useState(false);
  const [minPrice, setMinPrice] = useState(queryParams.get("minPrice") != null ? queryParams.get("minPrice") : "");
  const [maxPrice, setMaxPrice] = useState(queryParams.get("maxPrice") != null ? queryParams.get("maxPrice") : "");
  const [minPriceValue, setMinPriceValue] = useState(queryParams.get("minPrice") != null ? queryParams.get("minPrice") : "");
  const [maxPriceValue, setMaxPriceValue] = useState(queryParams.get("maxPrice") != null ? queryParams.get("maxPrice") : "");
  const [currentCategory, setCurrentCategory] = useState(queryParams.get("currentCategory") != null ? queryParams.get("currentCategory") : 'All Categories');




  const [category, setCategory] = useState(queryParams.get("category") != null ? queryParams.get("category") : "all");
  const [sortBy, setSortBy] = useState(queryParams.get("sortBy") != null ? queryParams.get("sortBy") : "relevance");
  const [minOrders, setMinOrders] = useState(queryParams.get("minOrders") != null ? queryParams.get("minOrders") : "");
  const [maxOrders, setMaxOrders] = useState(queryParams.get("maxOrders") != null ? queryParams.get("maxOrders") : "");
  const [minReviewScore, setMinReviewScore] = useState(queryParams.get("minReviewScore") != null ? queryParams.get("minReviewScore") : "4.0");
  const [maxReviewScore, setMaxReviewScore] = useState(queryParams.get("maxReviewScore") != null ? queryParams.get("maxReviewScore") : "5.0");
  const [minItemAsDescribed, setMinItemAsDescribed] = useState(queryParams.get("minItemAsDescribed") != null ? queryParams.get("minItemAsDescribed") : "4.0");
  const [maxItemAsDescribed, setMaxItemAsDescribed] = useState(queryParams.get("maxItemAsDescribed") != null ? queryParams.get("maxItemAsDescribed") : "5.0");
  const [minCommunication] = useState(queryParams.get("minCommunication") != null ? queryParams.get("minCommunication") : "");
  const [maxCommunication] = useState(queryParams.get("maxCommunication") != null ? queryParams.get("maxCommunication") : "");
  const [minFeedbackRate, setMinFeedbackRate] = useState(queryParams.get("minFeedbackRate") != null ? queryParams.get("minFeedbackRate") : "90");
  const [maxFeedbackRate, setMaxFeedbackRate] = useState(queryParams.get("maxFeedbackRate") != null ? queryParams.get("maxFeedbackRate") : "100");
  const [minShippingSpeed, setMinShippingSpeed] = useState(queryParams.get("minShippingSpeed") != null ? queryParams.get("minShippingSpeed") : "4.0");
  const [maxShippingSpeed, setMaxShippingSpeed] = useState(queryParams.get("maxShippingSpeed") != null ? queryParams.get("maxShippingSpeed") : "5.0");
  const [hasVideo] = useState(false);
  const [shipFromUS, setShipFromUS] = useState(queryParams.get("shipFromUS") === "true" ? true : false);
  const [shipDay, setShipDay] = useState(queryParams.get("shipDay") != null ? queryParams.get("shipDay") : "100");
  const [isFreeShip, setIsFreeShip] = useState(queryParams.get("isFreeShip") === "true" ? true : false);
  const [isOpenSubscriptionSuccessTips, setIsOpenSubscriptionSuccessTips] = useState(false);
  const [currentAPICurrency, setCurrentAPICurrency] = useState("USD");

  const [subscriptionTips, setSubscriptionTips] = useState('');

  const { setState, state } = React.useContext(Context);
  const { sendLog } = state;
  const { loading, data, refetch } = useQuery(GET_IS_SURVEY_COMPLETED, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  const { loading: getAccountCurrenciesLoading, data: getAccountCurrenciesData } = useQuery(GET_API_CURRENCIES, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  // Show sign up if user has stayed on web for 1 second.
  useEffect(() => {
    if (state.firstName == null) {
      setState({
        ...state,
        isAccountPopUpDialogOpen: true,
        currentComponentName: "SIGN_UP",
      });
    }

    if (subscriptionStatus !== null) {
      if (isSurveyCompleted == "false") {
        // setIsOpenSubscriptionSuccessTips(true);
        // setSubscriptionTips(subscriptionStatus === '1' ? 'Monthly' : 'Yearly');
        // setTimeout(() => {
        //   setIsOpenSubscriptionSuccessTips(false);
        // }, 2000);
        setState({
          ...state,
          isLoginQuestionnaireOpen: true,
        })
      } else if (state.shopName != null && localStorage.getItem("IsShowSwitchAccountDialog") != "false") {
        setState({
          ...state,
          //isOpenSwitchAccountDialog: true,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (getAccountCurrenciesLoading == false && getAccountCurrenciesData != null) {
      setCurrentAPICurrency(getAccountCurrenciesData.getAccountCurrencies);
    }
  }, [getAccountCurrenciesLoading]);

  useEffect(() => {
    if (loading == false && data != null) {
      if (data.getIsSurveyCompleted == false) {
        localStorage.setItem("isSurveyCompleted", "false");
      } else {
        localStorage.setItem("isSurveyCompleted", "true");
      }
    }
  }, [loading]);

  const fontStyle = {
    fontFamily: "Arial",
    fontStyle: "normal",
  };
  const filterItem = {
    height: "11.8rem",
    background: "#FFFFFF",
    boxShadow: "0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)",
    borderRadius: "1rem",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#333333",
    paddingTop: "1rem",
  };
  const selectBoxStyle = {
    height: "5rem",
    margin: " 1.3rem 2.5rem",
    borderRadius: "1rem",
    border: "1px solid rgba(112, 112, 112, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  };

  let title, description;

  switch (props.currentPopup) {
    case "SignUp":
      title = "Sign Up";
      description = "Sign up as a Cartifind user, and enjoy our free service.";
      break;
    case "SignIn":
      title = "Sign In";
      description = "Sign in your Cartifind account, and enjoy our free service.";
      break;
    default:
      title = "Cartifind: Shopify dropshipping products";
      description =
        "Automate your Shopify store with affordable and high-quality products from China.  Keep your dropshipping business thriving with AliExpress products.";
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {isOpenSubscriptionSuccessTips ? <Box sx={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: '#32323299',
        display: 'flex',
        justifyContent: 'center',
        zIndex: '99999'
      }} onClick={() => {
        setIsOpenSubscriptionSuccessTips(false);
      }}>
        <Box sx={{
          marginTop: '42rem',
          height: '12.5rem',
          borderRadius: '1rem',
          padding: '4rem',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#FFFFFF',
          fontWeight: '600',
          fontSize: '24px',
          color: '#333333'
        }}>
          <Box sx={{ width: '3rem', height: '3rem', background: 'url("img/subscription_success.png") no-repeat 0 0', backgroundSize: 'contain', marginRight: '2rem' }}></Box>
          <Box>Upgrade to Cartifind Pro {subscriptionTips} plan successfully</Box>
        </Box>
      </Box> : null}
      <div className="App">
        <AppHeader />
        <AppSidebar />
        <Box sx={{ display: "flex" }}>
          <Drawer
            anchor="right"
            open={isOpenFilter}
            onClose={() => {
              setIsOpenFilter(false);
            }}
          >
            <Box sx={{
              width: '300px',
              padding: '28px 72px 28px 24px',
              height: '100%',
              "& .MuiInputLabel-root": {
                fontSize: '16px',
                height: '20px',
                lineHeight: '16px',
                color: "rgba(0, 0, 0, 0.6)!important",
                "&.Mui-focused": {
                  fontSize: '16px',
                  lineHeight: '16px',
                  color: "rgba(0, 0, 0, 0.6)!important",
                },
              },
              "& .MuiSvgIcon-root": {
                fontSize: '28px',
              },
              "& fieldset": {
                fontSize: '14px',
                borderColor: "rgba(0, 0, 0, 0.23)!important",
                "&.Mui-focused": {
                  borderColor: "rgba(0, 0, 0, 0.23)!important",
                },
              }
            }}>
              <Box sx={{ width: 'auto', display: 'flex', alignItems: 'center', fontSize: '14px', letterSpacing: '0.46px', color: '#FF9711', cursor: 'pointer' }} onClick={() => {
                setIsOpenFilter(false);
              }}>
                <Box sx={{ transform: 'rotate(-45deg)', fontSize: "30px", fontWeight: '200', marginRight: '10px' }}>+</Box>
                <Box>CLOSE</Box>
              </Box>
              <Box sx={{
                fontSize: "14px",
                width: "300px",
                height: "40px",
                border: "1px solid rgba(112, 112, 112, 0.7)",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
                marginTop: "20px"
              }}>
                <CategorySelector setSelectedValue={setCategory} option={currentCategory} setSelectedOption={setCurrentCategory} />
              </Box>
              <Box sx={{ marginTop: "20px" }}>
                <Box sx={{
                  width: "300px",
                  height: "48px",
                  lineHeight: "48px",
                  fontSize: "14px",
                  letterSpacing: "0.1px",
                  paddingLeft: "16px"
                }}>Price Range ({currentAPICurrency})</Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: "center",
                  gap: "10px"
                }}>
                  <Box sx={{
                    width: "73px",
                    height: "42px",
                    lineHeight: "42px",
                    fontSize: "14px",
                    letterSpacing: "0.1px",
                  }}>Min Price</Box>
                  <Box sx={{
                    width: "105px",
                    height: "30px",
                    border: "1px solid #0000003B",
                    borderRadius: "4px"
                  }}>
                    <TextField
                      focused
                      onChange={(e) => {
                        setMinPriceValue(e.target.value);
                      }}
                      onBlur={(e) => {
                        const number = e.target.value != null && e.target.value.length > 0 ? handleCheckNumber(e.target.value) : "";
                        if ((maxPriceValue) != null && (String(maxPriceValue).length > 0) && (number > maxPriceValue)) {
                          setMinPriceValue(maxPriceValue);
                          setMinPrice(maxPriceValue);
                        } else {
                          setMinPriceValue(number);
                          setMinPrice(number);
                        }
                      }}
                      value={minPriceValue}
                      sx={{
                        paddingRight: "0",
                        width: "90px",
                        height: "30px",
                        backgroundColor: "#FFF",
                        borderRadius: "10px 0 0 10px",
                        "& .MuiInputBase-root": {
                          "&.MuiOutlinedInput-root input": {
                            textIndent: '8px',
                            height: "30px",
                            fontSize: '14px',
                            padding: 0
                          },
                        },
                        "& .MuiOutlinedInput-root": {
                          border: "none",
                          "&.Mui-focused fieldset": {
                            borderRadius: "10px 0 0 10px",
                            border: "none",
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: "center",
                  gap: "10px"
                }}>
                  <Box sx={{
                    width: "73px",
                    height: "42px",
                    lineHeight: "42px",
                    fontSize: "14px",
                    letterSpacing: "0.1px",
                  }}>Max Price</Box>
                  <Box sx={{
                    width: "105px",
                    height: "30px",
                    border: "1px solid #0000003B",
                    borderRadius: "4px"
                  }}>
                    <TextField
                      focused
                      onChange={(e) => {
                        setMaxPriceValue(e.target.value);
                      }}
                      onBlur={(e) => {
                        const number = e.target.value != null && e.target.value.length > 0 ? handleCheckNumber(e.target.value) : "";
                        if ((minPriceValue != null) && (String(minPriceValue).length > 0) && (number < minPriceValue)) {
                          setMaxPriceValue(minPriceValue);
                          setMaxPrice(minPriceValue);
                        } else {
                          setMaxPriceValue(number);
                          setMaxPrice(number);
                        }
                      }}
                      value={maxPriceValue}
                      sx={{
                        paddingRight: "0",
                        width: "90px",
                        height: "30px",
                        backgroundColor: "#FFF",
                        borderRadius: "10px 0 0 10px",
                        "& .MuiInputBase-root": {
                          "&.MuiOutlinedInput-root input": {
                            textIndent: '8px',
                            height: "30px",
                            fontSize: '14px',
                            padding: 0
                          },
                        },
                        "& .MuiOutlinedInput-root": {
                          border: "none",
                          "&.Mui-focused fieldset": {
                            borderRadius: "10px 0 0 10px",
                            border: "none",
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
          <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
          <Box sx={{ display: "flex", width: "100%", justifyContent: 'center', position: 'relative' }}>
            <Box sx={{ minWidth: "calc(100% - 36px)", padding: "16px 16px 20px 20px" }}>
              <Box sx={{
                width: "100%",
                // minWidth: "1200px",
                height: "84px",
                backgroundColor: "#FFF",
                borderRadius: '4px',
                boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                "& .MuiInputLabel-root": {
                  fontSize: '16px',
                  lineHeight: '16px',
                  color: "rgba(0, 0, 0, 0.6)!important",
                  "&.Mui-focused": {
                    fontSize: '16px',
                    lineHeight: '16px',
                    color: "rgba(0, 0, 0, 0.6)!important",
                  },
                },
                "& .MuiSvgIcon-root": {
                  fontSize: '28px',
                },
                "& fieldset": {
                  fontSize: '14px',
                  borderColor: "rgba(0, 0, 0, 0.23)!important",
                  "&.Mui-focused": {
                    borderColor: "rgba(0, 0, 0, 0.23)!important",
                  },
                },
              }}>
                <Box sx={{
                  padding: '10px 10px 10px',
                  height: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  "&>div": {
                    height: '48px'
                  }
                }}>
                  <Box sx={{
                    fontSize: "14px",
                    width: "300px",
                    height: "40px",
                    border: "1px solid rgba(112, 112, 112, 0.7)",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "relative",
                  }}>

                    <CategorySelector setSelectedValue={setCategory} option={currentCategory} setSelectedOption={setCurrentCategory} />
                  </Box>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    "&>div": {
                      height: '48px'
                    }
                  }}>
                    <Box sx={{ width: '160px' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          sx={{
                            "& .MuiSelect-select.MuiInputBase-input": {
                              padding: '0px',
                              paddingLeft: '10px',
                              height: '48px',
                              display: 'flex',
                              alignItems: 'center',
                              overflow: "hidden",
                              fontSize: '14px'
                            }
                          }}
                          value={sort}
                          label="Sort"
                          onChange={(e) => {
                            setSort(e.target.value);
                          }}
                        >
                          <MenuItem value='default' sx={{ fontSize: '14px' }}>Default</MenuItem>
                          <MenuItem value="latest" sx={{ fontSize: '14px' }}>Latest</MenuItem>
                          <MenuItem value="priceAsc" sx={{ fontSize: '14px' }}>Price: Low to High</MenuItem>
                          <MenuItem value="priceDesc" sx={{ fontSize: '14px' }}>Price: High to Low</MenuItem>
                          <MenuItem value="salesDesc" sx={{ fontSize: '14px' }}>Sales: High to Low</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <ButtonTooltip title={getButtonTooltipLayout("More filters")} arrow>
                      <Box sx={{ width: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {
                        setIsOpenFilter(true);
                      }}>
                        <TuneOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '28px', cursor: 'pointer' }} />
                      </Box>
                    </ButtonTooltip>
                  </Box>
                </Box>
              </Box>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <Box>
                  <CardList
                    category={category}
                    currentCategory={currentCategory}
                    sortBy={sort}
                    minOrders={minOrders}
                    maxOrders={maxOrders}
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                    minReviewScore={minReviewScore}
                    maxReviewScore={maxReviewScore}
                    minFeedbackRate={minFeedbackRate}
                    maxFeedbackRate={maxFeedbackRate}
                    minItemAsDescribed={minItemAsDescribed}
                    maxItemAsDescribed={maxItemAsDescribed}
                    minShippingSpeed={minShippingSpeed}
                    maxShippingSpeed={maxShippingSpeed}
                    minCommunication={minCommunication}
                    maxCommunication={maxCommunication}
                    hasVideo={hasVideo}
                    shipFromUS={shipFromUS}
                    shipDay={shipDay}
                    isFreeShip={isFreeShip}
                    titleSearch={searchTitle}
                    currentPageIndex={pageIndex}
                  />

                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
}
