import React from 'react'
import AppHeader from './AppHeader.react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


export default function AboutUs() {
    return (
        <div className="App">
            <AppHeader />
            <Container maxWidth="md">
                <Box sx={{
                    marginTop: 5,
                }}>
                    <Typography variant="body1">
                        HFF Products is to enable small and medium businesses and dropshippers to succeed.
                        We provide services including aliexpress, alibaba, 1688 sourcings, and automated ads solutions.
                        The startup is based in Beijing, China, and founded by team from Meta (Facebook), and Tiktok.
                    </Typography>
                    <Typography sx={{
                        marginTop: 5,
                    }} variant="h6">
                        <a href="mailto: yinghechen1993@163.com">Provide feedbacks: yinghechen1993@163.com</a>
                    </Typography>
                </Box>
            </Container>
        </div>
    );
}

