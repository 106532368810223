
export function isInExperiment(userID = '0', userIDEndList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']) {
    const storePlatform = localStorage.getItem("storePlatform");
    if (storePlatform == "wix") {
        return false;
    }
    if (userID == null) {
        return true;
    }
    const userIDStr = String(userID);
    return userIDEndList.some(item => userIDStr.charAt(userIDStr.length - 1) == item || (Number(userID) == 179 || Number(userID) == 220 || Number(userID) == 356));
}

export function handleCheckNumber(value) {
    let str = value.replace(/[^\d^\.]+/g, '');
    const len1 = str.substr(0, 1);
    const len2 = str.substr(1, 1);

    //如果第一位是0，第二位不是点，就用数字把点替换掉
    if (str.length > 1 && len1 == 0 && len2 != '.') {
        str = str.substr(1, 1);
    }

    //第一位不能是.
    if (len1 == '.') {
        str = '';
    }

    //限制只能输入一个小数点
    if (str.indexOf(".") != -1) {
        const str_ = str.substr(str.indexOf(".") + 1);
        //限制只能输入一个小数点
        if (str_.indexOf(".") != -1) {
            str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
    }

    return Number(str);
}
