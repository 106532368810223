import React from "react";
import { Suspense, lazy } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Context } from "../Context/Context";
import { Box } from "@mui/system";

const ConnectShopify = lazy(() =>
    import("../SignInComponents/ConnectShopify.react")
);
const SignIn = lazy(() => import("../SignInComponents/SignIn.react"));
const SignUp = lazy(() => import("../SignInComponents/SignUp.react"));
const ResetUserPassword = lazy(() =>
    import("../SignInComponents/ResetUserPassword.react")
);

export default function AccountPopUpDialog() {
    const { state, setState } = React.useContext(Context);
    const { isAccountPopUpDialogOpen, currentComponentName } = state;
    let currentComponent;
    switch (currentComponentName) {
        case 'SIGN_IN':
            currentComponent = (<Suspense fallback={<Box />}>
                <SignIn />
            </Suspense>);
            break;
        case 'SIGN_UP':
            currentComponent = (<Suspense fallback={<Box />}>
                <SignUp />
            </Suspense>);
            break;
        case 'RESET_USER_PASSWORD':
            currentComponent = (<Suspense fallback={<Box />}>
                <ResetUserPassword />
            </Suspense>);
            break;
        case 'CONNECT_SHOPIFY':
            currentComponent = (<Suspense fallback={<Box />}>
                <ConnectShopify />
            </Suspense>);
            break;
        default:
            currentComponent = (<Suspense fallback={<Box />}>
                <SignIn />
            </Suspense>);
    }
    return (
        <Dialog
            open={isAccountPopUpDialogOpen ? isAccountPopUpDialogOpen : false}
            onClose={() => {
                setState({
                    ...state,
                    isAccountPopUpDialogOpen: false
                });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                "& .MuiPaper-root": {
                    maxHeight: "none",
                    maxWidth: "none",
                    border: '0.6rem solid #FF9711',
                    borderRadius: '1rem',
                },
            }}
        >
            <DialogContent
                sx={{
                    width: "74.2rem",
                    height: "80rem",
                    padding: '0'
                }}
            >
                {currentComponent}
            </DialogContent>
        </Dialog>
    )
}