import React from 'react';
import { checkHmacValidity } from 'shopify-hmac-validation';
import { ShopifyAppKey, ShopifyAppSecret, AuthScopes } from './ShopifyConstants';
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const queryParams = new URLSearchParams(window.location.search);
const code = queryParams.get('code');
const hmac = queryParams.get('hmac');
const shop = queryParams.get('shop'); //testhffproducts.myshopify.com

export default function ShopifyInstall() {
    if (code == null && hmac != null && shop != null) {
        // Need to conduct security check here
        // grant_options%5B%5D=per-user
        const HmacValidation = checkHmacValidity(ShopifyAppSecret, window.location.search);
        if (HmacValidation) {
            const url = `https://${shop}/admin/oauth/authorize?client_id=${ShopifyAppKey}&scope=${AuthScopes}&redirect_uri=https://cartifind.com/shopifyRedirect&state=43758342758d`;
            window.location.href = url;
            return;
        } else {
            //return 401;
            const url = `https://${shop}/admin/oauth/authorize?client_id=${ShopifyAppKey}&scope=${AuthScopes}&redirect_uri=https://cartifind.com/shopifyRedirect&state=43758342758d`;
            window.location.href = url;
            return;
        }
    };

    return (
        <Box sx={{
            height: "40rem",
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
        }}
        >
            <CircularProgress />
        </Box>
    );
};