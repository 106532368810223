// @flow
import React, { useContext } from "react";
import { gql, useQuery } from '@apollo/client';
import { Context } from "/home/ubuntu/hff/src/Context/Context";
import { setCookie } from "doc-cookie";

const SIGN_IN_BY_USER_TOKEN = gql`
query Query($userToken: String) {
    signInByUserToken(userToken: $userToken) {
        firstName
        lastName
        password
        email
        shopifyToken
        shopName
        token
        shipToCountry
        adjustedEmail
    }
  }
  
`;

export default function ExternalAppRedirect() {
    const queryParams = new URLSearchParams(window.location.search);
    const userToken = queryParams.get('userToken');
    const [redirectLink, setRedirectLink] = React.useState(null);
    React.useEffect(() => {
        if (redirectLink != null) {
            window.location = redirectLink;
        }
    }, [redirectLink]);

    if (userToken != null && userToken.length > 0) {
        const variables = {
            userToken: String(decodeURIComponent(userToken)),
        };
        const { data } = useQuery(SIGN_IN_BY_USER_TOKEN, {
            variables,
            // notifyOnNetworkStatusChange: true,
        });

        if (data != null && data.signInByUserToken !== null) {
            // Success saved shopify token, thus login to account
            const { setState } = useContext(Context);
            const { firstName, shopifyToken, email, token, shipToCountry, adjustedEmail, shopName } = data.signInByUserToken;
            localStorage.setItem("firstName", firstName);
            localStorage.setItem("shopifyToken", shopifyToken);
            localStorage.setItem("email", email);
            localStorage.setItem("shopName", String(shopName));
            localStorage.setItem("storePlatform", "shopify");
            localStorage.setItem("shipToCountry", shipToCountry);
            // adjustedEmail: shopify account email
            localStorage.setItem("adjustedEmail", adjustedEmail);
            setState({
                firstName,
                shopifyToken,
                email,
                shopName: String(shopName),
                storePlatform: "shopify",
                shipToCountry,
                adjustedEmail
            });
            setCookie({
                key: "token",
                value: token,
                "Max-Age": 604800, // seconds
            });

            // Check enviroment to prevent infinite reloading in shopify mobile app.
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                if (redirectLink == null) {
                    setRedirectLink("/app?subscriptionStatus=1");
                }
            } else {
                if (redirectLink == null) {
                    setRedirectLink('/app?subscriptionStatus=1');
                }
            }
        }
        return;
    };

    return <div />;

};