export default function GetCurrencySymbol(currency) {
    switch (currency) {
        case "USD":
            return "$";
        case "CNY":
            return "￥";
        case "GBP":
            return "£";
        case "JPY":
            return "Ұ";
        case "AUD":
            return "A$";
        case "EUR":
            return "€";
        case "CAD":
            return "C$";
        case "CZK":
            return "Kč";
        case "DKK":
            return "kr";
        case "HKD":
            return "HK$";
        case "NZD":
            return "NZ$";
        case "SGD":
            return "S$";
        case "SEK":
            return "kr";
        case "CHF":
            return "CHF";
        case "PLN":
            return "zł";
        case "KWD":
            return "KWD";
        case "SAR":
            return "SAR";
        default:
            return currency;
    }
}