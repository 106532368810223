// @flow
import React from 'react';
import AppHeader from './AppHeader.react';
import Box from '@mui/material/Box';
import AppFooter from "./AppFooter.react";
import { useEffect, useState } from 'react';


export default function Blog() {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const queryParams = new URLSearchParams(window.location.search);
    const tabIndex = queryParams.get("tabIndex");
    useEffect(() => {
        setCurrentTabIndex(Number(tabIndex));
    }, [tabIndex]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="App">
            <AppHeader />
            <Box sx={{ fontSize: '3.6rem', fontWeight: '700', fontFamily: 'Arial', color: "#333333", textAlign: 'center', marginTop: '2rem', marginBottom: '2rem' }}>BLOG</Box>
            <Box sx={{
                fontSize: '3.2rem',
                fontWeight: '400',
                fontFamily: 'Arial',
                color: "#333333",
                marginLeft: '12.5rem',
                display: 'flex',
                alignItems: 'center',
                '& .item': {
                    marginRight: '12.5rem',
                    fontSize: '3.2rem',
                    fontWeight: '600',
                    fontFamily: 'Nunito',
                    cursor: 'pointer'
                },
            }}>
                <Box className='item' sx={{ color: currentTabIndex === 0 ? '#333333' : '#666666' }} onClick={() => { setCurrentTabIndex(0) }}>Dropshipping</Box>
                <Box className='item' sx={{ color: currentTabIndex === 1 ? '#333333' : '#666666' }} onClick={() => { setCurrentTabIndex(1) }}>Guide</Box>
                <Box className='item' sx={{ color: currentTabIndex === 2 ? '#333333' : '#666666' }} onClick={() => { setCurrentTabIndex(2) }}>Marketing Tips</Box>
            </Box>
            <Box sx={{ width: '100%', height: '0.1rem', backgroundColor: '#000000', margin: '4rem 0 2rem' }}></Box>
            <Box sx={{
                marginLeft: '12.5rem',
                height: '150rem',
                '&>div': {
                    width: '167rem'
                },
                '& .content': {
                    fontSize: '2rem',
                    fontWeight: '400',
                    color: '#333333',
                    lineHeight: '150%'
                },
                '& .title': {
                    fontSize: '2.6rem',
                    fontWeight: '600',
                    color: '#333333',
                    lineHeight: '150%'
                },
                '& ul,& li': {
                    margin: '0',
                    padding: '0'
                },
                '& ul': {
                    paddingLeft: '16px'
                },

            }}>
                {currentTabIndex === 0 ? <Box sx={{
                    '&>div': {
                        width: '167rem'
                    },
                }}>
                    <Box className='content'>Dropshipping is a retail fulfillment method where a store doesn't keep the products it sells in stock. Instead, when a store sells a  product, it purchases the item from a third party and has it shipped directly to the customer. As a result, the merchant never sees or handles the product.
                    </Box>
                    <Box sx={{ marginTop: '3rem', marginBottom: '1.5rem' }} className='title'>How Does Dropshipping Work?</Box>
                    <Box sx={{ width: '167rem', height: '26.8rem', }}>
                        <img src='img/Blog_Dropshipping.png' alt='Dropshipping' width='100%' />
                    </Box>
                    <Box sx={{ marginTop: '3rem', }} className='title'>Dropshipping works like this:</Box>
                    <Box className='content'>
                        <ul>
                            <li>You set up an online store and list products for sale.</li>
                            <li>A customer orders a product from your store.</li>
                            <li>You forward the order to a third-party supplier (the "dropshipper") who has the product in stock.</li>
                            <li>The dropshipper ships the product directly to the customer.</li>
                            <li>You keep the difference between the price you charged the customer and the price the dropshipper charged you for the product.</li>
                        </ul>
                    </Box>
                    <Box className='content' sx={{ marginTop: '3rem' }}>So, for example, let's say you're selling a t-shirt on your dropshipping store for $30. Your supplier charges you $15 for the t-shirt, and charges you an additional $5 for shipping. When a customer buys the t-shirt, you forward the order to the supplier, pay them $20 for the t-shirt and shipping, and keep the remaining $10 as profit.</Box>
                    <Box className='content'>What are the Advantages of Dropshipping? </Box>
                    <Box sx={{ marginTop: '3rem' }} className='title'>There are several advantages to using dropshipping as a fulfillment method:</Box>
                    <Box className='content'>
                        <ul>
                            <li>Low upfront costs: Since you don't need to purchase inventory upfront, you can start a dropshipping business with relatively low startup costs. This makes it an attractive option for entrepreneurs who don't have a lot of capital to invest.</li>
                            <li>Low risk: Because you're not purchasing inventory upfront, you don't have to worry about being stuck with unsold inventory if your products don't sell. This reduces your risk and allows you to test the market with minimal investment.</li>
                            <li>Scalability: Dropshipping makes it easy to scale your business because you don't have to worry about storing and managing inventory. As your sales increase, you simply forward more orders to your supplier, who takes care of the fulfillment.</li>
                            <li>Flexibility: Dropshipping allows you to sell a wide variety of products without having to worry about managing inventory. This gives you the freedom to experiment with different products and niches without committing to a large inventory investment.</li>
                        </ul>
                    </Box>
                    <Box sx={{ marginTop: '3rem' }} className='title'>How Can Shopify and Cartifind.com Help with Dropshipping?</Box>
                    <Box className='content'>Shopify is a popular ecommerce platform that makes it easy to set up an online store and start dropshipping. With Shopify, you can choose from a wide variety of templates and themes to create a professional-looking store, and you can easily add products and manage orders from a single dashboard. Cartifind.com is a dropshipping marketplace that connects you with a wide variety of suppliers who are willing to dropship products for you. With Cartifind.com, you can browse products from multiple suppliers, compare prices and shipping times, and easily add products to your Shopify store with just a few clicks. Using Shopify and Cartifind.com together can streamline the dropshipping process and help you build a successful online store with minimal investment. With the right products, marketing strategy, and customer service, dropshipping can be a profitable and rewarding business model for aspiring entrepreneurs. </Box>
                </Box> : <Box />}
                {currentTabIndex === 1 ? <Box sx={{
                    '&>div': {
                        width: '167rem',
                        paddingRight: '2rem'
                    },
                    background: 'url("img/Blog_Guide_bg.png") no-repeat 117rem 24rem'
                }}>
                    <Box className='content'>November offers business opportunities through Black Friday, Thanksgiving, Small Business Saturday, Cyber Monday, etc. Among all, Small Business Saturday (SBS) allows local business owners to serve their communities. E-Commerce and dropshipping too can avail the opportunity to increase their sales in SBS. How? Let’s understand.
                    </Box>
                    <Box sx={{ marginTop: '3rem', marginBottom: '1.5rem' }} className='title'>What and When Is Small Business Saturday</Box>
                    <Box className='content'>This year, Small Business Saturday falls on 26th November. American Express was founded in 2010, and SBS officially cosponsored the event in 2011. Small Business Saturday movement was the initiative to support local businesses, increase sales and provide a platform for maximum customer exposure.
                    </Box>
                    <Box className='content'>As dropshippers and e-commerce business owners deal with online customers, this national shopping event can help them reach closer to their buyers. However, you must understand how these two businesses can channel and benefit from the event. So let’s learn more about it.
                    </Box>
                    <Box sx={{ marginTop: '3rem', marginBottom: '1.5rem' }} className='title'>How Does Small Business Saturday Help eCommerce and Dropshippers</Box>
                    <Box className='content'>The small business Saturday movement started to support small and local business owners. Sellers from different platforms get ready to attract them and secure their deals. In other words, it's an opportunity to expose their business even to local and long-distance buyers.
                    </Box>
                    <Box className='content'>Small Business Saturday is a nationwide shopping day in the USA; business owners get the registry through the business directory to appear in the online search. As an E-commerce business owner, you can also avail the opportunity to increase your sales. Besides, Small Business Saturday or shop small is all about discounts, offers, gift coupons, etc., and provides an opportunity to create new customers and close business from the existing customers.
                    </Box>
                    <Box className='content' sx={{ marginTop: '2rem' }}>As in dropshipping business, Small Business Saturday will impact your sales if you know how and what to display on your store window and attract buyers. In addition, there are shopping holidays for buyers, including Black Friday, Thanksgiving, Small Business Saturday, Cyber Monday, and if you need to highlight what you are offering them. Shop small is shopping local, so you can highlight the local products in your store, so your visitors know you are included in this event.
                    </Box>
                    <Box className='content'>Both dropshippers and e-commerce business owners can get the limelight of Small Saturday Business hype, target your sales and achieve it. And it's possible.Let’s discuss tips on how to increase sales and create new customers during Small Business Saturday.
                    </Box>
                </Box> : <Box />}
                {currentTabIndex === 2 ? <Box sx={{
                    '&>div': {
                        width: '167rem'
                    },
                    '& .subtitle': {
                        fontWeight: '600',
                        fontSize: '2rem',
                        color: '#333333',
                        lineHeight: '150%'
                    },
                    background: 'url("img/Blog_Tips_bg1.png") no-repeat 95rem -4.5rem,url("img/Blog_Tips_bg2.png") no-repeat 94rem 77rem'
                }}>
                    <Box className='content'>In today's digital age, social media has become an integral part of promoting businesses and reaching customers. Among all the social media platforms, Instagram has proven to be one of the most effective channels for e-commerce and dropshipping businesses to promote their products and reach their target audience. In this article, we'll guide you through the process of advertising on Instagram and how to make the most of Instagram ads to grow your e-commerce business.
                    </Box>
                    <Box sx={{ marginTop: '3rem', marginBottom: '1.5rem' }} className='title'>What Are Instagram Ads</Box>
                    <Box className='content'>Instagram is one of the most popular social media platforms with over 1 billion monthly active users, making it a prime advertising channel for businesses of all sizes. In this article, we'll provide you with a comprehensive stepwise guide to help you nail Instagram ads and achieve your marketing goals. Instagram ads are sponsored content that appears in the users' newsfeed or stories. They can be in the form of photos, videos, shopping ads, collection ads, carousel ads, and more. They're an effective way to reach your target audience and achieve your marketing goals.
                    </Box>
                    <Box sx={{ marginTop: '3rem', marginBottom: '1.5rem' }} className='title'>How Much Do Instagram Ads Cost</Box>
                    <Box className='content'>The cost of Instagram ads varies depending on various factors such as location, audience, ad format, and more. Here are some of the cost metrics that you need to know:</Box>
                    <Box className='subtitle' sx={{ marginTop: '3rem' }}>Instagram cost per thousand impressions (CPM)</Box>
                    <Box className='content'>The cost per thousand impressions (CPM) is the cost of displaying an ad to 1000 users. The average CPM for Instagram ads is between $5 and $15, but it can vary depending on the ad's target audience and location. For example, if you target a highly competitive audience, the CPM may be higher.</Box>
                    <Box className='subtitle' sx={{ marginTop: '3rem' }}>Instagram cost per engagement (CPE)</Box>
                    <Box className='content'>The cost per engagement (CPE) is the cost of getting a user to engage with your ad, such as liking, commenting, or sharing it. The average CPE for Instagram ads is around $0.50 to $1.00, but it can vary depending on the ad's target audience and format.</Box>
                    <Box className='subtitle' sx={{ marginTop: '3rem' }}>Instagram cost per click (CPC)</Box>
                    <Box className='content'>The cost per click (CPC) is the cost of getting a user to click on your ad. The average CPC for Instagram ads is between $0.50 and $2.00, but it can vary depending on the ad's target audience and format.</Box>
                    <Box sx={{ marginTop: '3rem', marginBottom: '1.5rem' }} className='title'>How to Choose the Best Instagram Ad Type</Box>
                    <Box className='subtitle' sx={{ marginTop: '3rem' }}>Set Your Marketing Goal</Box>
                    <Box className='content'>Before you start advertising on Instagram, it's important to know what your marketing goal is. This will help you choose the best ad format to achieve your goals. For example, if your goal is to generate more sales, shopping, or collection ads might be the best option.</Box>
                    <Box className='subtitle' sx={{ marginTop: '3rem' }}>Define Your Target Audience</Box>
                    <Box className='content'>Once you have your marketing goal, it's time to define your target audience. This will help you choose the right ad format and target the right users. For example, if your target audience is mostly millennials, video ads might be the best option.</Box>
                    <Box className='subtitle' sx={{ marginTop: '3rem' }}>Run Ad Formats Based on Organic Data</Box>
                    <Box className='content'>If you have an existing Instagram account, you can use your organic data to determine the best ad format for your audience. Look at your most successful posts and see which ad format performs the best. This can give you an idea of what type of content your target audience is interested in and what type of ad format to use.</Box>
                    <Box className='subtitle' sx={{ marginTop: '3rem' }}>Check Competitor's Ads</Box>
                    <Box className='content'>Another way to determine the best ad format is to check your competitor's ads. See what type of ads they're running and what type of content is performing well for them. This can give you an idea of what's working in your industry and what you can do to stand out.</Box>
                </Box> : <Box />}
            </Box>
            <AppFooter />
        </div >
    );
}


