import React from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

export default function AppFooter(props) {
    const navigate = useNavigate();

    return (
        <Box sx={{
            width: '100%',
            minWidth: '192rem',
            height: '21rem',
            backgroundColor: '#444352',
            paddingTop: '5rem'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#FFF',
                "& .wrap": {
                    marginRight: '13rem'
                },
                "& .wrap:last-child": {
                    marginRight: '0rem'
                },
                "& .title": {
                    fontSize: '1.8rem',
                    fontWeight: '500',
                    marginBottom: '1.7rem'
                },
                "& .item": {
                    fontSize: '1.6rem',
                    fontWeight: '400',
                    marginBottom: '0.8rem',
                    cursor: 'pointer',
                }
            }}>
                <Box sx={{
                    width: '22.2rem',
                    '& img': {
                        width: '100%'
                    },
                }}>
                    <Box sx={{ width: '22.2rem', height: '6rem', marginBottom: '2rem' }}><img src='img/footer_logo.png' alt='cartifind' /></Box>
                    <Box sx={{
                        width: '22.2rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        "& div": {
                            width: '3.5rem',
                            height: '3.5rem'
                        }
                    }}>
                        <Box><img src='img/footer_Facebook.png' alt='Facebook' /></Box>
                        <Box><img src='img/footer_youtube.png' alt='youtube' /></Box>
                        <Box><img src='img/footer_Producthunt.png' alt='Producthunt' /></Box>
                        <Box><img src='img/footer_Instagram.png' alt='Instagram' /></Box>
                    </Box>
                </Box>
                <Box sx={{ width: '0.1rem', height: '14rem', backgroundColor: '#717086', margin: '0 6rem' }} />
                <Box className='wrap'>
                    <Box className='title'>Company</Box>
                    <Box className='item' onClick={() => { }}>Contact Us</Box>
                    <Box className='item' onClick={() => {
                        navigate('/privacy');
                    }}>Privacy Policy</Box>
                </Box>
                <Box className='wrap'>
                    <Box className='title'>Blog</Box>
                    <Box className='item' onClick={() => {
                        navigate('/blog?tabIndex=0');
                    }}>Dropshipping</Box>
                    <Box className='item' onClick={() => {
                        navigate('/blog?tabIndex=1');
                    }}>Guide</Box>
                    <Box className='item' onClick={() => {
                        navigate('/blog?tabIndex=2');
                    }}>Marketing Tips</Box>
                </Box>
                <Box className='wrap'>
                    <Box className='title'>Tutorial</Box>
                    <Box className='item' onClick={() => {
                        navigate('/tutorial');
                    }}>Tutorial</Box>
                </Box>
                <Box className='wrap'>
                    <Box className='title'>FAQ</Box>
                    <Box className='item' onClick={() => {
                        navigate('/faq');
                    }}>FAQ</Box>
                </Box>
                <Box className='wrap'>
                    <Box className='title'>Help Center</Box>
                    <Box className='item'>Contact Support</Box>
                    <Box className='item'>Live Chat Now</Box>
                </Box>
            </Box>
            <Box sx={{ textAlign: "center", fontSize: '1.6rem', fontWeight: "700", color: '#FFF', marginTopr: '1.5rem' }}>Cartifind © 2023</Box>
        </Box>
    );
}