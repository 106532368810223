
import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Box from "@mui/material/Box";
import { gql, useMutation } from "@apollo/client";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CardMedia from "@mui/material/CardMedia";
import { Context } from "../Context/Context.js";
import { Carousel } from 'antd';
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import GetCurrencySymbol from '../utils/GetCurrencySymbol.js';
import Typography from '@mui/material/Typography';
import CircularProgress from "@mui/material/CircularProgress";

const ADD_PRODUCT_LIST = gql`
  mutation AddProductList($id: String, $aliExpressProductURL: String) {
    addProductList(id: $id, aliExpressProductURL: $aliExpressProductURL) {
      status
      message
    }
  }
`;

const ADD_PRODUCT_LIST_FROM_URL = gql`
  mutation Mutation($aliExpressProductURL: String) {
    addProductListFormURL(aliExpressProductURL: $aliExpressProductURL) {
        status
        message
    }
  }
`;

const HtmlTooltipPro = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFAF3",
    fontSize: "3.2rem",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFAF3",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "1.6rem",
    boxShadow:
      "0rem 0.5rem 0.5rem -0.3rem rgb(0 0 0 / 20%), 0rem 0.8rem 1rem 0.1rem rgb(0 0 0 / 14%), 0rem 0.3rem 1.4rem 0.2rem rgb(0 0 0 / 12%)",
  },
}));

const HtmlTooltipFree = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#66615a",
    fontSize: "3.2rem",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#66615a",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "1.6rem",
    boxShadow:
      "0rem 0.5rem 0.5rem -0.3rem rgb(0 0 0 / 20%), 0rem 0.8rem 1rem 0.1rem rgb(0 0 0 / 14%), 0rem 0.3rem 1.4rem 0.2rem rgb(0 0 0 / 12%)",
  },
}));

export default function ProductCard(props) {
  const {
    title,
    numberSold,
    index,
    reviewScore,
    itemAsDescribed,
    shipSpeed,
    id,
    feedbackScore,
    isSaved,
    images,
    videoLink,
    communication,
    minSalePrice,
    maxSalePrice,
    minOriginalPrice,
    maxOriginalPrice,
    currency,
    isShowCartifindScore,
    productLink,
    productRating,
    discount,
    isFreeShip
  } = props;

  const { setState, state } = React.useContext(Context);
  const { sendLog, handleRefetchMyProductsCount, firstName, shopName, chromeExtensionIsInstall, browserName } = state;
  const [addIsSuccess, setAddIsSuccess] = React.useState(isSaved);
  const navigate = useNavigate();

  const [add_product_list_from_url] = useMutation(ADD_PRODUCT_LIST_FROM_URL);

  const handleLikeClick = (id, event) => {
    event.preventDefault();
    if (firstName == null) {
      setState({
        ...state,
        isAccountPopUpDialogOpen: true,
        currentComponentName: "SIGN_UP",
      });
      return;
    }
    if (addIsSuccess === false || isSaved === false) {
      setAddIsSuccess(true);
      add_product_list_from_url({
        variables: {
          aliExpressProductURL: productLink
        }
      }).then((result) => {

        if (
          result.data != null &&
          result.data.addProductListFormURL != null &&
          result.data.addProductListFormURL.status === true
        ) {
          setState({
            ...state,
            openSnackbar: true,
            snackbarText: 'The product is added to My Products tab.',
            snackbarState: 'SUCCESS',
          });
          handleRefetchMyProductsCount();
        }
        if (
          result.data != null &&
          result.data.addProductListFormURL != null &&
          result.data.addProductListFormURL.status === false
        ) {
          setAddIsSuccess(false);
          if (result.data.addProductListFormURL.message === "USER_NOT_LOG_IN") {
            setState({
              ...state,
              openSnackbar: true,
              snackbarText: 'Please log in and then operate !',
              snackbarState: 'FAILED',
            });
          }
          if (result.data.addProductListFormURL.message === "PLEASE_UPGRADE_PLAN") {
            setState({
              ...state,
              isOpenSelectPlanDialog: true,
              openSnackbar: false,
            });
          }
        }
      })
    }
  };
  const [currentCarouselIndex, setCurrentCarouselIndex] = React.useState(0);
  const [isShowVideo, setIsShowVideo] = React.useState(false);
  const [videoCanplay, setVideoCanplay] = React.useState(false);
  const [isShowAddButton, setIsShowAddButton] = React.useState(false);
  const carouselComponent = useRef(null);
  const videoComponent = useRef(null);
  const minSalePriceNumArr = minSalePrice.split('.');
  const maxSalePriceNumArr = maxSalePrice.split('.');
  const minOriginalPriceNumArr = minOriginalPrice.split('.');
  const maxOriginalPriceNumArr = maxOriginalPrice.split('.');

  const currencySymbol = GetCurrencySymbol(currency);

  const handleChangeCarousel = (index) => {
    setCurrentCarouselIndex(index);
  }
  // React.useEffect(() => {
  //   if (videoComponent != null && videoComponent.current != null) {
  //     //videoComponent.current.addEventListener('loadedmetadata', function () {
  //     //console.log('视频可以连续播放直到视频结束，而不需要缓冲', "index是" + index, new Date().getTime());
  //     setVideoCanplay(true);
  //     //});
  //   }
  // }, [videoComponent]);
  React.useEffect(() => {
    if (videoComponent != null && videoComponent.current != null) {
      const videoElement = videoComponent.current;

      const handleLoadedMetadata = () => {
        setVideoCanplay(true);
      };

      // Add event listener for loadedmetadata
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);

      // Check if the metadata has already been loaded
      if (videoElement.readyState >= 1) {
        handleLoadedMetadata();
      }

      // Cleanup function to remove event listener
      return () => {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [videoComponent]);
  return (
    <Box sx={{
      width: "280px",
      height: '400px',
    }} onMouseOver={() => {
      setIsShowAddButton(true);
      setIsShowVideo(true);
      carouselComponent.current.goTo(1);
      setCurrentCarouselIndex(1);
    }} onMouseLeave={() => {
      setIsShowAddButton(false);
      setIsShowVideo(false);
      carouselComponent.current.goTo(0);
      setCurrentCarouselIndex(0);
    }}>
      <Box sx={{
        width: "280px",
        height: isShowAddButton ? '455px' : '400px',
        transition: "height 0.3s",
        // padding: "9px 4px",
        boxSizing: "border-box",
        boxShadow: "0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F",
        background: "#FFF",
        borderRadius: '5px',
        zIndex: isShowAddButton ? "9" : "0",
        position: "relative",
        overflow: "hidden",
        borderRadius: '5px',
        '& .ant-carousel': {
          height: '280px',
        },
        '& .ant-carousel .slick-dots li, .ant-carousel .slick-dots li button': {
          width: '10px',
          height: '10px',
          borderRadius: '5px',
          backgroundColor: '#FFF',
          boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.19)'
        },
        '& .ant-carousel .slick-dots li.slick-active button': {
          backgroundColor: '#FF9711',
          boxShadow: 'inset 0px 4px 4px rgba(227, 36, 10, 0.45)'
        }
      }}>
        <Box>
          <Box onClick={() => {
            if (chromeExtensionIsInstall) {
              window.open(productLink);
            } else if (navigator.userAgent.includes("Chrome")) {
              window.open("/productDetailRedirect?productLink=" + productLink + "&productTitle=" + title);
            } else if (sessionStorage.getItem("otherBrowserForceOpenExtensionPage") == null) {
              sessionStorage.setItem("otherBrowserForceOpenExtensionPage", true)
              window.open("/productDetailRedirect?productLink=" + productLink + "&productTitle=" + title);
            } else {
              window.open(productLink);
            }
          }}
            // target="_blank"
            // href={productLink}
            // onClick={() => { sendLog("PRODUCT_CARD_IMAGE_CLICK", String(id)); }}
            sx={{
              position: 'relative',
              '& .slider-button': {
                width: '24px',
                height: '24px',
                borderRadius: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: '140px',
                cursor: "pointer",
              }
            }}
          >
            <Carousel style={{ height: "280px!important" }} ref={carouselComponent} afterChange={handleChangeCarousel} fade={false} speed={0}>
              {
                JSON.parse(images).map((src, index) => {
                  return <CardMedia
                    component="img"
                    image={src}
                    alt="thumbnailSource"
                    sx={{ width: "280px", height: "280px", borderRadius: '5px 5px 0 0', }}
                    key={index}
                  />
                })
              }
              {videoLink != null && videoLink.length > 0 ? <Box sx={{
                width: "280px",
                height: "280px",
                borderRadius: '15px',
                overflow: "hidden",
              }}><video
                  ref={videoComponent}
                  src={videoLink}
                  autoPlay
                  loop
                  muted
                  playsInline
                  preload="auto"
                  style={{ width: '100%', height: '100%' }}
                />
              </Box> : null}
            </Carousel>
            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "280px",
              height: "280px",
              borderRadius: '15px',
              position: "absolute",
              top: "0px",
              left: "0px",
              background: "#FFF",
              zIndex: isShowVideo && videoLink != null && videoLink.length > 0 ? (videoCanplay ? "-1" : "1") : "-1"
            }}><CircularProgress /></Box>


            {/* {!isShowVideo || (videoLink == null || videoLink.length == 0) ? <Carousel style={{ height: "280px!important" }} ref={carouselComponent} afterChange={handleChangeCarousel} fade={false} speed={0}>
              {
                JSON.parse(images).map((src, index) => {
                  return <CardMedia
                    component="img"
                    image={src}
                    alt="thumbnailSource"
                    sx={{ width: "280px", height: "280px", borderRadius: '5px 5px 0 0', }}
                    key={index}
                  />
                })
              }
            </Carousel> :
              <Box sx={{
                width: "280px",
                height: "280px",
                borderRadius: '15px',
                overflow: "hidden"
              }}>

                <video
                  src={videoLink}
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>} */}
            {/*
            // 此处用用照片分区，鼠标移动照片更换
            <Box sx={{
              width: "280px",
              height: "280px",
              borderRadius: '15px',
              display: "flex",
              justifyContent: "center",
              position: 'absolute',
              top: '9px',
              "& .area": {
                width: "16px"
              }
            }}>
              <Box sx={{
                width: "92px"
              }} onMouseOver={() => {
                carouselComponent.current.goTo(0);
                setCurrentCarouselIndex(0);
              }}></Box>
              <Box className="area" onMouseOver={() => {
                carouselComponent.current.goTo(0);
                setCurrentCarouselIndex(0);
              }}></Box>
              <Box className="area" onMouseOver={() => {
                carouselComponent.current.goTo(1);
                setCurrentCarouselIndex(1);
              }}></Box>
              <Box className="area" onMouseOver={() => {
                carouselComponent.current.goTo(2);
                setCurrentCarouselIndex(2);
              }}></Box>
              <Box className="area" onMouseOver={() => {
                carouselComponent.current.goTo(3);
                setCurrentCarouselIndex(3);
              }}></Box>
              <Box className="area" onMouseOver={() => {
                carouselComponent.current.goTo(4);
                setCurrentCarouselIndex(4);
              }}></Box>
              <Box className="area" onMouseOver={() => {
                carouselComponent.current.goTo(5);
                setCurrentCarouselIndex(5);
              }}></Box>
              <Box sx={{
                width: "92px"
              }} onMouseOver={() => {
                carouselComponent.current.goTo(5);
                setCurrentCarouselIndex(5);
              }}></Box>
            </Box> */}
            {JSON.parse(images).length > 1 ? <Box sx={{
              left: '8.2px',
              background: 'url("/img/icon_product_slide_left.png") no-repeat 0 0',
              backgroundSize: 'cover',
              "&:hover": {
                background: 'url("/img/icon_product_slide_left_selected.png") no-repeat 0 0',
                backgroundSize: 'cover',
              }
            }} className='slider-button' onClick={(e) => {
              e.preventDefault();
              carouselComponent.current.prev();
              setCurrentCarouselIndex(currentCarouselIndex - 1);
              sendLog("PRODUCT_CARD_IMAGE_LEFT_SLIDER", String(id));
            }} /> : <></>}
            {JSON.parse(images).length > 1 ? <Box sx={{
              right: '8.2px',
              background: 'url("/img/icon_product_slide_right.png") no-repeat 0 0',
              backgroundSize: 'cover',
              "&:hover": {
                background: 'url("/img/icon_product_slide_right_selected.png") no-repeat 0 0',
                backgroundSize: 'cover',
              }
            }} className='slider-button' onClick={(e) => {
              e.preventDefault();
              carouselComponent.current.next();
              setCurrentCarouselIndex(currentCarouselIndex + 1);
              sendLog("PRODUCT_CARD_IMAGE_RIGHT_SLIDER", String(id));
            }} /> : <></>}
            <Box sx={{
              width: '87px',
              height: '87px',
              borderRadius: '50%',
              display: 'none',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: '159px',
              left: '148px',
              background: 'url("/img/icon_product_slide_video_player.png") no-repeat 0 0',
              backgroundSize: 'cover',
            }} onClick={(e) => {
              e.preventDefault();
            }} />
          </Box>
        </Box>
        <Box sx={{
          width: "235px",
          height: '100px',
          padding: "10px 12px",
        }}>
          <Box sx={{
            width: '100%',
            fontSize: '14px',
            fontWeight: '400',
            color: '#666666',
            marginBottom: '8px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            display: '-webkit-box',
            wordBreak: 'break-all'
          }}>{title}</Box>
          <Box sx={{
            display: "flex",
            alignItems: 'center',
            gap: "20px",
            marginBottom: "5px"
          }}>
            {numberSold != null && numberSold != 0 ? <Box sx={{
              fontSize: "14px",
              color: "#666666",
              lineHeight: "21px",
              letterSpacing: "0.15px"
            }}>{numberSold} sold</Box> : null}
            {productRating != null && productRating != 0 ? <Box sx={{
              fontSize: "14px",
              color: "#707070",
              lineHeight: "21px",
              letterSpacing: "0.15px",
              display: "flex",
              alignItems: 'center',
              gap: "10px"
            }}>
              <Box sx={{
                background: 'url("/img/icon_product_score.png") no-repeat 0 0',
                backgroundSize: "cover",
                width: "15px",
                height: "15.5px"
              }} />
              <Box sx={{ color: '#707070' }}>{productRating}</Box>
            </Box> : null}

          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            height: '30px',
            color: '#333',
            marginBottom: '8px',
            '& .decimals': {
              alignSelf: 'flex-start',
              fontSize: '14px',
              fontWeight: '500',
            },
            '& .integer': {
              height: '26px',
              fontSize: '20px',
              fontWeight: '700',
            }
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              height: '32px',
              marginRight: '40px'
            }}>
              <Box className="integer">{currencySymbol + minSalePriceNumArr[0]}</Box>
              <Box className="decimals">{minSalePriceNumArr.length === 2 ? minSalePriceNumArr[1].slice(0, 2) : '00'}</Box>
              {minSalePrice !== maxSalePrice ? <Box className="integer">~{maxSalePriceNumArr[0]}</Box> : <Box />}
              {minSalePrice !== maxSalePrice ? <Box className="decimals">{maxSalePriceNumArr.length === 2 ? maxSalePriceNumArr[1].slice(0, 2) : '00'}</Box> : <Box />}
            </Box>
            {/* {minSalePrice !== minOriginalPrice && maxSalePrice !== maxOriginalPrice ? < Box sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '16px',
              height: '32px',
              color: '#707070',
              position: 'relative',
              justifyContent: 'center',
              '& .integer': {
                fontSize: '16px',
                fontWeight: '500',
              },
              '& .decimals': {
                alignSelf: 'flex-start',
                fontSize: '16px',
                fontWeight: '500',
              },
            }}>
              <Box className="integer">{currencySymbol + minOriginalPriceNumArr[0]}</Box>
              <Box className="decimals">{minOriginalPriceNumArr.length === 2 ? minOriginalPriceNumArr[1].slice(0, 2) : '00'}</Box>
              {minOriginalPrice !== maxOriginalPrice ? <Box className="integer">~{maxOriginalPriceNumArr[0]}</Box> : <Box />}
              {minOriginalPrice !== maxOriginalPrice ? <Box className="decimals">{maxOriginalPriceNumArr.length === 2 ? maxOriginalPriceNumArr[1].slice(0, 2) : '00'}</Box> : <Box />}
              <Box sx={{ width: 'calc(100% + 10px)', height: '1px', backgroundColor: '#707070', position: 'absolute', top: '12px', left: '-5px' }}></Box>
            </Box> : <Box />} */}
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "20px" }}>
              {/* {isFreeShip != null && isFreeShip == true ? <Box sx={{
              width: "81px",
              height: "18px",
              borderRadius: "5px",
              padding: "4px 10px",
              background: "#EBEBEB",
              color: "#000000DE",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "13px",
            }}>Free Shipping</Box> : null} */}
              {discount != null && discount != "0%" ? <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px"
              }}>
                <Box sx={{
                  width: "34px",
                  height: "14px",
                  borderRadius: "5px",
                  padding: "4px",
                  background: "red",
                  color: "#FFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "13px",
                }}>Sale</Box>
                <Box sx={{
                  fontSize: "16px",
                  color: "red",
                  lineHeight: "25.9px",
                  letterSpacing: "0.15px"
                }}>-{discount}</Box>
              </Box> : null}
            </Box>
          </Box>
        </Box>
        {isShowAddButton ? <Box sx={{
          width: 'calc(100% - 8px)',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#FFF',
          fontSize: '14px',
          fontWeight: '700',
          position: "absolute",
          bottom: "10px"
        }}>
          <Box sx={{
            width: '180px',
            height: '36px',
            borderRadius: '10px',
            backgroundColor: isSaved || addIsSuccess ? '#999999' : '#67B239',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }} onClick={(event) => {
            sendLog("PRODUCT_CARD_LIKE_ICON_CLICK", String(id));
            handleLikeClick(id, event);
          }}>{isSaved || addIsSuccess ? 'Added to My Products' : 'Add to My Products'}</Box>
        </Box> : <Box />}
      </Box>
    </Box>
  );
}
