/** @format */

import React, { useContext } from "react";
import { Box } from "@mui/system";
import CardMedia from "@mui/material/CardMedia";
import AppHeader from "../AppHeader.react";
import { gql, useQuery, useMutation } from "@apollo/client";
import AppSidebar from "../AppSidebar.react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MyOrderBlankPage from "./MyOrderBlankPage.react";
import Typography from "@mui/material/Typography";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CircularProgress from "@mui/material/CircularProgress";
import InfiniteScroll from "react-infinite-scroller";
import { Context } from "../Context/Context";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import TablePagination from '@mui/material/TablePagination';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Checkbox } from "@mui/material";
import Chip from '@mui/material/Chip';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const GET_ORDER_LIST = gql`
  query orderList($status: String, $cursor: String, $sort: String) {
    getOrderList(status: $status, cursor: $cursor, sort: $sort) {
      orderNumber
      orderPlaceDate
      cursor
      hasNextPage
      displayFinancialStatus
      displayFulfillmentStatus
      transactionTooltip
      transactionStatus
      orderName
      itemsNum
      customer
      channel
      totalPrice
      currencyCode
      trackingURLList
    }
  }
`;

const ButtonTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement={placement ? placement : "bottom"} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
    fontSize: "20px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    color: "#FFF",
    maxWidth: '260px',
    fontSize: "12px",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 1px rgb(0 0 0 / 12%)",
  },
}));

const getButtonTooltipLayout = (title) => {
  return <React.Fragment>
    <Box sx={{
      color: '#FFF',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '150%'
    }}>
      {title}
    </Box>
  </React.Fragment>
}

const cursorList = new Map();

export default function MyOrders() {
  const queryParams = new URLSearchParams(window.location.search);
  const [tabIndex, setTabIndex] = React.useState(0);
  const { state } = useContext(Context);
  const { sendLog } = state;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const [cursor, setCursor] = React.useState([]);
  const [pageIndex, setPageIndex] = React.useState(1);
  const [sort, setSort] = React.useState('latest');
  const [checkedAll, setCheckedAll] = React.useState(false);
  const [selectedIDList, setSelectedIDList] = React.useState([]);
  const [allOrderID, setAllOrderID] = React.useState([]);
  const [refreshPage, setRefreshPage] = React.useState(0);
  const {
    loading: orderListLoading,
    data: orderListData,
    refetch,
    fetchMore,
  } = useQuery(GET_ORDER_LIST, {
    variables: {
      status: "",
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const [isLoadingNewPage, setIsLoadingNewPage] = React.useState(false);
  const [currentStatus, setCurrentStatus] = React.useState("ALL");
  React.useEffect(() => {
    if (orderListLoading === false) {
      if (orderListData != null && orderListData.getOrderList != null || currentStatus != "ALL") {
        sendLog("MY_ORDERS_LOAD_ORDERS_PAGE");
        let temporaryHasNextPage, temporaryCursor;
        const Arr = orderListData.getOrderList.map((order, index) => {
          temporaryHasNextPage = order.hasNextPage;
          temporaryCursor = order.cursor;
          return order.orderNumber;
        })
        setHasNextPage(temporaryHasNextPage);
        if (cursorList.get(pageIndex) == null) {
          cursorList.set(
            pageIndex,
            temporaryCursor
          )
        }
        setAllOrderID(Arr);
      } else {
        sendLog("MY_ORDERS_LOAD_BLANK_PAGE");
      }
    }
  }, [orderListLoading]);
  React.useEffect(() => {
    if (sort != null && sort.length > 0) {
      refetch({
        status: currentStatus,
        sort
      })
    }
  }, [sort]);

  React.useEffect(() => {
    if (cursorList != null) {
      refetch({
        status: currentStatus,
        cursor: cursorList.get(pageIndex - 1),
        sort
      });
    }
  }, [pageIndex]);

  if (orderListLoading === false && (orderListData == null || orderListData.getOrderList == null || orderListData.getOrderList.length === 0) && currentStatus == "ALL") {
    return (

      <Box sx={{ fontSize: '14px', minWidth: '1440px' }}>
        <AppHeader />
        <AppSidebar />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
          <Box sx={{ display: "flex", width: "calc(100% - 240px)", justifyContent: 'center', alignItems: "center", position: 'relative' }}>
            <MyOrderBlankPage />
          </Box>
        </Box>
      </Box>
    )
  }
  return (
    <Box sx={{ fontSize: '14px', minWidth: '1440px' }}>
      <AppHeader />
      <AppSidebar />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
        <Box sx={{ display: "flex", width: "calc(100% - 240px)", justifyContent: 'center', alignItems: "center", position: 'relative' }}>
          <Box sx={{
            width: '1200px',
            padding: "16px 16px 20px 20px",
          }}>
            <Box sx={{
              width: '1146px',
              minHeight: "300px",
              backgroundColor: "#FFF",
              borderRadius: '4px',
              boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
            }}>
              <Box sx={{
                display: "flex",
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: '10px'
              }}>
                <Box sx={{
                  borderBottom: "0.1rem solid rgba(112, 112, 112, 0.5)",
                }} >
                  <Tabs
                    value={tabIndex}
                    onChange={(event, newValue) => {
                      setTabIndex(newValue);
                      setIsLoadingNewPage(false);
                      switch (newValue) {
                        case 0:
                          sendLog("MY_ORDERS_ALL_TAB_CLICK");
                          refetch({ status: "ALL", sort });
                          setCurrentStatus("ALL");
                          break;
                        case 1:
                          sendLog("MY_ORDERS_UNFILFILLED_TAB_CLICK");
                          refetch({ status: "UNFULFILLED", sort });
                          setCurrentStatus("UNFULFILLED");
                          break;
                        case 2:
                          sendLog("MY_ORDERS_FILFILLED_TAB_CLICK");
                          refetch({ status: "FULFILLED", sort });
                          setCurrentStatus("FULFILLED");
                          break;
                        case 3:
                          sendLog("MY_ORDERS_UNPAID_TAB_CLICK");
                          refetch({ status: "UNPAID", sort });
                          setCurrentStatus("UNPAID");
                          break;
                        default:
                          sendLog("MY_ORDERS_OTHERS_TAB_CLICK");
                          refetch({ status: "OTHERS", sort });
                          setCurrentStatus("OTHERS");
                          break;
                      }
                    }}
                    aria-label="My orders list"
                    TabIndicatorProps={{
                      style: {
                        height: "3px",
                        backgroundColor: "#FF9711",
                      },
                    }}
                    sx={{
                      "& .MuiTab-root": {
                        fontSize: "18px",
                        color: "#707070",
                        lineHeight: "150%",
                        fontFamily: "Arial",
                        letterSpacing: "0.4px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        "&.Mui-selected": {
                          color: "#FF9711",
                        },
                      },
                    }}
                  >
                    <Tab label={"ALL"} {...a11yProps(0)} />
                    <Tab label={"UNFULFILLED"} {...a11yProps(1)} />
                    <Tab label={"FULFILLED"} {...a11yProps(2)} />
                    <Tab label={"UNPAID"} {...a11yProps(3)} />
                    <Tab label={"OTHERS"} {...a11yProps(4)} />
                  </Tabs>
                </Box>
                <Box sx={{
                  display: "flex",
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: '20px',
                  "& .MuiInputLabel-root": {
                    fontSize: '16px',
                    lineHeight: '16px',
                    marginTop: "-4px",
                    color: "rgba(0, 0, 0, 0.6)!important",
                    "&.Mui-focused": {
                      fontSize: '16px',
                      lineHeight: '16px',
                      marginTop: "0px",
                      color: "rgba(0, 0, 0, 0.6)!important",
                    },
                    "&.MuiInputLabel-shrink": {
                      fontSize: '16px',
                      lineHeight: '16px',
                      marginTop: "0px!important",
                      color: "rgba(0, 0, 0, 0.6)!important",
                    }
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: '28px',
                  },
                  "& fieldset": {
                    fontSize: '14px',
                    borderColor: "rgba(0, 0, 0, 0.23)!important",
                    "&.Mui-focused": {
                      borderColor: "rgba(0, 0, 0, 0.23)!important",
                    },
                  },
                }}>

                  <Box sx={{ width: '120px' }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{
                          "& .MuiSelect-select.MuiInputBase-input": {
                            padding: '0px',
                            paddingLeft: '10px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            overflow: "hidden",
                            fontSize: '14px'
                          }
                        }}
                        value={sort}
                        label="Sort"
                        onChange={(e) => {
                          setSort(e.target.value);
                        }}
                      >
                        <MenuItem value='latest' sx={{ fontSize: '14px' }}>Latest</MenuItem>
                        <MenuItem value="oldest" sx={{ fontSize: '14px' }}>Oldest</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ marginTop: "24px" }}>
                {/* <TabPanel value={tabIndex} index={0}> */}
                <Box>
                  <Box sx={{
                    display: 'flex',
                    height: '42px',
                    alignItems: 'center',
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    "&>div": {
                      display: 'flex',
                      height: '42px',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      color: "rgba(0,0,0,0.87)",
                      fontWeight: '500'
                    }
                  }}>
                    <Box sx={{
                      width: '50px',
                      alignItems: 'flex-start!important',
                    }}>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0px 10px 0px 0px'
                      }}>
                        <Checkbox
                          checked={checkedAll}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const Arr = orderListData != null && orderListData.getOrderList != null ? orderListData.getOrderList.map((order, index) => {
                                return order.orderNumber;
                              }) : []
                              setSelectedIDList(Arr);
                              setCheckedAll(true);
                            } else {
                              setSelectedIDList([]);
                              setCheckedAll(false);
                            }
                          }}
                          inputProps={{
                            'aria-label': 'select all desserts',
                          }}
                          icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                          </Box>}
                          checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                          </Box>}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ width: '100px', alignItems: 'flex-start!important', }}>Order</Box>
                    <Box sx={{ width: '135px' }}>Date</Box>
                    <Box sx={{ width: '135px' }}>Customer</Box>
                    <Box sx={{ width: '135px' }}>Price</Box>
                    <Box sx={{ width: '135px' }}>Payment Status</Box>
                    <Box sx={{ width: '225px' }}>Fulfillment Status</Box>
                    <Box sx={{ width: '100px' }}>Items</Box>
                    <Box sx={{ width: '135px' }}>Tracking URLs</Box>
                  </Box>
                  <Box>
                    {orderListLoading ?
                      <Box>
                        <Box
                          sx={{
                            height: "400px",
                            marginTop: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </Box> :
                      (orderListData != null && orderListData.getOrderList != null && orderListData.getOrderList.length > 0 ? orderListData.getOrderList.map(({ orderNumber, orderPlaceDate, orderName, displayFinancialStatus, displayFulfillmentStatus, customer, trackingURLList, totalPrice, currencyCode, itemsNum, transactionTooltip, transactionStatus }, index) => {
                        return <Order
                          orderName={orderName}
                          orderNumber={orderNumber}
                          // date={"7/13 at 7:30AM"}
                          date={orderPlaceDate}
                          customer={customer}
                          price={totalPrice + currencyCode}
                          paymentStatus={displayFinancialStatus}
                          fulfillmentStatus={displayFulfillmentStatus}
                          transactionTooltip={transactionTooltip}
                          transactionStatus={transactionStatus}
                          items={itemsNum}
                          trackingURLList={trackingURLList}
                          key={index}
                          setSelectedIDList={setSelectedIDList}
                          selectedIDList={selectedIDList}
                          setCheckedAll={setCheckedAll}
                          orderListLength={orderListData.getOrderList.length}
                          refreshPage={refreshPage}
                          setRefreshPage={setRefreshPage}
                        />
                      }) : <Box sx={{ height: '124px', fontSize: '18px', color: "#666", fontWeight: '600', lineHeight: '124px', textAlign: 'center' }}>There are currently no orders that match the filter criteria</Box>)
                    }
                  </Box>
                </Box>
                {/* </TabPanel> */}
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                height: '50px',
                marginRight: "30px"
              }}>
                <Box>{(25 * (pageIndex - 1) + 1) + " — " + (25 * (pageIndex - 1) + (orderListData != null && orderListData.getOrderList != null ? orderListData.getOrderList.length : 1))}</Box>
                <ArrowBackIosIcon sx={{ fontSize: '16px', cursor: "pointer", color: pageIndex === 1 ? "#bdbdbd" : 'rgba(0, 0, 0, 0.87)', marginLeft: '10px', marginRight: '5px' }} onClick={() => {
                  if (pageIndex > 1) {
                    setPageIndex(pageIndex - 1);
                  }
                }} />
                <ArrowForwardIosIcon sx={{ fontSize: '16px', cursor: "pointer", color: hasNextPage ? 'rgba(0, 0, 0, 0.87)' : "#bdbdbd", }} onClick={() => {
                  if (hasNextPage) {
                    setPageIndex(pageIndex + 1);
                  }
                }} />
              </Box>
              <Box sx={{ height: '64px', width: '1px' }}></Box>
            </Box>
            <Box sx={{
              position: "fixed",
              bottom: '0',
              left: '240px',
              width: 'calc(100% - 240px)',
              height: "64px",
              background: '#FFF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0px 100px 0px 16px',
              userSelect: 'none',
              boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
              boxSizing: "border-box"
            }}>
              <Box sx={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: '600'
              }}>{selectedIDList.length} items selected</Box>
              <Box sx={{
                width: '350px',
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'space-between',
                justifyContent: 'flex-end',
                "&>div": {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }
              }}>
                {/* <Box>
                  <Box sx={{
                    width: "126px",
                    height: "24px",
                    padding: "6px 16px",
                    backgroundColor: "#FFF",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontFamily: 'Roboto',
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "0.4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    cursor: "pointer",
                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                    border: "1px solid rgba(0,0,0,0.5)"
                  }}
                    onClick={async () => {

                    }}>
                    Mark as Fulfilled
                  </Box>
                </Box> */}
                <Box sx={{
                  width: '126px',
                  height: "24px",
                  padding: '6px 16px',
                  background: "rgba(97, 196, 84, 1)",
                  boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.20), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                  color: '#FFF',
                  fontWeight: "500",
                  fontSize: "14px",
                  letterSpacing: "0.46px",
                  cursor: 'pointer',
                  borderRadius: '4px',
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                  onClick={(e) => {
                    selectedIDList.map(id => {
                      window.open("/orderDetail?id=" + id);
                    })
                  }}>
                  <Box>Review and Fulfill</Box>
                </Box>
              </Box>
            </Box>
          </Box>

        </Box>
      </Box>
    </Box>
  );
}

function Order(props) {
  const {
    orderName,
    orderNumber,
    date,
    customer,
    price,
    paymentStatus,
    fulfillmentStatus,
    transactionTooltip,
    transactionStatus,
    items,
    trackingURLList,
    setSelectedIDList,
    selectedIDList,
    setCheckedAll,
    orderListLength,
    refreshPage,
    setRefreshPage
  } = props;
  return (
    <Box sx={{
      display: 'flex',
      height: '64px',
      alignItems: 'center',
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      cursor: "pointer",
      "&>div": {
        display: 'flex',
        height: '54px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        color: "rgba(0,0,0,0.87)",
        gap: "5px",
        padding: "5px 0px"
      }
    }} onClick={() => { window.open("/orderDetail?id=" + orderNumber); }}>
      <Box sx={{
        width: '50px',
        alignItems: 'flex-start!important',
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0px 10px 0px 0px'
        }}>
          <Checkbox
            checked={selectedIDList.includes(orderNumber)}
            onChange={(e) => {
              const Arr = selectedIDList;
              if (selectedIDList.includes(orderNumber)) {
                Arr.splice(selectedIDList.indexOf(orderNumber), 1);
              } else {
                Arr.splice(0, 0, orderNumber);
              }
              setSelectedIDList(Arr);

              if (Arr.length === orderListLength) {
                setCheckedAll(true);
              } else {
                setCheckedAll(false);
              }
              setRefreshPage(refreshPage + 1);
            }}
            onClick={(e) => { e.stopPropagation() }}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
            </Box>}
            checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
            </Box>}
          />
        </Box>
      </Box>
      <Box sx={{ width: '100px', alignItems: 'flex-start!important', cursor: "pointer" }}>{orderName}</Box>
      <Box sx={{ width: '135px' }}>{date}</Box>
      <Box sx={{ width: '135px' }}>{customer}</Box>
      <Box sx={{ width: '135px' }}>{price}</Box>
      <Box sx={{ width: '135px' }}>
        <Chip label={paymentStatus} sx={{
          maxWidth: '120px',
          fontSize: '14px',
          color: "rgba(0, 0, 0, 0.87)",
          backgroundColor: "rgba(0, 0, 0, 0.08)",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }} />
      </Box>
      <Box sx={{ width: '225px' }}>
        <Chip label={fulfillmentStatus} sx={{
          maxWidth: '195px',
          fontSize: '14px',
          color: "rgba(0, 0, 0, 0.87)",
          backgroundColor: "rgba(255, 151, 17, 0.80)",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }} />
        {transactionStatus != null ?
          <ButtonTooltip title={getButtonTooltipLayout(transactionTooltip)} arrow>
            <Chip label={transactionStatus} sx={{
              maxWidth: '195px',
              fontSize: '14px',
              backgroundColor: "#61C454",
              color: "#FFF",
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }} /></ButtonTooltip> : null}
      </Box>
      <Box sx={{ width: '100px' }}>{items}</Box>
      <Box sx={{ width: '135px' }}>
        {trackingURLList != null && trackingURLList.length > 0 ? <Box sx={{ cursor: "pointer" }} onClick={(e) => {
          if (trackingURLList.length == 1) {
            e.stopPropagation();
            window.open(trackingURLList[0]);
          }
        }}>Tracking URL</Box> : null}
      </Box>
    </Box>
  )
}

function TabPanel(props) {
  const { children, value, index, onClick } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      onClick={onClick}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
