import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import CustomSelected from "../CommonComponents/CustomSelected.react";
import axios from "axios";
import { Context } from "../Context/Context";
import { currencyList, countryList } from "../utils/CurrencyList.js";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import GetCurrencySymbol from "../utils/GetCurrencySymbol.js";
import { Spin } from 'antd';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const GET_CURRENCY_RATIO = gql`
  query Query($from: String, $to: String) {
    getCurrencyRatio(from: $from, to: $to)
  }
`;

const GET_SHIPPING_OPTIONS = gql`
  query GetProductShippingOptions($id: String, $country: String, $warehouse: String, $productWeight: String, $fulfillmentOption: Boolean) {
    getProductShippingOptions(id: $id, country: $country, warehouse: $warehouse, productWeight: $productWeight, fulfillmentOption: $fulfillmentOption) {
      country
      currency
      fee
      hasTracking
      id
      shippingMethod
      time
      SKUShippingFee
    }
  }
`;

const ButtonTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement={placement ? placement : "bottom"} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
    fontSize: "20px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    color: "#FFF",
    maxWidth: '260px',
    fontSize: "12px",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 1px rgb(0 0 0 / 12%)",
  },
}));

const getButtonTooltipLayout = (title) => {
  return <React.Fragment>
    <Box sx={{
      color: '#FFF',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '150%'
    }}>
      {title}
    </Box>
  </React.Fragment>
}


function TextCenter(props) {
  const {
    sku,
    options,
    price: rawPrice,
    compareAtPrice: rawCompareAtPrice,
    index,
    variants,
    cost,
    imageSrc,
    setEphemeralVariants,
    currentExchangeRate,
    setCheckedAll,
    getVariantsSelectedIndexList,
    setOpenSavePriceAlert,
    setSavePrice,
    maxStock,
    stock,
    isRepetition,
    variantsSelectedIndexList,
    setVariantsSelectedIndexList,
    handleWatchrepetition,
    currentCurrency,
    currencyShippingFee,
    profitMarginRate,
    fulfillmentOption,
  } = props;

  const price = profitMarginRate > 0 ? ((currencyShippingFee + cost) * (100 + profitMarginRate) / 100).toFixed(2) : rawPrice;
  const compareAtPrice = profitMarginRate > 0 ? ((currencyShippingFee + cost) * (100 + profitMarginRate) / 100).toFixed(2) : rawCompareAtPrice;
  const { state, setState } = React.useContext(Context);
  const { sendLog } = state;
  const [editOptionsNum, setEditOptionsNum] = useState(0);
  const [editSellingPriceNum, setEditSellingPriceNum] = useState(0);
  const [sellingValue, setSellingValue] = useState(Math.round(price * 100) / 100);
  const [sellingInput, setSellingInput] = useState(Math.round(price * 100) / 100);
  const [compareAtPriceInput, setCompareAtPriceInput] = useState(Math.round(compareAtPrice * 100) / 100);
  const [compareAtPriceValue, setCompareAtPriceValue] = useState(Math.round(compareAtPrice * 100) / 100);
  const [costValue, setCostValue] = useState(Math.round(cost * 100) / 100);
  const [stockValue, setStockValue] = useState(stock);
  const [refetchPage, setRefetchPage] = useState(0);
  useEffect(() => {
    setSellingValue(Math.round(price * 100) / 100);
    setSellingInput(Math.round(price * 100) / 100);
    const NewVariants = variants.map((val, ind) => {
      if (ind === index) {
        val.price = Number(price);
      }
      return val;
    });
    setEphemeralVariants(NewVariants);
  }, [price]);
  useEffect(() => {
    setCompareAtPriceValue(Math.round(compareAtPrice * 100) / 100);
    setCompareAtPriceInput(Math.round(compareAtPrice * 100) / 100);
    const NewVariants = variants.map((val, ind) => {
      if (ind === index) {
        val.compareAtPrice = Number(compareAtPrice);
      }
      return val;
    });
    setEphemeralVariants(NewVariants);
  }, [price]);

  const handleCheckNumber = (value) => {
    let str = value.replace(/[^\d^\.]+/g, '');
    const len1 = str.substr(0, 1);
    const len2 = str.substr(1, 1);

    //如果第一位是0，第二位不是点，就用数字把点替换掉
    if (str.length > 1 && len1 == 0 && len2 != '.') {
      str = str.substr(1, 1);
    }

    //第一位不能是.
    if (len1 == '.') {
      str = '';
    }

    //限制只能输入一个小数点
    if (str.indexOf(".") != -1) {
      const str_ = str.substr(str.indexOf(".") + 1);
      //限制只能输入一个小数点
      if (str_.indexOf(".") != -1) {
        str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
      }
    }

    return str;
  }

  useEffect(() => {
    setSellingValue(Math.round(Number(price) * currentExchangeRate * 100) / 100);
    setSellingInput(Math.round(Number(price) * currentExchangeRate * 100) / 100)
    setCostValue(Math.round(Number(cost) * currentExchangeRate * 100) / 100);
    setCompareAtPriceInput(Math.round(Number(compareAtPrice) * currentExchangeRate * 100) / 100);
    setCompareAtPriceValue(Math.round(Number(compareAtPrice) * currentExchangeRate * 100) / 100)
    const NewVariants = variants.map((val, ind) => {
      if (ind === index) {
        val.price = Number(price) * currentExchangeRate;
        val.compareAtPrice = Number(compareAtPrice) * currentExchangeRate;
        val.inventoryItem.cost = Number(cost) * currentExchangeRate;
      }
      return val;
    });
    setEphemeralVariants(NewVariants);
  }, [currentExchangeRate]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "space-between",
        fontSize: "14px",
        borderTop: '1px solid #999999',
        color: "#707070",
        height: '76px',
        //borderRadius: '2px'
        "& .MuiInputBase-input": {
          textAlign: "center",
          fontWeight: "400",
          fontSize: "14px",
          fontFamily: "Arial",
          fontStyle: "normal",
          lineHeight: "150%",
          paddingLeft: "1px",
          paddingRight: "1px",
        },
        "& .Mui-disabled": {
          backgroundColor: "#f1f1f1",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#999999",
          },
          "&:hover fieldset": {
            borderColor: "#999999",
          },
          "& fieldset": {
            border: '1px solid #999999'
          },
          "&.Mui-disabled:hover fieldset": {
            borderColor: "rgba(112,112,112,0.5)",
          },
          "&.Mui-disabled fieldset": {
            borderColor: "rgba(112,112,112,0.5)",
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100px",
        }}
      >
        <Checkbox
          sx={{
            marginLeft: "10px",
            width: "2px",
            height: "2px",
            //marginRight: "15px",
            color: "#FF9711",
            "&.Mui-checked": {
              color: "#FF9711",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "21px",
            },
          }}
          checked={variantsSelectedIndexList.includes(index)}
          onChange={(e) => {
            const Arr = variantsSelectedIndexList;
            if (variantsSelectedIndexList.includes(index)) {
              Arr.splice(variantsSelectedIndexList.indexOf(index), 1)
            } else {
              Arr.splice(0, 0, index);
            }
            setVariantsSelectedIndexList(Arr);
            getVariantsSelectedIndexList(Arr);
            if (Arr.length === variants.length) {
              setCheckedAll(true);
            } else {
              setCheckedAll(false);
            }
            handleWatchrepetition();
            setRefetchPage(refetchPage + 1);
          }}
          inputProps={{
            "aria-label": "Checked item",
          }}
        />
        <Box
          sx={{
            marginLeft: "12px",
            width: "61px",
          }}
        >
          <CardMedia
            sx={{
              width: "61px",
              height: "61px",
            }}
            loading="lazy"
            component="img"
            image={imageSrc}
          />
        </Box>
      </Box>
      <Box sx={{
        maxWidth: "138px",
        minWidth: "138px",
        fontSize: "14px",
      }}>
        {sku}
      </Box>
      {options.map((item, optionsIndex) => {
        return (
          <Box sx={{
            marginRight: "11px",
            marginLeft: "11px",
            width: "81px",
            height: "30px",
            display: "flex",
            "& .MuiOutlinedInput-root": {
              height: "30px",
              color: "#707070",
              "&.Mui-focused fieldset": {
                border: isRepetition ? "4px solid #E84343" : "1px solid #999999",
              },
              "&:hover fieldset": {
                border: isRepetition ? "4px solid #E84343" : "1px solid #999999",
              },
              "& fieldset": {
                border: isRepetition ? "4px solid #E84343" : "1px solid #999999",
              },
            }
          }} key={optionsIndex}>
            <TextField
              value={item}
              size="small"
              onChange={(e) => {
                if (editOptionsNum === 0) {
                  sendLog("MY_PRODUCTS_CARD_DIALOG_VARIANTS_EDIT_INFO", "change options");
                  setEditOptionsNum(editOptionsNum + 1);
                }
                const NewVariants = variants.map((val, ind) => {
                  if (ind === index) {
                    val.options[optionsIndex] = e.target.value;
                  }
                  return val;
                });
                handleWatchrepetition();
                setEphemeralVariants(NewVariants);
              }}
            />
          </Box>
        );
      })}
      <Box sx={{ width: "99px" }}>{GetCurrencySymbol(currentCurrency.toUpperCase())}{costValue.toFixed(2)}</Box>
      {fulfillmentOption ? <Box sx={{ width: "99px" }}>{GetCurrencySymbol(currentCurrency.toUpperCase())}{currencyShippingFee.toFixed(2)}
        <ButtonTooltip title={getButtonTooltipLayout(`This is Fulfilled by Cartifind shipping cost.`)} arrow>
          <Box sx={{
            width: '13px',
            height: '15px',
            background: 'url("img/myProduct/product_fulfilled_by_cartifind.png") no-repeat 0 0',
            backgroundSize: 'contain',
            marginLeft: "3px",
            marginBottom: "-3px",
            display: "inline-block"
          }} /></ButtonTooltip></Box> : <Box sx={{ width: "99px" }}>{GetCurrencySymbol(currentCurrency.toUpperCase())}{currencyShippingFee.toFixed(2)}
        <ButtonTooltip title={getButtonTooltipLayout(`This is Fulfilled by You shipping cost.`)} arrow>
          <Box sx={{
            width: '13px',
            height: '15px',
            background: 'url("img/myProduct/product_fulfilled_by_you.png") no-repeat 0 0',
            backgroundSize: 'contain',
            marginLeft: "3px",
            marginBottom: "-3px",
            display: "inline-block"
          }} /></ButtonTooltip></Box>}
      <Box sx={{ width: "99px" }}>{GetCurrencySymbol(currentCurrency.toUpperCase())}{(costValue + currencyShippingFee).toFixed(2)}</Box>
      <Box sx={{
        width: '99px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: "30px",
        "& .MuiOutlinedInput-root": {
          height: "30px",
        }
      }} >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30px",
            position: "relative",
            border: '1px solid #999999',
            borderRadius: '5px',
            overflow: 'hidden'
          }}
        >
          <TextField
            focused
            onChange={(e) => {
              if (editSellingPriceNum === 0) {
                sendLog("MY_PRODUCTS_CARD_DIALOG_VARIANTS_EDIT_INFO", "change sell price");
                setEditSellingPriceNum(editSellingPriceNum + 1);
              }
              setSellingInput(e.target.value);

            }}
            onBlur={(e) => {
              const number = handleCheckNumber(e.target.value);
              setSellingValue(Number(number));
              setSellingInput(Number(number));
              const NewVariants = variants.map((val, ind) => {
                if (ind === index) {
                  val.price = Number(number);
                }
                return val;
              });
              setEphemeralVariants(NewVariants);
              // if (index === 0) {
              //   setOpenSavePriceAlert(true);
              //   setSavePrice(Number(number));
              // }
            }}
            value={sellingInput}
            sx={{
              paddingRight: "0",
              width: "74px",
              height: "30px",
              backgroundColor: "#FFF",
              borderRadius: "10px 0 0 10px",
              "& .MuiInputBase-root": {
                "&.MuiOutlinedInput-root input": {
                  textIndent: '8px',
                  height: "30px",
                  fontSize: '14px',
                  padding: 0
                },
              },
              "& .MuiOutlinedInput-root": {
                border: "none",
                "&.Mui-focused fieldset": {
                  borderRadius: "10px 0 0 10px",
                  border: "none",
                },
              },
            }}
          />
          <Box
            sx={{
              width: "33px",
              height: "30px",
              backgroundColor: "#D9D9D9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 400,
              fontSize: '10px'
            }}
          >
            {`${currentCurrency}`.toUpperCase()}
          </Box>
        </Box>
      </Box>
      <Box sx={{
        width: '99px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: "30px",
        "& .MuiOutlinedInput-root": {
          height: "30px",
        }
      }} >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30px",
            position: "relative",
            border: '1px solid #999999',
            borderRadius: '5px',
            overflow: 'hidden'
          }}
        >
          <TextField
            focused
            onChange={(e) => {
              setCompareAtPriceInput(e.target.value);
            }}
            onBlur={(e) => {
              const number = handleCheckNumber(e.target.value);
              setCompareAtPriceValue(Number(number));
              setCompareAtPriceInput(Number(number));
              const NewVariants = variants.map((val, ind) => {
                if (ind === index) {
                  val.compareAtPrice = Number(number);
                }
                return val;
              });
              setEphemeralVariants(NewVariants);
              // if (index === 0) {
              //   setOpenSavePriceAlert(true);
              //   setSavePrice(Number(number));
              // }
            }}
            value={compareAtPriceInput}
            sx={{
              paddingRight: "0",
              width: "74px",
              height: "30px",
              backgroundColor: "#FFF",
              borderRadius: "10px 0 0 10px",
              "& .MuiInputBase-root": {
                "&.MuiOutlinedInput-root input": {
                  textIndent: '8px',
                  height: "30px",
                  fontSize: '14px',
                  padding: 0
                },
              },
              "& .MuiOutlinedInput-root": {
                border: "none",
                "&.Mui-focused fieldset": {
                  borderRadius: "10px 0 0 10px",
                  border: "none",
                },
              },
            }}
          />
          <Box
            sx={{
              width: "33px",
              height: "30px",
              backgroundColor: "#D9D9D9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 400,
              fontSize: '10px'
            }}
          >
            {`${currentCurrency}`.toUpperCase()}
          </Box>
        </Box>
      </Box>
      {/* <Box sx={{ width: "99px" }}>{GetCurrencySymbol(currentCurrency.toUpperCase())}{comparePriceValue}</Box> */}
      <Box sx={{
        width: "99px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <Box sx={{ color: "#61C454" }}>
          {`+${currentCurrency.toUpperCase()} ${(parseFloat(sellingValue - costValue - currencyShippingFee)).toFixed(2)}`}
        </Box>
        <Box>{`(+${(100 * parseFloat((sellingValue - costValue - currencyShippingFee) / (costValue + currencyShippingFee))).toFixed(2)}%)`}</Box>
      </Box>
      <Box sx={{
        width: '99px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: "30px",
        "& .MuiOutlinedInput-root": {
          height: "30px",
        }
      }} >
        <TextField
          value={stockValue}
          size="small"
          onChange={(e) => {
            setStockValue(e.target.value);
          }}
          sx={{
            width: '81px'
          }}
          onBlur={(e) => {
            setTimeout(() => {
              const resStock = handleCheckNumber(e.target.value);
              setStockValue(Math.floor(resStock));
              if (Math.floor(resStock) > maxStock) {
                setState({ ...state, openEditSnackbar: true, editSnackbarText: 'Exceeded the maximum inventory' });
                setStockValue(maxStock);
                return;
              }
              const NewVariants = variants.map((val, ind) => {
                if (ind === index) {
                  val.inventoryQuantities.availableQuantity = Math.floor(resStock);
                }
                return val;
              });
              setEphemeralVariants(NewVariants);
            }, 100);
          }}
        />
      </Box>
    </Box >
  );
}

export default function VariantsContent(props) {
  const {
    currentClickID,
    variants,
    options,
    setEphemeralVariants,
    getVariantsSelectedIndexList,
    setEphemeralOptions,
    currency,
    setEphemeralCurrency,
    uneditedVariants,
    lastTimeVariantsSelectedIndexList,
    shippingSpec,
    setEphemeralShippingCountry,
    setEphemeralShippingID,
    ephemeralProductWeight,
    ephemeralFulfillmentOption,
    setEphemeralProductWeight,
    setEphemeralFulfillmentOption,
  } = props;
  if (shippingSpec == null) {
    return <div />;
  }
  const currencyCode = currency.toLowerCase();
  const [country, setCountry] = useState(shippingSpec.shippingMethodCountry != null ? shippingSpec.shippingMethodCountry : 'US');
  //const [shippingID, setShippingID] = useState(shippingSpec.id);
  const [shippingMethod, setShippingMethod] = useState(shippingSpec.shippingMethod);
  const [warehouse, setWarehouseList] = useState();
  const [productWeight, setProductWeight] = useState(ephemeralProductWeight);
  const [fulfillmentOption, setFulfillmentOption] = useState(ephemeralFulfillmentOption);

  const handleCheckNumber = (value) => {
    let str = value.replace(/[^\d^\.]+/g, '');
    const len1 = str.substr(0, 1);
    const len2 = str.substr(1, 1);

    //如果第一位是0，第二位不是点，就用数字把点替换掉
    if (str.length > 1 && len1 == 0 && len2 != '.') {
      str = str.substr(1, 1);
    }

    //第一位不能是.
    if (len1 == '.') {
      str = '';
    }

    //限制只能输入一个小数点
    if (str.indexOf(".") != -1) {
      const str_ = str.substr(str.indexOf(".") + 1);
      //限制只能输入一个小数点
      if (str_.indexOf(".") != -1) {
        str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
      }
    }

    return str;
  }

  let shipFromOptionIndex = 0;
  options.map((item, index) => {
    if (item === "Ship From" || item === "Ships From") {
      shipFromOptionIndex = index;
    }
  })
  const warehouseList = [];
  const warehouseOptionList = [];
  variants.map((item, index) => {
    if ((options[shipFromOptionIndex] === "Ship From" || options[shipFromOptionIndex] === "Ships From") && !warehouseOptionList.includes(item.options[shipFromOptionIndex])) {
      warehouseOptionList.push(item.options[shipFromOptionIndex]);
      warehouseList.push({
        option: item.options[shipFromOptionIndex],
        value: item.options[shipFromOptionIndex],
      });
    }
  })
  const { data, loading, refetch } = useQuery(GET_SHIPPING_OPTIONS, {
    variables: {
      id: String(currentClickID),
      warehouse: warehouseList != null && warehouseList.length > 0 ? warehouseList[0].option : "",
      productWeight: productWeight != null ? String(productWeight) : "0.4",
      fulfillmentOption,
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });


  let shippingOptionsMap = new Map();
  let shippingOptions = [{
    option: shippingMethod,
    value: shippingMethod,
  }]
  if (data != null && data.getProductShippingOptions != null) {
    shippingOptions = data.getProductShippingOptions.map(item => {
      shippingOptionsMap.set(
        item.shippingMethod,
        item,
      );
      return {
        option: item.shippingMethod,
        value: item.shippingMethod,
      }
    });
  }

  const { state, setState } = React.useContext(Context);
  const { sendLog } = state;
  const [checkedAll, setCheckedAll] = React.useState(lastTimeVariantsSelectedIndexList ? lastTimeVariantsSelectedIndexList.length === variants.length : true);
  const [openSavePriceAlert, setOpenSavePriceAlert] = useState(false);
  const [savePrice, setSavePrice] = useState(0);
  const [savePriceValue, setSavePriceValue] = useState(0);
  const [isClickSave, setIsClickSave] = useState(false);
  const [refetchPage, setRefetchPage] = useState(0);
  const [repetitionOptionIndexList, setRepetitionOptionIndexList] = React.useState([]);
  const [repetitionHeaderOptionIndexList, setRepetitionHeaderOptionIndexList] = React.useState([]);
  const [editHeaderOptionsNum, setEditHeaderOptionsNum] = useState(0);
  const [currentCurrency, setCurrentCurrency] = React.useState(currencyCode);
  const [previousCurrency, setPreviousCurrency] = React.useState(currencyCode);
  const [currentCurrencyOption, setCurrentCurrencyOption] = useState(currency);
  const [currentExchangeRate, setCurrentExchangeRate] = useState(1);
  const [USDToCurrentCurrencyExchangeRate, setUSDToCurrentCurrencyExchangeRate] = useState(1);
  const [profitMarginRate, setProfitMarginRate] = useState(0);
  const [profitMarginRateValue, setProfitMarginRateValue] = useState(0);
  const [countryChanged, setCountryChanged] = useState(false);
  const [shippingMethodIsLoadingComplete, setShippingMethodIsLoadingComplete] = useState(true);
  //const [bulkPrice, setBulkPrice] = useState(null);
  const [variantsSelectedIndexList, setVariantsSelectedIndexList] = useState(lastTimeVariantsSelectedIndexList ? lastTimeVariantsSelectedIndexList :
    variants.map((item, index) => {
      return index;
    }));
  const [getCurrencyRatio] = useLazyQuery(GET_CURRENCY_RATIO);
  const getCurrency = async (from, to) => {
    const result = await getCurrencyRatio({ variables: { from, to } });
    setCurrentExchangeRate(result.data.getCurrencyRatio);
    const USDToCurrentCurrencyResult = await getCurrencyRatio({ variables: { from: "usd", to } });
    setUSDToCurrentCurrencyExchangeRate(USDToCurrentCurrencyResult.data.getCurrencyRatio);
  };

  useEffect(() => {
    getCurrency(previousCurrency.toLowerCase(), currentCurrency.toLowerCase());
    setEphemeralCurrency(currentCurrency);
    setPreviousCurrency(currentCurrency);
  }, [currentCurrency]);

  // useEffect(() => {
  //   console.log(501, 'countryUpdate')
  //   // Refetch the shippingOptions
  //   const asyncFunction = (async () => {
  //     const data = await refetch({
  //       id: String(currentClickID),
  //       country: String(country),
  //     });

  //     setShippingMethod(data.data.getProductShippingOptions[0].shippingMethod);
  //   });
  //   asyncFunction();
  // }, [country]);

  const handleWatchrepetition = () => {
    let optionStringList = [];
    let repetition = [];
    variants.map((value, index) => {
      if (variantsSelectedIndexList.length === 0 || variantsSelectedIndexList.includes(index)) {
        optionStringList.push(value.options.join());
      } else {
        optionStringList.push('*******************' + index);
      }
    });
    optionStringList.map((val, index) => {
      if (optionStringList.indexOf(val) != optionStringList.lastIndexOf(val)) {
        repetition.push(index);
      }
    });

    if (repetition.length > 0) {
      setState({
        ...state,
        variantsIsWrong: true
      })
    } else {
      setState({
        ...state,
        variantsIsWrong: false
      })
    }
    setRepetitionOptionIndexList(repetition);
  }
  useEffect(() => {
    handleWatchrepetition();
  }, [variants]);

  // Update shipping fee to the selected currency
  let currencyShippingFee = (Math.round(Number(parseFloat(shippingOptionsMap.get(shippingMethod) != null && shippingOptionsMap.get(shippingMethod).fee != null ? shippingOptionsMap.get(shippingMethod).fee : shippingSpec.fee)) * USDToCurrentCurrencyExchangeRate * 100) / 100);
  currencyShippingFee = currencyShippingFee >= 0 ? currencyShippingFee : 0;

  // if (currentCurrency.toLowerCase() != "usd") {
  //   getCurrencyRatio({ variables: { from: "usd", to: currentCurrency.toLowerCase() } }).then((result) => {
  //     currencyShippingFee = result.data.getCurrencyRatio * currencyShippingFee;
  //   });
  // }

  let SKUShippingFee = shippingOptionsMap.get(shippingMethod) != null && shippingOptionsMap.get(shippingMethod).SKUShippingFee != null ? JSON.parse(shippingOptionsMap.get(shippingMethod).SKUShippingFee) : null;

  const setCountryEffect = async (value) => {
    setCountry(value);
    setEphemeralShippingCountry(value);
    setCountryChanged(true);
    const refetchData = await refetch({
      id: String(currentClickID),
      country: String(value),
      productWeight: String(productWeight),
      fulfillmentOption,
    });
    setShippingMethod(refetchData.data.getProductShippingOptions[0].shippingMethod);
  }

  const setWarehouseEffect = async (value) => {
    setWarehouseList(value);
    setCountryChanged(true);
    const refetchData = await refetch({
      id: String(currentClickID),
      country,
      warehouse: String(value),
      productWeight: String(productWeight),
      fulfillmentOption,
    });
    setShippingMethod(refetchData.data.getProductShippingOptions[0].shippingMethod);
  }

  const setShippingMethodEffect = (value) => {
    setShippingMethod(value);
    setEphemeralShippingID(shippingOptionsMap.get(value).id);
  }
  useEffect(() => {
    if (loading === false) {
      setTimeout(() => {
        setShippingMethodIsLoadingComplete(true);
      }, 500)
    } else {
      setShippingMethodIsLoadingComplete(false);
    }
  }, [loading]);

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        gap: '10px',
        fontSize: '14px',
        color: "#707070",
        justifyContent: "space-between"
        // "&>div": {
        //   flex: 1
        // }
      }}>
        <Box>
          <Box sx={{ height: "35px", lineHeight: '35px', display: "flex", alignItems: 'center' }}>
            <Box>Fulfillment Options</Box>
            <ButtonTooltip title={<React.Fragment>
              <Box sx={{
                color: '#FFF',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '150%'
              }}>
                <Box sx={{ marginBottom: "10px" }}>
                  <Box sx={{
                    width: '13px',
                    height: '15px',
                    background: 'url("img/myProduct/product_fulfilled_by_you_light.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                    marginRight: "3px",
                    marginBottom: "-3px",
                    display: "inline-block"
                  }} />Fulfilled by You: you fulfill directly on supplier website by placing an order there manually. This item may arrive with Temu package, and price tag, and under $10 minimum policy.
                </Box>
                <Box>
                  <Box sx={{
                    width: '13px',
                    height: '15px',
                    background: 'url("img/myProduct/product_fulfilled_by_cartifind.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                    marginRight: "3px",
                    marginBottom: "-3px",
                    display: "inline-block"
                  }} />Fulfilled by Cartifind: You can choose to fulfill from us, and our product price is similar or lower to supplier's price. But you need to pay us an additional VIP shipping fee to ship the item to your customer without any Temu package.
                </Box>
              </Box>
            </React.Fragment>} arrow>
              <Box sx={{
                width: '15px',
                height: '15px',
                background: 'url("img/common/icon_info.png") no-repeat 0 0',
                backgroundSize: 'contain',
                marginLeft: "3px",
              }} />
            </ButtonTooltip>
          </Box>
          <CustomSelected
            width="200px"
            height="40px"
            selectedWidth="200px"
            border="1px solid #999999"
            selectedItems={[
              {
                option: "Fulfilled by Cartifind",
                value: true,
              },
              {
                option: "Fulfilled by You",
                value: false,
              },
            ]}
            // defaultOption={"Fulfilled by Cartifind"}
            selectedValue={fulfillmentOption}
            setSelectValue={async (value) => {
              setCountryChanged(true);
              setEphemeralFulfillmentOption(value);
              setFulfillmentOption(value);
              const refetchData = await refetch({
                id: String(currentClickID),
                country,
                productWeight: String(productWeight),
                fulfillmentOption: value,
              });
              setShippingMethod(refetchData.data.getProductShippingOptions[0].shippingMethod);
            }}
          />
        </Box>
        {options.includes("Ship From") || false ? <Box>
          <Box sx={{ height: "35px", lineHeight: '35px' }}> Ship my order(s) from: </Box>
          <CustomSelected
            width="180px"
            height="40px"
            selectedWidth="200px"
            border="1px solid #999999"
            selectedItems={warehouseList}
            defaultOption={warehouseList != null && warehouseList.length > 0 ? warehouseList[0].option : ""}
            selectedValue={warehouse != null ? warehouse : (warehouseList != null && warehouseList.length > 0 ? warehouseList[0].option : "")}
            setSelectValue={setWarehouseEffect}
          // disabled={!fulfillmentOption}
          />
        </Box> : null}
        <Box>
          <Box sx={{ height: "35px", lineHeight: '35px' }}> Ship my order(s) to: </Box>
          <CustomSelected
            width={options.includes("Ship From") || false ? "180px" : "200px"}
            height="40px"
            selectedWidth="200px"
            border="1px solid #999999"
            selectedItems={countryList}
            selectedValue={country}
            setSelectValue={setCountryEffect}
          // disabled={!fulfillmentOption}
          />
        </Box>
        <Box>
          <Box sx={{ height: "35px", lineHeight: '35px' }}>Estimated Weight: </Box>
          <Box sx={{ display: "flex", alignItems: 'center' }}>
            <Box sx={{
              border: '1px solid #999999',
              width: options.includes("Ship From") || false ? "110px" : '200px',
              height: '40px',
              borderRadius: '10px',
              padding: '0 10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              boxSizing: 'border-box',
              fontSize: '16px',
              marginRight: "10px",
              background: "#FFF"
            }}>
              <TextField
                onChange={(e) => {
                  setProductWeight(e.target.value);
                }}
                onBlur={async (e) => {
                  let str = e.target.value.replace(/[^\d^\.]+/g, '');
                  const len1 = str.substr(0, 1);
                  const len2 = str.substr(1, 1);

                  //如果第一位是0，第二位不是点，就用数字把点替换掉
                  if (str.length > 1 && len1 == 0 && len2 != '.') {
                    str = str.substr(1, 1);
                  }

                  //第一位不能是.
                  if (len1 == '.') {
                    str = '';
                  }

                  //限制只能输入一个小数点
                  if (str.indexOf(".") != -1) {
                    const str_ = str.substr(str.indexOf(".") + 1);
                    //限制只能输入一个小数点
                    if (str_.indexOf(".") != -1) {
                      str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
                    }
                  }
                  setProductWeight(parseFloat(str));
                  setEphemeralProductWeight(parseFloat(str));
                  setCountryChanged(true);
                  const refetchData = await refetch({
                    id: String(currentClickID),
                    country,
                    productWeight: String(str),
                    fulfillmentOption,
                  });
                  setShippingMethod(refetchData.data.getProductShippingOptions[0].shippingMethod);
                }}
                value={productWeight}
                sx={{
                  width: options.includes("Ship From") || false ? "110px" : '200px',
                  height: "38px",
                  backgroundColor: "#FFF",
                  "& .MuiInputBase-root": {
                    "&.MuiOutlinedInput-root input": {
                      border: "none",
                      height: "38px",
                      fontSize: '16px',
                      padding: 0
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    border: "none",
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            </Box>KG
          </Box>
        </Box>

        <Box sx={{ width: "400px" }}>
          <Box sx={{ height: "35px", lineHeight: '35px' }}> Shipping Method: </Box>
          {shippingMethodIsLoadingComplete === false && countryChanged === true ? <Box sx={{ marginLeft: "10px", marginTop: "10px", height: "20px", color: "#FF97114D" }}><Spin /></Box> : <CustomSelected
            width="400px"
            height="40px"
            selectedWidth="400px"
            border="1px solid #999999"
            selectedItems={shippingOptions}
            defaultOption={shippingMethod}
            selectedValue={shippingMethod}
            setSelectValue={setShippingMethodEffect}
          // disabled={!fulfillmentOption}
          />}
        </Box>
      </Box>
      <Box sx={{
        //width: "1160px",
        height: "48px",
        backgroundColor: '#FF97114D',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        gap: '46px',
        fontSize: "15px",
        color: "#707070",
        marginTop: '10px'
      }}>
        <Box sx={{ marginLeft: '20px' }}>
          Estimated Delivery Time:
          <Typography variant="span" sx={{ fontWeight: '700', marginLeft: '2px' }}>
            {`${shippingOptionsMap.get(shippingMethod) != null && shippingOptionsMap.get(shippingMethod).time != null ? shippingOptionsMap.get(shippingMethod).time : shippingSpec.time}`}
          </Typography>
        </Box>
        <Box>Shipping Fee:
          <Typography variant="span" sx={{ fontWeight: '700', marginLeft: '2px' }}>
            {currencyShippingFee >= 0 ? `${currentCurrency.toUpperCase() + GetCurrencySymbol(currentCurrency.toUpperCase())}${currencyShippingFee}` : 'No Valid Option'}
          </Typography>
        </Box>
        <Box>Tracking Info: <Typography variant="span" sx={{ fontWeight: '700', marginLeft: '2px' }}>
          {`${(shippingOptionsMap.get(shippingMethod) != null && shippingOptionsMap.get(shippingMethod).hasTracking != null ? shippingOptionsMap.get(shippingMethod).hasTracking : shippingSpec.hasTracking) == true ? 'Available' : 'Not Available'}`}
        </Typography>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        height: "48px",
        fontSize: "15px",
        color: "#707070",
      }}>
        <Box>Bulk Edit Profit Rate:</Box>
        <Box
          sx={{
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            position: "relative",
            border: '1px solid #999999',
            borderRadius: '5px',
            overflow: 'hidden'
          }}
        >
          <TextField
            focused
            onChange={(e) => {
              setProfitMarginRate(e.target.value);
            }}
            onBlur={(e) => {
              const number = handleCheckNumber(e.target.value);
              setProfitMarginRateValue(Number(number));
              setProfitMarginRate(Number(number));
              setSavePriceValue(0);
              setSavePrice(0);
            }}
            value={profitMarginRate}
            sx={{
              paddingRight: "0",
              width: "45px",
              height: "35px",
              backgroundColor: "#FFF",
              borderRadius: "10px 0 0 10px",
              "& .MuiInputBase-root": {
                "&.MuiOutlinedInput-root input": {
                  textIndent: '10px',
                  height: "35px",
                  fontSize: '14px',
                  padding: 0
                },
              },
              "& .MuiOutlinedInput-root": {
                border: "none",
                "&.Mui-focused fieldset": {
                  borderRadius: "10px 0 0 10px",
                  border: "none",
                },
              },
            }}
          />
          <Box
            sx={{
              width: "30px",
              height: "35px",
              backgroundColor: "#D9D9D9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 400,
              fontSize: '14px'
            }}
          >
            %
          </Box>
        </Box>
        <Box sx={{ marginLeft: "20px" }}>Bulk Edit Prices:</Box>
        <Box
          sx={{
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            position: "relative",
            border: '1px solid #999999',
            borderRadius: '5px',
            overflow: 'hidden'
          }}
        >
          <TextField
            focused
            onChange={(e) => {
              setSavePrice(e.target.value);
              // setIsClickSave(true);
            }}
            onBlur={(e) => {
              const number = handleCheckNumber(e.target.value);
              setSavePriceValue(Number(number));
              setSavePrice(Number(number));
              setProfitMarginRateValue(0);
              setProfitMarginRate(0);
              setIsClickSave(true);
            }}
            value={savePrice}
            sx={{
              paddingRight: "0",
              width: "67px",
              height: "35px",
              backgroundColor: "#FFF",
              borderRadius: "10px 0 0 10px",
              "& .MuiInputBase-root": {
                "&.MuiOutlinedInput-root input": {
                  textIndent: '10px',
                  height: "35px",
                  fontSize: '14px',
                  padding: 0
                },
              },
              "& .MuiOutlinedInput-root": {
                border: "none",
                "&.Mui-focused fieldset": {
                  borderRadius: "10px 0 0 10px",
                  border: "none",
                },
              },
            }}
          />
          <Box
            sx={{
              width: "45px",
              height: "35px",
              backgroundColor: "#D9D9D9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 400,
              fontSize: '14px'
            }}
          >
            {currentCurrency.toUpperCase()}
          </Box>
        </Box>
        <Box sx={{ marginLeft: "20px" }}>Currency:</Box>
        <Box sx={{ marginLeft: "10px" }}>
          <CustomSelected
            sx={{ marginLeft: "10px" }}
            width="100px"
            height="35px"
            selectedWidth="80px"
            border="1px solid #999999"
            selectedItems={currencyList}
            defaultOption={currentCurrency.toUpperCase()}
            selectedValue={currentCurrency}
            setSelectValue={setCurrentCurrency}
          />
        </Box>
      </Box>

      <Box sx={{
        marginTop: "10px",
        border: '1px solid #999999',
        borderRadius: '2px',
      }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "space-between",
            fontWeight: "400",
            fontSize: "15px",
            fontFamily: "Arial",
            fontStyle: "normal",
            lineHeight: "150%",
            fontWeight: "bold",
            color: "#707070",
            height: "40px",
            backgroundColor: "#D9D9D9",
            "& .MuiInputBase-input": {
              textAlign: "center",
              fontWeight: "400",
              fontSize: "18px",
              fontFamily: "Arial",
              fontStyle: "normal",
              lineHeight: "150%",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#999999",
              },
              "&:hover fieldset": {
                borderColor: "#999999",
              },
              "& fieldset": {
                border: '2px solid #999999'
              },
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100px",
              flexShrink: "0"
            }}
          >
            <Checkbox
              sx={{
                marginLeft: "10px",
                width: "2px",
                height: "2px",
                color: "#FF9711",
                "&.Mui-checked": {
                  color: "#FF9711",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "30px",
                },
              }}
              checked={checkedAll}
              onChange={(e) => {
                let Arr = [];
                if (e.target.checked) {
                  variants.map((item, index) => {
                    Arr.push(index)
                  });
                  setVariantsSelectedIndexList(Arr);
                  getVariantsSelectedIndexList(Arr);
                  setCheckedAll(true);
                } else {
                  setVariantsSelectedIndexList([]);
                  getVariantsSelectedIndexList([]);
                  setCheckedAll(false);
                }
                let optionStringList = [];
                let repetition = [];
                variants.map((value, index) => {
                  if (Arr.length === 0 || Arr.includes(index)) {
                    optionStringList.push(value.options.join());
                  } else {
                    optionStringList.push('*******************' + index);
                  }
                });
                optionStringList.map((val, index) => {
                  if (optionStringList.indexOf(val) != optionStringList.lastIndexOf(val)) {
                    repetition.push(index);
                  }
                });

                if (repetition.length > 0) {
                  setState({
                    ...state,
                    variantsIsWrong: true
                  })
                } else {
                  setState({
                    ...state,
                    variantsIsWrong: false
                  })
                }
                setRepetitionOptionIndexList(repetition);
              }}
              inputProps={{
                "aria-label": "Checked All",
              }}
            />
            <Box sx={{ flex: "1" }}>Picture</Box>
          </Box>
          <Box sx={{ minWidth: "132px", marginRight: "5px" }}>SKU</Box>
          {options.map((item, index) => {
            return (
              <Box
                sx={{
                  marginRight: "11px",
                  marginLeft: "11px",
                  width: "81px",
                  height: "29px",
                  textAlign: "center",
                  position: 'relative',
                  fontWeight: "bold",
                  color: "#707070",
                  "& .MuiOutlinedInput-root": {
                    height: "29px",
                    fontWeight: "bold",
                    color: "#707070",
                    "&.Mui-focused fieldset": {
                      border: repetitionOptionIndexList.length > 0 || repetitionHeaderOptionIndexList.length > 0 ? "4px solid #E84343" : "1px solid #999999",
                    },
                    "&:hover fieldset": {
                      border: repetitionOptionIndexList.length > 0 || repetitionHeaderOptionIndexList.length > 0 ? "4px solid #E84343" : "1px solid #999999",
                    },
                    "& fieldset": {
                      border: repetitionOptionIndexList.length > 0 || repetitionHeaderOptionIndexList.length > 0 ? "4px solid #E84343" : "1px solid #999999",
                    },
                  }
                }}
                key={index + "title"}
              >
                <TextField
                  sx={{
                    height: "30px",
                    "& .MuiInputBase-root": {
                      backgroundColor: '#FFF',
                    },
                    "& .MuiInputBase-input": {
                      fontSize: '14px',
                    }
                  }}
                  value={item}
                  size="small"
                  onChange={(e) => {
                    if (editHeaderOptionsNum === 0) {
                      sendLog("MY_PRODUCTS_CARD_DIALOG_VARIANTS_EDIT_INFO", "change header options");
                      setEditHeaderOptionsNum(editHeaderOptionsNum + 1);
                    }
                    let midArr = options;
                    midArr[index] = e.target.value;
                    setEphemeralOptions(midArr);
                    let repetition = [];
                    options.map((val, ind) => {
                      if (options.indexOf(val) != options.lastIndexOf(val)) {
                        repetition.push(ind);
                      }
                    });
                    if (repetition.length > 0) {
                      setState({
                        ...state,
                        variantsIsWrong: true
                      })
                    } else {
                      setState({
                        ...state,
                        variantsIsWrong: false
                      })
                    }
                    setRepetitionHeaderOptionIndexList(repetition);
                    setRefetchPage(refetchPage + 1);
                  }}
                />

                <Box sx={{
                  width: '23px',
                  height: '23px',
                  borderRadius: '50%',
                  backgroundColor: '#E62E2EE5',
                  display: "flex",
                  alignItems: "center",
                  justifyContent: 'center',
                  position: 'absolute',
                  top: '-12px',
                  right: '-12px',
                  cursor: 'pointer'
                }} onClick={() => {
                  let Arr = options;
                  Arr.splice(index, 1);
                  setEphemeralOptions(Arr);
                  variants.map(({ options }) => {
                    options.splice(index, 1);
                  });
                  setEphemeralVariants(variants);
                  handleWatchrepetition();
                  if (Arr.length === 0) {
                    setEphemeralVariants([variants[0]]);
                    setRepetitionHeaderOptionIndexList([]);
                    setRepetitionOptionIndexList([]);
                    setState({
                      ...state,
                      variantsIsWrong: false
                    });
                  }
                  setRefetchPage(refetchPage + 1);
                }}><Box sx={{ width: '15px', height: '3.6px', borderRadius: '1.8px', backgroundColor: '#FFF' }} /></Box>
              </Box>
            );
          })}
          <Box sx={{ width: "99px", fontSize: '14px' }}>
            Product Cost
          </Box>
          <Box sx={{ width: "99px", fontSize: '14px' }}>
            Shipping Fee
          </Box>
          <Box sx={{ width: "99px", fontSize: '14px' }}>
            Total Cost
          </Box>
          <Box sx={{ width: "99px", fontSize: '14px' }}>
            Your Price
          </Box>
          <Box sx={{ width: "99px", fontSize: '14px' }}>
            Compare
          </Box>
          <Box sx={{ width: "99px", fontSize: '14px' }}>
            Net Profit
          </Box>
          <Box sx={{ width: "99px", fontSize: '14px' }}>Inventory</Box>
        </Box>
        {variants.map((value, index) => {
          if (uneditedVariants[index].inventoryQuantities == null) {
            return <div />
          }
          if (options.includes("Ship From") && value.options[shipFromOptionIndex] === (warehouse != null ? warehouse : (warehouseList != null && warehouseList.length > 0 ? warehouseList[0].option : "")) || !options.includes("Ship From") || true) {
            return (
              <TextCenter
                sku={value.sku}
                options={value.options}
                price={isClickSave ? Number(savePriceValue) : Number(value.price)}
                compareAtPrice={isClickSave ? Number(savePriceValue) : (value.compareAtPrice != null ? Number(value.compareAtPrice) : Number(value.price))}
                index={index}
                variants={variants}
                setEphemeralVariants={setEphemeralVariants}
                cost={value.inventoryItem.cost}
                imageSrc={value.imageSrc}
                key={index}
                setCheckedAll={setCheckedAll}
                currentExchangeRate={currentExchangeRate}
                getVariantsSelectedIndexList={getVariantsSelectedIndexList}
                setOpenSavePriceAlert={setOpenSavePriceAlert}
                setSavePrice={setSavePrice}
                maxStock={uneditedVariants[index].inventoryQuantities.availableQuantity}
                stock={value.inventoryQuantities.availableQuantity}
                isRepetition={repetitionOptionIndexList.includes(index)}
                variantsSelectedIndexList={variantsSelectedIndexList}
                setVariantsSelectedIndexList={setVariantsSelectedIndexList}
                handleWatchrepetition={handleWatchrepetition}
                currentCurrency={currentCurrency}
                currencyShippingFee={SKUShippingFee != null ? (Math.round(Number(parseFloat(SKUShippingFee[value.sku])) * USDToCurrentCurrencyExchangeRate * 100) / 100) : (Math.round(Number(parseFloat(currencyShippingFee)) * 100) / 100)}
                profitMarginRate={profitMarginRateValue}
                fulfillmentOption={fulfillmentOption}
              />
            );
          }

        })}
      </Box >
    </Box>
  );
}
