import React from 'react';
import { Box } from '@mui/system';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

export default function ReviewCard(props) {
    const {
        src,
        name,
        email,
        reviewContent,
        reviewTime
    } = props;
    return (
        <Box sx={{
            width: '89rem',
            height: '57.9rem',
            background: '#FFFFFF',
            boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.25), 0px 7px 5px 1px rgba(0, 0, 0, 0.25)',
            borderRadius: '1rem',
            marginRight: '4rem'
        }}>
            <Box sx={{
                height: '46.9rem',
                boxSizing: "border-box",
                padding: '4.2rem 2.1rem 2.6rem'
            }}>
                <Box sx={{
                    height: '16rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '4rem'
                }}>
                    <Box sx={{
                        height: '16rem',
                        display: 'flex',
                        fontSize: '2.8rem',
                    }}>
                        <Avatar
                            alt={src}
                            src={src ? src : "/img/review_default_Avatar1.png"}
                            sx={{ width: '16rem', height: '16rem' }}
                        />
                        <Box sx={{ marginLeft: '3rem' }}>
                            <Box sx={{ fontWeight: '700', marginTop: '2.2rem', height: '5.6rem' }}>{name}</Box>
                            <Box sx={{ height: '5.6rem' }}>{email}</Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        "& div": {
                            width: '1.5rem',
                            height: '1.5rem',
                            borderRadius: '50%',
                            backgroundColor: '#707070',
                            marginLeft: '1.8rem'
                        }
                    }}>
                        <Box />
                        <Box />
                        <Box />
                    </Box>
                </Box>
                <Box sx={{
                    width: '80.8rem',
                    height: '10.7rem',
                    fontSize: '2.6rem',
                    color: '#666666',
                    marginBottom: '3.1rem'
                }}>{reviewContent}</Box>
                <Box sx={{
                    width: '28.4rem',
                    height: '5.2rem',
                    lineHeight: '5.2rem',
                    color: '#707070',
                    fontSize: '2.6rem'
                }}>{reviewTime}</Box>
            </Box>
            <Box sx={{
                borderTop: '0.1rem solid rgba(112, 112, 112, 1)',
                height: '11rem',
                boxSizing: "border-box",
                display: 'flex',
                padding: '0 7.2rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '2.6rem',
                color: '#707070'
            }}>
                <Box sx={{
                    width: '34rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Box sx={{
                            width: '3.5rem',
                            height: '3.5rem',
                            background: 'url("/img/review_click.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                        }}></Box>1235
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Box sx={{
                            width: '3.5rem',
                            height: '3.5rem',
                            background: 'url("/img/review_reply.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                        }}></Box>1235
                    </Box>
                </Box>
                <Box sx={{
                    width: '3.5rem',
                    height: '3.5rem',
                    background: 'url("/img/review_share.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                }}></Box>
            </Box>
        </Box >
    );
}