import React, { useState } from "react";
import "./App.css";
import { Context } from "./Context/Context";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import { gql, useMutation, useQuery } from "@apollo/client";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "doc-cookie";
import AccountPopUpDialog from "./CommonComponents/AccountPopUpDialog.react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { countryList } from "./utils/CurrencyList.js";
import CustomSelected from "./CommonComponents/CustomSelected.react";
import { Spin } from 'antd';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Carousel } from 'antd';

const SIGN_OUT = gql`
  mutation Mutation {
    signOut
  }
`;
const SET_SHIP_TO_COUNTRY_AND_SETTING_FOURPX_SHIPPING_OPTIONS = gql`
  mutation setShipToCountryAndSettingFourPXShippingOptions($shipToCountry: String) {
    setShipToCountryAndSettingFourPXShippingOptions(shipToCountry: $shipToCountry){
      status
      message
    }
  }
`;

const GET_REFERRAL_EARNINGS = gql`
  query getReferralEarnings {
    getReferralEarnings {
      inviteEarnings
    }
  }
`;

export default function AppHeader(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const _title = queryParams.get("search") != null ? queryParams.get("search") : "";
  const { setState, state } = React.useContext(Context);
  const { firstName, shopName, sendLog, isOpenYellowBanner, email } = state;
  const [title, setTitle] = useState(_title);
  const [sign_out] = useMutation(SIGN_OUT);
  const [set_ship_to_country_and_setting_fourPX_shipping_options] = useMutation(SET_SHIP_TO_COUNTRY_AND_SETTING_FOURPX_SHIPPING_OPTIONS);
  const navigate = useNavigate();

  const [openMenu, setOpenMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const firstNameBox = React.useRef(null);
  const storePlatform = localStorage.getItem("storePlatform");
  const [country, setCountry] = React.useState(localStorage.getItem("shipToCountry") != null ? localStorage.getItem("shipToCountry") : "US");
  const [isLoadSetCountry, setIsLoadSetCountry] = React.useState(false);
  const [earnings, setEarnings] = React.useState(0);

  const { loading, data } = useQuery(GET_REFERRAL_EARNINGS, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    if (loading == false && data != null && data.getReferralEarnings != null) {
      setEarnings(data.getReferralEarnings.inviteEarnings != null ? data.getReferralEarnings.inviteEarnings : 0);
    }
  }, [loading])

  React.useEffect(() => {
    setTitle(_title);
  }, [_title]);
  React.useEffect(() => {
    if (sessionStorage.getItem("showIpopifyBanner") == null && isOpenYellowBanner) {
      sendLog("SHOW_IPOPIFY_BANNER");
      sessionStorage.setItem("showIpopifyBanner", "1")
    }
  }, []);
  const countryEffect = async (country) => {
    setIsLoadSetCountry(true);
    const result = await set_ship_to_country_and_setting_fourPX_shipping_options({
      variables: {
        shipToCountry: country,
      },
    })
    setIsLoadSetCountry(false);
    if (result != null && result.data != null && result.data.setShipToCountryAndSettingFourPXShippingOptions != null && result.data.setShipToCountryAndSettingFourPXShippingOptions.status) {
      setCountry(country);
      localStorage.setItem("shipToCountry", country);
    } else {
      setState({
        ...state,
        openSnackbar: true,
        snackbarText: `${country} FourPX api is failed`,
        snackbarState: 'FAILED',
      });
    }
  }
  if (firstName == null) {
    return (<Box sx={{
      height: isOpenYellowBanner ? "138px" : "64px"
    }}>
      <AccountPopUpDialog />
      {isOpenYellowBanner ? <Box sx={{
        height: '74px',
        width: "100%",
        minWidth: '1440px',
        position: 'fixed',
        top: '0rem',
        left: '0',
        zIndex: '999',
        backgroundColor: '#f2f2f2'
      }}>

        <Carousel dotPosition="left" dots={false} autoplay autoplaySpeed={5000}>
          <Box sx={{
            backgroundColor: 'rgb(97, 196, 84)',
            height: '64px',
            display: 'flex!important',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            fontSize: '16px',
            fontWeight: '400',
            color: '#000',
            lineHeight: '64px',
            letterSpacing: '0.15px',
            cursor: "pointer",
          }} onClick={() => {
            sendLog("IPOPIFY_BANNER_CLICK");
            window.open("https://apps.shopify.com/ipopify-google-onetap-signin");
          }} >
            <Box
              sx={{
                width: "3rem",
                height: "3rem",
                marginRight: "1rem",
                background: 'url("/img/common/icon_inform.png") no-repeat 0 0',
                backgroundSize: "contain",
              }} />
            <Box>Free Offer ($39.95) : Acquire new customers with the most Instant Newsletter ever, iPopify. <Typography variant="span" sx={{
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '64px',
              color: "#000",
              cursor: "pointer",
              textDecoration: "underline"
            }}>Install Now</Typography></Box>

            {/* <Box sx={{ background: 'url("/img/no_circle_close.png") no-repeat 0 0', width: '20px', height: '20px', backgroundSize: 'contain', position: 'absolute', top: '22px', right: "40px", cursor: 'pointer', zIndex: "10" }} onClick={}></Box> */}
          </Box>
          <Box sx={{
            backgroundColor: 'rgb(97, 196, 84)',
            height: '64px',
            display: 'flex!important',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            fontSize: '16px',
            fontWeight: '400',
            color: '#000',
            lineHeight: '64px',
            letterSpacing: '0.15px',
            cursor: "pointer",
          }} onClick={() => {
            sendLog("IPOPIFY_BANNER_CLICK");
            window.open("https://apps.shopify.com/ipopify-google-onetap-signin");
          }} >
            <Box
              sx={{
                width: "3rem",
                height: "3rem",
                marginRight: "1rem",
                background: 'url("/img/common/icon_inform.png") no-repeat 0 0',
                backgroundSize: "contain",
              }} />
            <Box>Free Offer ($39.95) : Acquire new customers with the most Instant Newsletter ever, iPopify. <Typography variant="span" sx={{
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '64px',
              color: "#000",
              cursor: "pointer",
              textDecoration: "underline"
            }}>Install Now</Typography></Box>
            {/* <Box sx={{ background: 'url("/img/no_circle_close.png") no-repeat 0 0', width: '20px', height: '20px', backgroundSize: 'contain', position: 'absolute', top: '22px', right: "40px", cursor: 'pointer', zIndex: "10" }} onClick={}></Box> */}
          </Box>
        </Carousel>
        <CloseIcon sx={{ fontSize: '20px', color: '#FFF', position: 'absolute', top: '22px', right: "40px", }} onClick={(e) => {
          e.stopPropagation();
          localStorage.setItem("isShowIpopifyLimitedTimeBanner", "false");
          setState({
            ...state,
            isOpenYellowBanner: false
          });
        }} />
      </Box> : null}
      <Box
        sx={{
          width: "100%",
          minWidth: '1440px',
          height: "64px",
          backgroundColor: "#FFF",
          color: "#FF9711",
          fontWeight: 700,
          fontFamily: "Arial",
          letterSpacing: "0.15px",
          display: 'flex',
          alignItems: 'center',
          position: 'fixed',
          top: isOpenYellowBanner ? '74px' : "0px",
          left: '0',
          zIndex: '999',
          boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.25)',
          boxSizing: "border-box",
          padding: '0 63px 0 30px'
        }}
      >
        <Box
          onClick={() => {
            sendLog("LOGO_CLICK");
            window.location.href = "/app";
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Box sx={{ width: "21.6rem", height: "6rem", display: "flex", alignItems: "center", }}>
            <img
              src="/img/logo.png"
              width="100%"
              loading={"lazy"}
              alt="Cartifind logo"
            />
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: "27.2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "5.5rem",
            position: "relative",
            border: '0.5rem solid #FF9711',
            borderRadius: '1rem',
            overflow: 'hidden'
          }}
        >
          <TextField
            focused
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                sendLog("SEARCH_INPUT_ENTER", title);
                if (title.includes('https:') || title.includes('www.')) {
                  setState({
                    ...state,
                    isOpenImportAliExpressURLDialog: true
                  })
                } else {
                  navigate("/app?pageIndex=1&search=" + title);
                  setState({ ...state, title });
                  document.documentElement.scrollTop = 0;
                }
              }
            }}
            value={title}
            sx={{
              paddingRight: "0",
              width: "51.2rem",
              height: "6.1rem",
              backgroundColor: "#FFF",
              borderRadius: "1rem 0 0 1rem",
              "& .MuiInputBase-root": {
                "&.MuiOutlinedInput-root input": {
                  textIndent: '2rem',
                  height: '6.1rem',
                  fontSize: '2rem',
                  padding: 0
                },
              },
              "& .MuiOutlinedInput-root": {
                border: "none",
                "&.Mui-focused fieldset": {
                  borderRadius: "1rem 0 0 1rem",
                  border: "none",
                },
              },
            }}
          />
          <Box
            sx={{
              width: "8.4rem",
              height: "7.5rem",
              backgroundColor: "#FF9711",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              fontWeight: 400,
              fontSize: '2.2rem'
            }}
            onClick={() => {
              sendLog("SEARCH_BUTTON_CLICK", title);
              // If this is a URL
              if (title.includes('https:') || title.includes('www.')) {
                setState({
                  ...state,
                  isOpenImportAliExpressURLDialog: true
                })
              } else {
                navigate("/app?pageIndex=1&search=" + title);
                setState({ ...state, title });
                document.documentElement.scrollTop = 0;
              }
            }}
          >
            Search
          </Box>
          {title === "" ? (
            <SearchIcon sx={{
              position: "absolute",
              left: "2rem",
              top: "50%",
              transform: "translate(0%, -50%)", fontSize: "3.2rem", color: "#C3CAD9"
            }} />
          ) : null}
        </Box>
        <Box sx={{
          width: "18.2rem",
          height: "5rem",
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          fontSize: "1.8rem",
        }}>
          <Box sx={{
            color: '#666666',
            fontSize: '2rem',
            fontWeight: '400',
            marginRight: '3.9rem',
            cursor: 'pointer'
          }} onClick={() => {
            sendLog("SIGN_IN_CLICK");
            setState({
              ...state,
              isAccountPopUpDialogOpen: true,
              currentComponentName: 'SIGN_IN',
            });
          }}>SIGN IN</Box>
          <Box sx={{
            width: '15.8rem',
            height: '6.8rem',
            borderRadius: '1rem',
            color: '#FFF',
            backgroundColor: "#FF9711",
            lineHeight: '6.8rem',
            fontSize: '2rem',
            textAlign: 'center',
            cursor: 'pointer'
          }} onClick={() => {
            sendLog("SIGN_UP_CLICK");
            setState({
              ...state,
              isAccountPopUpDialogOpen: true,
              currentComponentName: 'SIGN_UP',
            });
          }}>SIGN UP</Box>
        </Box>
      </Box>
    </Box >
    );
  }
  return (
    <Box sx={{
      height: isOpenYellowBanner ? "138px" : "64px"
    }}>
      <AccountPopUpDialog />
      {isOpenYellowBanner ? <Box sx={{
        height: '74px',
        width: "100%",
        minWidth: '1440px',
        position: 'fixed',
        top: '0rem',
        left: '0',
        zIndex: '999',
        backgroundColor: '#f2f2f2'
      }}>

        <Carousel dotPosition="left" dots={false} autoplay autoplaySpeed={5000}>
          <Box sx={{
            backgroundColor: 'rgb(97, 196, 84)',
            height: '64px',
            display: 'flex!important',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            fontSize: '16px',
            fontWeight: '400',
            color: '#000',
            lineHeight: '64px',
            letterSpacing: '0.15px',
            cursor: "pointer",
          }} onClick={() => {
            sendLog("IPOPIFY_BANNER_CLICK");
            window.open("https://apps.shopify.com/ipopify-google-onetap-signin");
          }} >
            <Box
              sx={{
                width: "3rem",
                height: "3rem",
                marginRight: "1rem",
                background: 'url("/img/common/icon_inform.png") no-repeat 0 0',
                backgroundSize: "contain",
              }} />
            <Box>Free Offer ($39.95) : Acquire new customers with the most Instant Newsletter ever, iPopify. <Typography variant="span" sx={{
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '64px',
              color: "#000",
              cursor: "pointer",
              textDecoration: "underline"
            }}>Install Now</Typography></Box>

            {/* <Box sx={{ background: 'url("/img/no_circle_close.png") no-repeat 0 0', width: '20px', height: '20px', backgroundSize: 'contain', position: 'absolute', top: '22px', right: "40px", cursor: 'pointer', zIndex: "10" }} onClick={}></Box> */}
          </Box>
          <Box sx={{
            backgroundColor: 'rgb(97, 196, 84)',
            height: '64px',
            display: 'flex!important',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            fontSize: '16px',
            fontWeight: '400',
            color: '#000',
            lineHeight: '64px',
            letterSpacing: '0.15px',
            cursor: "pointer",
          }} onClick={() => {
            sendLog("IPOPIFY_BANNER_CLICK");
            window.open("https://apps.shopify.com/ipopify-google-onetap-signin");
          }} >
            <Box
              sx={{
                width: "3rem",
                height: "3rem",
                marginRight: "1rem",
                background: 'url("/img/common/icon_inform.png") no-repeat 0 0',
                backgroundSize: "contain",
              }} />
            <Box>Free Offer ($39.95) : Acquire new customers with the most Instant Newsletter ever, iPopify. <Typography variant="span" sx={{
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '64px',
              color: "#000",
              cursor: "pointer",
              textDecoration: "underline"
            }}>Install Now</Typography></Box>
            {/* <Box sx={{ background: 'url("/img/no_circle_close.png") no-repeat 0 0', width: '20px', height: '20px', backgroundSize: 'contain', position: 'absolute', top: '22px', right: "40px", cursor: 'pointer', zIndex: "10" }} onClick={}></Box> */}
          </Box>
        </Carousel>
        <CloseIcon sx={{ fontSize: '20px', color: '#FFF', position: 'absolute', top: '22px', right: "40px", }} onClick={(e) => {
          e.stopPropagation();
          localStorage.setItem("isShowIpopifyLimitedTimeBanner", "false");
          setState({
            ...state,
            isOpenYellowBanner: false
          });
        }} />
      </Box> : null}
      <Box
        sx={{
          width: "100%",
          minWidth: '1440px',
          height: "64px",
          backgroundColor: "#FFF",
          color: "#FF9711",
          fontWeight: 700,
          fontFamily: "Arial",
          letterSpacing: "0.15px",
          display: 'flex',
          alignItems: 'center',
          position: 'fixed',
          top: isOpenYellowBanner ? '74px' : "0px",
          left: '0',
          zIndex: '999',
          boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Box
          onClick={() => {
            sendLog("LOGO_CLICK");
            window.location.href = "/app";
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "30px",
            cursor: "pointer",
          }}
        >
          <Box sx={{ width: "165px", height: "45px", display: "flex", alignItems: "center", }}>
            <img
              src="/img/logo.png"
              width="100%"
              loading={"lazy"}
              alt="Cartifind logo"
            />
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: "169px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "46px",
            position: "relative",
            border: '2px solid #FF9711',
            borderRadius: '1rem',
            overflow: 'hidden',
            boxSizing: "border-box"
          }}
        >
          <TextField
            focused
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                sendLog("SEARCH_INPUT_ENTER", title);
                navigate("/app?pageIndex=1&search=" + title);
                setState({ ...state, title });
                document.documentElement.scrollTop = 0;
              }
            }}
            value={title}
            placeholder="Search Cartifind"
            sx={{
              paddingRight: "0",
              width: "657px",
              height: "45px",
              backgroundColor: "#fff",
              borderRadius: "1rem 0 0 1rem",
              "& .MuiInputBase-root": {
                "&.MuiOutlinedInput-root input": {
                  textIndent: '20px',
                  height: '45px',
                  fontSize: '16px',
                  padding: 0
                },
              },
              "& .MuiOutlinedInput-root": {
                border: "none",
                "&.Mui-focused fieldset": {
                  borderRadius: "10px 0 0 10px",
                  border: "none",
                },
              },
            }}
          />
          <Box
            sx={{
              width: "55px",
              height: "46px",
              backgroundColor: "#FF9711",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              fontWeight: 400,
              boxSizing: "border-box"
            }}
            onClick={() => {
              sendLog("SEARCH_BUTTON_CLICK", title);
              navigate("/app?pageIndex=1&search=" + title);
              setState({ ...state, title });
              document.documentElement.scrollTop = 0;
            }}
          >
            <img
              src="/img/header/AppHeader_icon_search.png"
              width="21px"
              height="21px"
              loading={"lazy"}
              alt="Cartifind logo"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "350px",
            height: "50px",
            marginLeft: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "14px",
            color: '#666666'
          }}
        >
          <img
            src="/img/header/AppHeader_icon_locating_point.png"
            width="19px"
            height="27px"
            loading={"lazy"}
            alt="locating point icon"
          />
          <Box sx={{ fontSize: "14px" }}>
            <Box sx={{ padding: "0px 10px" }}>Ship to</Box>
            {isLoadSetCountry ? <Box sx={{ marginLeft: "10px", marginTop: "10px", height: "10px", color: "#FF97114D" }}><Spin /></Box> : <CustomSelected
              width="auto"
              height="30px"
              selectedWidth="150px"
              border="0px solid #999999"
              selectedItems={countryList}
              selectedValue={country}
              setSelectValue={countryEffect}
              fontSize={"14px"}
              topArrowNode={<ArrowDropUpIcon sx={{ fontSize: "3.2rem" }} />}
              bottomArrowNode={<ArrowDropDownIcon sx={{ fontSize: "3.2rem" }} />}
            />}
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            cursor: "pointer",
            width: "182px",
            height: "50px",
            marginLeft: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            fontSize: "14px",
            color: '#666666'
          }}
        >
          <Box>
            <Box
              aria-controls={openMenu ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onClick={(e) => {
                setOpenMenu(true);
                setAnchorEl(e.currentTarget)
              }}
              ref={firstNameBox}
              sx={{ display: "flex", alignItems: "center" }}
            >{
                storePlatform != "wix" ? <img
                  src="/img/shopify_logo.png"
                  height="50px"
                  loading={"lazy"}
                  alt="shopify logo"
                /> : <img
                  src="/img/wix_logo.png"
                  height="45px"
                  loading={"lazy"}
                  alt="wix logo"
                />
              }
              {
                storePlatform != "wix" ?
                  (shopName != null ? <Box>
                    {shopName.replace("myshopify.com", "")}
                    {shopName.includes("myshopify.com") ? <Box>myshopify.com</Box> : null}
                  </Box> : firstName) :
                  (shopName != null ? <Box sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 3,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                  }}>{shopName}</Box> : firstName)
              }
            </Box>
            <Menu
              sx={{
                "& .MuiList-root.MuiList-padding.MuiMenu-list": {
                  width: "240px"
                }
              }}
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={(e) => {
                e.stopPropagation();
                setAnchorEl(null)
                setOpenMenu(false);
              }}
              TransitionComponent={Fade}
            >
              <Box sx={{
                width: '240px',
                minHeight: '68px',
                padding: "8px 16px",
                boxSizing: "border-box",
                borderBottom: "1px solid #e0e0e0",
                "&>div": {
                  lineHeight: '23px',
                }
              }}>
                <Box sx={{
                  fontSize: '16px',
                  color: "rgba(0, 0, 0, 0.87)"
                }}>{firstName}</Box>
                <Box sx={{
                  fontSize: '14px',
                  color: "rgba(0, 0, 0, 0.6)"
                }}>{email}</Box>
              </Box>
              {shopName == null && storePlatform != "wix" ? <MenuItem onClick={(e) => {
                e.stopPropagation();
                sendLog("CONNECT_TO_SHOPIFY");
                setState({
                  ...state,
                  isAccountPopUpDialogOpen: true,
                  currentComponentName: 'CONNECT_SHOPIFY',
                });
                setAnchorEl(null);
                setOpenMenu(false);
              }} sx={{ fontSize: '14px' }}>
                <img src="img/myProduct/myProduct_icon_push.png" width="24px" height="24px" style={{ marginRight: "32px" }} />Connect to Shopify
              </MenuItem> : null}
              <MenuItem onClick={(e) => {
                e.stopPropagation();
                navigate("/setting");
                setAnchorEl(null);
                setOpenMenu(false);
              }} sx={{ fontSize: '14px' }}>
                <SettingsIcon sx={{
                  width: "24px",
                  height: "24px",
                  marginRight: "32px",
                  color: "rgba(0, 0, 0, 0.56)"
                }} />Setting</MenuItem>
              {/* <MenuItem onClick={(e) => {
                e.stopPropagation();
                navigate("/referral");
                setAnchorEl(null);
                setOpenMenu(false);
              }} sx={{ fontSize: '14px' }}>
                <PeopleAltOutlinedIcon sx={{
                  width: "24px",
                  height: "24px",
                  marginRight: "32px",
                  color: "rgba(0, 0, 0, 0.56)"
                }} />Referral Earnings: {earnings}</MenuItem> */}
              {localStorage.getItem("AllowShowSwitchAccountDialog") == "true" && shopName != null && storePlatform != "wix" ? <MenuItem onClick={(e) => {
                e.stopPropagation();
                setState({
                  ...state,
                  isOpenSwitchAccountDialog: true
                });
                setAnchorEl(null);
                setOpenMenu(false);
              }} sx={{ fontSize: '14px' }}>
                <SyncAltOutlinedIcon sx={{
                  width: "24px",
                  height: "24px",
                  marginRight: "32px",
                  color: "rgba(0, 0, 0, 0.56)"
                }} />Switch Account</MenuItem> : null}
              <MenuItem onClick={(e) => {
                e.stopPropagation();
                sendLog("SIGN_OUT_CLICK");
                localStorage.removeItem("email");
                localStorage.removeItem("firstName");
                localStorage.removeItem("shopName");
                localStorage.removeItem("shopifyToken");
                removeCookie('token');
                sign_out().then((result) => {
                  if (
                    result.data != null &&
                    result.data.signOut === true
                  ) {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText: 'Exit successfully !',
                      snackbarState: 'SUCCESS',
                    });
                  } else {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText: 'Exit failure !',
                      snackbarState: 'FAILED',
                    });
                  }
                });
                localStorage.clear();
                window.location.href = "/app";

                setAnchorEl(null);
                setOpenMenu(false);
              }} sx={{ fontSize: '14px' }}>
                <LogoutIcon sx={{
                  width: "24px",
                  height: "24px",
                  marginRight: "32px",
                  color: "rgba(0, 0, 0, 0.56)"
                }} />Sign Out</MenuItem>
            </Menu>
          </Box>
          <Box sx={{ color: "#707070" }} onClick={() => {
            firstNameBox.current.click();
          }}>
            {openMenu ? (
              <ArrowDropUpIcon sx={{ fontSize: "3.2rem" }} />
            ) : (
              <ArrowDropDownIcon sx={{ fontSize: "3.2rem" }} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
