import React, { useEffect } from "react";
import { useState, useContext, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Context } from "../Context/Context";
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { setCookie } from "doc-cookie";
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from "@mui/material";
import { removeCookie } from "doc-cookie";
import { ShopifyAppKey, AuthScopes } from "../ShopifyConstants";

const GET_USER_ALL_EMAIL_BY_SHOP_NAME = gql`
  query getUserAllEmailByShopName($shopName: String) {
    getUserAllEmailByShopName(shopName: $shopName) {
        status
        result
        message
    }
  }
`;

const SIGN_IN = gql`
  mutation signIn($email: String, $password: String) {
    signIn(email: $email, password: $password) {
      firstName
      email
      shopifyToken
      shopName
      token
      userID
      storePlatform
      wixSiteID
      shipToCountry
    }
  }
`;

const USER_SIGNUP = gql`
  mutation Mutation(
    $firstName: String
    $lastName: String
    $email: String
    $password: String
  ) {
    signUp(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
    ) {
      token
      userID
    }
  }
`;

export default function SwitchAccount() {
  const { state, setState } = useContext(Context);
  // adjustedEmail: shopify account email
  // email: user email
  const { isOpenSwitchAccountDialog, firstName, adjustedEmail, shopName, email } = state;
  // currentEmail: currently selected email
  const [currentEmail, setCurrentEmail] = useState(email != null ? email : "");
  const [notShowSwitchAccountDialog, setNotShowSwitchAccountDialog] = useState(localStorage.getItem("IsShowSwitchAccountDialog") != null ? localStorage.getItem("IsShowSwitchAccountDialog") == "false" : false);
  const navigate = useNavigate();
  const [sign_in] = useMutation(SIGN_IN);
  const [user_signup] = useMutation(USER_SIGNUP);
  const { loading, data, refetch, } = useQuery(GET_USER_ALL_EMAIL_BY_SHOP_NAME, {
    variables: {
      shopName,
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    setNotShowSwitchAccountDialog(localStorage.getItem("IsShowSwitchAccountDialog") != null ? localStorage.getItem("IsShowSwitchAccountDialog") == "false" : false);
    setCurrentEmail(email != null ? email : "");
  }, [isOpenSwitchAccountDialog]);
  useEffect(() => {
    if (loading == false && data != null && data.getUserAllEmailByShopName != null && data.getUserAllEmailByShopName.result != null) {
      localStorage.setItem("AllowShowSwitchAccountDialog", data.getUserAllEmailByShopName.result.length > 1 ? "true" : "false");
    }
  }, [loading]);
  if (loading || data == null || data.getUserAllEmailByShopName == null || data.getUserAllEmailByShopName.result == null || data.getUserAllEmailByShopName.result.length == 0) {
    return <></>;
  }
  return (
    <Dialog
      open={data.getUserAllEmailByShopName.result.length > 1 && (isOpenSwitchAccountDialog ? isOpenSwitchAccountDialog : false)}
      onClose={() => {
        setState({
          ...state,
          isOpenSwitchAccountDialog: false
        });
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          maxHeight: "none",
          maxWidth: "none",
          borderRadius: '10px',
        },
      }}
    >
      <DialogContent sx={{
        width: "463px",
        minHeight: "274px",
        padding: '10px 20px',
        fontSize: "14px",
        boxSizing: 'border-box',
      }}>
        {loading || data == null || data.getUserAllEmailByShopName == null || data.getUserAllEmailByShopName.result == null || data.getUserAllEmailByShopName.result.length == 0 ? <Box
          sx={{
            width: "500",
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "400px",
              height: "54px",
              borderRadius: "10px",
              backgroundColor: "#FF9711",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "22px",
              color: "#FFF",
            }}
          >
            Loading ...
            <CircularProgress
              size={"30px"}
              sx={{ margin: "0 30px", color: "#FFF" }}
            />
          </Box>
        </Box> : <Box>
          <Box sx={{ height: "48px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "30px", fontWeight: "600", letterSpacing: "0.15px" }}>Welcome to Cartifind</Box>
          <Box sx={{ minHeight: "22px", lineHeight: "22.4px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px", fontWeight: "500", letterSpacing: "0.15px" }}>We detect multiple accounts with {shopName}. Log in with:</Box>
          <Box sx={{
            fontSize: "14px",
            overflow: "auto",
            maxHeight: "350px",
            "& .radio_label_wrap": {
              height: '22px',
              display: 'flex',
              alignItems: 'center',
              fontSize: "14px",
              letterSpacing: "0.17px"
            }
          }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={currentEmail}
                onChange={(e) => { setCurrentEmail(e.target.value) }}
              >{data.getUserAllEmailByShopName.result.map((item, index) => {
                return <FormControlLabel key={index} value={item} control={<Radio icon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonUncheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} checkedIcon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonCheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} />} label={<Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                }} className="radio_label_wrap">
                  <Box sx={{ marginRight: "10px" }}>{item}</Box>
                </Box>} />
              })}
                {adjustedEmail != null && !data.getUserAllEmailByShopName.result.includes(adjustedEmail) ? <FormControlLabel value={adjustedEmail} control={<Radio icon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonUncheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} checkedIcon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonCheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} />} label={<Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                }} className="radio_label_wrap">
                  <Box sx={{ marginRight: "10px" }}>Create a new account with {adjustedEmail}</Box>
                </Box>} /> : null}
              </RadioGroup>
            </FormControl>
          </Box>
          {/* <Box sx={{ display: 'flex', alignItems: "center", flexDirection: "column", fontSize: "16px" }}>
            {data.getUserAllEmailByShopName.result.map(item => {
              return <Box sx={{ height: "30px", lineHeight: '30px' }} onClick={() => {
                sign_in({
                  variables: {
                    email: item + "_isAdmin_" + shopName,
                    password: "#hffsuperpassword@2",
                  },
                }).then((result) => {
                  if (result.data != null && result.data.signIn != null) {
                    const { firstName, shopifyToken, email, shopName, token, userID, storePlatform, wixSiteID, shipToCountry } = result.data.signIn;
                    localStorage.setItem("firstName", firstName);
                    localStorage.setItem("shopifyToken", shopifyToken);
                    localStorage.setItem("email", email);
                    localStorage.setItem("shopName", shopName);
                    localStorage.setItem("userID", userID);
                    localStorage.setItem("storePlatform", storePlatform);
                    localStorage.setItem("wixSiteID", wixSiteID);
                    localStorage.setItem("shipToCountry", shipToCountry);
                    if (localStorage.getItem("isOpenYellowBanner2") !== "false") {
                      localStorage.setItem("isOpenYellowBanner2", "true");
                    }
                    setCookie({
                      key: "token",
                      value: token,
                      'Max-Age': 604800, // seconds
                    });
                    setState({
                      ...setState,
                      firstName,
                      shopifyToken,
                      email,
                      shopName,
                      isAccountPopUpDialogOpen: false,
                      userID,
                      storePlatform,
                      wixSiteID,
                      shipToCountry
                    });
                    if (shopName != null) {
                      window.location.href = "/app";
                    } else {
                      window.location.href = "/app?currentComponentName=CONNECT_SHOPIFY";
                    }
                  }

                });
              }}>{item}</Box>
            })}
          </Box> */}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
            <Box sx={{
              backgroundColor: "#61C454",
              width: "245px",
              height: "38px",
              padding: '6px 16px',
              borderRadius: '10px',
              color: "#FFF",
              fontSize: "24px",
              fontWeight: "600",
              letterSpacing: "0.4px",
              textTransform: "uppercase",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer"
            }} onClick={() => {
              if (currentEmail == null || currentEmail.length == 0) {
                return;
              }
              localStorage.setItem("IsShowSwitchAccountDialog", notShowSwitchAccountDialog ? "false" : "true");
              if (email == currentEmail) {
                setState({
                  ...state,
                  isOpenSwitchAccountDialog: false
                });
                return;
              }
              const currentShopName = shopName;
              if (currentEmail == adjustedEmail) {
                localStorage.setItem("testCreateAccountTimes", Number(localStorage.getItem("testCreateAccountTimes") + 1))
                localStorage.removeItem("shopName");
                localStorage.removeItem("shopifyToken");
                user_signup({
                  variables: {
                    firstName,
                    lastName: "lastName",
                    email: adjustedEmail,
                    password: "#hffsuperpassword@",
                  },
                }).then((result) => {
                  if (
                    result.data != null &&
                    result.data.signUp != null &&
                    result.data.signUp.token != null
                  ) {
                    localStorage.setItem("firstName", firstName);
                    localStorage.setItem("email", adjustedEmail);
                    localStorage.setItem("userID", result.data.signUp.userID);
                    setCookie({
                      key: "token",
                      value: result.data.signUp.token,
                      'Max-Age': 604800, // seconds
                    });
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText: 'You have signed up successfully !',
                      snackbarState: 'SUCCESS',
                      firstName: firstName,
                      email: adjustedEmail,
                      userID: result.data.signUp.userID
                    });
                    // Need window href to reload cookie.
                    // 这里需要注意把redirectURL 替换成https://cartifind.com/app  "https://" + window.location.hostname + "/app"
                    const URL = `https://${currentShopName}/admin/oauth/authorize?client_id=${ShopifyAppKey}&scope=${AuthScopes}&redirect_uri=https://${window.location.hostname}/shopifyAuth&state=43758342758d`;
                    // &grant_options%5B%5D=per-user
                    window.location = URL;
                  } else {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText: 'Your email has already existed. Try sign in instead.',
                      snackbarState: 'FAILED',
                    });
                  }
                });
                return;
              }
              localStorage.setItem("email", currentEmail);
              // Need window href to reload cookie.
              const URL = `https://${currentShopName}/admin/oauth/authorize?client_id=${ShopifyAppKey}&scope=${AuthScopes}&redirect_uri=https://${window.location.hostname}/shopifyAuth&state=43758342758d`;
              // &grant_options%5B%5D=per-user

              window.location = URL;

            }}>Continue</Box>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: "center",
            marginBottom: '20px',
            justifyContent: "center"
          }}>
            <Checkbox
              checked={notShowSwitchAccountDialog}
              onChange={(e) => {
                setNotShowSwitchAccountDialog(e.target.checked);
              }}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
              </Box>}
              checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_gray_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
              </Box>}
            />
            <Box>Don’t show this again</Box>
          </Box>
        </Box>}
      </DialogContent>
    </Dialog>
  )
}