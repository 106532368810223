/** @format */

import React from "react";
import { Box } from "@mui/system";
import { gql, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { Context } from "./Context/Context";

const GET_ONE_TIME_PURCHASE = gql`
  query appOneTimePurchaseCreate(
    $shopifyOrderID: String, 
    $amount: String, 
    $domain: String, 
    $shippingMethod: String, 
    $orderName: String,
    $inventoryDetailList: String
    $currencyCode: String
    $costDetail: String
) {
    appOneTimePurchaseCreate(
        shopifyOrderID: $shopifyOrderID, 
        amount: $amount,
        domain: $domain,
        shippingMethod: $shippingMethod, 
        inventoryDetailList: $inventoryDetailList
        orderName: $orderName
        currencyCode: $currencyCode
        costDetail: $costDetail
    )
  }
`;

export default function OneTimePurchase() {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const amount = queryParams.get('amount');
    const shopifyOrderID = queryParams.get('shopifyOrderID');
    const shippingMethod = queryParams.get('shippingMethod');
    const orderName = queryParams.get('orderName');
    const inventoryDetailList = queryParams.get('inventoryDetailList');
    const currencyCode = queryParams.get('currencyCode');
    const costDetail = queryParams.get('costDetail');
    const { setState, state } = React.useContext(Context);

    const { loading, data } = useQuery(GET_ONE_TIME_PURCHASE, {
        variables: {
            shopifyOrderID,
            amount,
            domain: window.location.hostname,
            shippingMethod,
            orderName,
            inventoryDetailList: decodeURIComponent(inventoryDetailList).replace(';', ''),
            currencyCode: currencyCode != null ? currencyCode.toUpperCase() : "USD",
            costDetail: decodeURIComponent(costDetail)
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
    });

    if (loading === false) {
        if (data != null && data.appOneTimePurchaseCreate != null) {
            window.location.replace(data.appOneTimePurchaseCreate);
        } else {
            setState({
                ...state,
                openSnackbar: true,
                snackbarText: 'Oops! Something went wrong.',
                snackbarState: 'FAILED',
            });
            navigate('/MyOrders');
        }
    }
    return (
        <>
            <Box sx={{
                height: "400px",
                marginTop: 10,
                display: "flex",
                flexDirection: 'column',
                alignItems: "center",
            }} >
                <CircularProgress />
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    "& .text": {
                        height: '40px',
                        lineHeight: '40px',
                        color: '#666666',
                        fontWeight: '400',
                        fontSize: '30px',
                        textAlign: 'center'
                    }
                }}>
                    <Box className="text">Redirecting to Shopify Payment Page ...</Box>
                </Box>
            </Box>
        </>
    );
}
