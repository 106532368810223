import React from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import { Context } from "../Context/Context";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SettingBlankPage(props) {
    const { setState, state } = React.useContext(Context);
    const { firstName, sendLog } = state;
    return (
        <Box sx={{
            fontFamily: 'Arial',
            fontStyle: 'normal',
            paddingLeft: '10rem'
        }}>
            <Box sx={{
                width: '100%',
                fontWeight: '500',
                fontSize: '2.4rem',
                lineHeight: ' 150%',
                letterSpacing: '0.015rem',
                color: '#707070',
                marginTop: '11.6rem'
            }}>
                <Box sx={{ marginBottom: '2.2rem' }}>
                    Please {" "}<Typography variant="span"
                        sx={{ color: "#FF9711", cursor: "pointer" }}
                        onClick={() => {
                            sendLog("SETTING_BLANK_PAGE_CTA_CLICK");
                            setState({
                                ...state,
                                isAccountPopUpDialogOpen: true,
                                currentComponentName: firstName == null ? 'SIGN_IN' : 'CONNECT_SHOPIFY',
                            });
                        }}> connect to Shopify </Typography>{" "} before using this function.
                </Box>
                <Box sx={{ marginBottom: '1.2rem' }}>1. Adjust the currency of the saved products.</Box>
                <Box>2. Customize the product price by using "Pricing Rule" .</Box>
            </Box>
            <Box sx={{
                width: '100%',
                height: '35.6rem',
                position: 'relative',
                fontWeight: '500',
                fontSize: '2.4rem',
                lineHeight: ' 150%',
                color: '#A0A0A0',
                boxSizing: 'border-box',
                marginTop: '3.7rem',
            }}>
                <Box
                    sx={{
                        width: "127.2rem",
                        height: '14.1rem',
                        background: "#e6e6e6",
                        borderRadius: "1rem",
                        marginBottom: '6rem'
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            height: "4.8rem",
                            alignItems: "flex-end",
                            justifyContent: "flex-start",
                            padding: "0 3.5rem 0 2.3rem",
                        }}
                    >
                        Shopify Stores
                    </Box>
                    <Box>
                        <Box>
                            <Divider
                                sx={{ borderColor: "rgba(196, 196, 196, 1)", borderBottomWidth: "0.2rem" }}
                            />
                            <Box sx={{
                                height: '9.3rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                boxSizing: 'border-box',
                                padding: '0 9.2rem 0 7.5rem'
                            }}>
                                <Box sx={{ fontSize: '2.2rem' }}>Currencies</Box>
                                <Box sx={{
                                    border: '0.1rem solid #ccc',
                                    width: '10rem',
                                    height: '3.3rem',
                                    borderRadius: '1rem',
                                    padding: '0 1rem',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    boxSizing: 'border-box',
                                    fontSize: '1.6rem'
                                }}><Box>USD</Box><ExpandMoreIcon sx={{ fontSize: '3.2rem' }} /></Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "127.2rem",
                        height: '15.6rem',
                        background: "#e6e6e6",
                        borderRadius: "1rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            height: "4.8rem",
                            alignItems: "flex-end",
                            justifyContent: "flex-start",
                            padding: "0 3.5rem 0 2.3rem",
                        }}
                    >
                        Pricing Rule
                    </Box>
                    <Box>
                        <Box>
                            <Divider
                                sx={{ borderColor: "rgba(196, 196, 196, 1)", borderBottomWidth: "0.2rem" }}
                            />
                            <Box sx={{
                                height: '10.8rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                boxSizing: 'border-box',
                                padding: '0 9.2rem 0 7.5rem'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '79.8rem',
                                    height: '5.7rem',
                                    "& .grey-background": {
                                        width: '15.5rem',
                                        height: '5.7rem',
                                        borderRadius: '1rem',
                                        backgroundColor: '#d8d8d8',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }
                                }}>
                                    <Box>Cost ( USD )</Box>
                                    <Box sx={{ fontSize: '5.0rem' }} className='grey-background'>
                                        <Box sx={{ transform: 'rotate(-45deg)' }}>+</Box>
                                    </Box>
                                    <Box className='grey-background'>
                                        <Box sx={{ width: '12.5rem', textAlign: 'center' }}>1.5</Box>
                                    </Box>
                                    <Box sx={{ fontSize: '5.0rem' }}>=</Box>
                                    <Box>Sell Price ( USD )</Box>
                                </Box>
                                <Box sx={{
                                    width: '8rem',
                                    height: '4rem',
                                    borderRadius: '2rem',
                                    backgroundColor: '#c4c4c4'
                                }}>
                                    <Box sx={{
                                        width: '3rem',
                                        height: '3rem',
                                        margin: '0.5rem',
                                        backgroundColor: '#e6e6e6',
                                        borderRadius: '1.5rem'
                                    }}></Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: '56.7rem', height: '20.7rem', position: 'absolute', top: '-10rem', left: '53rem', pointerEvents: 'none', background: 'url("img/arrows_settingBlankPage.png") no-repeat 0 0', backgroundSize: 'cover' }}></Box>
            </Box>
        </Box>
    );
}