/** @format */

import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const GET_ALL_SUBSCRIPTIONS = gql`
  query getAllSubscriptions {
    getAllSubscriptions {
        status
        subscriptionType
        message
    }
  }
`;

export default function ShopifySubscriptionRedirect() {
    const navigate = useNavigate();

    const { loading, data, refetch } = useQuery(GET_ALL_SUBSCRIPTIONS, {
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
    });
    if (loading === false && data != null && data.getAllSubscriptions != null) {
        if (data.getAllSubscriptions.status) {
            if (data.getAllSubscriptions.subscriptionType === 'ProMonthly') {
                navigate('/app?subscriptionStatus=1');
                return;
            }
            if (data.getAllSubscriptions.subscriptionType === 'ProYearly') {
                navigate('/app?subscriptionStatus=2');
                return;
            }
        } else {
            navigate('/app?subscriptionStatus=0');
            return;
        }
    }

    return (
        <div>
            <Box sx={{
                height: "40rem",
                marginTop: 10,
                display: "flex",
                justifyContent: "center",
            }}
            >
                <CircularProgress />
            </Box>
        </div>
    );
}
