import React from 'react';
import { useState, useContext, useRef } from 'react';
import { Box } from '@mui/system';
import { gql, useQuery, useMutation } from "@apollo/client";
import GetCurrencySymbol from '../utils/GetCurrencySymbol.js';
import Convdate from '../utils/Convdate.js';
import { Context } from "../Context/Context";
import AppHeader from '../AppHeader.react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { categoryList } from '../utils/CategoryList.js';
import { Carousel } from 'antd';
import { useNavigate } from "react-router-dom";

const GET_PRODUCT = gql`
  query Query($id: String) {
    getDetailPageProduct(id: $id) {
        subCatogery
        title
        reviewScore
        productLink
        feedbackScore
        itemAsDescribed
        shipSpeed
        communication
        images
        videoLink
        minSalePrice
        maxSalePrice
        minOriginalPrice
        maxOriginalPrice
        currency
        feedbacks
        shippingDayMin
        shippingDayMax
        shippingFee
        varOptions
        varPrices
        productID
        orders
        description
        spec
        hffScore
        exchangeRate
    }
  }
`;

const ADD_PRODUCT_LIST = gql`
  mutation AddProductList($id: String, $aliExpressProductURL: String) {
    addProductList(id: $id, aliExpressProductURL: $aliExpressProductURL) {
      status
      message
    }
  }
`;

const GET_SUBSCRIPTION_STATUS = gql`
  query getSubscriptionStatus {
    getSubscriptionStatus
  }
`;

const GET_PRODUCT_ADD_MY_PRODUCT_STATUS = gql`
  query getProductAddMyProductStatus($id: String) {
    getProductAddMyProductStatus(id: $id)
  }
`;


const layoutFlexAlignItemsenter = {
  display: 'flex',
  alignItems: 'center',
}

export default function DetailPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  if (id == null) {
    navigate('/app');
    return <></>;
  }
  const { loading, data, refetch } = useQuery(GET_PRODUCT, {
    variables: {
      id
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const { loading: statusLoading, data: statusData, refetch: statusRefetch } = useQuery(GET_SUBSCRIPTION_STATUS, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const { loading: isAddedLoading, data: isAddedData, refetch: isAddedRefetch } = useQuery(GET_PRODUCT_ADD_MY_PRODUCT_STATUS, {
    variables: {
      id
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  const [currentLeftImageTopIndex, setCurrentLeftImageTopIndex] = useState(0);
  const [currentSelectedImage, setCurrentSelectedImage] = useState();
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [add_product_list] = useMutation(ADD_PRODUCT_LIST);
  const { setState, state } = useContext(Context);
  const { sendLog, firstName, shopName } = state;
  const [tabsValue, setTabsValue] = useState(0);
  const [showReviewsNum, setShowReviewsNum] = useState(1);
  const [isShowBigImage, setIsShowBigImage] = useState(false);
  const [currentShowBigImage, setCurrentShowBigImage] = useState('');
  const [currentBigImageList, setCurrentBigImageList] = useState([]);
  const [newOptionList, setNewOptionList] = useState([]);
  const [loadPage, setLoadPage] = useState(0);
  const [currentSalePrice, setCurrentSalePrice] = useState('');
  const [currentOriginalPrice, setCurrentOriginalPrice] = useState('');
  const [currentSkuID, setCurrentSkuID] = useState('');
  const [isShowBigImageSwitchButton, setIsShowBigImageSwitchButton] = useState(false);
  const carouselComponent = useRef(null);
  const [currentScrollTop, setCurrentScrollTop] = useState(0);
  const [isShowSkuImage, setIsShowSkuImage] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);


  if (loading === true) {
    return <></>;
  }
  if (data == null || data.getDetailPageProduct == null) {
    navigate('/app');
    return <></>;
  }

  const {
    subCatogery,
    title,
    reviewScore,
    productLink,
    itemAsDescribed,
    shipSpeed,
    feedbackScore,
    images,
    videoLink,
    communication,
    minSalePrice,
    maxSalePrice,
    minOriginalPrice,
    maxOriginalPrice,
    currency,
    feedbacks,
    shippingDayMin,
    shippingDayMax,
    shippingFee,
    varOptions,
    varPrices,
    productID,
    orders,
    description,
    spec,
    hffScore,
    exchangeRate
  } = data.getDetailPageProduct;
  const minSalePriceNumArr = minSalePrice.split('.');
  const maxSalePriceNumArr = maxSalePrice.split('.');
  const minOriginalPriceNumArr = minOriginalPrice.split('.');
  const maxOriginalPriceNumArr = maxOriginalPrice.split('.');
  const currencySymbol = GetCurrencySymbol(currency);
  const leftImageList = JSON.parse(images);
  const reviews = JSON.parse(feedbacks);
  const optionList = JSON.parse(varOptions);
  const priceList = JSON.parse(varPrices);
  const Specifications = JSON.parse(spec);
  const defaultptionList = optionList.map((item) => {
    let variantOptionsArray = new Set();
    let serialNum = 1;
    item.values.map(value => {
      if (variantOptionsArray.has(value.displayName)) {
        variantOptionsArray.add(value.displayName + String(serialNum));
        value.displayName = value.displayName + String(serialNum);
        serialNum++;
      } else {
        variantOptionsArray.add(value.displayName);
      }
    })
    return {
      ...item,
      currentSelectedName: item.values != null ? item.values[0].displayName : '',
      currentSelectedId: item.values != null ? item.values[0].id : ''
    }
  });

  let reviewPhoto = [];
  reviews.map((item) => {
    if (item.photos != null && item.photos.length > 0) {
      reviewPhoto.push(...item.photos);
    }
  })
  if (newOptionList != null && newOptionList.length === 0) {
    setNewOptionList(defaultptionList);
    priceList.some((priceItem) => {
      if (priceItem.optionValueIds != null) {
        if (defaultptionList.every(optionItem => priceItem.optionValueIds.includes(optionItem.currentSelectedId))) {
          setCurrentSalePrice(String(priceItem.salePrice / exchangeRate));
          setCurrentOriginalPrice(String(priceItem.originalPrice / exchangeRate));
          setCurrentSkuID(priceItem.skuId);
          return true;
        }
      }
    })
  }

  let parentCategory, childrenCategory;
  categoryList.map((category) => {
    if (category.children != null) {
      category.children.map((item) => {
        if (item.value === subCatogery) {
          parentCategory = category.option;
          childrenCategory = item.option;
        }
      })
    }
  });

  const handleChangeCarousel = (index) => {
    setCurrentSelectedImage(leftImageList[index]);
    if (index - currentLeftImageTopIndex > 4) {
      setCurrentLeftImageTopIndex(currentLeftImageTopIndex + 1);
    }
    if (currentLeftImageTopIndex > index) {
      setCurrentLeftImageTopIndex(currentLeftImageTopIndex - 1);
    }
  }

  const reviewScrollByID = (anchorName) => {
    const anchorElement = document.getElementById(anchorName);
    if (anchorElement) {
      const reviewOffsetTop = anchorElement.offsetTop - 100;
      setCurrentScrollTop(document.documentElement.scrollTop);
      const timer = setInterval(() => {
        setCurrentScrollTop((oldScrollTop) => {
          document.documentElement.scrollTop = oldScrollTop;
          if (oldScrollTop >= reviewOffsetTop) {
            clearInterval(timer);
            document.documentElement.scrollTop = reviewOffsetTop;
            return oldScrollTop;
          }
          return oldScrollTop + reviewOffsetTop / 10;
        });
      }, 20);
    }
  }
  const FreeShowProductList = JSON.parse(localStorage.getItem("FreeShowProductList"));

  return (
    <Box sx={{
      fontFamily: 'Arial',
      letterSpacing: '0.15px',
    }} >
      <AppHeader />
      <Box sx={{
        '& img': {
          width: '100%',
          height: '100%'
        }
      }}>
        <Box sx={{
          width: '100%',
          minHeight: '72.4rem',
          display: 'flex',
          justifyContent: 'space-between',
          border: '0.2rem solid #FFF',
          boxSizing: 'border-box',
          marginBottom: '3rem'
        }}>
          <Box sx={{
            width: '140.2rem',
            backgroundColor: "#FFF",
            paddingLeft: '4rem',
          }}>
            {parentCategory != null && childrenCategory != null ? <Box sx={{
              fontSize: '2.8rem',
              fontWeight: '700',
              color: '#333333',
              padding: '2rem 0',
              height: '5rem',
              ...layoutFlexAlignItemsenter,
              '& div': {
                padding: "0 1.2rem",
                textAlign: 'center',
                lineHeight: '5rem'
              }
            }}>
              <Box sx={{
                height: '5rem',
                borderRadius: '1rem',
                border: '0.1rem solid #F4F4F4'
              }}>{parentCategory}</Box>
              <ArrowForwardIosRoundedIcon sx={{ fontSize: '2rem', margin: '0 2.9rem 0 3.5rem', }} />
              <Box sx={{
                height: '5rem',
                borderRadius: '1rem',
                border: '0.3rem solid #FF9711'
              }}>{childrenCategory}</Box>
            </Box> : <Box sx={{
              width: '1rem',
              padding: '2rem 0',
              height: '5rem',
            }} />}
            <Box sx={{
              display: 'flex',
              minHeight: '60rem',
              width: '100%'
            }}>
              <Box sx={{
                width: '8rem'
              }}>
                <Box sx={{
                  ...layoutFlexAlignItemsenter,
                  height: '60rem',
                  width: '8rem',
                  position: 'sticky',
                  top: '9.8rem',
                  marginBottom: '5rem'
                }}>
                  <Box sx={{
                    width: '8rem',
                    height: '48rem',
                    position: 'relative',
                    '& .change-image-buttom': {
                      width: '2.5rem',
                      height: '2.5rem',
                      borderRadius: '50%',
                      backgroundSize: 'contain',
                      position: 'absolute',
                      left: '2.75rem',
                      cursor: 'pointer'
                    }
                  }}>
                    <Box sx={{
                      width: '8rem',
                      height: '48rem',
                      position: 'relative',
                      overflow: 'hidden'
                    }}>
                      <Box sx={{
                        width: '8rem',
                        position: 'absolute',
                        top: -currentLeftImageTopIndex * 10 + 'rem',
                        left: '0',
                        transition: 'top 0.5s',
                        '& div:last-child': {
                          marginBottom: '0rem'
                        },
                        '& div': {
                          width: '8rem',
                          height: '8rem',
                          borderRadius: '0.2rem',
                          border: '0.1rem solid #B3B3B3',
                          marginBottom: '2rem',
                          boxSizing: 'border-box',
                          cursor: 'pointer',
                        },
                        '& .selected': {
                          border: '0.3rem solid #FF9711',
                        }
                      }}>
                        {
                          leftImageList.map((src, index) => {
                            return (
                              <Box className={currentSelectedImage != null ? (src === currentSelectedImage ? 'selected' : '') : (index === 0 ? 'selected' : '')} onClick={() => {
                                setCurrentSelectedImage(src);
                                setIsShowVideo(false);
                                setIsShowSkuImage(false);
                                if (isShowSkuImage === false && isShowVideo === false) {
                                  carouselComponent.current.goTo(index);
                                }
                              }} key={index}>
                                <img src={src} alt={'image' + index} />
                              </Box>
                            );
                          })
                        }
                      </Box>
                    </Box>
                    {leftImageList.length > 5 ?
                      <Box>
                        <Box sx={{
                          top: '-1.25rem',
                          background: 'url("img/DetailPage_image_top.png") no-repeat 0 0',
                          "&:hover": {
                            background: 'url("img/DetailPage_image_top_selected.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                          }
                        }} className='change-image-buttom' onClick={() => {
                          if (currentLeftImageTopIndex > 0) {
                            setCurrentLeftImageTopIndex(currentLeftImageTopIndex - 1);
                          }
                        }} />
                        <Box sx={{
                          bottom: '-1.25rem',
                          background: 'url("img/DetailPage_image_bottom.png") no-repeat 0 0',
                          "&:hover": {
                            background: 'url("img/DetailPage_image_bottom_selected.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                          }
                        }} className='change-image-buttom' onClick={() => {
                          if (currentLeftImageTopIndex < leftImageList.length - 5) {
                            setCurrentLeftImageTopIndex(currentLeftImageTopIndex + 1);
                          }
                        }}></Box>
                      </Box> : <Box />
                    }
                  </Box>
                </Box>
              </Box>
              <Box sx={{
                width: '60rem',
                margin: '0 2.1rem 0 3.1rem',
              }}>
                <Box sx={{
                  width: '60rem',
                  height: '60rem',
                  borderRadius: '0.5rem',
                  marginBottom: '5rem',
                  position: 'sticky',
                  top: '9.8rem',
                  border: '0.1rem solid #B3B3B3',
                  '& .slide-img': {
                    height: '60rem',
                  },
                  '& img': {
                    borderRadius: '0.5rem',
                  },
                  '& video': {
                    width: '100%',
                    height: '100%'
                  }
                }} onMouseOver={() => {
                  setIsShowBigImageSwitchButton(true);
                }} onMouseLeave={() => {
                  setIsShowBigImageSwitchButton(false);
                }}>
                  {
                    isShowVideo ?
                      <video loop muted controls autoPlay style={{ "borderRadius": '1rem' }}>
                        <source src={videoLink} />
                      </video> :
                      (isShowSkuImage ? <img src={currentSelectedImage} alt={'big image'} className='slide-img' onClick={() => {
                        setCurrentShowBigImage(currentSelectedImage);
                        setIsShowBigImage(true);
                        setCurrentBigImageList([currentSelectedImage]);
                      }} /> :
                        <Carousel
                          ref={carouselComponent}
                          afterChange={handleChangeCarousel}
                          dots={false}
                          effect="fade">
                          {
                            leftImageList.map((item, index) => {
                              return <img src={item} alt={'big image' + index} onClick={() => {
                                setCurrentShowBigImage(currentSelectedImage != null ? currentSelectedImage : leftImageList[0]);
                                setIsShowBigImage(true);
                                setCurrentBigImageList(leftImageList);
                              }} key={'big image slide' + index} className='slide-img' />
                            })
                          }
                        </Carousel>
                      )
                  }
                  {videoLink != null && videoLink.length > 1 && (currentSelectedImage == null || currentSelectedImage === leftImageList[0]) && isShowVideo === false ? <Box sx={{ position: 'absolute', width: '6.7rem', height: '6.7rem', top: '26.65rem', left: '26.65rem', cursor: 'pointer' }} onClick={() => { setIsShowVideo(true); }}>
                    <img src='/img/icon_product_slide_video_player.png' alt='player button' />
                  </Box> : <Box />}
                  {isShowBigImageSwitchButton === true ? <Box sx={{
                    '& .switch-button': {
                      width: '6.5rem',
                      height: '6.5rem',
                      borderRadius: '50%',
                      position: 'absolute',
                      top: '50%',
                      marginTop: '-3.25rem'
                    }
                  }}>
                    <Box className='switch-button' sx={{ left: '1rem', background: 'url("/img/DetailPage_slide_botton_left.png") no-repeat 0 0', backgroundSize: 'cover', '&:hover': { background: 'url("/img/DetailPage_slide_botton_left_selected.png") no-repeat 0 0', backgroundSize: 'cover' }, cursor: 'pointer' }} onClick={() => {
                      if (isShowSkuImage || isShowVideo) {
                        setCurrentLeftImageTopIndex(0);
                        setCurrentSelectedImage(leftImageList[0]);
                        setIsShowVideo(false);
                        setIsShowSkuImage(false);
                      } else {
                        carouselComponent.current.prev();
                      }
                    }}></Box>
                    <Box className='switch-button' sx={{ right: '1rem', background: 'url("/img/DetailPage_slide_botton_right.png") no-repeat 0 0', backgroundSize: 'cover', '&:hover': { background: 'url("/img/DetailPage_slide_botton_right_selected.png") no-repeat 0 0', backgroundSize: 'cover' }, cursor: 'pointer' }} onClick={() => {
                      if (isShowSkuImage || isShowVideo) {
                        setCurrentLeftImageTopIndex(0);
                        setCurrentSelectedImage(leftImageList[0]);
                        setIsShowVideo(false);
                        setIsShowSkuImage(false);
                      } else {
                        carouselComponent.current.next();
                      }
                    }}></Box>
                  </Box> : <Box />}
                </Box>
              </Box>
              <Box sx={{ alignSelf: 'flex-start', width: '67.5rem', marginBottom: '5rem', }}>
                <Box sx={{
                  width: '67.5rem',
                  height: '9.6rem',
                  color: '#333',
                  fontSize: '2rem',
                  fontWeight: '500',
                  lineHeight: '150%'
                }}>{title}</Box>
                {reviews.length > 0 ? <Box sx={{
                  height: '3.1rem',
                  margin: '0.5rem 0 5.5rem',
                  ...layoutFlexAlignItemsenter,
                  color: '#666666',
                  fontSize: '1.8rem',
                  lineHeight: '150%',
                  fontWeight: '500'
                }}>
                  {/* <Box sx={{
                                    width: '2.5rem',
                                    height: '2.6rem',
                                    background: 'url("img/DetailPage_numberSold.png") no-repeat 0 0',
                                    backgroundSize: 'contain',
                                    marginRight: '1rem'
                                }} />
                                <Box>{orders} sold</Box> */}
                  {reviews.length > 0 ? <Box sx={{
                    width: '2.5rem',
                    height: '2.6rem',
                    background: 'url("img/DetailPage_review.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                    marginRight: '1rem',
                    marginLeft: '0rem'
                  }} /> : null}
                  {reviews.length > 0 ? <Box sx={{ color: '#65A3FF', cursor: 'pointer' }} onClick={() => { reviewScrollByID('review') }}>{reviews.length} reviews</Box> : null}
                </Box> : null}
                <Box sx={{
                  '& .price-wrap': {
                    display: 'flex',
                    alignItems: 'center',
                    height: '3.6rem',
                    color: '#D62626',
                    marginBottom: '4.9rem',
                    '& .decimals': {
                      alignSelf: 'flex-start',
                      fontSize: '2.4rem',
                      fontWeight: '500',
                    },
                    '& .integer': {
                      height: '3.6rem',
                      fontSize: '3.6rem',
                      fontWeight: '700',
                    }
                  },
                }}>
                  {currentSalePrice !== '' && currentOriginalPrice !== '' ? <Box className='price-wrap'>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '3.6rem',
                      marginRight: '9rem'
                    }}>

                      <Box className="integer">{currencySymbol + currentSalePrice.split('.')[0]}</Box>
                      <Box className="decimals">{currentSalePrice.split('.').length === 2 ? currentSalePrice.split('.')[1].slice(0, 2) : '00'}</Box>
                    </Box>
                    {currentSalePrice !== currentOriginalPrice ? < Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '1.6rem',
                      height: '3.6rem',
                      color: '#707070',
                      position: 'relative',
                      justifyContent: 'center',
                      '& .integer': {
                        fontSize: '3.6rem',
                        fontWeight: '400',
                      },
                      '& .decimals': {
                        alignSelf: 'flex-start',
                        fontSize: '2.4rem',
                        fontWeight: '400',
                      },
                    }}>
                      <Box className="integer">{currencySymbol + currentOriginalPrice.split('.')[0]}</Box>
                      <Box className="decimals">{currentOriginalPrice.split('.').length === 2 ? currentOriginalPrice.split('.')[1].slice(0, 2) : '00'}</Box>
                      <Box sx={{ width: 'calc(100% + 1rem)', height: '0.1rem', backgroundColor: '#707070', position: 'absolute', top: '2rem', left: '-0.5rem' }}></Box>
                    </Box> : <Box />}
                  </Box> : <Box className='price-wrap'>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '3.6rem',
                      marginRight: '9rem'
                    }}>
                      <Box className="integer">{currencySymbol + minSalePriceNumArr[0]}</Box>
                      <Box className="decimals">{minSalePriceNumArr.length === 2 ? minSalePriceNumArr[1].slice(0, 2) : '00'}</Box>
                      {minSalePrice !== maxSalePrice ? <Box className="integer">~{currencySymbol + maxSalePriceNumArr[0]}</Box> : <Box />}
                      {minSalePrice !== maxSalePrice ? <Box className="decimals">{maxSalePriceNumArr.length === 2 ? maxSalePriceNumArr[1].slice(0, 2) : '00'}</Box> : <Box />}
                    </Box>
                    {minSalePrice !== minOriginalPrice && maxSalePrice !== maxOriginalPrice ? < Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '1.6rem',
                      height: '3.6rem',
                      color: '#707070',
                      position: 'relative',
                      justifyContent: 'center',
                      '& .integer': {
                        fontSize: '3.6rem',
                        fontWeight: '400',
                      },
                      '& .decimals': {
                        alignSelf: 'flex-start',
                        fontSize: '2.4rem',
                        fontWeight: '400',
                      },
                    }}>
                      <Box className="integer">{currencySymbol + minOriginalPriceNumArr[0]}</Box>
                      <Box className="decimals">{minOriginalPriceNumArr.length === 2 ? minOriginalPriceNumArr[1].slice(0, 2) : '00'}</Box>
                      {minOriginalPrice !== maxOriginalPrice ? <Box className="integer">~{currencySymbol + maxOriginalPriceNumArr[0]}</Box> : <Box />}
                      {minOriginalPrice !== maxOriginalPrice ? <Box className="decimals">{maxOriginalPriceNumArr.length === 2 ? maxOriginalPriceNumArr[1].slice(0, 2) : '00'}</Box> : <Box />}
                      <Box sx={{ width: 'calc(100% + 1rem)', height: '0.1rem', backgroundColor: '#707070', position: 'absolute', top: '2rem', left: '-0.5rem' }}></Box>
                    </Box> : <Box />}
                  </Box>}
                </Box>
                {
                  newOptionList.map((item, optionIndex) => {
                    return (
                      <Box key={item.id}>
                        <Box sx={{ height: '2rem', ...layoutFlexAlignItemsenter, color: '#333333', fontSize: "1.8rem", lineHeight: '110%', fontWeight: '500', marginBottom: '0.6rem' }}>
                          <Box>{item.name}: </Box>
                          <Box sx={{ color: '#707070' }}>{item.currentSelectedName}</Box>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          color: '#000',
                          fontSize: '2rem',
                          fontWeight: '500',
                          flexWrap: 'wrap',
                          '& div:last-child': {
                            marginRight: '0rem'
                          },
                          '& div': {
                            minWidth: '6rem',
                            height: '6rem',
                            borderRadius: '0.2rem',
                            border: '0.1rem solid #B3B3B3',
                            marginRight: '2rem',
                            padding: '1rem',
                            boxSizing: 'border-box',
                            ...layoutFlexAlignItemsenter,
                            justifyContent: 'center',
                            cursor: 'pointer',
                            marginBottom: '2rem'
                          },
                          '& .selected': {
                            border: '0.3rem solid #FF9711',
                          }
                        }}>
                          {item.values != null ?
                            item.values.map(({ displayName, id: itemId, image }, index) => {
                              return <Box key={index} className={item.currentSelectedName === displayName ? 'selected' : ''} onClick={() => {
                                let midArr = newOptionList;
                                if (item.currentSelectedName === displayName) {
                                  return;
                                }
                                if (image != null && image.length > 0) {
                                  setIsShowVideo(false);
                                  setIsShowSkuImage(true);
                                  setCurrentSelectedImage(image);
                                }
                                midArr[optionIndex].currentSelectedName = displayName;
                                midArr[optionIndex].currentSelectedId = itemId;
                                setNewOptionList(midArr);
                                setLoadPage(loadPage + 1);
                                if (newOptionList.some(op => op.currentSelectedName === '')) {
                                  return;
                                }
                                priceList.some((priceItem) => {
                                  if (priceItem.optionValueIds != null) {
                                    if (newOptionList.every(optionItem => priceItem.optionValueIds.includes(optionItem.currentSelectedId))) {
                                      setCurrentSalePrice(String(priceItem.salePrice / exchangeRate));
                                      setCurrentOriginalPrice(String(priceItem.originalPrice / exchangeRate));
                                      setCurrentSkuID(priceItem.skuId);
                                      return true;
                                    }
                                  }
                                })
                              }}>{displayName}</Box>;
                            }) : null
                          }
                        </Box>
                      </Box>
                    )
                  })
                }
                {shippingFee != null && shippingFee.length > 0 && shippingDayMin != null && shippingDayMin.length > 0 && shippingDayMax != null && shippingDayMax.length > 0 ?
                  <Box sx={{
                    height: '2rem',
                    ...layoutFlexAlignItemsenter,
                    fontSize: '1.8rem',
                    color: '#666',
                    fontWeight: '500'
                  }}>
                    <Box sx={{ width: '2.5rem', height: '1.8rem', background: 'url("img/DetailPage_shipping.png") no-repeat 0 0', backgroundSize: 'contain', marginRight: '1rem' }}></Box>
                    {shippingFee != null && shippingFee.length > 0 ? <Box sx={{ marginRight: '1.8rem' }}>Shipping Cost: {currencySymbol + shippingFee}</Box> : <Box />}
                    {shippingDayMin != null && shippingDayMin.length > 0 && shippingDayMax != null && shippingDayMax.length > 0 ? <Box>Shipping Speed: {shippingDayMin} - {shippingDayMax} days</Box> : <Box />}
                  </Box> : <Box />}

              </Box>
            </Box>
          </Box>
          <Box sx={{
            width: '36.1rem',
            backgroundColor: "#FFF",
            paddingRight: '4rem',
            paddingLeft: '4.7rem',
            '& .button': {
              width: '36.6rem',
              height: '7.7rem',
              borderRadius: '0.5rem',
              ...layoutFlexAlignItemsenter,
              justifyContent: 'center',
              cursor: 'pointer',
              border: '0.1rem solid #B3B3B3',
              backgroundColor: '#FAFAFA',
              marginBottom: '4.8rem',
              color: '#707070',
              fontSize: '2.8rem',
              fontWeight: '700'
            }
          }}>
            <Box sx={{
              padding: '2rem 0',
              height: '5rem',
            }} />
            <Box sx={{
              position: 'sticky',
              top: '9.8rem',
              width: '36.6rem',
              height: 'auto',
              marginBottom: '6.5rem',
            }}>
              {(statusLoading === true || statusData == null || statusData.getSubscriptionStatus === "Free") && !FreeShowProductList.includes(id) ? <Box sx={{
                display: 'flex',
                marginBottom: '4.8rem'
              }}>
                <Box sx={{ width: '3rem', height: '3rem', background: `url("img/detail_free_plan_recommended_icon.png") no-repeat 0 0`, backgroundSize: 'contain', marginRight: '1.4rem' }} />
                <Box sx={{
                  color: '#999999',
                  fontSize: '2.4rem',
                  fontWeight: '700',
                  lineHeight: '150%'
                }}>This item is ?</Box>
              </Box> : <Box sx={{
                display: 'flex',
                marginBottom: '4.8rem'
              }}>
                <Box sx={{ width: '3rem', height: '3rem', background: `url("${getRecommendedImage(hffScore)}") no-repeat 0 0`, backgroundSize: 'contain', marginRight: '1.4rem', marginTop: '1rem' }} />
                <Box sx={{
                  color: getScoreColor(hffScore),
                  fontSize: '2.4rem',
                  fontWeight: '700',
                  lineHeight: '150%'
                }}>{getRecommendedText(hffScore)}</Box>
              </Box>}
              <Box sx={{
                display: 'flex',
                marginBottom: '8.8rem',
                alignItems: 'center'
              }}>
                <Box sx={{ width: '3rem', height: '3rem', background: 'url("/img/DetailPage_hffScore.png") no-repeat 0 0', backgroundSize: 'contain', marginRight: '1.4rem' }}></Box>
                <Box sx={{
                  color: (statusLoading === true || statusData == null || statusData.getSubscriptionStatus === "Free") && !FreeShowProductList.includes(id) && false ? '#999999' : '#333333',
                  fontSize: '2.6rem',
                  fontWeight: '700',
                  lineHeight: '150%'
                }}>Cartifind Score:  {(statusLoading === true || statusData == null || statusData.getSubscriptionStatus === "Free") && !FreeShowProductList.includes(id) && false ? "4.?" : hffScore.slice(0, 3)}</Box>
              </Box>
              {(isAddedLoading === false && isAddedData != null && isAddedData.getProductAddMyProductStatus !== "ALREADY_ADDED") && isSaveSuccess === false ? <Box className='button' sx={{ border: 'none!important', backgroundColor: '#67B239!important', color: '#FFF!important' }} onClick={() => {
                if (isAddedLoading === true || isAddedData == null || isAddedData.getProductAddMyProductStatus === "ALREADY_ADDED") {
                  return;
                }
                sendLog("DETAIL_PAGE_ADD_PRODUCT", String(id));
                add_product_list({
                  variables: {
                    id: String(id),
                    aliExpressProductURL: productLink
                  },
                }).then((result) => {
                  if (
                    result.data != null &&
                    result.data.addProductList != null
                  ) {
                    if (result.data.addProductList.message === 'PRODUCT_IS_SAVE_ALREADY') {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: 'The product has been added!',
                        snackbarState: 'FAILED',
                      });
                      setIsSaveSuccess(true);
                    } else if (result.data.addProductList.message === "USER_NOT_LOG_IN") {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: 'Please log in and then operate !',
                        snackbarState: 'FAILED',
                      });
                      setIsSaveSuccess(false);
                    } else if (result.data.addProductList.message === "SUCCESS") {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: 'successfully added !',
                        snackbarState: 'SUCCESS',
                      });
                      setIsSaveSuccess(true);
                    } else if (result.data.addProductList.message === "PLEASE_UPGRADE_PLAN") {
                      setState({
                        ...state,
                        isOpenSelectPlanDialog: true,
                        openSnackbar: false,
                      });
                    }
                  }
                });
              }}>Add To My Products</Box> : <Box className='button' sx={{ border: 'none!important', backgroundColor: '#999999!important', color: '#FFF!important' }}>Added To My Products</Box>}
              <Box className='button' onClick={() => {
                sendLog("DETAIL_PAGE_ALIEXPRESS_VIEW", String(id));
                window.open(
                  `/orderLink?orderLink=${productLink}`
                );
              }}>Show On AliExpress <Box sx={{ width: '2.4rem', height: '2.6rem', background: 'url("/img/DetailPage_skip.png") no-repeat 0 0', backgroundSize: 'contain', marginLeft: '1.1rem' }}></Box></Box>
              <Box className='button' onClick={() => {
                sendLog("DETAIL_PAGE_ALIEXPRESS_ORDER", String(id));
                if (currentSkuID === '') {
                  setState({
                    ...state,
                    openSnackbar: true,
                    snackbarText: 'Please select goods !',
                    snackbarState: 'FAILED',
                  });
                  return;
                }
                const orderLink = `https://www.aliexpress.com/p/trade/confirm.html?objectId=${productID}&from=aliexpress&countryCode=US&skuId=${currentSkuID}&quantity=1`;
                window.open(
                  `${orderLink}`
                );
              }}>Place Order <Box sx={{ width: '2.4rem', height: '2.6rem', background: 'url("/img/DetailPage_skip.png") no-repeat 0 0', backgroundSize: 'contain', marginLeft: '5.6rem' }}></Box></Box>
            </Box>
          </Box>
        </Box>
        {(statusLoading === true || statusData == null || statusData.getSubscriptionStatus === "Free") && !FreeShowProductList.includes(id) ? <Box sx={{
          width: '100%',
          height: '55.5rem',
          background: 'url("img/detail_recommendtion_filter.png") no-repeat 0 0',
          backgroundSize: 'cover',
          boxSizing: 'border-box',
          marginBottom: '3rem',
          color: '#FFFFFF',
          fontSize: '3rem',
          fontWeight: '500',
          letterSpacing: '0.15px'
        }}>
          <Box sx={{ paddingTop: '14rem', textAlign: 'center', height: '4.5rem', lineHeight: '150%' }}>Upgrade to Pro Plan, and unblock analysis of the product, and the supplier.</Box>
          <Box sx={{ margin: '5rem auto 0rem', borderRadius: '1rem', width: '21.2rem', height: '6.5rem', backgroundColor: '#FF9711', textAlign: 'center', lineHeight: '6.5rem', cursor: 'pointer' }} onClick={() => {
            if (firstName == null) {
              setState({
                ...state,
                isAccountPopUpDialogOpen: true,
                currentComponentName: "SIGN_UP",
              });
              return;
            }
            if (shopName == null) {
              setState({
                ...state,
                isAccountPopUpDialogOpen: true,
                currentComponentName: "CONNECT_SHOPIFY",
              });
              return;
            }
            setState({
              ...state,
              isOpenSelectPlanDialog: true
            });
          }}>
            Upgrade plan
          </Box>
        </Box> :
          <Box sx={{
            width: '100%',
            height: '55.5rem',
            backgroundColor: '#FFF',
            boxSizing: 'border-box',
            marginBottom: '3rem'
          }}>
            <Box sx={{ height: '3rem', ...layoutFlexAlignItemsenter, fontSize: '2rem', fontWeight: '700', color: '#333333', paddingLeft: '4rem', marginBottom: '1rem', paddingTop: '2.5rem' }}>
              <Box sx={{ width: '2.5rem', height: '2.5rem', background: 'url("/img/DetailPage_recommdention.png") no-repeat 0 0', backgroundSize: 'contain', marginRight: '0.5rem' }} />
              <Box>Recommendtion</Box>
            </Box>
            <Box sx={{
              ...layoutFlexAlignItemsenter,
              height: '2.2rem',
              fontSize: '2rem',
              fontWeight: '700',
              color: '#333333',
              paddingLeft: '7rem',
              borderBottom: '0.1rem solid #E3E3E3',
              marginBottom: '2rem',
              paddingBottom: '1.5rem'
            }}>
              Cartifind Score:  {hffScore.slice(0, 3)}
              <Box sx={{ margin: '0 2rem', color: '#707070' }}>/</Box>
              <Box sx={{ color: getScoreColor(hffScore) + '!important' }}> {getRecommendedText(hffScore)}</Box>
              <Box sx={{ width: '2.5rem', height: '2.5rem', background: `url("${getRecommendedImage(hffScore)}") no-repeat 0 0`, backgroundSize: 'contain', marginLeft: '0.5rem' }}></Box>
            </Box>
            <Box sx={{
              paddingLeft: '7rem',
              borderBottom: '0.1rem solid #E3E3E3',
              '& .box-item': {
                ...layoutFlexAlignItemsenter,
                height: '2rem',
                marginBottom: '2rem',
                fontSize: '1.8rem',
                color: '#333333',
                fontWeight: '500'
              },
              '& .title': {
                width: '17rem'
              },
              '& .score': {
                width: '6rem',
                marginRight: '1rem'
              },
              '& .content': {
                color: '#707070',
                fontSize: '1.6rem',
                fontWeight: '400'
              }
            }}>
              <Box sx={{
                fontSize: '2rem',
                color: '#333333',
                fontWeight: '700',
                marginBottom: '2rem'
              }}>Supplier Rating: </Box>
              <Box className='box-item'>
                <Box className='title'>Good Feedback:</Box>
                <Box className='score' sx={{ color: getFeedbackScoreColor(feedbackScore) }}>{feedbackScore}%</Box>
                <Box className='content'>{getFeedbackText(feedbackScore)}</Box>
              </Box>
              <Box className='box-item'>
                <Box className='title'>Item As Described:</Box>
                <Box className='score' sx={{ color: getScoreColor(itemAsDescribed) }}>{itemAsDescribed}</Box>
                <Box className='content'>{getItemAsDescribedText(itemAsDescribed)}</Box>
              </Box>
              <Box className='box-item'>
                <Box className='title'>Shipping:</Box>
                <Box className='score' sx={{ color: getScoreColor(shipSpeed) }}>{shipSpeed}</Box>
                <Box className='content'>{getShippingText(shipSpeed)}</Box>
              </Box>
              <Box className='box-item'>
                <Box className='title'>Communication:</Box>
                <Box className='score' sx={{ color: getScoreColor(communication) }}>{communication}</Box>
                <Box className='content'>{getCommunicationText(communication)}</Box>
              </Box>
            </Box>
            <Box sx={{

              ...layoutFlexAlignItemsenter,
              fontSize: '1.8rem',
              fontWeight: '500',
              color: '#333333',
              padding: '2rem 0rem 2rem 7rem',
              borderBottom: '0.1rem solid #E3E3E3',
            }}>
              <Box sx={{ fontSize: '2rem', fontWeight: '700', width: '17rem' }}>Reviews:</Box>
              <Box sx={{ width: '6rem', color: getScoreColor(reviewScore) }}>{reviewScore}</Box>
              <Box sx={{ color: '#65A2FF', cursor: 'pointer' }} onClick={() => { reviewScrollByID('review') }}>{reviews.length} reviews</Box>
            </Box>
            {/* <Box sx={{
                        ...layoutFlexAlignItemsenter,
                        fontSize: '1.8rem',
                        fontWeight: '500',
                        color: '#333333',
                        padding: '2rem 0rem 2rem 7rem',
                        borderBottom: '0.1rem solid #E3E3E3',
                    }}>
                        <Box sx={{ fontSize: '2rem', fontWeight: '700', width: '17rem', ...layoutFlexAlignItemsenter, }}>
                            Orders:
                            <Box sx={{ width: '2.5rem', height: '2rem', background: `url("${getOrderImage(orders)}") no-repeat 0 0`, backgroundSize: 'contain', marginLeft: '3rem' }}></Box>
                        </Box>
                        <Box sx={{ width: '6rem', color: getOrderColor(orders) }}>{orders}</Box>
                        <Box sx={{ color: '#707070', fontWeight: '400' }}>{getOrderText(orders)}</Box>
                    </Box> */}
            <Box sx={{
              ...layoutFlexAlignItemsenter,
              color: '#333333',
              padding: '2rem 0rem 2rem 7rem',
              borderBottom: '0.1rem solid #E3E3E3',
            }}>
              <Box sx={{ fontSize: '2rem', fontWeight: '700', ...layoutFlexAlignItemsenter, }}>
                Video:
                {videoLink != null && videoLink.length > 0 ? <Box sx={{ color: '#65A2FF', fontWeight: '500', marginLeft: '3.9rem', cursor: 'pointer' }} onClick={() => { window.open(videoLink) }}>Link</Box> : <Box sx={{ color: '#999999', fontWeight: '500', marginLeft: '3.9rem' }}>No Video</Box>}
              </Box>
            </Box>
          </Box>}
        {reviewPhoto.length > 0 ? <Box sx={{
          width: '100%',
          height: '28rem',
          boxSizing: 'border-box',
          backgroundColor: '#FFF',
          marginBottom: '3rem'
        }}>
          <Box sx={{ height: '3rem', ...layoutFlexAlignItemsenter, fontSize: '2rem', fontWeight: '700', color: '#333333', paddingLeft: '4rem', marginBottom: '1.5rem', paddingTop: '1.5rem' }}>
            <Box sx={{ width: '2.5rem', height: '2.5rem', background: 'url("/img/DetailPage_photoOfBuys.png") no-repeat 0 0', backgroundSize: 'contain', marginRight: '0.5rem' }}></Box>
            <Box>Photos of buyers</Box>
          </Box>
          <Box sx={{
            ...layoutFlexAlignItemsenter,
            height: '20rem',
            marginLeft: '7.1rem',
            position: 'relative',
            '&>div': {
              width: '20rem',
              height: '20rem',
              marginLeft: '3rem',
              borderRadius: '0.5rem',
              overflow: 'hidden',
              border: '0.1rem solid #E3E3E3',
              ...layoutFlexAlignItemsenter,
              justifyContent: 'center',
              boxSizing: 'border-box',
              cursor: 'pointer'
            },
            '&>div:first-of-type': {
              marginLeft: '0'
            },
            '& img': {
              height: '100%',
              width: 'auto',
              borderRadius: '0.5rem',
            }
          }}>
            {
              reviewPhoto.map((src, index) => {
                if (index < 8) {
                  return <Box key={index} onClick={() => {
                    setCurrentShowBigImage(src);
                    setCurrentBigImageList(reviewPhoto);
                    setIsShowBigImage(true);
                  }}>
                    <img src={src} alt={'image' + index} />
                  </Box>;
                }
              })
            }
            {reviewPhoto.length > 8 ?
              <Box sx={{ position: 'absolute', top: '0', left: '158rem', flexDirection: 'column', backgroundColor: 'rgba(45, 45, 45, 0.8)', color: '#FFF', fontSize: '3.2rem', fontWeight: '700' }}>
                <Box>{reviewPhoto.length - 8}</Box>
                <Box sx={{ fontSize: '1.8rem' }}>more photos</Box>
              </Box> : <></>
            }
          </Box>
        </Box> : <Box />}
        {reviews.length > 0 ? <Box sx={{
          width: '100%',
          minHeight: '116.4rem',
          boxSizing: 'border-box',
          backgroundColor: '#FFF',
          marginBottom: '3rem'
        }} id='review'>
          <Box sx={{ height: '3rem', ...layoutFlexAlignItemsenter, fontSize: '2rem', fontWeight: '700', color: '#333333', paddingLeft: '4rem', marginBottom: '1.5rem', paddingTop: '1.6rem' }}>
            <Box sx={{ width: '2.5rem', height: '2.5rem', background: 'url("/img/DetailPage_review.png") no-repeat 0 0', backgroundSize: 'contain', marginRight: '0.5rem' }}></Box>
            <Box>Reviews</Box>
          </Box>
          {
            reviews.map(({ date, info, content, country, name, photos, rating }, index) => {
              if (index < showReviewsNum * 10) {
                return (
                  <Box sx={{
                    paddingLeft: '7.2rem',
                    display: 'flex',
                    borderBottom: '0.1rem solid #B3B3B3',
                    marginBottom: '2rem'
                  }} key={index}>
                    <Box sx={{ ...layoutFlexAlignItemsenter, flexDirection: 'column', }}>
                      <Avatar alt="Remy Sharp" src='/img/review_default_Avatar1.png' sx={{ width: '6rem', height: '6rem', marginBottom: '1.2rem' }} />
                      <Box sx={{ height: '2.4rem', fontSize: '1.6rem', fontWeight: '500', color: '#333' }}>{name.slice(0, 1) + '***' + name.slice(name.length - 1)}</Box>
                      <Box sx={{ width: '4rem', height: '2rem', background: content.length % 2 === 1 ? 'url("/img/DetailPage_country1.png") no-repeat 0 0' : 'url("/img/DetailPage_country2.png") no-repeat 0 0', backgroundSize: 'contain', }}></Box>
                    </Box>
                    <Box sx={{
                      marginLeft: '3.1rem',
                      width: '120rem',
                    }}>
                      <Box sx={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
                        <Rating name="read-only" value={rating} readOnly size="large" />
                      </Box>
                      <Box sx={{ height: '2.4rem', ...layoutFlexAlignItemsenter, fontSize: '1.6rem', fontWeight: '500', color: '#707070', marginBottom: '0.6rem', '& div': { marginRight: '2rem' }, '& div:last-child': { marginRight: '0' } }}>
                        {
                          Object.keys(info).map((key, keyIndex) => {
                            return <Box key={'info' + keyIndex}>{key} {info[key]}</Box>
                          })
                        }
                      </Box>
                      <Box sx={{
                        width: '100%',
                        fontSize: '1.8rem',
                        fontWeight: '500',
                        color: '#333',
                        lineHeight: '110%',
                        marginBottom: '2.2rem',
                        minHeight: photos != null && photos.length > 0 ? '2rem' : '4rem'
                      }}>{content}</Box>
                      {photos != null && photos.length > 0 ?
                        <Box sx={{
                          marginBottom: '1.5rem',
                          display: 'flex',
                          '& div': {
                            width: '4.5rem',
                            height: '4.5rem',
                            border: '0.1rem solid #B3B3B3',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '0.5rem',
                            cursor: 'pointer'
                          },
                          '& img': {
                            height: '100%',
                            width: 'auto',
                            maxWidth: '100%',
                            borderRadius: '0.5rem'
                          },
                        }}>
                          {photos.map((src, photoIndex) => {
                            return <Box key={src} sx={{ marginLeft: photoIndex === 0 ? '0rem' : '1rem' }} onClick={() => {
                              setCurrentShowBigImage(src);
                              setCurrentBigImageList(reviewPhoto);
                              setIsShowBigImage(true);
                            }}>
                              <img src={src} alt={'photos' + index} />
                            </Box>
                          })}
                        </Box> : <Box />
                      }
                    </Box>
                    <Box sx={{ marginLeft: '35rem', height: '2.7rem', fontSize: '1.8rem', fontWeight: '500', ...layoutFlexAlignItemsenter, color: '#333333' }}>{Convdate(date)}</Box>
                  </Box>)
              }
            })
          }
          {reviews.length > 10 ?
            <Box sx={{ width: '100%', height: '6.8rem', ...layoutFlexAlignItemsenter, justifyContent: 'center' }}>
              {reviews.length > showReviewsNum * 10 ? <Box sx={{ width: '3rem', height: '2.8rem', background: 'url("img/DetailPage_morePhotos.png") no-repeat 0 0', backgroundSize: 'contain', marginRight: '3rem', cursor: reviews.length > showReviewsNum * 10 ? 'pointer' : 'auto' }} onClick={() => {
                if (reviews.length > showReviewsNum * 10) {
                  setShowReviewsNum(showReviewsNum + 1);
                }
              }}></Box> : <Box sx={{ width: '3rem', height: '3rem', background: 'url("img/DetailPage_fold.png") no-repeat 0 0', backgroundSize: 'contain', cursor: 'pointer' }} onClick={() => { setShowReviewsNum(1); }}></Box>}
            </Box> : <Box />
          }
        </Box> : <Box />}
        <Box sx={{
          width: '100%',
          height: 'auto',
          minHeight: '116.4rem',
          boxSizing: 'border-box',
          backgroundColor: '#FFF',
          marginBottom: '0'
        }}>
          <Tabs value={tabsValue} onChange={(e, newValue) => { setTabsValue(newValue); }} aria-label="basic tabs example" TabIndicatorProps={{
            style: {
              height: "0.4rem",
              backgroundColor: "#333333",
            },
          }} sx={{
            borderBottom: '0.1rem solid #E3E3E3',
            paddingLeft: '4rem',
            '& .MuiTab-root': {
              fontSize: '2rem',
              fontWeight: '700',
              color: '#707070',
              textTransform: 'capitalize',
              "&.Mui-selected": {
                color: "#333333",
              }
            }
          }}>
            <Tab label="Description" iconPosition="start" icon={<Box sx={{
              width: '2rem',
              height: '2rem',
              background: tabsValue === 0 ? 'url("/img/DetailPage_description_selected.png") no-repeat 0 0' : 'url("/img/DetailPage_description.png") no-repeat 0 0',
              backgroundSize: 'contain'
            }} />} id='simple-tab-0' aria-controls='simple-tabpanel-0' />
            <Tab label="Specifications" iconPosition="start" icon={<Box sx={{
              width: '2rem',
              height: '2rem',
              background: tabsValue === 1 ? 'url("/img/DetailPage_specifications_selected.png") no-repeat 0 0' : 'url("/img/DetailPage_specifications.png") no-repeat 0 0',
              backgroundSize: 'contain'
            }} />} id='simple-tab-1' aria-controls='simple-tabpanel-1' />
          </Tabs>
          <Box hidden={tabsValue !== 0} sx={{ width: '100.1rem', height: 'auto', border: '0.4rem solid #E3E3E3', margin: '2rem auto', boxSizing: 'border-box', padding: '4rem 8rem 4rem 5rem', position: 'relative' }}>
            {tabsValue === 0 && <Box dangerouslySetInnerHTML={{ __html: description }} sx={{ width: '100%' }}></Box>}
          </Box>
          <Box hidden={tabsValue !== 1} sx={{
            padding: tabsValue === 1 ? '2.8rem 6.8rem 0' : '0',
            paddingLeft: 'calc(6rem + 24px)',
            display: 'flex',
            overflowY: 'auto',
            flexWrap: 'wrap',
            fontSize: '1.8rem',
            fontWeight: '500',
            color: '#707070',
            '&>div': {
              width: '50%',
              height: '3.2rem',
              ...layoutFlexAlignItemsenter,
              '& div:last-child': {
                fontWeight: '700',
                color: '#333333',
                marginLeft: '0.5rem'
              }
            }
          }}>
            {tabsValue === 1 &&
              Specifications.map(({ attrName, attrValue }, index) => {
                return <Box key={index}>
                  <Box>{attrName}: </Box>
                  <Box>{attrValue}</Box>
                </Box>;
              })
            }
          </Box>
        </Box>
        {isShowBigImage ? <Box sx={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.76)',
          top: '0',
          left: '0',
          zIndex: '9999',
          ...layoutFlexAlignItemsenter,
          justifyContent: 'center',
          '& .button': {
            width: '10rem',
            height: '10rem',
            position: 'absolute',
            top: '50%',
            marginTop: '-5rem',
            zIndex: '99999',
            cursor: 'pointer'
          }
        }} onClick={() => { setIsShowBigImage(false) }}>
          <Box sx={{ width: '90rem', height: '90rem', overflow: 'hidden', ...layoutFlexAlignItemsenter, justifyContent: 'center', '& img': { width: 'auto', height: '100%', borderRadius: '0.5rem' } }} onClick={(e) => {
            e.stopPropagation();
          }}>
            <img src={currentShowBigImage} alt='photo0' />
          </Box>
          <Box sx={{
            left: '15rem',
            background: 'url("/img/DetailPage_bigImage_left.png") no-repeat 0 0',
            backgroundSize: 'cover',
            '&:hover': {
              background: 'url("/img/DetailPage_bigImage_left_selected.png") no-repeat 0 0',
              backgroundSize: 'cover',
            }
          }} className='button' onClick={(e) => {
            e.stopPropagation();
            const currentIndex = currentBigImageList.indexOf(currentShowBigImage);
            if (currentIndex > 0) {
              setCurrentShowBigImage(currentBigImageList[currentIndex - 1]);
            }
          }}></Box>
          <Box sx={{
            right: '15rem',
            background: 'url("/img/DetailPage_big_image_right.png") no-repeat 0 0',
            backgroundSize: 'cover',
            '&:hover': {
              background: 'url("/img/DetailPage_big_image_right_selected.png") no-repeat 0 0',
              backgroundSize: 'cover',
            }
          }} className='button' onClick={(e) => {
            e.stopPropagation();
            const currentIndex = currentBigImageList.indexOf(currentShowBigImage);
            if (currentIndex < currentBigImageList.length - 1) {
              setCurrentShowBigImage(currentBigImageList[currentIndex + 1]);
            }
          }}></Box>
        </Box> : <Box />}

      </Box>
    </Box >
  );
}

function getRecommendedImage(HFFscore) {
  switch (true) {
    case (HFFscore >= 4.8):
      return '/img/DetailPage_highly.png';
    case (HFFscore >= 4.3):
      return '/img/DetailPage_fairly.png';
    default:
      return '/img/DetailPage_not.png';
  }
}

function getRecommendedText(HFFscore) {
  switch (true) {
    case (HFFscore >= 4.8):
      return 'This item is highly recommended.';
    case (HFFscore >= 4.3):
      return 'This item is fairly recommended.';
    default:
      return 'This item is not recommended.';
  }
}

function getScoreColor(score) {
  switch (true) {
    case (score >= 4.7):
      return '#67B239';
    case (score >= 4.4):
      return '#EDD500';
    default:
      return '#E80000';
  }
}

function getFeedbackScoreColor(score) {
  switch (true) {
    case (score >= 95):
      return '#67B239';
    case (score >= 90):
      return '#EDD500';
    default:
      return '#E80000';
  }
}

function getOrderColor(score) {
  switch (true) {
    case (score >= 700):
      return '#EDD500';
    case (score >= 100):
      return '#67B239';
    default:
      return '#E80000';
  }
}

function getFeedbackText(score) {
  switch (true) {
    case (score >= 95):
      return 'A high percentage of good reviews and satisfied customers.';
    case (score >= 90):
      return 'There is a small percentage of neutral and negative reviews.';
    default:
      return 'There are negative and neutral reviews, we advise to get acquainted.';
  }
}

function getItemAsDescribedText(score) {
  switch (true) {
    case (score >= 4.7):
      return 'The goods correspond to the description, there are practically no complaints.';
    case (score >= 4.4):
      return 'The product may not exactly match the description and photos.';
    default:
      return 'Goods may differ from the description, contact the seller for more details.';
  }
}

function getShippingText(score) {
  switch (true) {
    case (score >= 4.7):
      return 'Fast shipment and delivery of goods, will receive the parcel on time.';
    case (score >= 4.4):
      return 'Generally, delivery of goods of the seller occurs without delays.';
    default:
      return 'Delays when sending or delivering an order sometimes occur.';
  }
}

function getCommunicationText(score) {
  switch (true) {
    case (score >= 4.7):
      return 'The seller answers quickly, all issues are resolved without problems.';
    case (score >= 4.4):
      return 'Buyers almost do not have problems in communication with the seller.';
    default:
      return 'Buyers sometimes have problems in communication with the seller.';
  }
}

function getOrderText(score) {
  switch (true) {
    case (score >= 700):
      return 'The number of products sold is very high, and be cautious about market saturation.';
    case (score >= 100):
      return 'The number of products sold is solid.  There is a strong desire for this product and not too many competitors.';
    default:
      return 'The number of products sold is not impressive, and there might be limited desire for this product.';
  }
}

function getOrderImage(score) {
  switch (true) {
    case (score >= 700):
      return '/img/DetailPage_order_fierce.png';
    case (score >= 100):
      return '/img/DetailPage_order_popular.png';
    default:
      return '/img/DetailPage_order_low.png';
  }
}



