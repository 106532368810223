import * as React from "react";
import { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Context } from "../Context/Context";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Checkbox } from "@mui/material";
import Typography from '@mui/material/Typography';
import CustomSelected from "../CommonComponents/CustomSelected.react";
import { Spin } from 'antd';

const GEN_AI_TITLE_TEXT = gql`
  mutation Mutation($id: String, $title: String) {
    genAITitleText(id: $id, title: $title) {
      status
      result
      message
    }
  }
`;

const GET_OPENAI_PROMPT = gql`
query query($name: String) {
  getOpenAIPromptIDInTemplateList(name: $name)
  }
`;

const SAVE_OPENAI_PROMPT_SELECTED_TEMPLATE_ID = gql`
mutation mutation($name: String, $templateID: Int) {
  saveOpenAIPromptSelectedTemplateID(name: $name, templateID: $templateID)
  }
`;

const GET_PRODUCT_COLLECTION = gql`
mutation getProductCollection {
  getProductCollection {
    collectionOptionTitleList
  }
}
`;

const GET_AI_TEMPLATE_LIST = gql`
  query getAITemplateList {
    getAITemplateList {
      id
      name
      prompt
    }
  }
`;

let AITemplateOptionsMap = new Map();
let AITemplateOptions = [{
  option: "Template 1",
  value: 0,
}]
let currentDefaultOption = "Template 1";

export default function ProductContent(props) {
  const {
    title,
    thumbnailSource,
    images,
    OrighinalTitle,
    setEphemeralTitle,
    setEphemeralThumbnailSource,
    setCurrentThumbnailSourceIndex,
    currentThumbnailSourceIndex,
    id,
    tagListProp,
    tagOptionListProp,
    collectionListProp,
    setEphemeralCollectionList,
    setEphemeralTagList,
    setEphemeralTagOptionList,
    setEphemeralProductType,
    productType,
  } = props;

  const wrapperRef = useRef(null);
  const [editNum, setEditNum] = useState(0);
  const { state, setState } = React.useContext(Context);
  const { sendLog, tabValue } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [gen_ai_title_text] = useMutation(GEN_AI_TITLE_TEXT);
  const [save_openAI_prompt_selected_template_id] = useMutation(SAVE_OPENAI_PROMPT_SELECTED_TEMPLATE_ID);
  const [get_product_collection] = useMutation(GET_PRODUCT_COLLECTION);
  const [openAIChangeTitle, setOpenAIChangeTitle] = useState('');
  const [isEditOpenAIPrompt, setIsEditOpenAIPrompt] = useState(false);
  const [tagsInput, setTagsInput] = useState("");
  const [tagList, setTagList] = useState(tagListProp ? tagListProp : []);
  const [tagOptionList, setTagOptionList] = useState(tagOptionListProp ? tagOptionListProp : []);
  const [collectionsInput, setCollectionsInput] = useState("");
  const [productTypeInput, setProductTypeInput] = useState("");
  const [collectionList, setCollectionList] = useState(collectionListProp ? collectionListProp : []);
  const [collectionOptionList, setCollectionOptionList] = useState([]);
  const [handleRefetchPageTimes, setHandleRefetchPageTimes] = useState(0);
  const [isOpenTagsSelelct, setIsOpenTagsSelelct] = useState(false);
  const [isOpenCollectionsSelelct, setIsOpenCollectionsSelelct] = useState(false);
  const [currentAITemplate, setCurrentAITemplate] = useState(0);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(true);
  const storePlatform = localStorage.getItem("storePlatform");
  const { loading, data, refetch } = useQuery(GET_OPENAI_PROMPT, {
    variables: {
      name: "title",
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const { loading: templateLoading, data: templateData, refetch: templateRefetch } = useQuery(GET_AI_TEMPLATE_LIST, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  const [openAIPrompt, setOpenAIPrompt] = useState(null);

  const setTemplateEffect = (value) => {
    save_openAI_prompt_selected_template_id({
      variables: {
        name: "title",
        templateID: Number(value)
      }
    });
    setCurrentAITemplate(value);
  }

  useEffect(() => {
    refetch({
      name: "title",
    })
  }, [tabValue]);

  useEffect(() => {
    setIsLoadingTemplate(true);
    if (templateData != null && templateData.getAITemplateList != null) {
      AITemplateOptions = templateData.getAITemplateList.map((item, index) => {
        AITemplateOptionsMap.set(
          item.id,
          item,
        );
        return {
          option: item.name,
          value: item.id,
        }
      });
      refetch({
        name: "title",
      }).then(res => {
        setIsLoadingTemplate(false);
        if (res.data != null && res.data.getOpenAIPromptIDInTemplateList != null) {
          currentDefaultOption = AITemplateOptionsMap.get(res.data.getOpenAIPromptIDInTemplateList).name;
          setCurrentAITemplate(res.data.getOpenAIPromptIDInTemplateList);
          save_openAI_prompt_selected_template_id({
            variables: {
              name: "title",
              templateID: Number(res.data.getOpenAIPromptIDInTemplateList)
            }
          });
        }
      })
    }
  }, [templateLoading]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "57rem",
        position: "relative",
        overflowX: "hidden",
        "& .wrapper": {
          width: "calc(100% - 35rem)",
          height: "100%",
          transition: "right 0.5s",
          top: "0",
          right: "calc(35rem - 100%)",
          position: "absolute",
          backgroundColor: "#FFF",
          zIndex: "1",
          borderRadius: "1rem 0px 1rem 1rem",
        },
        "& .wrapper.is-nav-open": {
          boxShadow: "-5px 4px 4px rgba(0, 0, 0, 0.25)",
          right: "0",
        },
      }}
      onClick={() => {
        setIsOpenTagsSelelct(false);
        setIsOpenCollectionsSelelct(false);
      }}
    >
      <Box
        ref={wrapperRef}
        className="wrapper"
        sx={{
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            height: "5rem",
            fontFamily: "Arial",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "2.2rem",
            lineHeight: "150%",
            letterSpacing: "0.015rem",
            color: "#333333",
          }}
        >
          Images
        </Box>
        <Box
          sx={{
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "33rem",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {images.map((item, index) => (
              <Box
                key={index}
                sx={{
                  cursor: "pointer",
                  overflow: "hidden",
                  width: "26rem",
                  height: "26rem",
                  border:
                    index === currentThumbnailSourceIndex
                      ? "0.4rem solid #FF9711"
                      : "0.4rem solid transparent",
                  boxSizing: "border-box",
                  marginLeft: "2rem",
                  marginBottom: "1.5rem",
                }}
                onClick={(e) => {
                  setCurrentThumbnailSourceIndex(index);
                }}
              >
                <img src={item.src} loading="lazy" width="100%" />
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            height: "7rem",
            width: "calc( 100% - 48px)",
            padding: "0 24px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "space-between",
            "& .button": {
              padding: "1.4rem 3.3rem",
              borderRadius: "1rem",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "2.6rem",
              lineHeight: "150%",
              letterSpacing: "0.015rem",
              color: "rgba(112, 112, 112, 0.7)",
              backgroundColor: "#e4e4e4",
            },
            "& .saveButton": {
              marginLeft: "6.4rem",
              color: "#FFF",
              backgroundColor: "#FF9711",
            },
          }}
        >
          <Box
            className="button"
            onClick={(e) => {
              const wrapper = wrapperRef.current;
              wrapper.classList.toggle("is-nav-open");
            }}
          >
            Cancel
          </Box>
          <Box
            className="button saveButton"
            onClick={() => {
              setEphemeralThumbnailSource(images[currentThumbnailSourceIndex].src);
              const wrapper = wrapperRef.current;
              wrapper.classList.toggle("is-nav-open");
            }}
          >
            Save
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box>
          <CardMedia
            sx={{
              width: "30rem",
              height: "30rem",
              borderRadius: "1rem",
            }}
            component="img"
            image={thumbnailSource}
          />
          <Button
            sx={{
              width: "30rem",
              height: "6rem",
              border: "0.4rem solid #FF9711",
              color: "#FF9711",
              marginTop: "1.6rem",
              fontWeight: "500",
              fontSize: "2.4rem",
              "&:hover": { backgroundColor: "rgba(255,151,17,0.2)" },
            }}
            onClick={(e) => {
              sendLog("MY_PRODUCTS_CARD_DIALOG_PRODUCT_CHANGE_IMAGE");
              const wrapper = wrapperRef.current;
              wrapper.classList.toggle("is-nav-open");
            }}
          >
            CHANGE IMAGE
          </Button>
        </Box>
        <Box
          sx={{
            flex: 1,
            paddingLeft: "5rem",
            fontFamily: "Arial",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "2.2rem",
            letterSpacing: "0.015rem",
            color: "#333333",
            lineHeight: "150%",
          }}
        >
          <Box
            sx={{
              fontWeight: "500",
            }}
          >
            Original Title:
          </Box>
          <Box sx={{ maxHeight: '6.5rem', overflow: 'hidden' }}>{OrighinalTitle}</Box>
          <Box sx={{
            height: '4.5rem',
            margin: '1.5rem 0 2rem',
            display: 'flex',
            alignItems: 'center',
            "& .button": {
              width: '25.3rem',
              height: '4.5rem',
              borderRadius: '1rem',
              color: '#FFF',
              fontWeight: '500',
              fontSize: '1.4rem',
              letterSpacing: '0.15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }
          }}>

            {!isLoadingTemplate ? <Box sx={{ fontSize: '16px', marginRight: '20px' }}>
              <CustomSelected
                width="400px"
                height="40px"
                selectedWidth="400px"
                border="1px solid #999999"
                selectedItems={AITemplateOptions}
                defaultOption={currentDefaultOption}
                selectedValue={currentAITemplate}
                setSelectValue={setTemplateEffect}
              />
            </Box> : <Spin />}
            {isLoading ? <Box sx={{
              backgroundColor: '#9BB7B0',
              cursor: 'default',
              "@keyframes load": {
                from: {
                  transform: "rotate(0deg)",
                },
                to: {
                  transform: "rotate(360deg)",
                }
              },
            }} className='button'>
              <Box sx={{
                animation: 'load 1.5s infinite',
                width: '2.5rem',
                height: '2.5rem',
                marginRight: '1rem'
              }}>
                <img src='/img/ImportAliExpressURL_loading.png' alt="loading" width={'100%'} height={"100%"} />
              </Box>
              Generating
            </Box> :
              <Box sx={{ backgroundColor: '#75A99C', cursor: 'pointer', }} className='button' onClick={() => {
                sendLog("MY_PRODUCTS_CARD_DIALOG_PRODUCT_CLICK_OPENAI");
                setIsLoading(true);
                gen_ai_title_text({
                  variables: {
                    id,
                    title: OrighinalTitle
                  }
                }).then((result) => {
                  if (result != null && result.data != null && result.data.genAITitleText != null && result.data.genAITitleText.result != null && result.data.genAITitleText.result.length > 0) {
                    if (result.data.genAITitleText.status) {
                      const reg = new RegExp("\n", "g");
                      const titleRes = result.data.genAITitleText.result.replace(reg, "");
                      setEphemeralTitle(titleRes);
                      setOpenAIChangeTitle(titleRes);
                      setIsLoading(false);
                    } else {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: result.data.genAITitleText.result,
                        snackbarState: 'FAILED',
                      });
                      setIsLoading(false);
                    }
                  } else {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText: 'Something went wrong !',
                      snackbarState: 'FAILED',
                    });
                    setIsLoading(false);
                  }
                })
              }}>
                <Box sx={{
                  width: '3.0rem',
                  height: '3.1rem',
                  marginRight: '0.7rem'
                }}>
                  <img src='/img/openAI_logo.png' alt="loading" width={'100%'} height={"100%"} />
                </Box>
                Generate Title by ChatGPT
              </Box>}
          </Box>
          <Box>
            <TextField
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  fontSize: "2.2rem",
                  lineHeight: "150%",
                },
                "&>.MuiInputBase-root.MuiOutlinedInput-root": {
                  padding: "0 1rem!important",
                  height: "7.9rem",
                },
                "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                  borderColor: "#FF9711",
                },
              }}
              multiline
              maxRows={2}
              value={openAIChangeTitle.length === 0 ? title.replace(/\"/g, "") : openAIChangeTitle.replace(/\"/g, "")}
              onChange={(e) => {
                if (editNum === 0) {
                  setEditNum(editNum + 1);
                  sendLog("MY_PRODUCTS_CARD_DIALOG_PRODUCT_EDIT_TITLE");
                }
                setEphemeralTitle(e.target.value);
                setOpenAIChangeTitle(e.target.value);
              }}
              autoFocus
            />
          </Box>
          {storePlatform != "wix" ? <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2rem',
            gap: '1rem'
          }}>
            <Box sx={{
              display: 'flex',
              position: 'relative'
            }}>
              <Box sx={{
                width: '14.5rem',
                height: '6rem',
                borderRadius: '1rem 0 0 1rem',
                backgroundColor: '#FF9711',
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                color: '#FFF'
              }}>Tags:</Box>
              <Box sx={{
                flex: 1,
                boxSizing: "border-box",
                border: '0.1rem solid #707070',
                borderRadius: '0rem 1rem 1rem 0rem',
                borderLeft: "none",
                display: 'flex',
                alignItems: "center",
              }}>
                <Box sx={{
                  maxHeight: '30rem',
                  display: 'flex',
                  alignItems: "center",
                }}>
                  {
                    tagList.map((item, index) => {
                      return <Box sx={{
                        padding: "1rem 2rem",
                        display: 'flex',
                        alignItems: "center",
                        marginLeft: '1rem',
                        backgroundColor: '#E8E8E8',
                        color: '#333333',
                        fontWeight: '500',
                        fontSize: '2rem',
                        letterSpacing: '0.15px',
                        borderRadius: '1rem'
                      }} key={index}>
                        <Box sx={{
                          maxWidth: "14rem",
                          overflow: "hidden",
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}>{item}</Box>
                        <Box sx={{
                          width: '2rem',
                          height: '2rem',
                          background: "url('img/no_circle_close.png') no-repeat 0 0",
                          backgroundSize: 'contain',
                          marginLeft: '1rem',
                          cursor: "pointer",
                        }} onClick={(e) => {
                          e.stopPropagation();
                          let midArr = [...tagList];
                          midArr.splice(index, 1);
                          setTagList(midArr);
                          setEphemeralTagList(midArr);
                          setHandleRefetchPageTimes(handleRefetchPageTimes + 1);
                        }} />
                      </Box>
                    })
                  }
                </Box>
                <TextField
                  value={tagsInput}
                  onChange={(e) => {
                    setTagsInput(e.target.value);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenTagsSelelct(true);
                    setIsOpenCollectionsSelelct(false);
                  }}
                  sx={{
                    flex: 1,
                    cursor: "pointer",
                    "& .MuiInputBase-input": {
                      padding: '0 0 0 1rem',
                    },
                    "& .MuiInputBase-root": {
                      fontSize: '2.2rem',
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </Box>
              <HtmlSelect sx={isOpenTagsSelelct ? {
                position: 'absolute',
                top: '6rem',
                left: '0.5%',
                width: '99%',
                maxHeight: '16rem',
                height: 'auto',
                overflow: 'auto',
                backgroundColor: "#FFF",
                borderRadius: '1rem',
                boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25), 0px -1px 6px rgba(0, 0, 0, 0.25)',
                zIndex: '9'
              } : { with: 0, height: 0 }} onClick={(e) => {
                e.stopPropagation();
              }}
                selectedItems={tagList}
                selectedOptions={tagOptionList}
                open={isOpenTagsSelelct}
                userInput={tagsInput}
                isAllowAdd={true}
                handleClickItem={(value) => {
                  if (tagList.includes(value)) {
                    let midArr = tagList;
                    midArr.splice(tagList.indexOf(value), 1);
                    setTagList(midArr);
                    setEphemeralTagList(midArr);
                  } else {
                    setTagList([...tagList, value]);
                    setEphemeralTagList([...tagList, value]);
                  }
                  setHandleRefetchPageTimes(handleRefetchPageTimes + 1);
                }}
                handleAddOption={(value) => {
                  let newTagList = [...tagList];
                  let newTagOptionList = [...tagOptionList];
                  newTagList.push(value);
                  newTagOptionList.push(value);
                  setTagList(newTagList);
                  setTagOptionList(newTagOptionList);
                  setEphemeralTagList(newTagList);
                  setEphemeralTagOptionList(newTagOptionList);
                  setTagsInput("");
                  setHandleRefetchPageTimes(handleRefetchPageTimes + 1);
                }}
              />
            </Box>
            <Box sx={{
              display: 'flex',
              position: 'relative'
            }}>
              <Box sx={{
                width: '14.5rem',
                height: '6rem',
                borderRadius: '1rem 0 0 1rem',
                backgroundColor: '#FF9711',
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                color: '#FFF'
              }}>Collections:</Box>
              <Box sx={{
                flex: 1,
                boxSizing: "border-box",
                border: '0.1rem solid #707070',
                borderRadius: '0rem 1rem 1rem 0rem',
                borderLeft: "none",
                display: 'flex',
                alignItems: "center",
              }}>
                {
                  collectionList.map((item, index) => {
                    return <Box sx={{
                      padding: "1rem 2rem",
                      display: 'flex',
                      alignItems: "center",
                      marginLeft: '1rem',
                      backgroundColor: '#E8E8E8',
                      color: '#333333',
                      fontWeight: '500',
                      fontSize: '2rem',
                      letterSpacing: '0.15px',
                      borderRadius: '1rem'
                    }} key={index}>
                      <Box sx={{
                        maxWidth: "14rem",
                        overflow: "hidden",
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>{item}</Box>
                      <Box sx={{
                        width: '2rem',
                        height: '2rem',
                        background: "url('img/no_circle_close.png') no-repeat 0 0",
                        backgroundSize: 'contain',
                        marginLeft: '1rem',
                        cursor: 'pointer'
                      }} onClick={(e) => {
                        e.stopPropagation();
                        let midArr = [...collectionList];
                        midArr.splice(index, 1);
                        setCollectionList(midArr);
                        setEphemeralCollectionList(midArr);
                        setHandleRefetchPageTimes(handleRefetchPageTimes + 1);
                      }} />
                    </Box>
                  })
                }
                <TextField
                  value={collectionsInput}
                  onChange={(e) => {
                    setCollectionsInput(e.target.value);
                  }}
                  onClick={(e) => {
                    get_product_collection().then(res => {
                      if (res != null && res.data != null && res.data.getProductCollection) {
                        setCollectionOptionList(res.data.getProductCollection.collectionOptionTitleList);
                      }
                    });
                    e.stopPropagation();
                    setIsOpenTagsSelelct(false);
                    setIsOpenCollectionsSelelct(true);
                  }}
                  sx={{
                    flex: 1,
                    cursor: "pointer",
                    "& .MuiInputBase-input": {
                      padding: '0 0 0 1rem',
                    },
                    "& .MuiInputBase-root": {
                      fontSize: '2.2rem',
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </Box>
              <HtmlSelect sx={isOpenCollectionsSelelct && collectionOptionList != null && collectionOptionList.length > 0 ? {
                position: 'absolute',
                top: '6rem',
                left: '0.5%',
                width: '99%',
                height: '16rem',
                overflow: 'auto',
                backgroundColor: "#FFF",
                borderRadius: '1rem',
                boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25), 0px -1px 6px rgba(0, 0, 0, 0.25)',
                zIndex: '9'
              } : { with: 0, height: 0 }} onClick={(e) => {
                e.stopPropagation();
              }} selectedItems={collectionList} selectedOptions={collectionOptionList} open={isOpenCollectionsSelelct} userInput={collectionsInput}
                handleClickItem={(value) => {
                  if (collectionList.includes(value)) {
                    let midArr = [...collectionList];
                    midArr.splice(collectionList.indexOf(value), 1);
                    setCollectionList(midArr);
                    setEphemeralCollectionList(midArr);
                  } else {
                    setCollectionList([...collectionList, value]);
                    setEphemeralCollectionList([...collectionList, value]);
                  }
                  setHandleRefetchPageTimes(handleRefetchPageTimes + 1);
                }} />
            </Box>
            <Box sx={{
              display: 'flex',
              position: 'relative'
            }}>
              <Box sx={{
                width: '14.5rem',
                height: '6rem',
                borderRadius: '1rem 0 0 1rem',
                backgroundColor: '#FF9711',
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                color: '#FFF'
              }}>Type:</Box>
              <Box sx={{
                flex: 1,
                boxSizing: "border-box",
                border: '0.1rem solid #707070',
                borderRadius: '0rem 1rem 1rem 0rem',
                borderLeft: "none",
                display: 'flex',
                alignItems: "center",
              }}>
                <TextField
                  value={productTypeInput.length === 0 ? productType : productTypeInput}
                  onChange={(e) => {
                    setEphemeralProductType(e.target.value);
                    setProductTypeInput(e.target.value);
                  }}
                  sx={{
                    flex: 1,
                    cursor: "pointer",
                    "& .MuiInputBase-input": {
                      padding: '0 0 0 1rem',
                    },
                    "& .MuiInputBase-root": {
                      fontSize: '2.2rem',
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </Box> : null}
        </Box>
      </Box>
    </Box>
  );
}

function HtmlSelect(props) {
  const {
    sx,
    onClick,
    open,
    selectedItems,
    selectedOptions,
    userInput,
    handleClickItem,
    isAllowAdd,
    handleAddOption
  } = props;
  const [isShowAddItem, setIsShowAddItem] = useState(false);
  useEffect(() => {
    let timer;
    if (userInput != null && userInput.length > 0 && !selectedOptions.includes(userInput)) {
      timer = setTimeout(() => {
        setIsShowAddItem(true);
      }, 200)
    } else {
      setIsShowAddItem(false);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [userInput]);

  return <>{
    open ? <Box sx={sx} onClick={onClick}>
      {isShowAddItem && isAllowAdd ? <Box sx={{
        height: '4rem',
        display: 'flex',
        alignItems: 'center',
        cursor: "pointer",
        backgroundColor: "#f2edfd",
      }} onClick={() => {
        if (handleAddOption) {
          setIsShowAddItem(false);
          handleAddOption(userInput);
        }
      }}>
        <Box sx={{ width: '3rem', height: '3rem', background: 'url("img/icon_product_add_tag.png") no-repeat 0 0', backgroundSize: 'contain', margin: '0 1rem' }}></Box>
        <Box><Typography variant="span" sx={{ color: '#333333', fontWeight: '600' }}>Add</Typography> {userInput}</Box>
      </Box> : null}
      {
        selectedOptions != null ? selectedOptions.map((item, index) => {
          if (item.slice(0, userInput.length).includes(userInput)) {
            return (
              <Box sx={{
                height: '4rem',
                display: 'flex',
                alignItems: 'center',
                cursor: "pointer",
                backgroundColor: false ? '#e0e0e0' : '#FFF',
                '&:hover': {
                  backgroundColor: '#f5f5f5'
                }
              }} onClick={() => {
                handleClickItem(item);
              }} key={index}>
                <Checkbox sx={{
                  color: "#FF9711",
                  padding: '0',
                  "&.Mui-checked": {
                    color: "#FF9711",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "5rem",
                  },
                  margin: '0 1rem'
                }}
                  checked={selectedItems.includes(item)}
                  onChange={(e) => {

                  }}
                  inputProps={{
                    "aria-label": `Checked item${0}`,
                  }}
                  icon={<Box sx={{ width: '3rem', height: '3rem', background: 'url("img/checkbox_icon.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                  </Box>}
                  checkedIcon={<Box sx={{ width: '3rem', height: '3rem', background: 'url("img/checkbox_icon_checked.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                  </Box>} />
                <Box>{item}</Box>
              </Box>
            )
          }
        }) : null
      }
    </Box> : null
  }</>
}
