/** @format */

import React, { useContext } from "react";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context/Context.js";

const queryParams = new URLSearchParams(window.location.search);
const productLink = queryParams.get("productLink");
const productTitle = queryParams.get("productTitle");

export default function ProductDetailRedirectPage() {
  const { setState, state } = React.useContext(Context);
  const { sendLog } = state;
  React.useEffect(() => {
    // Function to redirect to the product link after 5 seconds
    const redirectToProductLink = () => {
      window.location.href = productLink;
    };

    // Set the timeout to redirect after 5 seconds
    const timer = setTimeout(redirectToProductLink, 5000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      clearTimeout(timer);
    };
  }, [productLink]); // Depend on productLink to ensure it updates correctly if the link changes
  return (
    <Box sx={{
      width: "100%",
      marginTop: 10,
      display: "flex",
      justifyContent: "center",
    }}
    >
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "700px",
        gap: "10px"
      }}>
        <Box sx={{
          background: 'url("/img/productDetailRedirect_logo.png") no-repeat 0 0',
          backgroundSize: 'cover',
          width: "100px",
          height: "100px"
        }} />
        <Box sx={{
          height: "50px",
          fontSize: "28px",
          fontWeight: "700",
          lineHeight: "50px",
          letterSpacing: "0.15px",
          color: "#000000"
        }}>Congratulations, you are on your way to</Box>
        <Box sx={{
          maxWidth: "400px",
          height: "40px",
          fontSize: "20px",
          fontWeight: "700",
          lineHeight: "40px",
          letterSpacing: "0.15px",
          color: "#EF8702",
          textOverflow: 'ellipsis',
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
          display: '-webkit-box',
          wordBreak: 'break-all',
          overflow: "hidden"
        }}>{productTitle != null ? productTitle : "AliExpress Product"}</Box>
        <Box sx={{
          height: "69px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px"
        }}>
          <Box sx={{
            height: "40px",
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "40px",
            letterSpacing: "0.15px",
            color: "#000000",
            fontStyle: "italic"
          }}>Listed on</Box>
          <Box sx={{
            background: 'url("/img/productDetailRedirect_AliExpress_logo.png") no-repeat 0 0',
            backgroundSize: 'cover',
            width: "110px",
            height: "30px",
            cursor: "pointer"
          }} onClick={() => {
            window.open("https://www.aliexpress.us/");
          }} />
        </Box>
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Box sx={{
            width: "460px",
            height: "48px",
            borderRadius: "10px",
            backgroundColor: "#FF9711",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
            fontSize: "20px",
            fontWeight: "600",
            letterSpacing: "0.4px",
            color: "#FFF",
            cursor: "pointer"
          }} onClick={() => {
            sendLog("PRODUCT_DETAIL_REDIRECT_PAGE_CLICK_INSTALL");
            window.open("https://cartifind.com/extensionInstructions");
          }}>Install Cartifind Importer For Best Experience</Box>
        </Box>
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Box sx={{
            color: "#707070",
            cursor: "pointer",
            textDecoration: "underline",
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "28px",
            letterSpacing: "0.15px",
          }} onClick={() => {
            sendLog("PRODUCT_DETAIL_REDIRECT_PAGE_CLICK_CONTINUE");
            window.location = productLink;
          }}>Continue without Cartifind Importer</Box>
        </Box>
      </Box>
      {/* <CircularProgress /> */}
    </Box>
  );
}