import * as React from "react";
import { lazy } from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { Context } from "../Context/Context";
import { gql, useQuery, useMutation } from "@apollo/client";
import Divider from "@mui/material/Divider";

import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { currencyList } from "../utils/CurrencyList.js";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from '@mui/material/Typography';

import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";


const APP_SUBSCRIPTION_CANCEL = gql`
mutation appSubscriptionCancel {
    appSubscriptionCancel {
        status
        message
    }
}
`;

export default function TabAccount(prop) {
  const {
    currentPlanShowDate,
    subscriptionType,
    refetch,
    isShowCancel
  } = prop;

  const { state, setState } = React.useContext(Context);
  const [isOpenChangePlan, setIsOpenChangePlan] = useState(false);
  const [app_subscription_cancel] = useMutation(APP_SUBSCRIPTION_CANCEL);

  return (
    <>
      <Dialog
        open={isOpenChangePlan}
        onClose={() => {
          setIsOpenChangePlan(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            width: "75.7rem",
            height: "59.2rem",
            maxHeight: "none",
            maxWidth: "none",
            borderRadius: "1rem",
          },
        }}
      >
        <DialogContent
          id="alert-dialog-title"
          sx={{
            height: '42.4rem',
            fontFamily: "Arial",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            letterSpacing: "0.015rem",
            color: "#666666",
            position: "relative",
            padding: '4rem 4rem 0rem',
          }}
        >
          <Box sx={{
            height: '8.4rem', "& div": {
              height: '4.2rem',
              lineHeight: '4.2rem',
              color: '#333333',
              fontWeight: '600',
              fontSize: '24px'
            },
            marginBottom: '3.4rem'
          }}>
            <Box>Review your changes</Box>
            <Box>You'll lose access to these features:</Box>
          </Box>
          <Box sx={{
            "& .unsubscribe-item": {
              display: 'flex',
              alignItems: 'center',
              height: '3rem',
              marginBottom: '2rem',
              "& .red-close": {
                background: 'url("img/unsubscribe_red_close.png") no-repeat 0 0',
                width: '1.5rem',
                height: '1.5rem',
                marginRight: '1.5rem',
                marginLeft: '1.2rem',
                backgroundSize: 'contain',
              },
              "& .unsubscribe-title": {
                height: '3rem',
                lineHeight: '3rem',
                color: '#333333',
                fontSize: '16px',
                fontWeight: '500'
              }
            }
          }}>
            <Box className="unsubscribe-item">
              <Box className="red-close" />
              <Box className="unsubscribe-title">Unlimited push products to Shopify store</Box>
            </Box>
            <Box className="unsubscribe-item">
              <Box className="red-close" />
              <Box className="unsubscribe-title">Add products from AliExpress with unlimited URLs</Box>
            </Box>
            <Box className="unsubscribe-item">
              <Box className="red-close" />
              <Box className="unsubscribe-title">Use AI to generate titles or descriptions for multiple products at once</Box>
            </Box>
            <Box className="unsubscribe-item">
              <Box className="red-close" />
              <Box className="unsubscribe-title">Show all Cartifind.com product comprehensive scores</Box>
            </Box>
            <Box className="unsubscribe-item">
              <Box className="red-close" />
              <Box className="unsubscribe-title">Select all products on the page with one click</Box>
            </Box>
            <Box className="unsubscribe-item">
              <Box className="red-close" />
              <Box className="unsubscribe-title">First priority service & SLA guaranteed</Box>
            </Box>
          </Box>
          <Box sx={{ background: 'url("img/no_circle_close.png") no-repeat 0 0', width: '2.5rem', height: '2.5rem', backgroundSize: 'contain', position: 'absolute', top: '2.4rem', right: '2.5rem', cursor: 'pointer' }} onClick={() => {
            setIsOpenChangePlan(false);
          }}></Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: '0 4rem 4rem',
            "& .button": {
              width: '16.6rem',
              height: '5rem',
              padding: "0rem",
              borderRadius: "1rem",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "150%",
              letterSpacing: "0.015rem",
              color: "#707070B2",
              backgroundColor: "#e4e4e4",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#e4e4e4",
              },
            },
            "& .cancel-button": {
              color: "#FFF",
              width: '18.6rem',
              backgroundColor: "#FF9711",
              "&:hover": {
                color: "#FFF",
                backgroundColor: "#FF9711",
              },
            },
          }}
        >
          <Button
            className="button"
            onClick={() => {
              app_subscription_cancel().then(result => {
                if (result != null && result.data != null && result.data.appSubscriptionCancel != null) {
                  if (result.data.appSubscriptionCancel.status) {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText: 'Unsubscribe Success !',
                      snackbarState: 'SUCCESS',
                    });
                  } else {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText: 'Unsubscribe failed !',
                      snackbarState: 'FAILED',
                    });
                  }
                } else {
                  setState({
                    ...state,
                    openSnackbar: true,
                    snackbarText: 'Unsubscribe failed !',
                    snackbarState: 'FAILED',
                  });
                }
                setIsOpenChangePlan(false);
                refetch();
              })
            }}
          >
            Unsubscribe
          </Button>
          <Button
            className="button cancel-button"
            onClick={() => {
              setIsOpenChangePlan(false);
            }}
          >
            Thinking About It
          </Button>
        </DialogActions>
      </Dialog >
      <Box sx={{
        width: "100%",
        height: 'auto',
        background: "#FFFFFF",
        boxShadow: "0px 0.4rem 0.4rem rgba(0, 0, 0, 0.25)",
        borderRadius: "1rem",
        marginBottom: '5rem',
      }}>
        <Box>
          <Box sx={{
            height: '3.3rem',
            fontSize: '18px',
            fontWeight: '500',
            padding: '2rem 4rem',
            color: '#333333'
          }}>Current Plan</Box>
          {subscriptionType !== 'Free' ? <Box>
            <Box sx={{
              paddingLeft: '7rem',
              fontSize: '16px',
              fontWeight: '500',
              color: '#333333',
              height: '3rem',
              lineHeight: '3rem'
            }}>{(subscriptionType == "Cancelled Pro") ? "Cartifind Pro plan" : (subscriptionType == "ProMonthly" ? 'Cartifind Pro monthly plan' : 'Cartifind Pro yearly plan')} (<Typography variant="span" sx={{ fontWeight: '400', color: '#666666' }}>{(subscriptionType === "Cancelled Pro") ? "Until: " : "Renews on: "}{currentPlanShowDate}</Typography>) </Box>
            {(subscriptionType == "Cancelled Pro") ? <Box sx={{
              width: '135.7rem',
              height: '6rem',
              lineHeight: '150%',
              fontSize: "16px",
              color: '#333333',
              fontWeight: '500',
              letterSpacing: '0.15px',
              marginTop: '1rem',
              paddingLeft: '7rem',
            }}>{subscriptionType == "ProMonthly" ? 'When the subscription expires, the automatic renewal fee is $9.95/ month. If the service is unsubscribed, the renewal fee will not be charged when the service expires.' :
              'When the subscription expires, the automatic renewal fee is $69.99/ year. If the service is unsubscribed, the renewal fee will not be charged when the service expires.'}</Box> : null}
          </Box> :
            <Box sx={{
              paddingLeft: '7rem',
              fontSize: '16px',
              fontWeight: '500',
              color: '#333333'
            }}>Basic (<Typography variant="span" sx={{ fontWeight: '400', color: '#666666' }}>since: {currentPlanShowDate}</Typography>) </Box>}
          <Box sx={{
            paddingLeft: '7rem',
            display: 'flex',
            alignItems: 'center',
            margin: '1rem 0 2.5rem',
            "& .button": {
              width: '17.4rem',
              height: '5rem',
              borderRadius: '1rem',
              lineHeight: '5rem',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: '700',
              color: '#FFFFFF',
              cursor: 'pointer'
            }
          }}>
            {subscriptionType != "ProYearly" ? <Box className="button" sx={{ backgroundColor: '#FF9711', marginRight: '3rem' }} onClick={() => {
              setState({
                ...state,
                isOpenSelectPlanDialog: true
              });
            }}>Change My Plan</Box> : null}
            {(subscriptionType === 'ProMonthly' || subscriptionType === "ProYearly") && isShowCancel == true ? <Box className="button" sx={{ backgroundColor: "#999999" }} onClick={() => {
              setIsOpenChangePlan(true);
            }}>Unsubscribe</Box> : null}
          </Box>
        </Box>
        <Box>
          <Divider sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }} />
          <Box sx={{
            height: '3.3rem',
            fontSize: '18px',
            fontWeight: '500',
            paddingLeft: '4rem',
            margin: '2rem 0 1rem',
            color: '#333333'
          }}>Your Plan Includes</Box>
          {subscriptionType === 'Free' ?
            <Box sx={{
              width: '74.6rem',
              height: '43.2rem',
              border: '1px solid #99999966',
              marginTop: '2rem',
              boxSizing: 'border-box',
              marginLeft: '4rem',
              "&>div": {
                borderBottom: '1px solid #99999966',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                height: '7.2rem',
                justifyContent: 'space-between'
              },
              "& .first-item": {
                width: '52.1rem',
                height: '7.2rem',
                borderRight: '1px solid #99999966',
                paddingLeft: '3rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                "& .title": {
                  fontSize: '16px',
                  fontWeight: '500',
                  color: '#333333',
                  height: '3rem',
                  lineHeight: '3rem'
                },
                "& .detail": {
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#666666',
                  height: '2.4rem',
                  lineHeight: '2.4rem'
                },
              },
              "& .second-item": {
                width: '22.5rem',
                fontSize: '16px',
                fontWeight: '500',
                color: '#333333',
                textAlign: 'center'
              }
            }}>
              <Box>
                <Box className='first-item'>
                  <Box className='title'>Push Products</Box>
                  <Box className='detail'>Push the product to the shopify store</Box>
                </Box>
                <Box className='second-item'>10</Box>
              </Box>
              <Box>
                <Box className='first-item'>
                  <Box className='title'>URL Add Product</Box>
                  <Box className='detail'>Add products from AliExpress by URL</Box>
                </Box>
                <Box className='second-item'>10</Box>
              </Box>
              <Box>
                <Box className='first-item'>
                  <Box className='title'>AI Automation Function</Box>
                  <Box className='detail'>Use AI to generate titles and product descriptions</Box>
                </Box>
                <Box className='second-item'>Single Operation</Box>
              </Box>
              <Box>
                <Box className='first-item'>
                  <Box className='title'>Cartifind Score</Box>
                  <Box className='detail'>Comprehensive evaluation of merchants by Cartifind</Box>
                </Box>
                <Box className='second-item' sx={{
                  display: 'flex',
                  alignItem: 'center',
                  justifyContent: 'center'
                }}>
                  <Box sx={{
                    width: '3.6rem',
                    height: '0.2rem',
                    backgroundColor: '#999999'
                  }} />
                </Box>
              </Box>
              <Box>
                <Box className='first-item'>
                  <Box className='title'>Select All With One Click</Box>
                  <Box className='detail'>One-click selection of all products for push or AI editing</Box>
                </Box>
                <Box className='second-item' sx={{
                  display: 'flex',
                  alignItem: 'center',
                  justifyContent: 'center'
                }}>
                  <Box sx={{
                    width: '3.6rem',
                    height: '0.2rem',
                    backgroundColor: '#999999'
                  }} />
                </Box>
              </Box>
              <Box sx={{
                border: 'none!important'
              }}>
                <Box className='first-item'>
                  <Box className='title'>Customer Support</Box>
                  <Box className='detail'>First priority service & SLA guaranteed</Box>
                </Box>
                <Box className='second-item' sx={{
                  display: 'flex',
                  alignItem: 'center',
                  justifyContent: 'center'
                }}>
                  <Box sx={{
                    width: '3.6rem',
                    height: '0.2rem',
                    backgroundColor: '#999999'
                  }} />
                </Box>
              </Box>
            </Box>
            :
            <Box sx={{
              width: '74.6rem',
              height: '43.2rem',
              border: '1px solid #99999966',
              marginTop: '2rem',
              boxSizing: 'border-box',
              marginLeft: '4rem',
              "&>div": {
                borderBottom: '1px solid #99999966',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                height: '7.2rem',
                justifyContent: 'space-between'
              },
              "& .first-item": {
                width: '52.1rem',
                height: '7.2rem',
                borderRight: '1px solid #99999966',
                paddingLeft: '3rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                "& .title": {
                  fontSize: '16px',
                  fontWeight: '500',
                  color: '#333333',
                  height: '3rem',
                  lineHeight: '3rem'
                },
                "& .detail": {
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#666666',
                  height: '2.4rem',
                  lineHeight: '2.4rem'
                },
              },
              "& .second-item": {
                width: '22.5rem',
                fontSize: '16px',
                fontWeight: '500',
                color: '#333333',
                textAlign: 'center'
              }
            }}>
              <Box>
                <Box className='first-item'>
                  <Box className='title'>Push Products</Box>
                  <Box className='detail'>Push the product to the shopify store</Box>
                </Box>
                <Box className='second-item'>Unlimited</Box>
              </Box>
              <Box>
                <Box className='first-item'>
                  <Box className='title'>URL Add Product</Box>
                  <Box className='detail'>Add products from AliExpress by URL</Box>
                </Box>
                <Box className='second-item'>Unlimited</Box>
              </Box>
              <Box>
                <Box className='first-item'>
                  <Box className='title'>AI Automation Function</Box>
                  <Box className='detail'>Use AI to generate titles and product descriptions</Box>
                </Box>
                <Box className='second-item' sx={{
                  display: 'flex',
                  alignItem: 'center',
                  justifyContent: 'center'
                }}>
                  <Box sx={{ background: 'url("img/plan_pigeon_green.png") no-repeat 0 0', width: '3.2rem', height: '2.5rem', backgroundSize: 'contain' }} />
                </Box>
              </Box>
              <Box>
                <Box className='first-item'>
                  <Box className='title'>Cartifind Score</Box>
                  <Box className='detail'>Comprehensive evaluation of merchants by Cartifind</Box>
                </Box>
                <Box className='second-item' sx={{
                  display: 'flex',
                  alignItem: 'center',
                  justifyContent: 'center'
                }}>
                  <Box sx={{ background: 'url("img/plan_pigeon_green.png") no-repeat 0 0', width: '3.2rem', height: '2.5rem', backgroundSize: 'contain' }} />
                </Box>
              </Box>
              <Box>
                <Box className='first-item'>
                  <Box className='title'>Select All With One Click</Box>
                  <Box className='detail'>One-click selection of all products for push or AI editing</Box>
                </Box>
                <Box className='second-item' sx={{
                  display: 'flex',
                  alignItem: 'center',
                  justifyContent: 'center'
                }}>
                  <Box sx={{ background: 'url("img/plan_pigeon_green.png") no-repeat 0 0', width: '3.2rem', height: '2.5rem', backgroundSize: 'contain' }} />
                </Box>
              </Box>
              <Box sx={{
                border: 'none!important'
              }}>
                <Box className='first-item'>
                  <Box className='title'>Customer Support</Box>
                  <Box className='detail'>First priority service & SLA guaranteed</Box>
                </Box>
                <Box className='second-item' sx={{
                  display: 'flex',
                  alignItem: 'center',
                  justifyContent: 'center'
                }}>
                  <Box sx={{ background: 'url("img/plan_pigeon_green.png") no-repeat 0 0', width: '3.2rem', height: '2.5rem', backgroundSize: 'contain' }} />
                </Box>
              </Box>
            </Box>}
        </Box>
        <Box sx={{ height: '5rem' }}></Box>
      </Box>
    </>
  );
}