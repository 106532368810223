import React, { useState, useEffect } from "react";
import { Context } from "../Context/Context";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { gql, useMutation } from "@apollo/client";
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


const APP_SUBSCRIPTION_CREATE = gql`
mutation appSubscriptionCreate($timeType: String, $domain: String) {
    appSubscriptionCreate(timeType: $timeType, domain: $domain){
        status
        confirmationUrl
        message
    }
}
`;

const PlanSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: '8rem',
    height: '4rem',
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: '0.5rem',
        transitionDuration: '300ms',
        backgroundColor: "#FF9711",
        '& + .MuiSwitch-track': {
            backgroundColor: "#FF9711",
            opacity: 1,
            border: 0,
        },
        '&.Mui-checked': {
            transform: 'translateX(4rem)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: "#FF9711",
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: "3rem",
        height: "3rem",
    },
    '& .MuiSwitch-track': {
        borderRadius: "2rem",
        backgroundColor: 'rgba(112, 112, 112, 0.7)',
        opacity: 1,
        transition: "background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
}));


export default function ProductEditDialog() {
    const [app_subscription_create] = useMutation(APP_SUBSCRIPTION_CREATE);
    const { state, setState } = React.useContext(Context);
    const { isOpenSelectPlanDialog, firstName, shopName, sendLog } = state;
    const [isYearly, setIsYearly] = useState(false);

    useEffect(() => {
        if (isOpenSelectPlanDialog) {
            sendLog("SELECT_PLAN_PAGE_OPEN", window.location.href)
        }
    }, [isOpenSelectPlanDialog]);

    return (
        <>
            <Dialog
                open={isOpenSelectPlanDialog}
                onClose={() => {
                    setState({
                        ...state,
                        isOpenSelectPlanDialog: false
                    });
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{
                    "&.MuiDialog-root": {
                        zIndex: '99999',
                    },
                    "& .MuiPaper-root": {
                        maxHeight: "none",
                        maxWidth: "none",
                        borderRadius: '1rem'
                    },
                }}
            >
                <DialogContent dividers={true} sx={{
                    width: "133.4rem",
                    height: "81rem",
                    padding: '3.5rem 5.6rem',
                    backgroundColor: "#F8F8F8",
                    boxSizing: 'border-box'
                }} >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '5.5rem'
                    }}>
                        <Box sx={{
                            width: '36rem',
                            paddingRight: '6rem'
                        }}>
                            <Box sx={{
                                fontSize: '3rem',
                                fontWeight: '600',
                                color: '#333333',
                                paddingTop: '4rem',
                                paddingBottom: "4.5rem",
                                textAlign: 'center'
                            }}>Select a pricing plan</Box>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '2.6rem',
                                fontWeight: '400',
                                color: '#333333',
                                justifyContent: 'center'
                            }}>
                                Monthly
                                <Box>
                                    <FormControlLabel control={<PlanSwitch checked={isYearly} onClick={(e) => {
                                        setIsYearly(e.target.checked);
                                    }} />} sx={{ margin: '0rem 1rem' }} />
                                </Box>
                                Yearly
                                <Box sx={{ width: '9.2rem', height: '3rem', borderRadius: '0.5rem', backgroundColor: '#FF971133', fontSize: '1.8rem', fontWeight: '400', color: '#FF9711', lineHeight: '3rem', textAlign: 'center', marginLeft: '1rem' }}>Save 42%</Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            width: '36rem',
                            height: '20.1rem',
                            borderRadius: "1rem",
                            backgroundColor: '#FFF',
                            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 1px 2px rgba(0, 0, 0, 0.25)',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '2.8rem',
                            fontWeight: '600',
                            color: '#333333'
                        }}>
                            <Box sx={{
                                height: '4.2rem',
                                marginTop: '1.5rem',
                                lineHeight: '4.2rem'
                            }}>Pro</Box>
                            {isYearly ? <Box sx={{
                                color: '#333333',
                                fontSize: '3.2rem',
                                fontWeight: '500',
                                '& .decimals': {
                                    alignSelf: 'flex-start',
                                    fontSize: '2.4rem',
                                    fontWeight: '500',
                                },
                                '& .integer': {
                                    height: '3.2rem',
                                    fontSize: '3.2rem',
                                    fontWeight: '700',
                                }
                            }}>
                                <Box sx={{ textAlign: 'center' }}>$5.83 / month </Box>
                                <Box sx={{ fontSize: '1.8rem', fontWeight: '400', color: '#FF9711', textAlign: 'center' }}>billed at $69.99 once per year</Box>
                            </Box> : <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '3.2rem',
                                color: '#333333',
                                fontSize: '3.2rem',
                                '& .decimals': {
                                    alignSelf: 'flex-start',
                                    fontSize: '2.4rem',
                                    fontWeight: '500',
                                },
                                '& .integer': {
                                    height: '3.2rem',
                                    fontSize: '3.2rem',
                                    fontWeight: '700',
                                }
                            }}>
                                <Box className='integer'>$9</Box>.
                                <Box className='decimals'>95</Box>
                            </Box>}
                            <Box sx={{
                                width: '15rem',
                                height: '5rem',
                                borderRadius: '1rem',
                                color: '#FFF',
                                lineHeight: '5rem',
                                textAlign: 'center',
                                background: '#FF9711',
                                fontSize: '2rem',
                                fontWeight: '700',
                                marginBottom: '2rem',
                                cursor: 'pointer'
                            }} onClick={() => {
                                if (firstName == null) {
                                    setState({
                                        ...state,
                                        isAccountPopUpDialogOpen: true,
                                        currentComponentName: "SIGN_UP",
                                    });
                                    return;
                                }
                                if (shopName == null) {
                                    setState({
                                        ...state,
                                        isAccountPopUpDialogOpen: true,
                                        currentComponentName: "CONNECT_SHOPIFY",
                                    });
                                    return;
                                }
                                app_subscription_create({
                                    variables: {
                                        timeType: isYearly ? "yearly" : "monthly",
                                        domain: window.location.hostname, /* 可能是www.cartifind.com 或者 cartifind.com 或者cloudfront url */
                                    },
                                }).then(res => {
                                    if (res.data != null && res.data.appSubscriptionCreate != null) {
                                        if (res.data.appSubscriptionCreate.confirmationUrl !== '') {
                                            window.location.href = res.data.appSubscriptionCreate.confirmationUrl;
                                        }
                                    }
                                })
                            }}>Upgrade Plan</Box>
                            <Box sx={{
                                width: '36rem',
                                height: '67rem',
                                position: 'absolute',
                                top: '0',
                                left: '-0.4rem',
                                borderRadius: '1rem',
                                border: '0.4rem solid #FF9711',
                                pointerEvents: "none",
                            }}></Box>
                            <Box sx={{
                                width: '10rem',
                                height: '6.5rem',
                                background: isYearly ? 'url("img/plan_pro_yearly.png") no-repeat 0rem 2rem' : 'url("img/plan_pro_monthly.png") no-repeat 0rem 1rem',
                                backgroundSize: 'contain',
                                position: 'absolute',
                                top: '-6.5rem',
                                left: '13rem'
                            }}></Box>
                        </Box>
                        <Box sx={{
                            width: '36rem',
                            height: '20.1rem',
                            borderRadius: "1rem",
                            backgroundColor: '#FFF',
                            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 1px 2px rgba(0, 0, 0, 0.25)',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '2.8rem',
                            fontWeight: '600',
                            color: '#333333'
                        }}>
                            <Box sx={{
                                height: '4.2rem',
                                marginTop: '1.5rem',
                                lineHeight: '4.2rem'
                            }}>Pro+</Box>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '3.2rem',
                                color: '#333333',
                                fontSize: '2.2rem',
                                fontWeight: '600'
                            }}>
                                Coming Soon
                            </Box>
                            <Box sx={{
                                width: '15rem',
                                height: '5rem',
                                borderRadius: '1rem',
                                color: '#FFF',
                                lineHeight: '5rem',
                                textAlign: 'center',
                                background: '#999999',
                                fontSize: '2rem',
                                fontWeight: '700',
                                marginBottom: '2rem'
                            }}>Coming Soon</Box>
                            <Box sx={{
                                width: '36rem',
                                height: '67rem',
                                position: 'absolute',
                                top: '0',
                                left: '-0.4rem',
                                borderRadius: '1rem',
                                border: '0.4rem solid #FF9711',
                                zIndex: '-1'
                            }} />
                        </Box>
                    </Box>
                    <Box sx={{
                        width: '122rem',
                        height: '45.2rem',
                        borderRadius: '1rem',
                        boxShadow: '0px -1px 1px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
                        backgroundColor: '#FFF',
                        marginTop: '2rem',
                        padding: '1.6rem 0rem 0.4rem',
                        boxSizing: 'border-box',
                        "&>div": {
                            borderBottom: '1px solid #99999966',
                            display: 'flex',
                            alignItems: 'center',
                            height: '7.2rem',
                            justifyContent: 'space-between'
                        },
                        "& .first-item": {
                            width: '42rem',
                            paddingLeft: '3rem',
                            "& .title": {
                                fontSize: '2rem',
                                fontWeight: '500',
                                color: '#333333',
                                height: '3rem',
                                lineHeight: '3rem'
                            },
                            "& .detail": {
                                fontSize: '1.6rem',
                                fontWeight: '400',
                                color: '#666666',
                                height: '2.4rem',
                                lineHeight: '2.4rem'
                            },
                        },
                        "& .second-item": {
                            width: '36rem',
                            fontSize: '2rem',
                            fontWeight: '600',
                            color: '#333333',
                            textAlign: 'center'
                        },
                        "& .third-item": {
                            width: '36rem',
                            marginLeft: '4.6rem',
                            fontSize: '2rem',
                            fontWeight: '500',
                            color: '#333333',
                            textAlign: 'center',
                        }
                    }}>
                        <Box>
                            <Box className='first-item'>
                                <Box className='title'>Push Products</Box>
                                <Box className='detail'>Push the product to the shopify store</Box>
                            </Box>
                            <Box className='second-item'>Unlimited</Box>
                            <Box className='third-item' >Unlimited</Box>
                        </Box>
                        <Box>
                            <Box className='first-item'>
                                <Box className='title'>URL Add Product</Box>
                                <Box className='detail'>Add products from AliExpress by URL</Box>
                            </Box>
                            <Box className='second-item'>Unlimited</Box>
                            <Box className='third-item' >Unlimited</Box>
                        </Box>
                        <Box>
                            <Box className='first-item'>
                                <Box className='title'>AI Automation Function</Box>
                                <Box className='detail'>Use AI to generate titles and product descriptions</Box>
                            </Box>
                            <Box className='second-item' sx={{
                                display: 'flex',
                                alignItem: 'center',
                                justifyContent: 'center'
                            }}>
                                <Box sx={{ background: 'url("img/plan_pigeon_green.png") no-repeat 0 0', width: '3.2rem', height: '2.5rem', backgroundSize: 'contain' }} />
                            </Box>
                            <Box className='third-item' >Coming Soon</Box>
                        </Box>
                        <Box>
                            <Box className='first-item'>
                                <Box className='title'>Cartifind Score</Box>
                                <Box className='detail'>Comprehensive evaluation of merchants by Cartifind</Box>
                            </Box>
                            <Box className='second-item' sx={{
                                display: 'flex',
                                alignItem: 'center',
                                justifyContent: 'center'
                            }}>
                                <Box sx={{ background: 'url("img/plan_pigeon_green.png") no-repeat 0 0', width: '3.2rem', height: '2.5rem', backgroundSize: 'contain' }} />
                            </Box>
                            <Box className='third-item' sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Box sx={{
                                    width: '3.6rem',
                                    height: '0.2rem',
                                    backgroundColor: '#999999'
                                }} />
                            </Box>
                        </Box>
                        <Box>
                            <Box className='first-item'>
                                <Box className='title'>Select All With One Click</Box>
                                <Box className='detail'>One-click selection of all products for push or AI editing</Box>
                            </Box>
                            <Box className='second-item' sx={{
                                display: 'flex',
                                alignItem: 'center',
                                justifyContent: 'center'
                            }}>
                                <Box sx={{ background: 'url("img/plan_pigeon_green.png") no-repeat 0 0', width: '3.2rem', height: '2.5rem', backgroundSize: 'contain' }} />
                            </Box>
                            <Box className='third-item' sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Box sx={{
                                    width: '3.6rem',
                                    height: '0.2rem',
                                    backgroundColor: '#999999'
                                }} />
                            </Box>
                        </Box>
                        <Box sx={{
                            border: 'none!important'
                        }}>
                            <Box className='first-item'>
                                <Box className='title'>Customer Support</Box>
                                <Box className='detail'>First priority service & SLA guaranteed</Box>
                            </Box>
                            <Box className='second-item' sx={{
                                display: 'flex',
                                alignItem: 'center',
                                justifyContent: 'center'
                            }}>
                                <Box sx={{ background: 'url("img/plan_pigeon_green.png") no-repeat 0 0', width: '3.2rem', height: '2.5rem', backgroundSize: 'contain' }} />
                            </Box>
                            <Box className='third-item' sx={{
                                display: 'flex',
                                alignItem: 'center',
                                justifyContent: 'center'
                            }}>
                                <Box sx={{
                                    width: '3.6rem',
                                    height: '0.2rem',
                                    backgroundColor: '#999999'
                                }} />
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog >
        </>

    );
}