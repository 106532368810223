import * as React from "react";
import { lazy, useImperativeHandle } from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { Context } from "../Context/Context";
import { gql, useQuery, useMutation } from "@apollo/client";
import Divider from "@mui/material/Divider";

import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { currencyList } from "../utils/CurrencyList.js";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomSelected = lazy(() =>
  import("../CommonComponents/CustomSelected.react")
);

const SAVE_SETTING_PRODUCT = gql`
  mutation saveSettingProduct(
    $currency: String, 
    $priceCalculate: String, 
    $timesValue: String, 
    $plusValue: String, 
    $isTaxable: String, 
    $pushProductInformationValue: String, 
    $pushProductDescriptionValue: String, 
    $inventoryAutomaticUpdate: String
    $isOpenAssignCents: String, 
    $AssignCents: String, 
    $vendor: String
    $optimizeValue: String
    $customPricingRule: String
    $defaultFulfillmentOption: Boolean
    $productPricingRuleComposition: String
  ) {
    saveSettingProduct(
      currency: $currency, 
      priceCalculate: $priceCalculate, 
      timesValue: $timesValue, 
      plusValue: $plusValue, 
      isTaxable: $isTaxable, 
      pushProductInformationValue: $pushProductInformationValue, 
      pushProductDescriptionValue: $pushProductDescriptionValue, 
      inventoryAutomaticUpdate: $inventoryAutomaticUpdate, 
      isOpenAssignCents: $isOpenAssignCents, 
      AssignCents: $AssignCents, 
      vendor: $vendor
      optimizeValue: $optimizeValue
      customPricingRule: $customPricingRule
      defaultFulfillmentOption: $defaultFulfillmentOption
      productPricingRuleComposition: $productPricingRuleComposition
    ) {
      status
      message
    }
  }
`;

const SelectionSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: '8rem',
  height: '4rem',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '0.5rem',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(4rem)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: "#FF9711",
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: "3rem",
    height: "3rem",
  },
  '& .MuiSwitch-track': {
    borderRadius: "2rem",
    backgroundColor: 'rgba(112, 112, 112, 0.7)',
    opacity: 1,
    transition: "background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}));
const ButtonTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement={placement ? placement : "bottom"} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
    fontSize: "20px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    color: "#FFF",
    maxWidth: '260px',
    fontSize: "12px",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 1px rgb(0 0 0 / 12%)",
  },
}));
export default function TabProduct(prop) {
  const {
    initialCurrency,
    initialSettingContent,
    shopName,
    ProductRef,
    setCurrentUnSaveIndex,
    refetch,
    setIsOpenUnSave,
    customPricingRule,
    fulfillmentOption,
    productPricingRuleComposition
  } = prop;

  const { state, setState } = React.useContext(Context);
  const [currency, setCurrency] = useState(initialSettingContent.currency);
  const [currentStore, setCurrentStore] = useState();
  const [timesValue, setTimesValue] = useState(initialSettingContent.timesValue);
  const [plusValue, setPlusValue] = useState(initialSettingContent.plusValue);
  const [customPricingRuleDisable, setCustomPricingRuleDisable] = useState(false);
  const [priceCalculateValue, setPriceCalculateValue] = useState(initialSettingContent.priceCalculate);
  const [productInformationValue, setProductInformationValue] = useState(initialSettingContent.pushProductInformationValue);
  const [productDescriptionValue, setProductDescriptionValue] = useState(initialSettingContent.pushProductDescriptionValue);
  const [isTaxable, setIsTaxable] = useState(initialSettingContent.isTaxable === '1');
  const [inventoryAutomaticUpdate, setInventoryAutomaticUpdate] = useState(initialSettingContent.inventoryAutomaticUpdate === '1');
  const [isOpenSuggestedPrice, setIsOpenSuggestedPrice] = useState(false);
  const [optimizeValue, setOptimizeValue] = useState(initialSettingContent.optimizeValue);
  const [isOpenAssignCents, setIsOpenAssignCents] = useState(initialSettingContent.isOpenAssignCents === '1');
  const [AssignCents, setAssignCents] = useState(initialSettingContent.AssignCents);
  const [vendor, setVendor] = useState(initialSettingContent.vendor);
  const [customPricingRuleValue, setCustomPricingRuleValue] = useState(customPricingRule.slice(0, customPricingRule.length - 1));
  const [restOfTheRangesOperativeSymbol, setRestOfTheRangesOperativeSymbol] = useState(customPricingRule[customPricingRule.length - 1].operativeSymbol);
  const [restOfTheRangesOperativeValue, setRestOfTheRangesOperativeValue] = useState(customPricingRule[customPricingRule.length - 1].operativeValue);
  const [refreshPage, setRefreshPage] = React.useState(0);
  const [currentFulfillmentOption, setCurrentFulfillmentOption] = useState(fulfillmentOption);
  const [currentProductPricingRuleComposition, setCurrentProductPricingRuleComposition] = useState(productPricingRuleComposition);
  useImperativeHandle(ProductRef, () => ({
    handleSaveProduct,
    handleDiscardProduct,
  }));
  const [save_setting_product] = useMutation(SAVE_SETTING_PRODUCT);
  const [originalVariables, setOriginalVariables] = useState('');
  const storePlatform = localStorage.getItem("storePlatform");

  const handleSaveProduct = async () => {
    save_setting_product({
      variables: {
        currency,
        priceCalculate: String(priceCalculateValue),
        timesValue: String(timesValue),
        plusValue: String(plusValue),
        isTaxable: isTaxable ? '1' : '0',
        pushProductInformationValue: String(productInformationValue),
        pushProductDescriptionValue: String(productDescriptionValue),
        inventoryAutomaticUpdate: inventoryAutomaticUpdate ? '1' : '0',
        isOpenAssignCents: isOpenAssignCents ? '1' : '0',
        AssignCents: String(AssignCents),
        vendor: String(vendor),
        optimizeValue: String(optimizeValue),
        customPricingRule: priceCalculateValue == "custom" ? JSON.stringify([...customPricingRuleValue, {
          id: "Rest of the ranges",
          costRangeStart: "",
          costRangeEnd: "",
          operativeSymbol: restOfTheRangesOperativeSymbol,
          operativeValue: restOfTheRangesOperativeValue,
        }]) : null,
        defaultFulfillmentOption: currentFulfillmentOption,
        productPricingRuleComposition: currentProductPricingRuleComposition
      },
    }).then((result) => {
      if (result != null && result.data != null && result.data.saveSettingProduct != null) {
        if (result.data.saveSettingProduct.status) {
          setState({
            ...state,
            openSnackbar: true,
            snackbarText: 'Save Setting Success !',
            snackbarState: 'SUCCESS',
          });
          setCurrentUnSaveIndex('-1');
          refetch();
        } else {
          setState({
            ...state,
            openSnackbar: true,
            snackbarText: 'Save Setting failed !',
            snackbarState: 'FAILED',
          });
        }
      } else {
        setState({
          ...state,
          openSnackbar: true,
          snackbarText: 'Save Setting failed !',
          snackbarState: 'FAILED',
        });
      }
      setIsOpenUnSave(false);
    });
  }
  const handleDiscardProduct = () => {
    setCurrency(initialSettingContent.currency);
    setTimesValue(initialSettingContent.timesValue);
    setPlusValue(initialSettingContent.plusValue);
    setPriceCalculateValue(initialSettingContent.priceCalculate);
    setProductInformationValue(initialSettingContent.pushProductInformationValue);
    setProductDescriptionValue(initialSettingContent.pushProductDescriptionValue);
    setIsTaxable(initialSettingContent.isTaxable === '1');
    setInventoryAutomaticUpdate(initialSettingContent.inventoryAutomaticUpdate === '1');
    setIsOpenSuggestedPrice(false);
    setOptimizeValue(initialSettingContent.optimizeValue);
    setIsOpenAssignCents(initialSettingContent.isOpenAssignCents === '1');
    setAssignCents(initialSettingContent.AssignCents);
    setVendor(initialSettingContent.vendor);
  }
  useEffect(() => {
    setOriginalVariables(JSON.stringify({
      currency,
      priceCalculate: String(priceCalculateValue),
      timesValue: String(timesValue),
      plusValue: String(plusValue),
      isTaxable: isTaxable ? '1' : '0',
      pushProductInformationValue: String(productInformationValue),
      pushProductDescriptionValue: String(productDescriptionValue),
      inventoryAutomaticUpdate: inventoryAutomaticUpdate ? '1' : '0',
      isOpenAssignCents: isOpenAssignCents ? '1' : '0',
      AssignCents: String(AssignCents),
      vendor: String(vendor),
      optimizeValue: String(optimizeValue),
    }));
  }, []);

  useEffect(() => {
    if (originalVariables !== '' && JSON.stringify({
      currency,
      priceCalculate: String(priceCalculateValue),
      timesValue: String(timesValue),
      plusValue: String(plusValue),
      isTaxable: isTaxable ? '1' : '0',
      pushProductInformationValue: String(productInformationValue),
      pushProductDescriptionValue: String(productDescriptionValue),
      inventoryAutomaticUpdate: inventoryAutomaticUpdate ? '1' : '0',
      isOpenAssignCents: isOpenAssignCents ? '1' : '0',
      AssignCents: String(AssignCents),
      vendor: String(vendor),
      optimizeValue: String(optimizeValue),
    }) !== originalVariables) {
      setCurrentUnSaveIndex('2');
    } else {
      setCurrentUnSaveIndex('-1');
    }
  }, [
    currency,
    priceCalculateValue,
    timesValue,
    plusValue,
    isTaxable,
    productInformationValue,
    productDescriptionValue,
    inventoryAutomaticUpdate,
    isOpenAssignCents,
    AssignCents,
    vendor,
    optimizeValue,
  ]);

  return (
    <>
      <Dialog
        open={isOpenSuggestedPrice}
        onClose={() => {
          setIsOpenSuggestedPrice(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            maxHeight: "none",
            maxWidth: "none",
            border: '0.2rem solid #999999',
            borderRadius: '1rem',
          },
        }} >
        <DialogContent sx={{
          padding: '0',
        }} >
          <Box sx={{
            width: "128rem",
            height: "65.4rem",
            padding: '0',
            overflow: 'hidden'
          }}>
            <img src='img/cartifind_suggested_price.png' alt="Cartifind Suggested Price" width='100%' height='100%' />
          </Box>
        </DialogContent>
      </Dialog>
      <Box sx={{
        width: "100%",
        height: 'auto',
        background: "#FFFFFF",
        boxShadow: "0px 0.4rem 0.4rem rgba(0, 0, 0, 0.25)",
        borderRadius: "1rem",
        marginBottom: '15rem',
        "& .title": {
          height: '5.3rem',
          display: 'flex',
          alignItems: 'center',
          boxSizing: 'border-box',
          fontSize: '18px',
          fontWeight: '500',
          paddingLeft: '4rem',
          paddingRight: '9.2rem',
          marginTop: '1rem',
          paddingTop: '2rem',
          color: '#333333'
        },
        "& .custom-selected-item": {
          fontSize: '16px'
        },
        "& .item-title": {
          fontSize: "18px",
          fontWeight: '500',
          color: '#333333',
          height: '3.3rem',
          lineHeight: '3.3rem',
        },
        "& .item-description": {
          fontSize: "14px",
          fontWeight: '400',
          color: '#999999',
          height: '2.7rem',
          lineHeight: '2.7rem',
        },
      }} >
        <Box>
          <Box sx={{
            marginBottom: '2rem',
            justifyContent: 'space-between',
            fontSize: '18px'
          }} className="title">
            {storePlatform != "wix" ? <Box>Shopify Stores Currency</Box> : <Box>Wix Stores Currency</Box>}
            <Box sx={{ fontSize: "16px" }}>
              <CustomSelected
                width="10.4rem"
                height="4rem"
                selectedWidth="10.4rem"
                border="0.1rem solid #999999"
                selectedItems={currencyList}
                defaultOption={initialCurrency}
                selectedValue={currency}
                setSelectValue={setCurrency}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Divider sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }} />
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '4rem',
            paddingRight: '9.2rem',
            marginTop: '1rem',
          }}>
            <Box sx={{
              height: '5.3rem',
              display: 'flex',
              alignItems: 'center',
              boxSizing: 'border-box',
              fontSize: '18px',
              fontWeight: '500',
              color: '#333333',
              marginRight: "20px"
            }}>Pricing Rule</Box>
            <Box sx={{ marginBottom: '2rem', marginTop: '1rem', fontSize: '16px' }}>
              <CustomSelected
                width="350px"
                height="4.8rem"
                selectedWidth="350px"
                border="0.1rem solid #999999"
                selectedItems={[
                  {
                    option: "shipping cost and product cost",
                    value: "1",
                  },
                  {
                    option: "product cost",
                    value: "0",
                  },
                ]}
                // defaultOption={shopName}
                selectedValue={currentProductPricingRuleComposition}
                setSelectValue={setCurrentProductPricingRuleComposition}
              />
            </Box>

          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            paddingLeft: 'calc(4rem - 9px)'
          }}>
            <FormControl sx={{
              width: '100%', '& .radio-button': {
                '& .MuiSvgIcon-root': {
                  fontSize: '2.5rem',
                },
                '&.Mui-checked': {
                  color: '#FF9711!important'
                },
                marginRight: '1rem'
              }
            }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={priceCalculateValue}
                onChange={(event) => {
                  setPriceCalculateValue(event.target.value);
                }} >
                <Box sx={{
                  marginBottom: '2rem',
                  display: 'flex',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                }}>
                  <Radio value='suggested' className="radio-button" />
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Apply Cartifind Suggsted Selling Price</Box>
                    <Box sx={{ width: '2.5rem', height: '2.5rem', marginLeft: '1.8rem', cursor: 'pointer', background: 'url("/img/pricing_rule_helper.png") no-repeat 0 0', backgroundSize: 'contain' }} onClick={() => { setIsOpenSuggestedPrice(true); }} />
                  </Box>
                </Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                  marginBottom: '2rem'
                }}>
                  <Radio value="times" className="radio-button" />
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '79.8rem',
                    height: '5.7rem',
                    "& .grey-background": {
                      width: '15.5rem',
                      height: '5.7rem',
                      borderRadius: '1rem',
                      backgroundColor: '#F6F6F6',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  }}>
                    <Box sx={{ fontSize: '16px' }}>Cost ( {currency} )</Box>
                    <Box sx={{ fontSize: '5.0rem' }} className='grey-background'>
                      <Box sx={{ transform: 'rotate(-45deg)' }}>+</Box>
                    </Box>
                    <Box className='grey-background' sx={{ justifyContent: 'flex-start!important' }}>
                      <TextField
                        value={timesValue ? timesValue : ''}
                        onChange={(e) => {
                          setTimesValue(e.target.value.replace(/[^\d.\d]/g, ''));
                        }}
                        sx={{
                          width: "10.5rem",
                          "& .MuiInputBase-input": {
                            textAlign: 'center',
                          },
                          "& .MuiInputBase-root": {
                            fontSize: '16px',
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "none",
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                      <Box sx={{ width: '3rem' }}>
                        <Box sx={{ width: '2rem', height: '2rem', marginBottom: '0.4rem', background: 'url("img/arrows_up.png") no-repeat 0 0 ', backgroundSize: 'contain', cursor: 'pointer' }} onClick={() => {
                          setTimesValue(Number(timesValue) + 1);
                        }} />
                        <Box sx={{ width: '2rem', height: '2rem', background: 'url("img/arrows_down.png") no-repeat 0 0 ', backgroundSize: 'contain', cursor: 'pointer' }} onClick={() => {
                          if (timesValue > 1) {
                            setTimesValue(Number(timesValue) - 1);
                          }
                        }
                        }
                        />
                      </Box>
                    </Box>
                    <Box sx={{ fontSize: '5.0rem' }}>=</Box>
                    <Box sx={{ fontSize: '16px' }}>Sell Price ( {currency} )</Box>
                  </Box>
                </Box>
                <Box sx={{
                  marginBottom: '2rem',
                  display: 'flex',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                }}>
                  <Radio value='plus' className="radio-button" />
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '79.8rem',
                    height: '5.7rem',
                    "& .grey-background": {
                      width: '15.5rem',
                      height: '5.7rem',
                      borderRadius: '1rem',
                      backgroundColor: '#F6F6F6',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  }}>
                    <Box sx={{ fontSize: '16px' }}>Cost ( {currency} )</Box>
                    <Box sx={{ fontSize: '5.0rem' }} className='grey-background'>+
                    </Box>
                    <Box className='grey-background' sx={{ justifyContent: 'flex-start!important' }}>
                      <TextField
                        value={plusValue ? plusValue : ''}
                        onChange={(e) => {
                          setPlusValue(e.target.value.replace(/[^\d.\d]/g, ''));
                        }}
                        sx={{
                          width: "10.5rem",
                          "& .MuiInputBase-input": {
                            textAlign: 'center',
                          },
                          "& .MuiInputBase-root": {
                            fontSize: '16px',
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "none",
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                      <Box sx={{ width: '3rem' }}>
                        <Box sx={{ width: '2rem', height: '2rem', marginBottom: '0.4rem', background: 'url("img/arrows_up.png") no-repeat 0 0 ', backgroundSize: 'contain', cursor: 'pointer' }} onClick={() => {
                          setPlusValue(Number(plusValue) + 1);
                        }} />
                        <Box sx={{ width: '2rem', height: '2rem', background: 'url("img/arrows_down.png") no-repeat 0 0 ', backgroundSize: 'contain', cursor: 'pointer' }} onClick={() => {
                          if (plusValue > 1) {
                            setPlusValue(Number(plusValue) - 1);
                          }
                        }} />
                      </Box>
                    </Box>
                    <Box sx={{ fontSize: '5.0rem' }}>=</Box>
                    <Box sx={{ fontSize: '16px' }}>Sell Price ( {currency} )</Box>
                  </Box>
                </Box>
                <Box sx={{
                  marginBottom: '2rem',
                }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                  }}>
                    <Radio value='custom' className="radio-button" />
                    <Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Custom Pricing Rules</Box>
                  </Box>
                  {priceCalculateValue == "custom" ?
                    <Box sx={{ position: "relative", paddingLeft: "50px", fontSize: "14px" }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', borderTop: "1px solid #f0f0f0", borderBottom: "1px solid #f0f0f0" }}>
                        <Box sx={{ width: "190px", height: "40px", textAlign: "center", lineHeight: "40px" }}>Cost Range({currency})</Box>
                        <Box sx={{ width: "170px", height: "40px", textAlign: "center", lineHeight: "40px", marginLeft: "50px" }}>Price({currency})</Box>
                      </Box>
                      {customPricingRuleValue.map((item, index) => {
                        return <CustomPricingRuleRow
                          costRangeStart={item.costRangeStart}
                          costRangeEnd={item.costRangeEnd}
                          operativeSymbol={item.operativeSymbol}
                          operativeValue={item.operativeValue}
                          customPricingRuleID={item.id}
                          customPricingRuleValue={customPricingRuleValue}
                          setCustomPricingRuleValue={setCustomPricingRuleValue}
                          refreshPage={refreshPage}
                          setRefreshPage={setRefreshPage}
                          lastCostRangeEnd={index != 0 ? customPricingRuleValue[index - 1].costRangeEnd : -1}
                          nextCostRangeStart={customPricingRuleValue.length > index + 1 ? customPricingRuleValue[index + 1].costRangeStart : "infinity"}
                          key={item.id}
                        />
                      })}

                      <Box sx={{ display: "flex", alignItems: "center", padding: "5px 0px", borderBottom: "1px solid #f0f0f0" }}>
                        <Box sx={{ width: "190px", height: "32px", lineHeight: "32px", textAlign: "center" }}>Rest of the ranges</Box>
                        <Box sx={{ fontSize: "20px", marginLeft: "50px", marginRight: "10px" }}>

                          <CustomSelected
                            width="80px"
                            height="32px"
                            selectedWidth="80px"
                            border="0.1rem solid #9e9e9e"
                            selectedItems={[{
                              option: "+",
                              value: "plus"
                            }, {
                              option: "×",
                              value: "times"
                            }]}
                            fontSize="20px"
                            selectedValue={restOfTheRangesOperativeSymbol}
                            setSelectValue={setRestOfTheRangesOperativeSymbol}
                          />
                        </Box>
                        <Box sx={{
                          boxSizing: 'border-box',
                          width: "80px",
                          height: "32px",
                          borderRadius: "8px",
                          padding: "0px 11px 0 11px",
                          display: "flex",
                          border: "1px solid #9e9e9e",
                          alignItems: 'center'
                        }}>
                          <TextField
                            value={restOfTheRangesOperativeValue}
                            onChange={(e) => {
                              setRestOfTheRangesOperativeValue(e.target.value)
                            }}
                            onBlur={(e) => {
                              const inputValue = parseFloat(e.target.value.replace(/[^\d.\d]/g, ''));
                              setRestOfTheRangesOperativeValue(inputValue.toFixed(2));
                            }}
                            sx={{
                              width: "56px",
                              "& .MuiInputBase-input": {
                                padding: "0px",
                              },
                              "& .MuiInputBase-root": {
                                fontSize: '14px',
                                color: "rgba(0,0,0,0.8)",
                                padding: "0px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "none",
                                "&.Mui-focused fieldset": {
                                  border: "none",
                                },
                              },
                            }}
                          />

                        </Box>
                      </Box>
                    </Box> : null
                  }
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Divider sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }} />
          <Box sx={{
            boxSizing: 'border-box',
            padding: '0 4rem 0 4rem'
          }}>
            <Box className="item-title" sx={{ marginTop: '2rem', display: 'flex', alignItems: "center" }}>
              <Box>Fulfillment Options</Box>
              <ButtonTooltip title={<React.Fragment>
                <Box sx={{
                  color: '#FFF',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '150%'
                }}>
                  <Box sx={{ marginBottom: "10px" }}>
                    <Box sx={{
                      width: '13px',
                      height: '15px',
                      background: 'url("img/myProduct/product_fulfilled_by_you_light.png") no-repeat 0 0',
                      backgroundSize: 'contain',
                      marginRight: "3px",
                      marginBottom: "-3px",
                      display: "inline-block"
                    }} />Fulfilled by You: you fulfill directly on supplier website by placing an order there manually. This item may arrive with Temu package, and price tag, and under $10 minimum policy.
                  </Box>
                  <Box>
                    <Box sx={{
                      width: '13px',
                      height: '15px',
                      background: 'url("img/myProduct/product_fulfilled_by_cartifind.png") no-repeat 0 0',
                      backgroundSize: 'contain',
                      marginRight: "3px",
                      marginBottom: "-3px",
                      display: "inline-block"
                    }} />Fulfilled by Cartifind: You can choose to fulfill from us, and our product price is similar or lower to supplier's price. But you need to pay us an additional VIP shipping fee to ship the item to your customer without any Temu package.
                  </Box>
                </Box>
              </React.Fragment>} arrow>
                <Box sx={{
                  width: '15px',
                  height: '15px',
                  background: 'url("img/common/icon_info.png") no-repeat 0 0',
                  backgroundSize: 'contain',
                  marginLeft: "3px",
                }} />
              </ButtonTooltip>
            </Box>
            <Box sx={{ marginBottom: '2rem', marginTop: '1rem', fontSize: '16px' }}>
              <CustomSelected
                width="50rem"
                height="4.8rem"
                selectedWidth="50rem"
                border="0.1rem solid #999999"
                selectedItems={[
                  {
                    option: "Fulfilled by Cartifind",
                    value: true,
                  },
                  {
                    option: "Fulfilled by You",
                    value: false,
                  },
                ]}
                // defaultOption={shopName}
                selectedValue={currentFulfillmentOption}
                setSelectValue={setCurrentFulfillmentOption}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Divider sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }} />
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            padding: '0 calc(9.2rem - 16px) 2rem 4rem',
            marginTop: '2rem'
          }}>
            <Box sx={{
            }}>
              <Box className="item-title">Inventory Automatic Update</Box>
              <Box className="item-description">After opening, all suppliers except for Temu, including AliExpress and Shein' inventory will be automatically synchronized.</Box>
            </Box>
            <Box>
              <FormControlLabel
                control={<SelectionSwitch checked={inventoryAutomaticUpdate} onClick={(e) => {
                  setInventoryAutomaticUpdate(e.target.checked);
                }} />}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Divider sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }} />
          <Box sx={{
            boxSizing: 'border-box',
            padding: '0 9.2rem 0 4rem'
          }}>
            <Box className="item-title" sx={{ marginTop: '2rem' }}>Assign Cents</Box>
            <Box className="item-description"> We will use this value when forming the final price for your items (e.g., if you want the cost of your product to be XX.99 then add 99 to the fields below).</Box>
            <Box sx={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
              <Box sx={{ height: '4rem', display: 'flex', alignItems: 'center', marginLeft: '-11px', fontSize: "16px", color: '#333333' }}>
                <Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '2.5rem',
                  },
                  '&.Mui-checked': {
                    color: '#FF9711!important'
                  },
                }} checked={isOpenAssignCents} onClick={() => {
                  setIsOpenAssignCents(!isOpenAssignCents);
                }} />
                <Box>Assign Cents</Box>
              </Box>
              <Box sx={{
                width: '10.4rem',
                height: '4.3rem',
                borderRadius: '1rem',
                border: '1px solid #999999',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingRight: '1rem'
              }}>
                <TextField
                  value={AssignCents ? AssignCents : ''}
                  onChange={(e) => {
                    setAssignCents(e.target.value.replace(/[^\d.\d]/g, ''));
                  }}
                  sx={{
                    width: "10.5rem",
                    color: '#333333',
                    "& .MuiInputBase-input": {
                      textAlign: 'center',
                    },
                    "& .MuiInputBase-root": {
                      fontSize: '16px',
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <Box sx={{ width: '3rem' }}>
                  <Box sx={{ width: '1.5rem', height: '1.5rem', marginBottom: '0.4rem', background: 'url("img/arrows_up.png") no-repeat 0 0 ', backgroundSize: 'contain', cursor: 'pointer' }} onClick={() => {
                    if (AssignCents < 99) {
                      setAssignCents(Number(AssignCents) + 1);
                    }
                  }} />
                  <Box sx={{ width: '1.5rem', height: '1.5rem', background: 'url("img/arrows_down.png") no-repeat 0 0 ', backgroundSize: 'contain', cursor: 'pointer' }} onClick={() => {
                    if (AssignCents > 1) {
                      setAssignCents(Number(AssignCents) - 1);
                    }
                  }
                  }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Divider sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }} />
          <Box sx={{
            boxSizing: 'border-box',
            padding: '0 4rem'
          }}>
            <Box className="item-title" sx={{ marginTop: '2rem' }}>Customize product description</Box>
            <Box className="item-description">When pushing products, you want Cartifind to:</Box>
            <FormControl sx={{
              marginBottom: '1rem',
              width: '100%', '& .radio-button': {
                '& .MuiSvgIcon-root': {
                  fontSize: '2.5rem',
                },
                '&.Mui-checked': {
                  color: '#FF9711!important'
                },
              }
            }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={productDescriptionValue}
                onChange={(event) => {
                  setProductDescriptionValue(event.target.value);
                }}
              >
                <FormControlLabel value="0" control={<Radio className="radio-button" />} label={<Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Empty</Box>} />
                <FormControlLabel value="1" control={<Radio className="radio-button" />} label={<Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Only push products' SPECIFICATIONS</Box>} />
                <FormControlLabel value="2" control={<Radio className="radio-button" />} label={<Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Only push products' OVERVIEW</Box>} />
                <FormControlLabel value="3" control={<Radio className="radio-button" />} label={<Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Only push products' SPECIFICATIONS & OVERVIEW</Box>} />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Divider
            sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }}
          />
          <Box sx={{
            boxSizing: 'border-box',
            padding: '0 4rem 0 4rem'
          }}>
            <Box className="item-title" sx={{ marginTop: '2rem' }}>Optimize</Box>
            <Box className="item-description">When optimizing products, you want Open AI to:</Box>
            <FormControl sx={{
              marginBottom: '2rem',
              width: '100%',
              '& .radio-button': {
                '& .MuiSvgIcon-root': {
                  fontSize: '2.5rem',
                },
                '&.Mui-checked': {
                  color: '#FF9711!important'
                },
              }
            }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={optimizeValue}
                onChange={(event) => {
                  setOptimizeValue(event.target.value);
                }}
              >
                <FormControlLabel value="0" control={<Radio className="radio-button" />} label={<Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Generate title by Open AI</Box>} />
                <FormControlLabel value="1" control={<Radio className="radio-button" />} label={<Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Generate description by Open AI</Box>} />
                <FormControlLabel value="2" control={<Radio className="radio-button" />} label={<Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Generate title & description by Open AI</Box>} />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        {storePlatform != "wix" ? <Box>
          <Divider sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }} />
          <Box sx={{
            boxSizing: 'border-box',
            padding: '0 4rem 0 4rem'
          }}>
            <Box className="item-title" sx={{ marginTop: '2rem' }}>Send product information to Shopify</Box>
            <Box className="item-description">When pushing products, you want Cartifind to:</Box>
            <FormControl sx={{
              marginBottom: '2rem',
              width: '100%',
              '& .radio-button': {
                '& .MuiSvgIcon-root': {
                  fontSize: '2.5rem',
                },
                '&.Mui-checked': {
                  color: '#FF9711!important'
                },
              }
            }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={productInformationValue}
                onChange={(event) => {
                  setProductInformationValue(event.target.value);
                }}
              >
                <FormControlLabel value="0" control={<Radio className="radio-button" />} label={<Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Do Nothing</Box>} />
                <FormControlLabel value="1" control={<Radio className="radio-button" />} label={<Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Send product cost to Shopify</Box>} />
                <FormControlLabel value="2" control={<Radio className="radio-button" />} label={<Box sx={{ fontSize: '16px', fontWeight: '500', color: '#333333' }}>Send product and shipping cost to Shopify</Box>} />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box> : null}
        <Box>
          <Divider sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }} />
          <Box sx={{
            boxSizing: 'border-box',
            padding: '0 4rem 0 4rem'
          }}>
            <Box className="item-title" sx={{ marginTop: '2rem' }}>Push Products</Box>
            <Box className="item-description">Here you can choose which {storePlatform != "wix" ? "Shopify" : "Wix"} store to push the product to.</Box>
            <Box sx={{ marginBottom: '2rem', marginTop: '1rem', fontSize: '16px' }}>
              <CustomSelected
                width="50rem"
                height="4.8rem"
                selectedWidth="50rem"
                border="0.1rem solid #999999"
                selectedItems={[{
                  option: shopName,
                  value: 'store1'
                }]}
                defaultOption={shopName}
                selectedValue={currentStore}
                setSelectValue={setCurrentStore}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Divider
            sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }}
          />
          <Box sx={{
            boxSizing: 'border-box',
            padding: '0 4rem 0 4rem'
          }}>
            <Box className="item-title" sx={{ marginTop: '2rem' }}>Vendor:</Box>
            <Box sx={{ marginBottom: '2rem', marginTop: '1rem' }}>
              <TextField
                type={"text"}
                value={vendor}
                onChange={(e) => {
                  setVendor(e.target.value);
                }}
                sx={{
                  width: '50rem',
                  height: '5rem',
                  borderRadius: '1rem',
                  "& .MuiInputBase-root": {
                    borderRadius: '1rem',
                    "&.MuiOutlinedInput-root input": {
                      textIndent: '2rem',
                      height: '5rem',
                      fontSize: '16px',
                      padding: 0,
                      color: '#333333'
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      border: '0.1rem solid #999999'
                    },
                    "&:hover fieldset": {
                      border: '0.1rem solid #999999'
                    },
                  },

                }}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Divider
            sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }}
          />
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            padding: '0 calc(9.2rem - 16px) 0 4rem',
            margin: '2rem 0',
            borderBottom: '2rem',
          }}>
            <Box>
              <Box className="item-title">Set products as taxable</Box>
              <Box className="item-description">Specifies whether or not a tax is charged when the product variant is sold.</Box>
            </Box>
            <Box>
              <FormControlLabel
                control={<SelectionSwitch checked={isTaxable} onClick={(e) => {
                  setIsTaxable(e.target.checked);
                }} />}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: '10px' }}></Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            position: 'fixed',
            right: '25.1rem',
            bottom: '5rem',
            "& .button": {
              padding: "1.4rem 3.3rem",
              borderRadius: "1rem",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "150%",
              letterSpacing: "0.015rem",
              color: "rgba(112, 112, 112, 0.7)",
              backgroundColor: "#e4e4e4",
              cursor: "pointer",
            },
            "& .saveButton": {
              marginLeft: "6.4rem",
              color: "#FFF",
              backgroundColor: "#FF9711",
              boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.25)',
            },
          }}
        >
          <Box className="saveButton button" onClick={() => {
            handleSaveProduct();
          }}>
            Save
          </Box>
        </Box>
      </Box>
    </>
  );
}

function CustomPricingRuleRow(prop) {
  const {
    costRangeStart,
    costRangeEnd,
    operativeSymbol,
    operativeValue,
    customPricingRuleID,
    customPricingRuleValue,
    setCustomPricingRuleValue,
    refreshPage,
    setRefreshPage,
    lastCostRangeEnd,
    nextCostRangeStart
  } = prop;
  if (setCustomPricingRuleValue == null) {
    return null;
  }
  const [costRangeStartInput, setCostRangeStartInput] = useState(costRangeStart != null ? costRangeStart : "0.00");
  const [costRangeEndInput, setCostRangeEndInput] = useState(costRangeEnd != null ? costRangeEnd : "");
  const [operativeSymbolValue, setOperativeSymbolValue] = useState(operativeSymbol != null ? operativeSymbol : "plus");
  const [operativeValueInput, setOperativeValueInput] = useState(operativeValue != null ? operativeValue : "0.00");
  const [towInputValueWrong, setTowInputValueWrong] = useState(false);
  const [costRangeStartInputWrong, setCostRangeStartInputWrong] = useState(false);
  const [costRangeEndInputWrong, setCostRangeEndInputWrong] = useState(false);

  const handleSetCustomPricingRuleValue = (e, key, callback) => {
    const inputValue = parseFloat(e.target.value.replace(/[^\d.\d]/g, ''));
    const result = customPricingRuleValue.map(item => {
      if (customPricingRuleID == item.id) {
        return {
          ...item,
          [key]: e.target.value != null && e.target.value.length > 0 ? inputValue.toFixed(2) : ""
        }
      }
      return item;
    })
    callback(e.target.value != null && e.target.value.length > 0 ? inputValue.toFixed(2) : "");
    if (nextCostRangeStart == "infinity" && (costRangeEndInput != null && costRangeEndInput.length > 0) && key == "costRangeEnd" && !towInputValueWrong) {
      result.push({
        id: customPricingRuleID + 1,
        costRangeStart: parseFloat(inputValue + 0.01),
        costRangeEnd: "",
        operativeSymbol: "plus",
        operativeValue: "0.00",
      })
    }
    setCustomPricingRuleValue(result);
    setRefreshPage(refreshPage + 1);
  }

  useEffect(() => {
    setCostRangeStartInputWrong(Number(costRangeStartInput) <= Number(lastCostRangeEnd));
    setTowInputValueWrong((costRangeEndInput != null && costRangeEndInput.length > 0) && Number(costRangeEndInput) <= Number(costRangeStartInput));
    setCostRangeEndInputWrong(nextCostRangeStart != "infinity" && Number(nextCostRangeStart) <= Number(costRangeEndInput));
  }, [costRangeStartInput, costRangeEndInput, lastCostRangeEnd, nextCostRangeStart])
  return <Box sx={{ display: "flex", alignItems: "center", padding: "5px 0px", borderBottom: "1px solid #f0f0f0" }}>
    <Box sx={{
      boxSizing: 'border-box',
      width: "80px",
      height: "32px",
      borderRadius: "8px",
      padding: "0px 11px 0 11px",
      display: "flex",
      border: towInputValueWrong || costRangeStartInputWrong ? "1px solid #ff4d4f" : "1px solid #9e9e9e",
      alignItems: 'center',
    }}>
      <TextField
        value={costRangeStartInput}
        onChange={(e) => {
          setCostRangeStartInput(e.target.value)
        }}
        onBlur={(e) => handleSetCustomPricingRuleValue(e, "costRangeStart", setCostRangeStartInput)}
        sx={{
          width: "56px",
          "& .MuiInputBase-input": {
            padding: "0px",
          },
          "& .MuiInputBase-root": {
            fontSize: '14px',
            color: "rgba(0,0,0,0.8)",
            padding: "0px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          "& .MuiOutlinedInput-root": {
            border: "none",
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
        }}
      />

    </Box>
    <Box sx={{ width: "30px", textAlign: "center" }}>—</Box>
    <Box sx={{
      boxSizing: 'border-box',
      width: "80px",
      height: "32px",
      borderRadius: "8px",
      padding: "0px 11px 0 11px",
      display: "flex",
      border: towInputValueWrong || costRangeEndInputWrong ? "1px solid #ff4d4f" : "1px solid #9e9e9e",
      alignItems: 'center'
    }}>
      <TextField
        value={costRangeEndInput}
        onChange={(e) => {
          setCostRangeEndInput(e.target.value)
        }}
        onBlur={(e) => handleSetCustomPricingRuleValue(e, "costRangeEnd", setCostRangeEndInput)}
        sx={{
          width: "56px",
          "& .MuiInputBase-input": {
            padding: "0px",
          },
          "& .MuiInputBase-root": {
            fontSize: '14px',
            color: "rgba(0,0,0,0.8)",
            padding: "0px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          "& .MuiOutlinedInput-root": {
            border: "none",
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
        }}
      />

    </Box>
    <Box sx={{ fontSize: "20px", marginLeft: "50px", marginRight: "10px" }}>

      <CustomSelected
        width="80px"
        height="32px"
        selectedWidth="80px"
        border="0.1rem solid #9e9e9e"
        selectedItems={[{
          option: "+",
          value: "plus"
        }, {
          option: "×",
          value: "times"
        }]}
        fontSize="20px"
        selectedValue={operativeSymbolValue}
        setSelectValue={(value) => {
          setOperativeSymbolValue(value);
          const result = customPricingRuleValue.map(item => {
            if (customPricingRuleID == item.id) {
              return {
                ...item,
                operativeSymbol: value
              }
            }
            return item;
          });
          setCustomPricingRuleValue(result);
          setRefreshPage(refreshPage + 1);
        }}
      />
    </Box>
    <Box sx={{
      boxSizing: 'border-box',
      width: "80px",
      height: "32px",
      borderRadius: "8px",
      padding: "0px 11px 0 11px",
      display: "flex",
      border: "1px solid #9e9e9e",
      alignItems: 'center'
    }}>
      <TextField
        value={operativeValueInput}
        onChange={(e) => {
          setOperativeValueInput(e.target.value)
        }}
        onBlur={(e) => handleSetCustomPricingRuleValue(e, "operativeValue", setOperativeValueInput)}
        sx={{
          width: "56px",
          "& .MuiInputBase-input": {
            padding: "0px",
          },
          "& .MuiInputBase-root": {
            fontSize: '14px',
            color: "rgba(0,0,0,0.8)",
            padding: "0px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          "& .MuiOutlinedInput-root": {
            border: "none",
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
        }}
      />

    </Box>
    {nextCostRangeStart != "infinity" ? <DeleteIcon sx={{ fontSize: "24px", marginLeft: "50px", color: "rgba(0,0,0,0.5)", cursor: "pointer", "&:hover": { color: "#000" } }} onClick={() => {
      const result = customPricingRuleValue.map(item => {
        if (customPricingRuleID != item.id) {
          return item;
        }
      }).filter(
        (element) => element != null
      );
      setCustomPricingRuleValue(result);
      setRefreshPage(refreshPage + 1);
    }} /> : null}
  </Box>
}