/** @format */

import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { Context } from "/home/ubuntu/hff/src/Context/Context";
import { setCookie } from "doc-cookie";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const queryParams = new URLSearchParams(window.location.search);
const code = queryParams.get("code");
const state = queryParams.get("state");
const shop = queryParams.get("instanceId"); //https://dev.wix.com/docs/rest/articles/getting-started/authentication

const SAVE_TOKEN = gql`
  query Query(
    $email: String
    $shopName: String
    $code: String
    $useCodeForEmail: Boolean
    $domain: String
    $platform: String
  ) {
    saveShopifyToken(
      email: $email
      shopName: $shopName
      code: $code
      useCodeForEmail: $useCodeForEmail
      domain: $domain
      platform: $platform
    ) {
      firstName
      lastName
      password
      email
      shopifyToken
      shopName
      token
      subscriptionURL
      wixSiteID
    }
  }
`;

export default function wixRedirect() {
    console.log(45, 'test');
    const [redirectLink, setRedirectLink] = React.useState(null);
    React.useEffect(() => {
        if (redirectLink != null) {
            window.location = redirectLink;
            return;
        }
    }, [redirectLink]);

    if (code != null && shop != null) {
        console.log('here54');
        const variables = {
            shopName: String(shop),
            code: String(code),
            email: null,
            useCodeForEmail: true,
            domain: window.location.hostname,
            platform: 'wix',
        };
        console.log(variables);
        const { data } = useQuery(SAVE_TOKEN, {
            variables,
            //notifyOnNetworkStatusChange: true,
        });

        console.log(65, data);
        console.log(67, data != null ? data.saveShopifyToken : '12345');

        if (data != null && data.saveShopifyToken !== null) {
            // Success saved shopify token, thus login to account
            const { setState } = useContext(Context);
            const { firstName, shopifyToken, email, token, wixSiteID } = data.saveShopifyToken;
            localStorage.setItem("firstName", firstName);
            localStorage.setItem("shopifyToken", shopifyToken);
            localStorage.setItem("email", email);
            localStorage.setItem("shopName", String(shop));
            localStorage.setItem("storePlatform", 'wix');
            localStorage.setItem("wixSiteID", wixSiteID);
            setState({
                firstName,
                shopifyToken,
                email,
                shopName: String(shop),
                storePlatform: "wix",
                wixSiteID
            });
            setCookie({
                key: "token",
                value: token,
                "Max-Age": 604800, // seconds
            });

            // Check enviroment to prevent infinite reloading in shopify mobile app.
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                if (redirectLink == null) {
                    setRedirectLink('/app');
                }
                // if (subscriptionURL != null && redirectLink == null) {
                //     setRedirectLink(subscriptionURL);
                // } else if (redirectLink == null) {
                //     setRedirectLink('/app?subscriptionStatus=1');
                // }
            } else {
                if (redirectLink == null) {
                    setRedirectLink('/app');
                }
                // if (subscriptionURL != null && redirectLink == null) {
                //     setRedirectLink(subscriptionURL);
                // } else if (redirectLink == null) {
                //     setRedirectLink('/app?subscriptionStatus=1');
                // }
            }
        }
    }

    return (
        <div>
            <Box sx={{
                height: "40rem",
                marginTop: 10,
                display: "flex",
                justifyContent: "center",
            }}
            >
                <CircularProgress />
            </Box>
        </div>
    );
}