/** @format */

import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const UPGRADE_USER_STATUS = gql`
  query upgradeUserStatus($purchaseID: String, $type: String) {
    upgradeUserStatus(purchaseID: $purchaseID, type: $type)
  }
`;

export default function ShopifyOneTimePurchaseSubscriptionRedirect() {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    //const purchaseID = queryParams.get('purchaseID');
    const type = queryParams.get('type');
    const purchaseID = '12345';

    const { loading, data } = useQuery(UPGRADE_USER_STATUS, {
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        variables: {
            purchaseID,
            type
        },
    });
    if (loading === false && data != null && data.upgradeUserStatus != null) {
        if (data.upgradeUserStatus == true) {
            navigate('/app?subscriptionStatus=1');
            return;
        } else {
            navigate('/app?subscriptionStatus=0');
            return;
        }
    }

    return (
        <div>
            <Box sx={{
                height: "40rem",
                marginTop: 10,
                display: "flex",
                justifyContent: "center",
            }}
            >
                <CircularProgress />
            </Box>
        </div>
    );
}
