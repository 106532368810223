import React, { createContext, useReducer } from 'react';

export const initialState = {
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    shopifyToken: null,
    authCode: null,
    title: null,
    openSnackbar: false,
    snackbarText: '',
    snackbarState: null,
    currentComponentName: null,
    isAccountPopUpDialogOpen: false,
    isOpenImportProductDialog: false,
    tabValue: 0,
    pushSuccessList: [],
    currentClickProduct: {
        id: null,
        title: '',
        thumbnailSource: '',
        shopifyProductID: ''
    },
    handleRefetchProductList: null,
    sessionID: null,
    IP: null,
    sendLog: null,
    isPushingToShopify: false,
    productListAfterEdit: [],
    isOpenImportAliExpressURLDialog: false,
    isExplanatoryVideoDialogOpen: false,
    explanatoryVideoSrc: '',
    handleRefetchMyProductsCount: null
}

export const Context = createContext({});

export const actions = {
    SET: "SET",
    ADD_USER_INFO: "ADD_USER_INFO",
};

export const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET:
            return action.value;
        case actions.ADD_USER_INFO:
            console.log(action);
            const { firstName, shopifyToken } = action.value;
            return {
                firstName,
                shopifyToken,
                ...state,
            }
        default:
            return state;
    }
};