/*
自定义下拉选择
*/
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function CustomSelected(props) {
    const { width, height, border, selectedItems, setSelectValue, defaultOption, withoutOptions, setOption, selectedWidth, selectedValue, onChange: handleChangeFun, selectedHeight, onOpen: handleOpen, disabled, fontSize, topArrowNode, bottomArrowNode } = props;
    const [boxSwitch, setBoxSwitch] = useState(false);
    if (selectedItems == null || selectedItems.length === 0) {
        return <div />;
    }
    const [initialize, setInitialize] = useState(true);
    const [currentOption, setCurrentOption] = useState(defaultOption);
    useEffect(() => {
        selectedItems.map(item => {
            if (item.value === selectedValue) {
                setCurrentOption(item.option);
            }
        });
        if (initialize) {
            setInitialize(false);
        }
        if (handleChangeFun != null && initialize == false) {
            handleChangeFun(selectedValue)
        }
    }, [selectedValue]);
    useEffect(() => {
        if (defaultOption != null) {
            setCurrentOption(defaultOption);
        }
    }, [defaultOption]);
    return (
        <Box sx={{ position: 'relative' }}>
            <Box sx={{
                border: border ? border : '0.1rem solid #ccc',
                width: width ? width : '30rem',
                height: height ? height : '5rem',
                borderRadius: '1rem',
                padding: '0 1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: disabled === true ? "auto" : 'pointer',
                boxSizing: 'border-box',
                fontSize: fontSize ? fontSize : '16px',
                background: disabled === true ? "rgba(1,1,1,0.1)" : "#FFF"
            }} onClick={() => {
                if (disabled !== true) {
                    setBoxSwitch(!boxSwitch); if (handleOpen != null) { handleOpen() }
                }
            }}>
                {withoutOptions ? null : <Box sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{currentOption}</Box>}
                {boxSwitch ? (topArrowNode ? topArrowNode : <ExpandLessIcon sx={{ fontSize: '3.2rem' }} />) : (bottomArrowNode ? bottomArrowNode : <ExpandMoreIcon sx={{ fontSize: '3.2rem' }} />)}
            </Box>
            {boxSwitch && selectedItems ?
                <Box sx={{
                    width: selectedWidth ? selectedWidth : (width ? `calc(${width} + 2rem)` : '30rem'),
                    height: selectedHeight ? selectedHeight : 'auto',
                    maxHeight: '30rem',
                    boxShadow: '0rem 0.5rem 0.5rem -0.3rem rgb(0 0 0 / 20%), 0rem 0.8rem 1rem 0.1rem rgb(0 0 0 / 14%), 0rem 0.3rem 1.4rem 0.2rem rgb(0 0 0 / 12%)',
                    borderRadius: '0.4rem',
                    backgroundColor: '#fff',
                    padding: '1rem 0',
                    position: 'absolute',
                    left: withoutOptions ? (selectedWidth ? '-' + selectedWidth : '-10rem') : 0,
                    top: height ? height : '-5rem',
                    zIndex: 99999,
                    cursor: 'pointer',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}>
                    {selectedItems.map((item, index) => {
                        return (
                            <Box sx={{
                                padding: '0.4rem 1rem',
                                backgroundColor: item.option === currentOption ? '#e0e0e0' : '#FFF',
                                '&:hover': {
                                    backgroundColor: '#f5f5f5'
                                }
                            }} onClick={() => {
                                setSelectValue(item.value);
                                setBoxSwitch(false);
                                setCurrentOption(item.option);
                                if (setOption) {
                                    setOption(item.option);
                                }
                            }} key={index}>{item.option}</Box>
                        )
                    })}

                </Box>
                : null
            }
            {boxSwitch && selectedItems ?
                <Box sx={{ width: '192rem', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: '99998' }} onClick={() => { setBoxSwitch(false); }}>
                </Box>
                : null
            }
        </Box>
    );
}