import React from 'react';
import { Box } from '@mui/system';
import { Context } from "../Context/Context";

export default function MyOrderBlankPage(props) {
    const { text } = props;
    const { state, setState } = React.useContext(Context);
    const { sendLog } = state;
    return (
        <Box sx={{
            fontFamily: 'Arial',
            fontStyle: 'normal',
            paddingLeft: '4rem'
        }}>
            <Box sx={{
                fontSize: '3.2rem',
                fontWeight: '700',
                letterSpacing: '0.15px',
                color: '#666666',
                height: '4.8rem',
                lineHeight: '4.8rem'
            }}>My Orders</Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '1rem'
            }}>
                <Box sx={{ background: 'url("/img/ImportAliExpressURL_exclamation_point.png") no-repeat 0 0', backgroundSize: 'contain', width: '2.5rem', height: '2.5rem', marginRight: '1rem' }} />
                <Box sx={{ fontSize: '2rem', fontWeight: '400', letterSpacing: '0.15px', color: '#707070' }}>This is an order sample</Box>
            </Box>
            <Box sx={{ width: "150.2rem", height: '34.7rem', borderRadius: '1rem', overflow: 'hidden', marginTop: '2.8rem' }}>
                <img src="img/myOrder_example.png" alt='example image' width="100%" height="100%" />
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                height: '36.2rem',
                marginBottom: "0.5rem"
            }}>
                <Box sx={{ width: "45rem", height: '36.2rem', marginTop: '2.8rem' }}>
                    <img src="img/myOrder_big_Image.png" alt='example image' width="100%" height="100%" />
                </Box>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}>
                    <Box sx={{ marginTop: '3.8rem', height: '4.8rem', lineHeight: '4.8rem', color: '#666666', fontWeight: '700', fontSize: '3.2rem', }}>{text ? text : "Your My Orders is empty！"}</Box>
                    <Box sx={{ marginTop: '4.8rem', height: '3.6rem', lineHeight: '3.6rem', color: '#666666', fontWeight: '500', fontSize: '2.4rem', }}>You can manage and proccess your orders here. </Box>
                    <Box sx={{
                        marginTop: '8.5rem',
                        height: '4.8rem',
                        lineHeight: '4.8rem',
                        color: '#FF9711',
                        fontWeight: '700',
                        fontSize: '3.2rem',
                        display: 'flex',
                        cursor: 'pointer'
                    }} onClick={() => {
                        sendLog("MY_OrderS_BLANK_PAGE_CTA_CLICK");
                        setState({
                            ...state,
                            isExplanatoryVideoDialogOpen: true,
                            explanatoryVideoSrc: '/img/tutorial/part4.mp4'
                        })
                    }}>
                        <Box sx={{ background: 'url("/img/blank_page_player.png") no-repeat 0 0', backgroundSize: 'contain', width: '5rem', height: '5rem', marginRight: '1.5rem' }} />
                        <Box>How to process orders</Box>
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}