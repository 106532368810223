/** @format */

import React from "react";
import { Box } from "@mui/system";
import { gql, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { Context } from "./Context/Context";

const GET_ALIEXPRESS_ORDER_LINK = gql`
  query AliexpressOrderLink($orderLink: String) {
    getAliexpressOrderLink(orderLink: $orderLink)
  }
`;

export default function AliExpressOrderLink() {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const orderLink = queryParams.get('orderLink');
    const { setState, state } = React.useContext(Context);

    const { loading, data } = useQuery(GET_ALIEXPRESS_ORDER_LINK, {
        variables: {
            orderLink
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
    });

    if (loading === false) {
        if (data != null && data.getAliexpressOrderLink != null) {
            window.location.replace(data.getAliexpressOrderLink);
        } else {
            setState({
                ...state,
                openSnackbar: true,
                snackbarText: 'Oops! Something went wrong.',
                snackbarState: 'FAILED',
            });
            navigate('/MyOrders');
        }
    }
    return (
        <Box sx={{
            height: "40rem",
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
        }}
        >
            <CircularProgress />
        </Box>
    );
}
