import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Context } from "../Context/Context";
import { Box } from "@mui/system";
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";



const SAVE_SETTING_QUESTIONNAIRE = gql`
mutation saveSettingQuestionnaire($isTemu: Boolean, $searchKeywords: String) {
    saveSettingQuestionnaire(isTemu: $isTemu, searchKeywords: $searchKeywords){
        status
        message
    }
}
`;
const questionList = [{
  step: 1,
  question: "Are you importing products from Temu.com?"
}, {
  step: 2,
  question: "How do you best describe your product type?"
}]
export default function LoginQuestionnaire() {
  const { state, setState } = React.useContext(Context);
  const { isLoginQuestionnaireOpen } = state;
  const [currentStep, setCurrentStep] = React.useState(1);
  const [firstQuestionReplyValue, setFirstQuestionReplyValue] = React.useState("yes");
  const [stepperWidth, setStepperWidth] = React.useState(50);
  const [currentInput, setCurrentInput] = React.useState("");
  const [save_setting_questionnaire] = useMutation(SAVE_SETTING_QUESTIONNAIRE);
  const navigate = useNavigate();
  React.useEffect(() => {
    setStepperWidth(currentStep / questionList.length * 100)
  }, [currentStep]);
  React.useEffect(() => {
    setCurrentStep(1);
    setFirstQuestionReplyValue("yes");
    setCurrentInput("");
  }, [isLoginQuestionnaireOpen]);

  return (
    <Dialog
      open={isLoginQuestionnaireOpen ? isLoginQuestionnaireOpen : false}
      onClose={() => {
        setState({
          ...state,
          isLoginQuestionnaireOpen: false
        });
        save_setting_questionnaire({
          variables: {
            isTemu: null,
            searchKeywords: null,
          },
        })
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          width: "700px",
          maxWidth: "700px",
          minHeight: "150px",
          borderRadius: "10px",
        },
        fontSize: '18px'
      }} >
      <DialogContent >
        <Box>
          {currentStep === 1 ? <Box sx={{
            padding: '10px'
          }}>
            <Box sx={{
              display: "flex",
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
              <Box sx={{ fontSize: '18px', fontWeight: "600", }}>{questionList[0].question}</Box>
              <Box sx={{ color: "#cfd2d9" }}>step {questionList[0].step}/2</Box>
            </Box>
            <Box sx={{
              width: '100%',
              height: '10px',
              borderRadius: '10px',
              backgroundColor: "#eceef0",
              margin: "20px 0px 20px"
            }}>
              <Box sx={{
                width: stepperWidth + "%",
                height: '10px',
                borderRadius: '10px',
                backgroundColor: "#FF9711",
                transition: "width 1s ease",
              }} />
            </Box>
            <Box sx={{
              width: '632px',
              display: "flex",
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '30px',
              '& .MuiSvgIcon-root': { fontSize: "20px" },
              "& .MuiRadio-root": {
                padding: "0px 0px 0px 16px",
                "&.Mui-checked": {
                  color: "#FF9711"
                }
              },
              "&>div": {
                flex: 1,
                padding: "16px 0px",
                borderRadius: '12px',
                display: "flex",
                alignItems: 'center',
                cursor: "pointer",
                gap: '12px',
                "& div": {
                  flex: '1',
                  display: "flex",
                  alignItems: 'center',
                }
              }
            }}>
              <Box onClick={() => { setFirstQuestionReplyValue("yes") }} sx={firstQuestionReplyValue == "yes" ? { border: "1px solid #FF9711", fontWeight: "600" } : { border: "1px solid #CCC", fontWeight: "500" }}> <Radio checked={firstQuestionReplyValue == "yes"} /><Box>Yes</Box> </Box>
              <Box onClick={() => { setFirstQuestionReplyValue("no") }} sx={firstQuestionReplyValue == "no" ? { border: "1px solid #FF9711", fontWeight: "600" } : { border: "1px solid #CCC", fontWeight: "500" }}> <Radio checked={firstQuestionReplyValue == "no"} /><Box>No</Box> </Box>
            </Box>

            <Box sx={{
              display: "flex",
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '20px'
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#939daa'
              }} onClick={() => {
                setState({
                  ...state,
                  isLoginQuestionnaireOpen: false
                });
                save_setting_questionnaire({
                  variables: {
                    isTemu: null,
                    searchKeywords: null,
                  },
                })
              }}>skip
                <img src="./img/arrow_right.svg" style={{ marginLeft: "7px" }} />
              </Box>
              <Box onClick={() => {
                setCurrentStep(currentStep + 1)
              }} sx={{
                padding: '8px 16px',
                borderRadius: '7px',
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                cursor: "pointer",
                backgroundColor: "#FF9711",
                color: "#FFF",
                fontSize: '16px',
                fontWeight: "600",
                lineHeight: '24px',
                letterSpacing: "0.4px"
              }}>Continue</Box>
            </Box>
          </Box> : <Box sx={{
            padding: '10px'
          }}>
            <Box sx={{
              display: "flex",
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
              <Box sx={{ fontSize: '18px', fontWeight: "600", }}>{questionList[1].question}</Box>
              <Box sx={{ color: "#cfd2d9" }}>step {questionList[1].step}/2</Box>
            </Box>
            <Box sx={{
              width: '100%',
              height: '10px',
              borderRadius: '10px',
              backgroundColor: "#eceef0",
              margin: "20px 0px 20px"
            }}>
              <Box sx={{
                width: stepperWidth + "%",
                height: '10px',
                borderRadius: '10px',
                backgroundColor: "#FF9711",
                transition: "width 1s ease",
              }} />
            </Box>
            <Box sx={{
              width: '632px',
              height: 'auto',
              display: "flex",
              alignItems: 'center',
              justifyContent: 'space-between',
              border: "1px solid #ccc",
              borderRadius: '5px'
            }}>

              <TextField
                sx={{
                  width: '100%',
                  paddingRight: "0",
                  backgroundColor: "#fff",
                  border: 'none',
                  fontSize: "14px",
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: "24px",
                  letterSpacing: '0.15px',
                  color: "rgba(0, 0, 0, 0.6)",
                  borderColor: "transparent",
                  borderRadius: '10px',
                  "& fieldset": {
                    borderColor: "transparent!important",
                    borderWidth: '1px',
                    "&.Mui-focused": {
                      borderColor: "transparent!important",
                      borderWidth: '1px',
                    },
                  },
                  "& .MuiInputBase-root.MuiOutlinedInput-root": {
                    padding: "8px 12px",
                    fontSize: '14px'
                  }
                }}
                id="outlined-multiline-static"
                multiline
                rows={6}
                // placeholder="Add your notes to Cartifind here"
                value={currentInput}
                onChange={(e) => {
                  if (e.target.value.length < 50) {
                    setCurrentInput(e.target.value);
                  } else {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText: "A maximum of 50 characters can be entered ",
                      snackbarState: 'FAILED',
                    })
                  }
                }}
              />
            </Box>

            <Box sx={{
              display: "flex",
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '20px'
            }}>
              <Box onClick={() => {
                setCurrentStep(currentStep - 1)
              }}>Back</Box>
              {firstQuestionReplyValue == "yes" ? <Box onClick={() => {
                save_setting_questionnaire({
                  variables: {
                    isTemu: firstQuestionReplyValue == "yes",
                    searchKeywords: currentInput,
                  },
                })
                localStorage.setItem("isSurveyCompleted", "true");
                setState({
                  ...state,
                  isLoginQuestionnaireOpen: false
                });
                navigate("/extensionInstructions")
              }} sx={{
                padding: '8px 16px',
                borderRadius: '5px',
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                cursor: "pointer",
                backgroundColor: "#FF9711",
                color: "#FFF",
                fontSize: '16px',
                fontWeight: "600",
                lineHeight: '24px',
                letterSpacing: "0.4px"
              }}>Install Temu Extension</Box> : <Box onClick={() => {
                save_setting_questionnaire({
                  variables: {
                    isTemu: firstQuestionReplyValue == "yes",
                    searchKeywords: currentInput,
                  },
                })
                localStorage.setItem("isSurveyCompleted", "true");
                setState({
                  ...state,
                  isLoginQuestionnaireOpen: false
                });
              }} sx={{
                padding: '8px 16px',
                width: '80px',
                height: '30px',
                borderRadius: '5px',
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                cursor: "pointer",
                backgroundColor: "#FF9711",
                color: "#FFF",
                fontSize: '16px',
                fontWeight: "600",
                lineHeight: '24px',
                letterSpacing: "0.4px"
              }}>Done</Box>}
            </Box>
          </Box>}
        </Box>
      </DialogContent>
    </Dialog >
  )
}