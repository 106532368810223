import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { gql, useMutation } from "@apollo/client";
import { Context } from "/home/ubuntu/hff/src/Context/Context";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

const RESET_PASSWORD = gql`
  mutation Mutation(
    $email: String
    $authCode: String
    $userAuthCode: String
    $newPassword: String
  ) {
    resetUserPassword(
      email: $email
      authCode: $authCode
      userAuthCode: $userAuthCode
      newPassword: $newPassword
    )
  }
`;
const SEND_EMAIL_RESET_CODE = gql`
  mutation Mutation($authCode: String, $email: String) {
    sendEmailResetCode(authCode: $authCode, email: $email)
  }
`;

let authCode = "";

export default function Retrieve(props) {
  const { setState, state } = useContext(Context);
  const { sendLog } = state;
  const [reset_password] = useMutation(RESET_PASSWORD);
  const [send_email_reset_code] = useMutation(SEND_EMAIL_RESET_CODE);
  const [buttonSwitch, setButtonSwitch] = useState(false);
  const [buttonText, setButtonText] = useState("Send Auth Code");
  const [messageState, setmessageState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const handlePopup = (newmessageState) => () => {
    setmessageState({ open: true, ...newmessageState });
  };

  const handleSendCode = (event) => {
    authCode = "";
    event.preventDefault();
    sendLog("DIALOG_RESET_PASSWORD_SEND_AUTH_CODE_CLICK");
    const formData = new FormData(event.currentTarget);
    const emailValue = formData.get("email");
    const reg =
      /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    if (!reg.test(emailValue)) {
      setState({
        ...state,
        openSnackbar: true,
        snackbarText: 'Please enter the correct email address !',
        snackbarState: 'FAILED',
      });
      return;
    }
    for (let i = 0; i < 6; i++) {
      authCode += String(Math.floor(Math.random() * 10));
    }
    send_email_reset_code({
      variables: {
        email: String(formData.get("email")),
        authCode: String(authCode),
      },
    }).then((result) => {
      if (result.data != null) {
        switch (result.data.sendEmailResetCode) {
          case "NO_ACCOUNT":
            setState({
              ...state,
              openSnackbar: true,
              snackbarText: 'No hff account has been bound to this email address !',
              snackbarState: 'FAILED',
            });
            break;
          case "SEND_FAILURE":
            setState({
              ...state,
              openSnackbar: true,
              snackbarText: 'The verification code fails to be sent !',
              snackbarState: 'FAILED',
            });
            break;
          default:
            localStorage.setItem("email", String(formData.get("email")));
            setButtonSwitch(true);
            let a = 60;
            const timerId = setInterval(() => {
              a -= 1;
              setButtonText("After " + a + " seconds");
            }, 1000);
            setTimeout(() => {
              clearInterval(timerId);
              setButtonSwitch(false);
              setButtonText("Send Auth Code");
            }, 60000);
            break;
        }
      }
    });
  };

  const handlePasswordRest = (event) => {
    event.preventDefault();
    sendLog("DIALOG_RESET_PASSWORD_CHANGE_PASSWORD_CLICK");
    const formData = new FormData(event.currentTarget);
    reset_password({
      variables: {
        email: String(localStorage.getItem("email")),
        authCode: String(authCode),
        userAuthCode: String(formData.get("authCode")),
        newPassword: String(formData.get("newPassword")),
      },
    }).then((result) => {
      if (result.data != null && result.data.resetUserPassword === true) {
        setState({
          ...state,
          isAccountPopUpDialogOpen: false,
          openSnackbar: true,
          snackbarText: 'You have changed password successfully !',
          snackbarState: 'SUCCESS',
        });
      } else {
        setState({
          ...state,
          openSnackbar: true,
          snackbarText: 'Wrong verification code!',
          snackbarState: 'FAILED',
        });
      }
    });
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const fromBoxStyle = {
    padding: "1rem 4rem",
    "& .MuiInputBase-root": {
      height: "10rem",
      lineHeight: "10rem",
      borderRadius: "2rem",
      fontSize: "2.4rem",
    },
    "& .submitButton": {
      height: "10rem",
      lineHeight: "10rem",
      borderRadius: "2rem",
      fontSize: "2.4rem",
      backgroundColor: "#FF9711",
      "&:hover": {
        backgroundColor: "#FF9711",
      },
    },
    "& .sendCode": {
      height: "7.3rem",
      lineHeight: "7.3rem",
    },
    "& .MuiFormLabel-root": {
      fontSize: "2.4rem",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#FF9711",
      },
    },
    "& .MuiInputLabel-root": {
      "&.Mui-focused": {
        color: "#FF9711",
      },
    },
  };

  return (
    <div className="App">
      <Box sx={{ position: "relative", height: '77rem' }}>
        <Box
          sx={{
            width: "4rem",
            height: "4rem",
            position: "absolute",
            zIndex: "99",
            right: "20px",
            top: "-5px",
            cursor: "pointer",
            borderRadius: "50%",
            background: 'url("img/icon_close.png") no-repeat 0 0',
            backgroundSize: 'contain',
          }}
          onClick={() => {
            setState({
              ...state,
              isAccountPopUpDialogOpen: false,
            });
          }}
        />
        <Box
          sx={{
            width: "10.7rem",
            height: "10.7rem",
            backgroundColor: "#FF9711",
            borderRadius: "50%",
            margin: "3rem auto 0",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LockOutlinedIcon sx={{ fontSize: "6.4rem" }} />
        </Box>
        <Box
          sx={{
            height: "4.8rem",
            fontSize: "3.2rem",
            fontFamily: "Arial",
            fontStyle: "normal",
            fontWeight: 400,
            color: "#707070",
            textAlign: "center",
          }}
        >
          CHANGE PASSWORD
        </Box>
        <Box
          component="form"
          onSubmit={handleSendCode}
          noValidate
          sx={{ ...fromBoxStyle }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={buttonSwitch ? true : false}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1 }}
                onClick={handlePopup({
                  vertical: "top",
                  horizontal: "center",
                })}
                className="sendCode submitButton"
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          component="form"
          onSubmit={handlePasswordRest}
          noValidate
          sx={{ ...fromBoxStyle }}
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="authCode"
                name="authCode"
                required
                fullWidth
                id="authCode"
                label="Auth Code"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type={showPassword ? "text" : "password"}
                id="newPassword"
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        {showPassword ? (
                          <Visibility sx={{ fontSize: "3.2rem" }} />
                        ) : (
                          <VisibilityOff sx={{ fontSize: "3.2rem" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="submitButton"
              >
                CHANGE PASSWORD
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            fontSize: "1.4rem",
            textAlign: "center",
            position: "absolute",
            bottom: "2rem",
            width: "100%",
          }}
        >
          Copyright © Cartifind 2023.
        </Box>
      </Box>
    </div>
  );
}
