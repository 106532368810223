import React from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Context } from "../Context/Context";

export default function MyProductBlankPage(props) {
  const navigate = useNavigate();
  const { state, setState } = React.useContext(Context);
  const { sendLog, isExplanatoryVideoDialogOpen, explanatoryVideoSrc } = state;
  return (
    <Box sx={{
      fontFamily: 'Arial',
      fontStyle: 'normal',
      paddingLeft: '4rem'
    }}>
      <Box sx={{
        fontSize: '3.2rem',
        fontWeight: '700',
        letterSpacing: '0.15px',
        color: '#666666',
        height: '4.8rem',
        lineHeight: '4.8rem'
      }}>My Products</Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '1rem'
      }}>
        <Box sx={{ background: 'url("/img/ImportAliExpressURL_exclamation_point.png") no-repeat 0 0', backgroundSize: 'contain', width: '2.5rem', height: '2.5rem', marginRight: '1rem' }} />
        <Box sx={{ fontSize: '2rem', fontWeight: '400', letterSpacing: '0.15px', color: '#707070' }}>This is a product sample</Box>
      </Box>
      <Box sx={{ width: "150.2rem", height: '23.7rem', borderRadius: '1rem', overflow: 'hidden', marginTop: '2.8rem' }}>
        <img src="img/myProduct_example.png" alt='example image' width="100%" height="100%" />
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        height: '39.7rem',
        marginBottom: "4.5rem"
      }}>
        <Box sx={{ width: "48.7rem", height: '39.72rem', marginTop: '2.8rem' }}>
          <img src="img/myProduct_big_image.png" alt='example image' width="100%" height="100%" />
        </Box>
        <Box sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          paddingRight: '12rem'
        }}>
          <Box sx={{ marginTop: '12.8rem', height: '4.8rem', lineHeight: '4.8rem', color: '#666666', fontWeight: '700', fontSize: '3.2rem', }}>Your My Products is empty！</Box>
          <Box sx={{ marginTop: '4.8rem', height: '3.6rem', lineHeight: '3.6rem', color: '#666666', fontWeight: '500', fontSize: '2.4rem', }}>Head over to the <Typography variant="span" sx={{ fontWeight: '700', color: '#333333' }}>Product Search</Typography> page to start adding products to your <Typography variant="span" sx={{ fontWeight: '700', color: '#333333' }}>My Products</Typography>.</Box>
          <Box sx={{
            width: '100%',
            marginTop: '8.5rem',
            fontWeight: '700',
            fontSize: '3.2rem',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Box sx={{
              width: '30.9rem',
              height: '6.8rem',
              borderRadius: '1rem',
              backgroundColor: '#FF9711',
              color: '#FFF',
              textAlign: 'center',
              lineHeight: '6.8rem',
              cursor: 'pointer'
            }} onClick={() => {
              sendLog("MY_PRODUCTS_BLANK_PAGE_CTA_CLICK");
              navigate('/app');
            }}>Product Search</Box>
            <Box sx={{
              height: '4.8rem',
              lineHeight: '4.8rem',
              color: '#FF9711',
              display: 'flex',
              cursor: 'pointer'
            }} onClick={() => {
              sendLog("MY_PRODUCTS_BLANK_PAGE_CTA_CLICK");
              setState({
                ...state,
                isExplanatoryVideoDialogOpen: true,
                explanatoryVideoSrc: '/img/tutorial/part3.mp4'
              })
            }}>
              <Box sx={{ background: 'url("/img/blank_page_player.png") no-repeat 0 0', backgroundSize: 'contain', width: '5rem', height: '5rem', marginRight: '1.5rem' }} />
              <Box>How to add products</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}
