import React, { useState, useEffect, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { Box } from "@mui/system";
import { Context } from "../Context/Context";
import { gql, useMutation, useQuery } from "@apollo/client";
import TextField from "@mui/material/TextField";
import CustomSelected from "../CommonComponents/CustomSelected.react";
import { Spin } from 'antd';

const GEN_AI_DESCRIPTION_TEXT = gql`
  mutation Mutation($id: String, $title: String) {
    genAIDescriptionText(id: $id, title: $title) {
      status
      result
      message
    }
  }
`;

const GET_OPENAI_PROMPT = gql`
query query($name: String) {
  getOpenAIPromptIDInTemplateList(name: $name)
  }
`;

const SAVE_OPENAI_PROMPT_SELECTED_TEMPLATE_ID = gql`
mutation mutation($name: String, $templateID: Int) {
  saveOpenAIPromptSelectedTemplateID(name: $name, templateID: $templateID)
  }
`;

const GET_AI_TEMPLATE_LIST = gql`
  query getAITemplateList {
    getAITemplateList {
      id
      name
      prompt
    }
  }
`;

let html;
const descriptionValue = [{
  option: "Empty",
  value: '0'
}, {
  option: "Only push products' SPECIFICATIONS",
  value: '1'
}, {
  option: "Only push products' OVERVIEW",
  value: '2'
}, {
  option: "Push both products' SPECIFICATIONS and OVERVIEW",
  value: '3'
}];

let AITemplateOptionsMap = new Map();
let AITemplateOptions = [{
  option: "Template 1",
  value: 0,
}]
let currentDefaultOption = "Template 1";

export default function MyEditor(props) {
  const { descriptionHtml, setEphemeralHtml, id, OrighinalTitle, specifications, pushProductDescriptionValue, setPushProductDescriptionValue, unSaveDescriptionHtml, loadRichText, setLoadRichText } = props;
  const [initialize, setInitialize] = useState(true);
  const [editNum, setEditNum] = useState(0);
  const { state, setState, tabValue } = React.useContext(Context);
  const { sendLog } = state;
  const editorRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditOpenAIPrompt, setIsEditOpenAIPrompt] = useState(false);
  const [currentAITemplate, setCurrentAITemplate] = useState(0);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(true);
  const [gen_ai_description_text] = useMutation(GEN_AI_DESCRIPTION_TEXT);
  const [save_openAI_prompt_selected_template_id] = useMutation(SAVE_OPENAI_PROMPT_SELECTED_TEMPLATE_ID);
  const { loading, data, refetch } = useQuery(GET_OPENAI_PROMPT, {
    variables: {
      name: "description",
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const { loading: templateLoading, data: templateData, refetch: templateRefetch } = useQuery(GET_AI_TEMPLATE_LIST, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const [openAIPrompt, setOpenAIPrompt] = useState("Write a concise web store product description in English");

  const setTemplateEffect = (value) => {
    save_openAI_prompt_selected_template_id({
      variables: {
        name: "description",
        templateID: Number(value)
      }
    });
    setCurrentAITemplate(value);
  }


  useEffect(() => {
    refetch({
      name: "description",
    })
  }, [tabValue]);

  useEffect(() => {
    if (initialize) {
      html = descriptionHtml;
      setInitialize(false);
    }
    if (loadRichText === 0) {
      setLoadRichText(1);
    }
  }, []);

  useEffect(() => {
    setIsLoadingTemplate(true);
    if (templateData != null && templateData.getAITemplateList != null) {
      AITemplateOptions = templateData.getAITemplateList.map((item, index) => {
        AITemplateOptionsMap.set(
          item.id,
          item,
        );
        return {
          option: item.name,
          value: item.id,
        }
      });
      refetch({
        name: "description",
      }).then(res => {
        setIsLoadingTemplate(false);
        if (res.data != null && res.data.getOpenAIPromptIDInTemplateList != null) {
          currentDefaultOption = AITemplateOptionsMap.get(res.data.getOpenAIPromptIDInTemplateList).name;
          setCurrentAITemplate(res.data.getOpenAIPromptIDInTemplateList);
          save_openAI_prompt_selected_template_id({
            variables: {
              name: "description",
              templateID: Number(res.data.getOpenAIPromptIDInTemplateList)
            }
          });
        }
      })
    }
  }, [templateLoading]);

  const AfterChange = () => {
    let temporary = '';
    if (specifications != null) {
      specifications.map((item, index) => {
        temporary = temporary + `<div>${item.attrName}:${item.attrValue}</div>`;
        if (index === specifications.length - 1) {
          temporary = temporary + `<div>  </div><div>  </div>`;
        }
      });
    }
    switch (pushProductDescriptionValue) {
      case '0':
        html = '';
        break;
      case '1':
        html = temporary;
        break;
      case '2':
        html = unSaveDescriptionHtml;
        break;
      default:
        html = temporary + unSaveDescriptionHtml;
        break;
    }
    setEphemeralHtml(html);
  }

  return (
    <>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        height: '3rem',
        letterSpacing: '0.15px'
      }}>
        <Box sx={{ fontSize: '1.8rem', fontWeight: '500', color: '#333333' }}>Customize product description</Box>
        <Box sx={{ fontSize: '1.6rem', fontWeight: '400', color: '#999999' }}> ( When pushing products, you want Cartifind to ) :</Box>
      </Box>
      <Box sx={{
        margin: '0.8rem 0 1.5rem',
        "& div": {
          fontSize: '2rem!important'
        }
      }}>
        <CustomSelected
          width="53.4rem"
          height="4.5rem"
          selectedWidth="53.4rem"
          border="0.1rem solid #999999"
          selectedItems={descriptionValue}
          defaultOption={descriptionValue[Number(pushProductDescriptionValue)] != null ? descriptionValue[Number(pushProductDescriptionValue)].option : 'Empty'}
          selectedValue={pushProductDescriptionValue}
          setSelectValue={setPushProductDescriptionValue}
          onChange={AfterChange}
        />
      </Box>
      <Box sx={{
        height: '4.5rem',
        margin: '0rem 0 2rem',
        display: 'flex',
        alignItems: 'center',
        "& .button": {
          width: '27.3rem',
          height: '4.5rem',
          borderRadius: '1rem',
          color: '#FFF',
          fontWeight: '500',
          fontSize: '1.4rem',
          letterSpacing: '0.15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }
      }}>

        {!isLoadingTemplate ? <Box sx={{ fontSize: '16px', marginRight: '20px' }}>
          <CustomSelected
            width="400px"
            height="40px"
            selectedWidth="400px"
            border="1px solid #999999"
            selectedItems={AITemplateOptions}
            defaultOption={currentDefaultOption}
            selectedValue={currentAITemplate}
            setSelectValue={setTemplateEffect}
          />
        </Box> : <Spin />}
        {isLoading ? <Box sx={{
          backgroundColor: '#9BB7B0',
          cursor: 'default',
          "@keyframes load": {
            from: {
              transform: "rotate(0deg)",
            },
            to: {
              transform: "rotate(360deg)",
            }
          },
        }} className='button'>
          <Box sx={{
            animation: 'load 1.5s infinite',
            width: '2.5rem',
            height: '2.5rem',
            marginRight: '1rem'
          }}>
            <img src='/img/ImportAliExpressURL_loading.png' alt="loading" width={'100%'} height={"100%"} />
          </Box>
          Generating
        </Box> :
          <Box sx={{ backgroundColor: '#75A99C', cursor: 'pointer', }} className='button' onClick={() => {
            sendLog("MY_PRODUCTS_CARD_DIALOG_DESCRIPTION_CLICK_OPENAI");
            setIsLoading(true);
            gen_ai_description_text({
              variables: {
                id,
                title: OrighinalTitle
              }
            }).then((result) => {
              if (result != null && result.data != null && result.data.genAIDescriptionText != null && result.data.genAIDescriptionText.result != null && result.data.genAIDescriptionText.result.length > 0) {
                if (result.data.genAIDescriptionText.status) {
                  const descriptionRes = `<div>${result.data.genAIDescriptionText.result}</div><div>  </div>`;
                  if (!html.includes(descriptionRes)) {
                    setEphemeralHtml(descriptionRes + html);
                    html = descriptionRes + html;
                  }
                  setIsLoading(false);
                } else {
                  setState({
                    ...state,
                    openSnackbar: true,
                    snackbarText: result.data.genAIDescriptionText.result,
                    snackbarState: 'FAILED',
                  });
                  setIsLoading(false);
                }
              } else {
                setState({
                  ...state,
                  openSnackbar: true,
                  snackbarText: 'Something went wrong !',
                  snackbarState: 'FAILED',
                });
                setIsLoading(false);
              }
            })
          }}>
            <Box sx={{
              width: '3.0rem',
              height: '3.1rem',
              marginRight: '0.7rem'
            }}>
              <img src='/img/openAI_logo.png' alt="loading" width={'100%'} height={"100%"} />
            </Box>
            Generate Description by ChatGPT
          </Box>}
      </Box>
      <Box sx={{ "&>textarea": { visibility: 'hidden!important' } }}>
        <Editor
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={html}
          tinymceScriptSrc={'/tinymce/js/tinymce/tinymce.min.js'}
          onEditorChange={(content, editor) => {
            if (editNum < 2) {
              setEditNum(editNum + 1);
            }
            if (editNum === 1) {
              sendLog("MY_PRODUCTS_CARD_DIALOG_DESCRIPTION_EDIT_DESCRIPTION");
            }
            setEphemeralHtml(content);
          }}
          init={{
            resize: false,
            menubar: false,
            statusbar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent',
            content_style: 'body { font-family:Arial,Helvetica,sans-serif; font-size:14px } img {max-width:100%;}',
          }}
          apiKey={'0xsjtcizm2f7j5og99gpkj3xbe0yr82jg00jwmwt264ihdkd'}
        />
      </Box>
    </>
  );
}
