import React from 'react';
import { WixAppID } from './ShopifyConstants';
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const queryParams = new URLSearchParams(window.location.search);
const token = queryParams.get('token');
const redirectURL = 'https://cartifind.com/wixRedirect';

export default function WixInstall() {
    if (token != null) {
        const url = `https://www.wix.com/installer/install?token=${token}&appId=${WixAppID}&redirectUrl=${redirectURL}`
        window.location.href = url;
        return;
    };

    return (
        <Box sx={{
            height: "40rem",
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
        }}
        >
            <CircularProgress />
        </Box>
    );
};