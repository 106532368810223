import * as React from "react";
import { useImperativeHandle } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import MyEditor from "./RichText.react";
import VariantsContent from "./VariantsContent.react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import MediaContent from "./MediaContent.react";
import ProductContent from "./ProductContent.react";
import AITemplate from "./AITemplate.react";
import { Context } from "../Context/Context";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import { supportCurrencyList } from "../utils/CurrencyList.js";

// const VariantsContent = React.lazy(() => import("./VariantsContent.react"));

const GET_USER_PRODUCT_DETAIL = gql`
  query GetUserProductDetail($id: String) {
    getUserProductDetail(id: $id) {
      result {
        settingCurrency
        productCurrency
        uneditedShopifyInput
        editedShopifyInput
        spec
        pushProductDescriptionValue
        shopifyMedia
        collectionList
        isSelectVideo
        productWeight
        fulfillmentOption
      }
    }
  }
`;

const GET_DEFAULT_PRODUCT_SHIPPING_SPEC = gql`
  query GetDefaultProductShippingSpec($id: String) {
    getDefaultProductShippingSpec(id: $id) {
      country
      currency
      fee
      hasTracking
      id
      shippingMethod
      time
      shippingMethodCountry
    }
  }
`;
// 加入 fulfillmentOption 

const PUSH_PRODUCT = gql`
  mutation Mutation($input: String, $shopifyProductID: String, $productSchemaID: String, $videoURL: String, $collectionTitleList: [String]) {
    pushProduct(input: $input, shopifyProductID: $shopifyProductID, productSchemaID: $productSchemaID, videoURL: $videoURL, collectionTitleList: $collectionTitleList) {
      status
      isShowUpgrade
      canBeEvaluated
      successShopifyID
      failedReason
      message
    }
  }
`;

const SAVE_PRODUCT_INPUT_FOR_USER = gql`
  mutation Mutation($id: String, $input: String, $currency: String, $pushProductDescriptionValue: String, $collectionTitleList: [String], $isSelectVideo: String, $shippingMethodCountry: String, $shippingMethodID: String, $productWeight: String, $fulfillmentOption: Boolean) {
    saveProductInputForUser(id: $id, input: $input, currency: $currency, pushProductDescriptionValue: $pushProductDescriptionValue, collectionTitleList: $collectionTitleList, isSelectVideo: $isSelectVideo, shippingMethodCountry: $shippingMethodCountry, shippingMethodID: $shippingMethodID, productWeight: $productWeight, fulfillmentOption: $fulfillmentOption) {
      status
      message
    }
  }
`;

const RESET_USER_PRODUCT_DETAIL = gql`
mutation Mutation($id: String) {
    resetUserProductDetail(id: $id) {
        status,
        message
    }
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
export default function ImportProduct(props) {
  const {
    saveShopifyInputRef,
    setIsOpenEdit,
  } = props;
  const [ephemeralTitle, setEphemeralTitle] = React.useState();
  const [ephemeralThumbnailSource, setEphemeralThumbnailSource] = React.useState();
  const [ephemeralHtml, setEphemeralHtml] = React.useState('Initialize descriptionHtml');
  const [ephemeralVariants, setEphemeralVariants] = React.useState();
  const [ephemeralImages, setEphemeralImages] = React.useState();
  const [ephemeralOptions, setEphemeralOptions] = React.useState();
  const [ephemeralCurrency, setEphemeralCurrency] = React.useState();
  const [ephemeralTagList, setEphemeralTagList] = React.useState();
  const [ephemeralTagOptionList, setEphemeralTagOptionList] = React.useState();
  const [ephemeralProductType, setEphemeralProductType] = React.useState();
  const [ephemeralCollectionList, setEphemeralCollectionList] = React.useState();
  const [isSelectVideo, setIsSelectVideo] = React.useState(true);
  const [ephemeralProductWeight, setEphemeralProductWeight] = React.useState();
  const [ephemeralFulfillmentOption, setEphemeralFulfillmentOption] = React.useState();

  const [pushProductDescriptionValue, setPushProductDescriptionValue] = React.useState();
  const [loadRichText, setLoadRichText] = React.useState(0);
  const [push_product] = useMutation(PUSH_PRODUCT);
  const [save_product_input_for_user] = useMutation(
    SAVE_PRODUCT_INPUT_FOR_USER
  );
  const [variantsSelectedIndexList, setVariantsSelectedIndexList] = React.useState();
  const [currentThumbnailSourceIndex, setCurrentThumbnailSourceIndex] = React.useState();
  const { setState, state } = React.useContext(Context);
  const currentClickProduct = sessionStorage.getItem("currentClickProduct");
  const {
    tabValue,
    openEditSnackbar,
    editSnackbarText,
    variantsIsWrong,
    imagesIsWrong,
    handleRefetchProductList,
    setPushSuccessIDList,
    setPushFailedIDList,
    setPushFailedReasonList,
    setCurrentFailedCount,
    setPushStatus,
    pushFailedIDList,
    pushFailedReasonList,
    setPushIDList
  } = state;
  const { id: currentClickID, thumbnailSource, shopifyProductID } = JSON.parse(currentClickProduct);
  const [reset_user_product_detail] = useMutation(RESET_USER_PRODUCT_DETAIL);

  useImperativeHandle(saveShopifyInputRef, () => ({
    handleSaveShopifyInput,
    handleSaveAndPushShopifyInput,
    handleResetShopifyInput,
  }));

  React.useEffect(() => {
    if (ephemeralTitle != null || ephemeralHtml != null || ephemeralVariants != null || ephemeralImages != null || ephemeralOptions != null || ephemeralCurrency != null) {
      setState({
        ...state,
        isEditShopifyInput: true,
      });
    }
  }, [ephemeralTitle, ephemeralHtml, ephemeralImages, ephemeralVariants, ephemeralOptions, ephemeralCurrency]);
  const [shopifyInputIsNull, setShopifyInputIsNull] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [openResetAlert, setOpenResetAlert] = React.useState(false);

  const { loading, data, refetch } = useQuery(GET_USER_PRODUCT_DETAIL, {
    variables: {
      id: String(currentClickID),
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  const { data: shippingSpec } = useQuery(GET_DEFAULT_PRODUCT_SHIPPING_SPEC, {
    variables: {
      id: String(currentClickID),
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const [shippingMethodID, setShippingMethodID] = React.useState(shippingSpec != null && shippingSpec.getDefaultProductShippingSpec != null && shippingSpec.getDefaultProductShippingSpec.id != null ? shippingSpec.getDefaultProductShippingSpec.id : null);
  const [shippingMethodCountry, setShippingMethodCountry] = React.useState(shippingSpec != null && shippingSpec.getDefaultProductShippingSpec != null && shippingSpec.getDefaultProductShippingSpec.shippingMethodCountry != null ? shippingSpec.getDefaultProductShippingSpec.shippingMethodCountry : null);

  const { pathname } = useLocation();
  React.useEffect(() => {
    refetch({
      id: String(currentClickID),
    });
  }, [pathname]);

  React.useEffect(() => {
    if (ephemeralImages != null && ephemeralImages.length === 0) {
      setState({
        ...state,
        imagesIsWrong: true
      });
    } else {
      setState({
        ...state,
        imagesIsWrong: false
      });
    }
  }, [ephemeralImages]);

  React.useEffect(() => {
    if (
      loading === false && (data == null ||
        data.getUserProductDetail == null ||
        data.getUserProductDetail.result == null ||
        data.getUserProductDetail.result.editedShopifyInput == null ||
        data.getUserProductDetail.result.editedShopifyInput == 'null' ||
        data.getUserProductDetail.result.uneditedShopifyInput == null ||
        data.getUserProductDetail.result.uneditedShopifyInput == 'null')
    ) {
      setState({
        ...state,
        openSnackbar: true,
        snackbarText: 'This product expires so it can not be edited or imported !',
        snackbarState: 'FAILED',
        isOpenImportProductDialog: false,
      });
      setShopifyInputIsNull(true);
    }
    if (loading === false && data != null &&
      data.getUserProductDetail != null &&
      data.getUserProductDetail.result != null) {
      setIsSelectVideo(data.getUserProductDetail.result.isSelectVideo === "1");
    }
    setIsLoading(loading);
  }, [loading]);

  if (loading || isLoading) {
    return (
      <Box
        sx={{
          width: "1148px",
          height: "360px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "481px",
            height: "54px",
            borderRadius: "10px",
            backgroundColor: "#FF9711",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "22px",
            color: "#FFF",
          }}
        >
          Loading ...
          <CircularProgress
            size={"30px"}
            sx={{ margin: "0 30px", color: "#FFF" }}
          />
        </Box>
      </Box>
    );
  }
  if (shopifyInputIsNull) {
    return;
  }
  const editedShopifyInput = JSON.parse(
    data.getUserProductDetail.result.editedShopifyInput
  );
  const uneditedShopifyInput = JSON.parse(
    data.getUserProductDetail.result.uneditedShopifyInput
  );
  const specifications = JSON.parse(
    data.getUserProductDetail.result.spec
  );
  const shopifyMedia = JSON.parse(
    data.getUserProductDetail.result.shopifyMedia
  );
  const collectionList = data.getUserProductDetail.result.collectionList;

  const newEditedImages = editedShopifyInput.images.map((item) => {
    if (item.altText.slice(0, 5) != 'descr') {
      return item;
    }
  }).filter(res => res != null);

  const settingCurrency = data.getUserProductDetail.result.settingCurrency;
  const productCurrency = data.getUserProductDetail.result.productCurrency;
  const productWeight = data.getUserProductDetail.result.productWeight;
  const fulfillmentOption = data.getUserProductDetail.result.fulfillmentOption;
  const handleSaveShopifyInput = (inputState) => {
    if (imagesIsWrong) {
      setState({ ...state, openEditSnackbar: true, editSnackbarText: 'Select at least one image.' });
      return;
    }
    if (variantsIsWrong) {
      setState({ ...state, openEditSnackbar: true, editSnackbarText: `The product's options are repeated, please change them.` });
      return;
    }
    const newChangedImage = ephemeralImages != null ? ephemeralImages.map((item, index) => {
      if (item.altText.slice(0, 5) == 'descr') {
        return {
          altText: 'image' + index,
          src: item.src
        }
      }
      return item;
    }) : ephemeralImages;
    setState({
      ...state,
      isOpenImportProductDialog: false,
    });
    sessionStorage.setItem("isEditing", 'false');
    document.documentElement.scrollTop = 0;
    setIsOpenEdit(false);
    let middleData = editedShopifyInput;
    middleData.title = ephemeralTitle
      ? ephemeralTitle
      : editedShopifyInput.title;
    middleData.descriptionHtml = ephemeralHtml !== 'Initialize descriptionHtml'
      ? ephemeralHtml
      : editedShopifyInput.descriptionHtml;
    middleData.variants = ephemeralVariants
      ? ephemeralVariants
      : editedShopifyInput.variants;
    middleData.options = ephemeralOptions
      ? ephemeralOptions
      : editedShopifyInput.options;
    middleData.images = newChangedImage
      ? newChangedImage
      : newEditedImages;
    middleData.seo = {
      description: ephemeralHtml !== 'Initialize descriptionHtml'
        ? ephemeralHtml
        : editedShopifyInput.descriptionHtml,
      title: ephemeralTitle
        ? ephemeralTitle
        : editedShopifyInput.title
    };
    middleData.tags = ephemeralTagList
      ? ephemeralTagList
      : editedShopifyInput.tags;
    middleData.customProductType = ephemeralProductType
      ? ephemeralProductType
      : editedShopifyInput.customProductType;
    if (ephemeralThumbnailSource) {
      let count = 0;
      middleData.images.map((item, index) => {
        if (item.src == ephemeralThumbnailSource) {
          count = 1;
          [middleData.images[0], middleData.images[index]] = [middleData.images[index], middleData.images[0]]
        }
      })
      if (count === 0) {
        middleData.images.splice(0, 0, { src: ephemeralThumbnailSource, altText: "image" + currentThumbnailSourceIndex });
      }
    }
    if (variantsSelectedIndexList != null && variantsSelectedIndexList.length > 0) {
      middleData.variants = middleData.variants.map((item, index) => {
        if (variantsSelectedIndexList.includes(index)) {
          return item;
        }
      }).filter(res => res != null);
    }
    const currency = ephemeralCurrency ? ephemeralCurrency : (productCurrency ? productCurrency : settingCurrency);
    save_product_input_for_user({
      variables: {
        id: String(currentClickID),
        input: JSON.stringify(middleData),
        currency: currency.toUpperCase(),
        pushProductDescriptionValue,
        collectionTitleList: ephemeralCollectionList ? ephemeralCollectionList : collectionList,
        isSelectVideo: isSelectVideo ? "1" : "0",
        shippingMethodCountry,
        shippingMethodID,
        productWeight: String(ephemeralProductWeight != null ? ephemeralProductWeight : productWeight),
        fulfillmentOption: ephemeralFulfillmentOption != null ? ephemeralFulfillmentOption : fulfillmentOption,
      },
    }).then((result) => {
      const isEditing = sessionStorage.getItem("isEditing");
      if (
        result.data != null &&
        result.data.saveProductInputForUser != null &&
        result.data.saveProductInputForUser.status
      ) {
        if (inputState !== "SAVE_AND_IMPORT") {
          setState({
            ...state,
            openSnackbar: true,
            snackbarText: 'Save successfully!',
            snackbarState: 'SUCCESS',
            isOpenImportProductDialog: isEditing === 'true',
          });
          if (handleRefetchProductList != null) {
            handleRefetchProductList();
          }
        }
      }
      //setIsOpenEdit(false);
    });

  };
  const handleSaveAndPushShopifyInput = () => {
    if (imagesIsWrong) {
      setState({ ...state, openEditSnackbar: true, editSnackbarText: 'Select at least one image.' });
      return;
    }
    if (variantsIsWrong) {
      setState({ ...state, openEditSnackbar: true, editSnackbarText: `The product's options are repeated, please change them.` });
      return;
    }
    handleSaveShopifyInput("SAVE_AND_IMPORT");
    setState({
      ...state,
      isOpenImportProductDialog: false,
      isPushingToShopify: true,
    });
    push_product({
      variables: {
        input: JSON.stringify(editedShopifyInput),
        shopifyProductID,
        productSchemaID: currentClickID,
        videoURL: isSelectVideo ? (shopifyMedia != null ? shopifyMedia.url : null) : null,
        collectionTitleList: ephemeralCollectionList ? ephemeralCollectionList : collectionList,
      },
    }).then((result) => {
      const isEditing = sessionStorage.getItem("isEditing");
      if (
        result.data != null &&
        result.data.pushProduct != null
      ) {
        if (!result.data.pushProduct.status && result.data.pushProduct.message === 'PLEASE_UPGRADE_PLAN') {
          setState({
            ...state,
            isOpenSelectPlanDialog: true,
            isPushingToShopify: false,
            isOpenImportProductDialog: isEditing === 'true',
          });
          return;
        }
        const cacheFailedIDList = pushFailedIDList != null ? pushFailedIDList : [];
        const cacheFailedReasonList = pushFailedReasonList;
        if (!result.data.pushProduct.status) {
          if (cacheFailedIDList.includes(String(currentClickID))) {
            cacheFailedReasonList[cacheFailedIDList.indexOf(String(currentClickID))] = result.data.pushProduct.failedReason;
          } else {
            cacheFailedIDList.push(String(currentClickID));
            cacheFailedReasonList.push(result.data.pushProduct.failedReason);
          }
        } else if (cacheFailedIDList.includes(String(currentClickID))) {
          cacheFailedIDList.splice(cacheFailedIDList.indexOf(String(currentClickID)), 1);
          cacheFailedReasonList.splice(cacheFailedIDList.indexOf(String(currentClickID)), 1);
        }
        setPushSuccessIDList(result.data.pushProduct.status ? [String(currentClickID)] : []);
        setPushFailedIDList(cacheFailedIDList);
        setPushFailedReasonList(cacheFailedReasonList);

        if (result.data.pushProduct.status) {
          setState({
            ...state,
            openSnackbar: true,
            snackbarText: 'Success! Your selected products have been pushed successfully.',
            snackbarState: 'SUCCESS',
            isPushingToShopify: false,
            isOpenImportProductDialog: isEditing === 'true',
            isOpenRatingDialog: localStorage.getItem("isRatingShown") == "false" && result.data.pushProduct.canBeEvaluated
          });
          //Open Upgrade Dailog
          if (result.data.pushProduct.isShowUpgrade) {
            document.getElementById("UpgradePlanDialog").click();
          }
          if (handleRefetchProductList != null) {
            handleRefetchProductList();
          }
        } else {
          if (result.data.pushProduct.message === 'PLEASE_UPGRADE_PLAN') {
            setState({
              ...state,
              isOpenSelectPlanDialog: true,
              openSnackbar: true,
              snackbarText: 'please upgrade plan',
              snackbarState: 'FAILED',
              isPushingToShopify: false,
              isOpenImportProductDialog: isEditing === 'true',
            });
          } else {
            setState({
              ...state,
              openSnackbar: true,
              snackbarText: 'Item fails to import.',
              snackbarState: 'FAILED',
              isPushingToShopify: false,
              isOpenImportProductDialog: isEditing === 'true',
            });
          }
        }
      }
    });
  };
  const handleResetShopifyInput = () => {
    setOpenResetAlert(true);
  }

  return (
    <Box>
      <Dialog
        open={openResetAlert}
        onClose={() => {
          setOpenResetAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            width: "440px",
            height: "209px",
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: '115px',
            fontFamily: "Arial",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "150%",
            letterSpacing: "0.15px",
            color: "#666666",
            position: "relative",
            paddingTop: '23px',
          }}
        >
          <Box sx={{ fontSize: '18px', color: "#333333", }}>Reset changes</Box>
          <Box>The information you modified will be lost, and all product details will be reset. If you want to continue, please click "Continue", otherwise, click "Cancel".</Box>
        </DialogTitle>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: '0 23px',
            "& .button": {
              width: '90px',
              height: '38px',
              padding: "0px",
              borderRadius: "10px",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "150%",
              letterSpacing: "0.15px",
              color: "#707070B2",
              backgroundColor: "#e4e4e4",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#e4e4e4",
              },
            },
            "& .save-button": {
              color: "#FFF",
              backgroundColor: "#FF9711",
              "&:hover": {
                color: "#FFF",
                backgroundColor: "#FF9711",
              },
            },
          }}
        >
          <Button
            className="button"
            onClick={() => {
              setOpenResetAlert(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="button save-button"
            onClick={() => {
              setOpenResetAlert(false);
              setState({
                ...state,
                isOpenImportProductDialog: false,
              });
              reset_user_product_detail({
                variables: {
                  id: currentClickID
                },
              }).then(result => {
                const currency = ephemeralCurrency ? ephemeralCurrency : (productCurrency ? productCurrency : settingCurrency);
                if (result != null && result.data != null && result.data.resetUserProductDetail != null) {
                  if (result.data.resetUserProductDetail.status) {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText: 'Success! Your selected products have been reset successfully.',
                      snackbarState: 'SUCCESS',
                      isOpenImportProductDialog: false,
                    });
                  } else if (result.data.resetUserProductDetail.message === 'ALIEXPRESS_URL_IMPORTANT_PRODUCT') {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText: 'This item cannot be reset, and you can import another one by URL.',
                      snackbarState: 'FAILED',
                      isOpenImportProductDialog: false,
                    });
                  }
                }
                if (handleRefetchProductList != null) {
                  handleRefetchProductList();
                }
              });
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openEditSnackbar ? openEditSnackbar : false}
        onClose={() => {
          setState({ ...state, openEditSnackbar: false });
        }}
        message={<React.Fragment>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "23px",
                height: "23px",
                marginRight: "10px",
                background: `url("img/red_wrong.png") no-repeat 0 0`,
                backgroundSize: "contain",
              }}
            ></Box>
            <Box>
              {editSnackbarText}
            </Box>
          </Box>
        </React.Fragment>}
        transitionDuration={500}
        autoHideDuration={5000}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#FFF",
            justifyContent: "center",
            width: 'auto',
            paddingLeft: '15px',
            paddingRight: '15px',
            height: "46px",
            fontFamily: "Arial",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "150%",
            letterSpacing: "0.15px",
            color: "#333333",
            alignItems: "center",
            boxSizing: 'border-box'
          },
          "& .MuiSnackbarContent-message": {
            padding: "0",
          },
          "& .MuiSnackbarContent-action": {
            marginLeft: "0",
          },
        }}
      />
      <Box sx={{ position: "relative", width: "1146px" }}>
        <TabPanel value={tabValue} index={0}>
          <ProductContent
            title={ephemeralTitle ? ephemeralTitle : editedShopifyInput.title}
            OrighinalTitle={uneditedShopifyInput.title}
            thumbnailSource={
              ephemeralThumbnailSource
                ? ephemeralThumbnailSource
                : thumbnailSource
            }
            setEphemeralTitle={setEphemeralTitle}
            setEphemeralThumbnailSource={setEphemeralThumbnailSource}
            images={newEditedImages}
            currentThumbnailSourceIndex={currentThumbnailSourceIndex}
            setCurrentThumbnailSourceIndex={setCurrentThumbnailSourceIndex}
            id={currentClickID}
            tagListProp={ephemeralTagList ? ephemeralTagList : editedShopifyInput.tags}
            tagOptionListProp={ephemeralTagOptionList ? ephemeralTagOptionList : editedShopifyInput.tags}
            collectionListProp={ephemeralCollectionList ? ephemeralCollectionList : collectionList}
            setEphemeralCollectionList={setEphemeralCollectionList}
            setEphemeralTagList={setEphemeralTagList}
            setEphemeralTagOptionList={setEphemeralTagOptionList}
            setEphemeralProductType={setEphemeralProductType}
            productType={ephemeralProductType ? ephemeralProductType : (editedShopifyInput.customProductType != null ? editedShopifyInput.customProductType : "")}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <MyEditor
            descriptionHtml={
              ephemeralHtml !== 'Initialize descriptionHtml'
                ? ephemeralHtml
                : editedShopifyInput.descriptionHtml
            }
            unSaveDescriptionHtml={uneditedShopifyInput.descriptionHtml}
            setEphemeralHtml={setEphemeralHtml}
            id={currentClickID}
            OrighinalTitle={uneditedShopifyInput.title}
            specifications={specifications}
            pushProductDescriptionValue={pushProductDescriptionValue ? pushProductDescriptionValue : data.getUserProductDetail.result.pushProductDescriptionValue}
            setPushProductDescriptionValue={setPushProductDescriptionValue}
            loadRichText={loadRichText}
            setLoadRichText={setLoadRichText}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <AITemplate
            editedImages={
              ephemeralImages != null ? ephemeralImages : newEditedImages
            }
            uneditedImages={uneditedShopifyInput.images}
            setEphemeralImages={setEphemeralImages}
            shopifyMedia={shopifyMedia != null ? [shopifyMedia] : []}
            isSelectVideo={isSelectVideo}
            setIsSelectVideo={setIsSelectVideo}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <VariantsContent
            currentClickID={currentClickID}
            variants={
              ephemeralVariants
                ? ephemeralVariants
                : editedShopifyInput.variants
            }
            uneditedVariants={editedShopifyInput.variants}
            options={
              ephemeralOptions ? ephemeralOptions : editedShopifyInput.options
            }
            currency={ephemeralCurrency ? ephemeralCurrency : (productCurrency ? productCurrency : settingCurrency)}
            setEphemeralOptions={setEphemeralOptions}
            setEphemeralCurrency={setEphemeralCurrency}
            setEphemeralVariants={setEphemeralVariants}
            getVariantsSelectedIndexList={(val) => {
              setVariantsSelectedIndexList(val);
            }}
            lastTimeVariantsSelectedIndexList={variantsSelectedIndexList}
            shippingSpec={shippingSpec != null ? shippingSpec.getDefaultProductShippingSpec : null}
            setEphemeralShippingCountry={setShippingMethodCountry}
            setEphemeralShippingID={setShippingMethodID}
            ephemeralProductWeight={ephemeralProductWeight != null ? ephemeralProductWeight : productWeight}
            ephemeralFulfillmentOption={ephemeralFulfillmentOption != null ? ephemeralFulfillmentOption : fulfillmentOption}
            setEphemeralProductWeight={setEphemeralProductWeight}
            setEphemeralFulfillmentOption={setEphemeralFulfillmentOption}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <MediaContent
            editedImages={
              ephemeralImages != null ? ephemeralImages : newEditedImages
            }
            uneditedImages={uneditedShopifyInput.images}
            setEphemeralImages={setEphemeralImages}
            shopifyMedia={shopifyMedia != null ? [shopifyMedia] : []}
            isSelectVideo={isSelectVideo}
            setIsSelectVideo={setIsSelectVideo}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}
