/** @format */

import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import ProductCard from "./ProductCard.react";
import Pagination from "@mui/material/Pagination";
import { Context } from "../Context/Context";
import { useNavigate } from "react-router-dom";

// const BLACK_LIST_CRAWLER_IDS = new Set(["4", "5", "6", "30", "31", "35", "1255249", "1708850", "1754151", "342014"]);
const BLACK_LIST_CRAWLER_IDS = new Set([]);
const FreeShowProductList = [];

const GET_PRODUCTS = gql`
  query Query(
    $titleSearch: String
    $category: String
    $sortBy: String
    $minOrders: Int
    $maxOrders: Int
    $maxPrice: Int
    $minFeedbackRate: Float
    $minPrice: Int
    $maxFeedbackRate: Float
    $minItemAsDescribed: Float
    $maxItemAsDescribed: Float
    $minShippingSpeed: Float
    $maxShippingSpeed: Float
    $minCommunication: Float
    $maxCommunication: Float
    $shipFromUs: Boolean
    $shipDay: Float
    $isFreeShip: Boolean
    $hasVideo: Boolean
    $IP: String
    $sessionID: String
    $pageSize: Int
    $pageIndex: Int
    $minReviewScore: Float
    $maxReviewScore: Float
  ) {
    searchProducts(
      titleSearch: $titleSearch
      category: $category
      sortBy: $sortBy
      minOrders: $minOrders
      maxOrders: $maxOrders
      maxPrice: $maxPrice
      minFeedbackRate: $minFeedbackRate
      minPrice: $minPrice
      maxFeedbackRate: $maxFeedbackRate
      minItemAsDescribed: $minItemAsDescribed
      maxItemAsDescribed: $maxItemAsDescribed
      minShippingSpeed: $minShippingSpeed
      maxShippingSpeed: $maxShippingSpeed
      minCommunication: $minCommunication
      maxCommunication: $maxCommunication
      shipFromUS: $shipFromUs
      shipDay: $shipDay
      isFreeShip: $isFreeShip
      hasVideo: $hasVideo
      IP: $IP
      sessionID: $sessionID
      pageSize: $pageSize
      pageIndex: $pageIndex
      minReviewScore: $minReviewScore
      maxReviewScore: $maxReviewScore
    ) {
      searchResult {
        id
        title
        numberSold
        reviewScore
        thumbnailSource
        price
        productLink
        feedbackScore
        itemAsDescribed
        shipSpeed
        communication
        images
        videoLink
        minSalePrice
        maxSalePrice
        minOriginalPrice
        maxOriginalPrice
        currency
      }
      savedList
    }
  }
`;

const GET_SUBSCRIPTION_STATUS = gql`
  query getSubscriptionStatus {
    getSubscriptionStatus
  }
`;

const NEW_GET_PRODUCTS = gql`
  query newSearchProducts(
    $titleSearch: String
    $category: String
    $sortBy: String
    $minOrders: Int
    $maxOrders: Int
    $maxPrice: Int
    $minFeedbackRate: Float
    $minPrice: Int
    $maxFeedbackRate: Float
    $minItemAsDescribed: Float
    $maxItemAsDescribed: Float
    $minShippingSpeed: Float
    $maxShippingSpeed: Float
    $minCommunication: Float
    $maxCommunication: Float
    $shipFromUs: Boolean
    $shipDay: Float
    $isFreeShip: Boolean
    $hasVideo: Boolean
    $IP: String
    $sessionID: String
    $pageSize: Int
    $pageIndex: Int
    $minReviewScore: Float
    $maxReviewScore: Float
  ) {
    newSearchProducts(
      titleSearch: $titleSearch
      category: $category
      sortBy: $sortBy
      minOrders: $minOrders
      maxOrders: $maxOrders
      maxPrice: $maxPrice
      minFeedbackRate: $minFeedbackRate
      minPrice: $minPrice
      maxFeedbackRate: $maxFeedbackRate
      minItemAsDescribed: $minItemAsDescribed
      maxItemAsDescribed: $maxItemAsDescribed
      minShippingSpeed: $minShippingSpeed
      maxShippingSpeed: $maxShippingSpeed
      minCommunication: $minCommunication
      maxCommunication: $maxCommunication
      shipFromUS: $shipFromUs
      shipDay: $shipDay
      isFreeShip: $isFreeShip
      hasVideo: $hasVideo
      IP: $IP
      sessionID: $sessionID
      pageSize: $pageSize
      pageIndex: $pageIndex
      minReviewScore: $minReviewScore
      maxReviewScore: $maxReviewScore
    ) {
      status
      result {
        id
        title
        numberSold
        reviewScore
        thumbnailSource
        price
        productLink
        feedbackScore
        itemAsDescribed
        shipSpeed
        communication
        images
        videoLink
        minSalePrice
        maxSalePrice
        minOriginalPrice
        maxOriginalPrice
        currency
        productRating
        discount
        isFreeShip
      }
      maximum
      savedList
      message
    }
  }
`;

export default function CardList(props) {
  const {
    category,
    sortBy,
    minPrice,
    maxPrice,
    titleSearch,
    currentPageIndex,
    currentCategory
  } = props;
  const navigate = useNavigate();
  const { state, setState } = React.useContext(Context);
  const { sessionID, IP, sendLog, useID } = state;
  const pageSize = 48;
  const [pageIndex, setPageIndex] = useState(currentPageIndex != null ? currentPageIndex : 1);
  const [initialize, setInitialize] = useState(true);
  const { loading: statusLoading, data: statusData, refetch: statusRefetch } = useQuery(GET_SUBSCRIPTION_STATUS);
  const [emptyProductCardList, setEmptyProductCardList] = useState([]);

  const { loading, data, refetch } = useQuery(NEW_GET_PRODUCTS, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  const searchProduct = () => {
    let variables = {
      category,
      sortBy,
      minPrice: castStringtoFloat(minPrice),
      maxPrice: castStringtoFloat(maxPrice),
      titleSearch,
      IP,
      sessionID,
      pageSize,
      pageIndex,
    };
    refetch(variables);
  };

  useEffect(searchProduct, [pageIndex]);
  useEffect(() => {
    setPageIndex(initialize === true ? currentPageIndex : 1);
    searchProduct();
    setState({ ...state, title: titleSearch });
    navigate(`/app?pageIndex=${initialize === true ? currentPageIndex : 1}&search=${titleSearch != null ? titleSearch : ""}&category=${category}&sortBy=${sortBy}&minPrice=${minPrice}&maxPrice=${maxPrice}&currentCategory=${currentCategory}`);
  }, [
    category,
    sortBy,
    minPrice,
    maxPrice,
    titleSearch,
  ]);

  useEffect(() => {
    if (
      loading === false &&
      data != null &&
      data.newSearchProducts != null &&
      data.newSearchProducts.result != null &&
      initialize === true) {
      const productListWrapElement = document.querySelector('#product_list_wrap');
      const currentProductNumberARow = productListWrapElement != null ? Math.floor((productListWrapElement.offsetWidth + 20) / 300) : 4;
      const midArr = [];
      for (let i = (data.newSearchProducts.result.length % currentProductNumberARow != 0 ? data.newSearchProducts.result.length % currentProductNumberARow : currentProductNumberARow); i < currentProductNumberARow; i++) {
        midArr.push(<Box sx={{ width: "280px", height: "450px" }} key={"empty" + i}></Box>)
      }
      setEmptyProductCardList(midArr);
      setInitialize(false);
    }
  }, [loading])
  window.onresize = function () {
    const productListWrapElement = document.querySelector('#product_list_wrap');
    const currentProductNumberARow = productListWrapElement != null ? Math.floor((productListWrapElement.offsetWidth + 20) / 300) : 4;
    const midArr = [];
    for (let i = (data.newSearchProducts.result.length % currentProductNumberARow != 0 ? data.newSearchProducts.result.length % currentProductNumberARow : currentProductNumberARow); i < currentProductNumberARow; i++) {
      midArr.push(<Box sx={{ width: "280px", height: "450px" }} key={"empty" + i}></Box>)
    }
    setEmptyProductCardList(midArr);
  };

  if (
    loading == null ||
    loading === true ||
    data == null ||
    data.newSearchProducts == null ||
    data.newSearchProducts.result == null
  ) {
    return (
      <>
        <Box
          sx={{
            height: "40rem",
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <CircularProgress />
          <Box sx={{
            width: '100%',
            marginTop: "5rem",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: '600',
            fontSize: '18px',
            color: '#666666',
            letterSpacing: '0.15px'
          }}>
            Don’t see the product you’re looking for?
            <Box sx={{
              width: '26.5rem',
              height: '6.9rem',
              marginLeft: '2.9rem',
              background: '#FF7D45',
              borderRadius: '0.5rem',
              color: '#FFF',
              cursor: 'pointer'
            }} onClick={() => {
              setState({
                ...state,
                isOpenImportAliExpressURLDialog: true
              })
            }}>
              <Box sx={{
                width: '26.5rem',
                height: '6.9rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '0.5rem',
                background: 'url("/img/ImportAliExpressURL_button_bg_circle.png") no-repeat 1.5rem 0',
                backgroundSize: 'cover',
              }}>Import By URL</Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  } else {
    let maxPageCount = 175;
    if (data.newSearchProducts.maximum != null) {
      maxPageCount = data.newSearchProducts.maximum;
    }
    return (
      <>
        {/* <Grid container columnSpacing={"2rem"} sx={{ marginTop: "3rem" }}>
          {data.newSearchProducts.result.map(
            (
              {
                id,
                subCatogery,
                title,
                numberSold,
                reviewScore,
                thumbnailSource,
                priceCurrency,
                price,
                productLink,
                itemAsDescribed,
                shipSpeed,
                feedbackScore,
                images,
                videoLink,
                communication,
                minSalePrice,
                maxSalePrice,
                minOriginalPrice,
                maxOriginalPrice,
                currency
              },
              index
            ) => {
              // Filter out broken title and broken img.
              if (
                title.length > 5 &&
                !BLACK_LIST_CRAWLER_IDS.has(id)
              ) {
                return (
                  <Grid key={index} xs={3} item sx={{ marginBottom: "2rem" }}>
                    <ProductCard
                      sessionID={sessionID}
                      subCatogery={subCatogery}
                      title={title}
                      numberSold={numberSold}
                      reviewScore={reviewScore}
                      thumbnailSource={thumbnailSource}
                      priceCurrency={priceCurrency}
                      price={price}
                      productLink={productLink}
                      itemAsDescribed={itemAsDescribed}
                      shipSpeed={shipSpeed}
                      feedbackScore={feedbackScore}
                      IP={IP}
                      id={id}
                      isSaved={false}
                      images={images}
                      videoLink={videoLink}
                      communication={communication}
                      minSalePrice={minSalePrice}
                      maxSalePrice={maxSalePrice}
                      minOriginalPrice={minOriginalPrice}
                      maxOriginalPrice={maxOriginalPrice}
                      currency={currency}
                      isShowCartifindScore={FreeShowProductList.includes(id) ? true : (statusLoading == true || statusData == null || statusData.getSubscriptionStatus === "Free" ? false : true)}
                    />
                  </Grid>
                );
              }
              return <div key={index} />;
            }
          )}
        </Grid> */}

        <Box sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
          marginTop: '20px',
          minHeight: "200px",
          gap: '20px',
        }} id="product_list_wrap">
          {data.newSearchProducts.result.map(
            (
              {
                id,
                subCatogery,
                title,
                numberSold,
                reviewScore,
                thumbnailSource,
                priceCurrency,
                price,
                productLink,
                itemAsDescribed,
                shipSpeed,
                feedbackScore,
                images,
                videoLink,
                communication,
                minSalePrice,
                maxSalePrice,
                minOriginalPrice,
                maxOriginalPrice,
                currency,
                productRating,
                discount,
                isFreeShip
              },
              index
            ) => {
              return (
                <ProductCard
                  key={index}
                  sessionID={sessionID}
                  subCatogery={subCatogery}
                  title={title}
                  numberSold={numberSold}
                  discount={discount}
                  isFreeShip={isFreeShip}
                  productRating={productRating}
                  reviewScore={reviewScore}
                  thumbnailSource={thumbnailSource}
                  priceCurrency={priceCurrency}
                  price={price}
                  productLink={productLink}
                  itemAsDescribed={itemAsDescribed}
                  shipSpeed={shipSpeed}
                  feedbackScore={feedbackScore}
                  IP={IP}
                  id={id}
                  index={index}
                  isSaved={data.newSearchProducts.savedList != null ? data.newSearchProducts.savedList.includes(id) : false}
                  images={images}
                  videoLink={videoLink}
                  communication={communication}
                  minSalePrice={minSalePrice}
                  maxSalePrice={maxSalePrice}
                  minOriginalPrice={minOriginalPrice}
                  maxOriginalPrice={maxOriginalPrice}
                  currency={currency}
                  isShowCartifindScore={FreeShowProductList.includes(id) ? true : (statusLoading == true || statusData == null || statusData.getSubscriptionStatus === "Free" ? false : true)}
                />
              );
            }
          )}
          {
            emptyProductCardList.map(item => {
              return item;
            })
          }
        </Box>

        <Box sx={{
          width: '100%',
          marginTop: '6rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: '600',
          fontSize: '18px',
          color: '#666666',
          letterSpacing: '0.15px'
        }}>
          Don’t see the product you’re looking for?
          <Box sx={{
            width: '26.5rem',
            height: '6.9rem',
            marginLeft: '2.9rem',
            background: '#FF7D45',
            borderRadius: '0.5rem',
            color: '#FFF',
            cursor: 'pointer'
          }} onClick={() => {
            setState({
              ...state,
              isOpenImportAliExpressURLDialog: true
            })
          }}>
            <Box sx={{
              width: '26.5rem',
              height: '6.9rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0.5rem',
              background: 'url("/img/ImportAliExpressURL_button_bg_circle.png") no-repeat 1.5rem 0',
              backgroundSize: 'cover',
            }}>Import By URL</Box>
          </Box>
        </Box>
        {maxPageCount !== 0 ? (
          <Box
            sx={{
              margin: "4rem 0 4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={maxPageCount}
              sx={{
                "& .MuiButtonBase-root": {
                  fontSize: "20px",
                  border: "0.2rem solid #999999",
                  color: "#999999",
                  width: "5rem",
                  height: "5rem",
                  "&.Mui-selected": {
                    backgroundColor: "#FF9711",
                    color: "#FFF",
                    border: "0.2rem solid #FF9711",
                    "&:hover": {
                      backgroundColor: "#FF9711",
                    },
                  },
                },
                "& .MuiPaginationItem-previousNext svg": {
                  fontSize: "36px",
                },
              }}
              variant="outlined"
              shape="rounded"
              page={pageIndex}
              onChange={(event, value) => {
                navigate(`/app?pageIndex=${value}&search=${titleSearch != null ? titleSearch : ""}&category=${category}&sortBy=${sortBy}&minPrice=${minPrice}&maxPrice=${maxPrice}`);
                sendLog("PAGINATION_INDEX_CLICK", String(value));
                setPageIndex(parseInt(value));
                document.documentElement.scrollTop = 0;
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              margin: "4rem 0 4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={1}
              sx={{
                "& .MuiButtonBase-root": {
                  fontSize: "20px",
                  border: "0.2rem solid #999999",
                  color: "#999999",
                  width: "5rem",
                  height: "5rem",
                  "&.Mui-selected": {
                    backgroundColor: "#FF9711",
                    color: "#FFF",
                    border: "0.2rem solid #FF9711",
                    "&:hover": {
                      backgroundColor: "#FF9711",
                    },
                  },
                },
                "& .MuiPaginationItem-previousNext svg": {
                  fontSize: "36px",
                },
              }}
              variant="outlined"
              shape="rounded"
              page={pageIndex}
              onChange={(event, value) => {
                navigate(`/app?pageIndex=${value}&search=${titleSearch != null ? titleSearch : ""}&category=${category}&sortBy=${sortBy}&minPrice=${minPrice}&maxPrice=${maxPrice}`);
                sendLog("PAGINATION_INDEX_CLICK", String(value));
                setPageIndex(parseInt(value));
                document.documentElement.scrollTop = 0;
              }}
            />
          </Box>
        )}
      </>
    );
  }
}

function _castStringtoInt(currentString) {
  if (currentString === "") {
    return null;
  }
  return parseInt(currentString);
}

function castStringtoFloat(currentString) {
  if (currentString === "") {
    return null;
  }
  return parseFloat(currentString);
}
