// @flow
import React from 'react';
import AppHeader from './AppHeader.react';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import AppFooter from "./AppFooter.react";
import { useEffect, useContext, useState, useRef } from 'react';
import AppSidebar from "./AppSidebar.react";
import { Card } from 'antd';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Context } from "./Context/Context";
const { Meta } = Card;
// import Card from '@mui/material/Card';

const TutorialList = [
  {
    id: "1",
    title: "01. Import by URL",
    description: "Easy, and simple way to import products to Cartifind. (For Temu, check out Chrome Extension)",
    src: "/img/tutorial/part1.mp4",
    controls: false,
    poster: "/img/tutorial/tutorial_part1_poster.jpg",
  },
  {
    id: "2",
    title: "02. Chrome Extension",
    description: "The most reliable, and efficient way to import products to Cartifind, especially for TEMU.",
    src: "/img/tutorial/part2.mp4",
    controls: false,
    poster: "/img/tutorial/tutorial_part2_poster.jpg",
  },
  {
    id: "3",
    title: "03. My Products",
    description: "Edit the product title, description, price, etc. and push the product to the Shopify store.",
    src: "/img/tutorial/part3.mp4",
    controls: false,
    poster: "/img/tutorial/tutorial_part3_poster.jpg",
  },
  {
    id: "4",
    title: "04. My Orders",
    description: "Manage, and fulfill product orders with various shipping options, and locate the order tracking URLs.",
    src: "/img/tutorial/part4.mp4",
    controls: false,
    poster: "/img/tutorial/tutorial_part4_poster.jpg",
  },
  {
    id: "5",
    title: "05. Settings",
    description: "Manage currency, pricing rule, and your subscription plan with Cartifind.",
    src: "/img/tutorial/part5.mp4",
    controls: false,
    poster: "/img/tutorial/tutorial_part5_poster.jpg",
  },
]

export default function Tutorial() {
  const [currentTutorialList, setCurrentTutorialList] = useState(TutorialList);
  const { state, setState } = useContext(Context);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <AppHeader />
      <AppSidebar />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
        <Box sx={{ display: "flex", width: "auto", justifyContent: 'center', alignItems: "center", position: 'relative' }}>
          <Box sx={{
            width: "100%",
            padding: '0px 18px',
            boxSizing: "border-box",
            "& .white_wrap": {
              borderRadius: "4px",
              boxShadow: "0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F",
              background: "#FFF",
              widthL: "100%",
              height: "auto",
              marginTop: "20px",
              "& .MuiPaper-root.MuiPaper-elevation.MuiAccordion-root": {
                backgroundColor: 'transparent',
              }
            },
            "& .title": {
              fontSize: "24px",
              lineHeight: "32px",
              fontWeight: "500",
              color: "#000000DE"
            },
            "& .question": {
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "400",
              letterSpacing: "0.17px",
              color: "#000000DE"
            },
            "& .answer": {
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "400",
              letterSpacing: "0.17px",
              color: "#00000099",
              paddingLeft: "17px"
            }
          }}>
            <Box className='white_wrap'>
              <Box sx={{
                padding: "12px 16px"
              }}>
                <Box sx={{
                  fontSize: "24px",
                  lineHeight: "32px",
                  fontWeight: "600",
                  color: "#000000DE"
                }}>Tutorials</Box>
                <Box sx={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "400",
                  letterSpacing: "0.17px",
                  color: "#00000099"
                }}>Step-by-step how to use Cartifind</Box>
              </Box>
            </Box>
            <Box sx={{
              display: "flex",
              justifyContent: "center",
              "& .ant-card-body": {
                padding: "0px"
              }
            }}>
              <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                marginTop: '20px',
                minHeight: "200px",
                gap: '20px',
                // justifyContent: "space-between",
                "& .item": {
                  width: "330px",
                  height: '247px',
                  background: "#FFF",
                  borderRadius: "4px"
                }
              }}>
                {currentTutorialList.map((tutorial, index) => {
                  return <Card
                    key={index}
                    style={{
                      width: "330px",
                      padding: "0px",
                      boxShadow: "0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F"
                    }}
                    cover={<Box sx={{ width: "330px", height: "186px", cursor: "pointer", background: `url("${tutorial.poster}") no-repeat 0 0`, backgroundSize: 'contain', borderRadius: "8px 8px 0px 0px", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => {
                      setState({
                        ...state,
                        isExplanatoryVideoDialogOpen: true,
                        explanatoryVideoSrc: tutorial.src
                      })
                    }}>
                      <Box sx={{ width: "330px", height: "186px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Box sx={{ width: '50px', height: '50px', background: 'url("/img/icon_product_slide_video_player.png") no-repeat 0 0', backgroundSize: 'contain', cursor: 'pointer' }} />
                      </Box>
                    </Box>}>
                    <Box sx={{ padding: "12px 16px 24px" }}>
                      <Box sx={{
                        fontSize: "14px",
                        lineHeight: "14px",
                        fontWeight: "700",
                        color: "#000000DE",
                      }}>{tutorial.title}</Box>
                      <Box sx={{
                        fontSize: "14px",
                        lineHeight: "14px",
                        fontWeight: "400",
                        color: "#00000099",
                        marginTop: '6px'
                      }}>Cartifind team</Box>
                    </Box>
                  </Card>
                })}
              </Box>
            </Box>
            <Box sx={{ width: "1px", height: "200px" }}></Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

function LinkCommponent() {
  const navigate = useNavigate();
  return <Typography variant="span"
    sx={{
      cursor: 'pointer',
      textDecoration: 'underline'
    }}
    onClick={() => {
      navigate('/');
    }}>www.cartifind.com</Typography>;
}

