import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import { Context } from "../Context/Context";

export default function MediaContent(props) {
  const { editedImages, uneditedImages, setEphemeralImages, shopifyMedia, isSelectVideo, setIsSelectVideo } = props;
  const { state, setState } = React.useContext(Context);
  const { sendLog, imagesIsWrong } = state;
  const imageList = [];
  const selectedIndexArr = [];
  uneditedImages.map((item) => {
    imageList.push(item.src);
    selectedIndexArr.push({
      src: item.src,
      selected: false,
    });
  });
  editedImages.map((item) => {
    if (selectedIndexArr[imageList.indexOf(item.src)] != null) {
      //下面报错
      selectedIndexArr[imageList.indexOf(item.src)].selected = true;
    }
  });
  const [selectedIndexList, setSelectedIndexList] =
    React.useState(selectedIndexArr);
  const [checkedAll, setCheckedAll] = React.useState(
    editedImages.length >= uneditedImages.length && isSelectVideo
  );

  const onImageClick = (index) => {
    sendLog("MY_PRODUCTS_CARD_DIALOG_MEDIA_IMAGE_SELECT");
    const midArr = selectedIndexList;
    midArr[index].selected = !midArr[index].selected;
    setSelectedIndexList(midArr);
    const resultImages = selectedIndexList
      .map((item, index) => {
        if (item.selected) {
          return uneditedImages[index];
        }
      })
      .filter((res) => {
        return res !== undefined;
      });
    setCheckedAll(resultImages.length >= uneditedImages.length && isSelectVideo);
    setEphemeralImages(resultImages);
  };
  return (
    <>
      <Box
        sx={{
          width: "1146px",
          height: "46px",
          backgroundColor: "rgba(255,144,0,0.2)",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxSizing: "border-box",
          paddingRight: "20px",
          fontFamily: "Arial",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "20px",
          letterSpacing: "0.15px",
          color: "#707070",
          lineHeight: "150%",
        }}
      >
        <Box>
          <Checkbox
            sx={{
              color: "#707070",
              "&.Mui-checked": {
                color: "#FF9711",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "30px",
              },
            }}
            checked={checkedAll}
            onChange={(e) => {
              sendLog("MY_PRODUCTS_CARD_DIALOG_MEDIA_IMAGE_SELECT");
              const midArr = uneditedImages.map((item) => {
                return {
                  src: item.src,
                  selected: e.target.checked,
                };
              });
              setSelectedIndexList(midArr);
              setCheckedAll(e.target.checked);
              setIsSelectVideo(e.target.checked);
              setEphemeralImages(e.target.checked ? uneditedImages : []);
              setState({
                ...state,
                imagesIsWrong: !e.target.checked
              });
            }}
            inputProps={{
              "aria-label": "checked All",
            }}
          />
          All
        </Box>
        <Box
          sx={{
            width: "153px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => {
            sendLog("MY_PRODUCTS_CARD_DIALOG_MEDIA_IMAGE_DOWNLOAD");
            selectedIndexList.map((item, index) => {
              if (item.selected) {
                handlerDown(
                  uneditedImages[index].src,
                  uneditedImages[index].altText
                );
              }
            });
          }}
        >
          <Box sx={{ width: "23px", height: "23px", lineHeight: "23px" }}>
            <img src="/img/icon_download.png" width="100%" height="100%" />
          </Box>
          <Box>DownLoad</Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{
          width: "97%",
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: '20px',
        }}>
          {shopifyMedia.map((item, index) => {
            return <Box sx={{
              width: '260px',
              height: 'auto',
              maxHeight: '306px',
              margin: '0 12px 20px 0',
              border: isSelectVideo
                ? "4px solid #FF9711"
                : "4px solid transparent",
              boxSizing: "border-box",
              overflow: 'hidden',
              position: 'relative',
              cursor: 'pointer'
            }} key={index}>
              <video loop muted controls style={{ "borderRadius": '10px' }} width={"100%"} height="100%">
                <source src={item.url} />
              </video>
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  left: "15px",
                  top: "15px",
                }}
              >
                <Checkbox
                  sx={{
                    color: "#707070",
                    "&.Mui-checked": {
                      color: "#FF9711",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "30px",
                    },
                  }}
                  checked={isSelectVideo}
                  onClick={(e) => {
                    e.stopPropagation();
                    const resultImages = selectedIndexList
                      .map((item, index) => {
                        if (item.selected) {
                          return uneditedImages[index];
                        }
                      })
                      .filter((res) => {
                        return res !== undefined;
                      });
                    setCheckedAll(resultImages.length >= uneditedImages.length && !isSelectVideo);
                    setIsSelectVideo(!isSelectVideo);
                  }}
                  inputProps={{
                    "aria-label": "controlled",
                  }}
                />
              </Box>
            </Box>
          })}
          {uneditedImages.map((item, index) => {
            return <Box sx={{
              width: '260px',
              height: 'auto',
              maxHeight: '306px',
              margin: '0 12px 20px 0',
              border: selectedIndexList[index].selected
                ? "4px solid #FF9711"
                : "4px solid transparent",
              boxSizing: "border-box",
              overflow: 'hidden',
              position: 'relative',
              cursor: 'pointer'
            }} onClick={() => {
              onImageClick(index);
            }} key={index}>
              <img
                src={item.src}
                loading="lazy"
                width={'100%'}
              /><Box
                sx={{
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  left: "15px",
                  top: "15px",
                }}
              >
                <Checkbox
                  sx={{
                    color: "#707070",
                    "&.Mui-checked": {
                      color: "#FF9711",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "3.2rem",
                    },
                  }}
                  checked={selectedIndexList[index].selected}
                  onClick={(e) => {
                    e.stopPropagation();
                    onImageClick(index);
                  }}
                  inputProps={{
                    "aria-label": "controlled",
                  }}
                />
              </Box>
            </Box>
          })}
        </Box>
      </Box>
    </>
  );
}


const handlerDown = (url, name) => {
  const getFileTypeFromUrl = (url) => {
    const match = url.match(/\.([a-z0-9]+)(\?.*)?$/i);
    return match ? match[1] : 'png'; // 默认使用 'png'
  };

  const fileType = getFileTypeFromUrl(url);
  // 创建一个新的图像对象
  const img = new Image();

  // 设置图像的跨域属性
  img.crossOrigin = 'anonymous';

  img.onload = () => {
    // 创建一个 canvas 元素
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // 设置 canvas 的宽高
    canvas.width = img.width;
    canvas.height = img.height;

    // 将图像绘制到 canvas 上
    ctx.drawImage(img, 0, 0);

    // 将 canvas 转为数据 URL
    canvas.toBlob((blob) => {
      // 创建一个下载链接
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${name}.${fileType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  img.src = url; // 触发图像加载
};
