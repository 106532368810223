/** @format */

import React from "react";
import "./App.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState, lazy, useEffect } from "react";
import { Context } from "./Context/Context";
import { gql, useMutation } from "@apollo/client";

const AppHeader = lazy(() => import("./AppHeader.react"));
const AppSidebar = lazy(() => import("./AppSidebar.react"));

const FULFILLED_USER_WITHDRAWN = gql`
  mutation fulfilledUserWithdrawn($userID: String, $email: String) {
    fulfilledUserWithdrawn(userID: $userID, email: $email){
      status
      message
    }
  }
`;

export default function AdminPage() {
  const { setState, state } = React.useContext(Context);
  const { userID } = state;
  const [currentUserID, setCurrentUserID] = useState("");
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [fulfilled_user_withdrawn] = useMutation(FULFILLED_USER_WITHDRAWN);
  if (userID == null) {
    return <Box></Box>;
  }
  return (
    <>
      <div className="App">
        <AppHeader />
        <AppSidebar />
        <Box sx={{
          display: "flex",
          "& .card": {
            width: '100%',
            backgroundColor: "#FFF",
            borderRadius: '4px',
            boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
            marginBottom: '20px',
            boxSizing: 'border-box',
            padding: "16px 0px"
          }
        }}>
          <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
          <Box sx={{ display: "flex", width: "100%", justifyContent: 'center', position: 'relative' }}>
            <Box>
              <Box className="card" sx={{ padding: "0px!important", marginTop: "20px" }}>
                <Box sx={{
                  height: '32px',
                  padding: '16px 24px',
                  fontSize: "18px",
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: "160%",
                  letterSpacing: '0.15px',
                  textAlign: "center"
                }}>
                  Complete User Withdrawal
                </Box>
                <Box sx={{
                  fontSize: "14px",
                  fontStyle: 'normal',
                  lineHeight: "143%",
                  padding: '16px 24px',
                  letterSpacing: '0.17px',
                }}>
                  <Box sx={{ display: "flex", alignItems: 'center', paddingBottom: "20px" }}>
                    <Box sx={{ width: "200px", fontWeight: '700', }}>userID:</Box>
                    <Box sx={{
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: '4px'
                    }}>
                      <TextField
                        sx={{
                          paddingRight: "0",
                          backgroundColor: "#fff",
                          border: 'none',
                          fontSize: "14px",
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: "24px",
                          letterSpacing: '0.15px',
                          color: "rgba(0, 0, 0, 0.6)",
                          borderColor: "transparent",
                          borderRadius: '10px',
                          width: "300px",
                          "& fieldset": {
                            borderColor: "transparent!important",
                            borderWidth: '1px',
                            "&.Mui-focused": {
                              borderColor: "transparent!important",
                              borderWidth: '1px',
                            },
                          },
                          "& .MuiInputBase-root.MuiOutlinedInput-root": {
                            padding: "0px",
                            fontSize: '14px'
                          },
                          "& .MuiInputBase-input.MuiOutlinedInput-input ": {
                            padding: "6px 10px",
                            fontSize: '14px'
                          }
                        }}
                        id="outlined-multiline-static-Tracking-Number"
                        placeholder="Enter the user id"
                        value={currentUserID}
                        onChange={(e) => {
                          setCurrentUserID(e.target.value);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: 'center', paddingBottom: "20px" }}>
                    <Box sx={{ width: "200px", fontWeight: '700', }}>email:</Box>
                    <Box sx={{
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: '4px'
                    }}>
                      <TextField
                        sx={{
                          paddingRight: "0",
                          backgroundColor: "#fff",
                          border: 'none',
                          fontSize: "14px",
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: "24px",
                          letterSpacing: '0.15px',
                          color: "rgba(0, 0, 0, 0.6)",
                          borderColor: "transparent",
                          borderRadius: '10px',
                          width: "300px",
                          "& fieldset": {
                            borderColor: "transparent!important",
                            borderWidth: '1px',
                            "&.Mui-focused": {
                              borderColor: "transparent!important",
                              borderWidth: '1px',
                            },
                          },
                          "& .MuiInputBase-root.MuiOutlinedInput-root": {
                            padding: "0px",
                            fontSize: '14px'
                          },
                          "& .MuiInputBase-input.MuiOutlinedInput-input ": {
                            padding: "6px 10px",
                            fontSize: '14px'
                          }
                        }}
                        id="outlined-multiline-static-Tracking-Number"
                        placeholder="Enter the user email"
                        value={currentUserEmail}
                        onChange={(e) => {
                          setCurrentUserEmail(e.target.value);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}>
                    <Box sx={{
                      padding: "6px 10px",
                      borderRadius: "4px",
                      fontFamily: "Arial",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "150%",
                      letterSpacing: "0.15px",
                      cursor: "pointer",
                      marginLeft: "15px",
                      color: "#FFF",
                      backgroundColor: "#FF9711",
                      boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.25)'
                    }} onClick={() => {
                      if (currentUserID == null || currentUserID.length == 0) {
                        setState({
                          ...state,
                          openSnackbar: true,
                          snackbarText: `Please enter the user ID`,
                          snackbarState: 'FAILED',
                        });
                        return;
                      }
                      if (currentUserEmail == null || currentUserEmail.length == 0) {
                        setState({
                          ...state,
                          openSnackbar: true,
                          snackbarText: `Please enter the user email`,
                          snackbarState: 'FAILED',
                        });
                        return;
                      }
                      fulfilled_user_withdrawn({
                        variables: {
                          userID: currentUserID,
                          email: currentUserEmail
                        }
                      }).then(res => {
                        if (res == null || res.data == null || res.data.fulfilledUserWithdrawn == null) {
                          setState({
                            ...state,
                            openSnackbar: true,
                            snackbarText: `Something Wrong !`,
                            snackbarState: 'FAILED',
                          });
                        } else if (!res.data.fulfilledUserWithdrawn.status) {
                          setState({
                            ...state,
                            openSnackbar: true,
                            snackbarText: res.data.fulfilledUserWithdrawn.message != null ? res.data.fulfilledUserWithdrawn.message : `Something Wrong !`,
                            snackbarState: 'FAILED',
                          });
                        } else {
                          setState({
                            ...state,
                            openSnackbar: true,
                            snackbarText: "Fulflled Success !",
                            snackbarState: 'SUCCESS',
                          });
                          console.log("提现完成")
                          setCurrentUserID("");
                          setCurrentUserEmail("");
                        }
                      })
                    }}>Fulfilled</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
}
