export const currencyList = [
    {
        option: "AED",
        value: "AED"
    },
    {
        option: "AFN",
        value: "AFN"
    },
    {
        option: "ALL",
        value: "ALL"
    },
    {
        option: "AMD",
        value: "AMD"
    },
    {
        option: "ANG",
        value: "ANG"
    },
    {
        option: "AOA",
        value: "AOA"
    },
    {
        option: "ARS",
        value: "ARS"
    },
    {
        option: "AUD",
        value: "AUD"
    },
    {
        option: "AWG",
        value: "AWG"
    },
    {
        option: "AZN",
        value: "AZN"
    },
    {
        option: "BAM",
        value: "BAM"
    },
    {
        option: "BBD",
        value: "BBD"
    },
    {
        option: "BDT",
        value: "BDT"
    },
    {
        option: "BGN",
        value: "BGN"
    },
    {
        option: "BHD",
        value: "BHD"
    },
    {
        option: "BIF",
        value: "BIF"
    },
    {
        option: "BMD",
        value: "BMD"
    },
    {
        option: "BND",
        value: "BND"
    },
    {
        option: "BOB",
        value: "BOB"
    },
    {
        option: "BRL",
        value: "BRL"
    },
    {
        option: "BSD",
        value: "BSD"
    },
    {
        option: "BTN",
        value: "BTN"
    },
    {
        option: "BWP",
        value: "BWP"
    },
    {
        option: "BYN",
        value: "BYN"
    },
    {
        option: "BZD",
        value: "BZD"
    },
    {
        option: "CAD",
        value: "CAD"
    },
    {
        option: "CDF",
        value: "CDF"
    },
    {
        option: "CHF",
        value: "CHF"
    },
    {
        option: "CLP",
        value: "CLP"
    },
    {
        option: "CNY",
        value: "CNY"
    },
    {
        option: "COP",
        value: "COP"
    },
    {
        option: "CRC",
        value: "CRC"
    },
    {
        option: "CVE",
        value: "CVE"
    },
    {
        option: "CZK",
        value: "CZK"
    },
    {
        option: "DJF",
        value: "DJF"
    },
    {
        option: "DKK",
        value: "DKK"
    },
    {
        option: "DOP",
        value: "DOP"
    },
    {
        option: "DZD",
        value: "DZD"
    },
    {
        option: "EGP",
        value: "EGP"
    },
    {
        option: "ERN",
        value: "ERN"
    },
    {
        option: "ETB",
        value: "ETB"
    },
    {
        option: "EUR",
        value: "EUR"
    },
    {
        option: "FJD",
        value: "FJD"
    },
    {
        option: "FKP",
        value: "FKP"
    },
    {
        option: "GBP",
        value: "GBP"
    },
    {
        option: "GEL",
        value: "GEL"
    },
    {
        option: "GHS",
        value: "GHS"
    },
    {
        option: "GIP",
        value: "GIP"
    },
    {
        option: "GMD",
        value: "GMD"
    },
    {
        option: "GNF",
        value: "GNF"
    },
    {
        option: "GTQ",
        value: "GTQ"
    },
    {
        option: "GYD",
        value: "GYD"
    },
    {
        option: "HKD",
        value: "HKD"
    },
    {
        option: "HNL",
        value: "HNL"
    },
    {
        option: "HRK",
        value: "HRK"
    },
    {
        option: "HTG",
        value: "HTG"
    },
    {
        option: "HUF",
        value: "HUF"
    },
    {
        option: "IDR",
        value: "IDR"
    },
    {
        option: "ILS",
        value: "ILS"
    },
    {
        option: "INR",
        value: "INR"
    },
    {
        option: "IQD",
        value: "IQD"
    },
    {
        option: "IRR",
        value: "IRR"
    },
    {
        option: "ISK",
        value: "ISK"
    },
    {
        option: "JEP",
        value: "JEP"
    },
    {
        option: "JMD",
        value: "JMD"
    },
    {
        option: "JOD",
        value: "JOD"
    },
    {
        option: "JPY",
        value: "JPY"
    },
    {
        option: "KES",
        value: "KES"
    },
    {
        option: "KGS",
        value: "KGS"
    },
    {
        option: "KHR",
        value: "KHR"
    },
    // {
    //     option: "KID",
    //     value: "KID"
    // },
    {
        option: "KMF",
        value: "KMF"
    },
    {
        option: "KRW",
        value: "KRW"
    },
    {
        option: "KWD",
        value: "KWD"
    },
    {
        option: "KYD",
        value: "KYD"
    },
    {
        option: "KZT",
        value: "KZT"
    },
    {
        option: "LAK",
        value: "LAK"
    },
    {
        option: "LBP",
        value: "LBP"
    },
    {
        option: "LKR",
        value: "LKR"
    },
    {
        option: "LRD",
        value: "LRD"
    },
    {
        option: "LSL",
        value: "LSL"
    },
    {
        option: "LTL",
        value: "LTL"
    },
    {
        option: "LVL",
        value: "LVL"
    },
    {
        option: "LYD",
        value: "LYD"
    },
    {
        option: "MAD",
        value: "MAD"
    },
    {
        option: "MDL",
        value: "MDL"
    },
    {
        option: "MGA",
        value: "MGA"
    },
    {
        option: "MKD",
        value: "MKD"
    },
    {
        option: "MMK",
        value: "MMK"
    },
    {
        option: "MNT",
        value: "MNT"
    },
    {
        option: "MOP",
        value: "MOP"
    },
    {
        option: "MRU",
        value: "MRU"
    },
    {
        option: "MUR",
        value: "MUR"
    },
    {
        option: "MVR",
        value: "MVR"
    },
    {
        option: "MWK",
        value: "MWK"
    },
    {
        option: "MXN",
        value: "MXN"
    },
    {
        option: "MYR",
        value: "MYR"
    },
    {
        option: "MZN",
        value: "MZN"
    },
    {
        option: "NAD",
        value: "NAD"
    },
    {
        option: "NGN",
        value: "NGN"
    },
    {
        option: "NIO",
        value: "NIO"
    },
    {
        option: "NOK",
        value: "NOK"
    },
    {
        option: "NPR",
        value: "NPR"
    },
    {
        option: "NZD",
        value: "NZD"
    },
    {
        option: "OMR",
        value: "OMR"
    },
    {
        option: "PAB",
        value: "PAB"
    },
    {
        option: "PEN",
        value: "PEN"
    },
    {
        option: "PGK",
        value: "PGK"
    },
    {
        option: "PHP",
        value: "PHP"
    },
    {
        option: "PKR",
        value: "PKR"
    },
    {
        option: "PLN",
        value: "PLN"
    },
    {
        option: "PYG",
        value: "PYG"
    },
    {
        option: "QAR",
        value: "QAR"
    },
    {
        option: "RON",
        value: "RON"
    },
    {
        option: "RSD",
        value: "RSD"
    },
    {
        option: "RUB",
        value: "RUB"
    },
    {
        option: "RWF",
        value: "RWF"
    },
    {
        option: "SAR",
        value: "SAR"
    },
    {
        option: "SBD",
        value: "SBD"
    },
    {
        option: "SCR",
        value: "SCR"
    },
    {
        option: "SDG",
        value: "SDG"
    },
    {
        option: "SEK",
        value: "SEK"
    },
    {
        option: "SGD",
        value: "SGD"
    },
    {
        option: "SHP",
        value: "SHP"
    },
    {
        option: "SLL",
        value: "SLL"
    },
    {
        option: "SOS",
        value: "SOS"
    },
    {
        option: "SRD",
        value: "SRD"
    },
    {
        option: "SSP",
        value: "SSP"
    },
    {
        option: "STN",
        value: "STN"
    },
    {
        option: "SYP",
        value: "SYP"
    },
    {
        option: "SZL",
        value: "SZL"
    },
    {
        option: "THB",
        value: "THB"
    },
    {
        option: "TJS",
        value: "TJS"
    },
    {
        option: "TMT",
        value: "TMT"
    },
    {
        option: "TND",
        value: "TND"
    },
    {
        option: "TOP",
        value: "TOP"
    },
    {
        option: "TRY",
        value: "TRY"
    },
    {
        option: "TTD",
        value: "TTD"
    },
    {
        option: "TWD",
        value: "TWD"
    },
    {
        option: "TZS",
        value: "TZS"
    },
    {
        option: "UAH",
        value: "UAH"
    },
    {
        option: "UGX",
        value: "UGX"
    },
    {
        option: "USD",
        value: "USD"
    },
    {
        option: "UYU",
        value: "UYU"
    },
    {
        option: "UZS",
        value: "UZS"
    },
    {
        option: "VED",
        value: "VED"
    },
    {
        option: "VES",
        value: "VES"
    },
    {
        option: "VND",
        value: "VND"
    },
    {
        option: "VUV",
        value: "VUV"
    },
    {
        option: "WST",
        value: "WST"
    },
    {
        option: "XAF",
        value: "XAF"
    },
    {
        option: "XCD",
        value: "XCD"
    },
    {
        option: "XOF",
        value: "XOF"
    },
    {
        option: "XPF",
        value: "XPF"
    },
    {
        option: "YER",
        value: "YER"
    },
    {
        option: "ZAR",
        value: "ZAR"
    },
    {
        option: "ZMW",
        value: "ZMW"
    }
]
export const supportCurrencyList = ["AED", "AFN", "ALL", "AMD", "ANG", "AOA", "ARS", "AUD", "AWG", "AZN", "BAM", "BBD", "BDT", "BGN", "BHD", "BIF", "BMD", "BND", "BOB", "BRL", "BSD", "BTN", "BWP", "BYN", "BZD", "CAD", "CDF", "CHF", "CLP", "CNY", "COP", "CRC", "CVE", "CZK", "DJF", "DKK", "DOP", "DZD", "EGP", "ERN", "ETB", "EUR", "FJD", "FKP", "GBP", "GEL", "GHS", "GIP", "GMD", "GNF", "GTQ", "GYD", "HKD", "HNL", "HRK", "HTG", "HUF", "IDR", "ILS", "INR", "IQD", "IRR", "ISK", "JEP", "JMD", "JOD", "JPY", "KES", "KGS", "KHR", "KMF", "KRW", "KWD", "KYD", "KZT", "LAK", "LBP", "LKR", "LRD", "LSL", "LTL", "LVL", "LYD", "MAD", "MDL", "MGA", "MKD", "MMK", "MNT", "MOP", "MRU", "MUR", "MVR", "MWK", "MXN", "MYR", "MZN", "NAD", "NGN", "NIO", "NOK", "NPR", "NZD", "OMR", "PAB", "PEN", "PGK", "PHP", "PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF", "SAR", "SBD", "SCR", "SDG", "SEK", "SGD", "SHP", "SLL", "SOS", "SRD", "SSP", "STN", "SYP", "SZL", "THB", "TJS", "TMT", "TND", "TOP", "TRY", "TTD", "TWD", "TZS", "UAH", "UGX", "USD", "UYU", "UZS", "VED", "VES", "VND", "VUV", "WST", "XAF", "XCD", "XOF", "XPF", "YER", "ZAR", "ZMW"]
export const countryList = [
    {
        "option": "Afghanistan",
        "value": "AF"
    },
    {
        "option": "Albania",
        "value": "AL"
    },
    {
        "option": "Algeria",
        "value": "DZ"
    },
    {
        "option": "American Samoa",
        "value": "AS"
    },
    {
        "option": "Andorra",
        "value": "AD"
    },
    {
        "option": "Angola",
        "value": "AO"
    },
    {
        "option": "Anguilla",
        "value": "AI"
    },
    {
        "option": "Antigua and Barbuda",
        "value": "AG"
    },
    {
        "option": "Argentina",
        "value": "AR"
    },
    {
        "option": "Armenia",
        "value": "AM"
    },
    {
        "option": "Aruba",
        "value": "AW"
    },
    {
        "option": "Australia",
        "value": "AU"
    },
    {
        "option": "Austria",
        "value": "AT"
    },
    {
        "option": "Azerbaijan",
        "value": "AZ"
    },
    {
        "option": "Bahamas",
        "value": "BS"
    },
    {
        "option": "Bahrain",
        "value": "BH"
    },
    {
        "option": "Bangladesh",
        "value": "BD"
    },
    {
        "option": "Barbados",
        "value": "BB"
    },
    {
        "option": "Belarus",
        "value": "BY"
    },
    {
        "option": "Belgium",
        "value": "BE"
    },
    {
        "option": "Belize",
        "value": "BZ"
    },
    {
        "option": "Benin",
        "value": "BJ"
    },
    {
        "option": "Bermuda",
        "value": "BM"
    },
    {
        "option": "Bhutan",
        "value": "BT"
    },
    {
        "option": "Bolivia",
        "value": "BO"
    },
    {
        "option": "Botswana",
        "value": "BW"
    },
    {
        "option": "Brazil",
        "value": "BR"
    },
    {
        "option": "Bulgaria",
        "value": "BG"
    },
    {
        "option": "Burkina Faso",
        "value": "BF"
    },
    {
        "option": "Burundi",
        "value": "BI"
    },
    {
        "option": "Cambodia",
        "value": "KH"
    },
    {
        "option": "Cameroon",
        "value": "CM"
    },
    {
        "option": "Canada",
        "value": "CA"
    },
    {
        "option": "Cape Verde",
        "value": "CV"
    },
    {
        "option": "Cayman Islands",
        "value": "KY"
    },
    {
        "option": "Central African Republic",
        "value": "CF"
    },
    {
        "option": "Chad",
        "value": "TD"
    },
    {
        "option": "Chile",
        "value": "CL"
    },
    {
        "option": "Christmas Island",
        "value": "CX"
    },
    {
        "option": "Cocos (Keeling) Islands",
        "value": "CC"
    },
    {
        "option": "Colombia",
        "value": "CO"
    },
    {
        "option": "Comoros",
        "value": "KM"
    },
    {
        "option": "Cook Islands",
        "value": "CK"
    },
    {
        "option": "Costa Rica",
        "value": "CR"
    },
    {
        "option": "Cote D'Ivoire",
        "value": "CI"
    },
    {
        "option": "Croatia (local name: Hrvatska)",
        "value": "HR"
    },
    {
        "option": "Cyprus",
        "value": "CY"
    },
    {
        "option": "Czech Republic",
        "value": "CZ"
    },
    {
        "option": "Denmark",
        "value": "DK"
    },
    {
        "option": "Djibouti",
        "value": "DJ"
    },
    {
        "option": "Dominica",
        "value": "DM"
    },
    {
        "option": "Dominican Republic",
        "value": "DO"
    },
    {
        "option": "Ecuador",
        "value": "EC"
    },
    {
        "option": "Egypt",
        "value": "EG"
    },
    {
        "option": "El Salvador",
        "value": "SV"
    },
    {
        "option": "Equatorial Guinea",
        "value": "GQ"
    },
    {
        "option": "Eritrea",
        "value": "ER"
    },
    {
        "option": "Estonia",
        "value": "EE"
    },
    {
        "option": "Ethiopia",
        "value": "ET"
    },
    {
        "option": "Falkland Islands (Malvinas)",
        "value": "FK"
    },
    {
        "option": "Faroe Islands",
        "value": "FO"
    },
    {
        "option": "Fiji",
        "value": "FJ"
    },
    {
        "option": "Finland",
        "value": "FI"
    },
    {
        "option": "France",
        "value": "FR"
    },
    {
        "option": "French Guiana",
        "value": "GF"
    },
    {
        "option": "French Polynesia",
        "value": "PF"
    },
    {
        "option": "Gabon",
        "value": "GA"
    },
    {
        "option": "Gambia",
        "value": "GM"
    },
    {
        "option": "Georgia",
        "value": "GE"
    },
    {
        "option": "Germany",
        "value": "DE"
    },
    {
        "option": "Ghana",
        "value": "GH"
    },
    {
        "option": "Gibraltar",
        "value": "GI"
    },
    {
        "option": "Greece",
        "value": "GR"
    },
    {
        "option": "Greenland",
        "value": "GL"
    },
    {
        "option": "Grenada",
        "value": "GD"
    },
    {
        "option": "Guadeloupe",
        "value": "GP"
    },
    {
        "option": "Guam",
        "value": "GU"
    },
    {
        "option": "Guatemala",
        "value": "GT"
    },
    {
        "option": "Guinea",
        "value": "GN"
    },
    {
        "option": "Guinea-Bissau",
        "value": "GW"
    },
    {
        "option": "Guyana",
        "value": "GY"
    },
    {
        "option": "Haiti",
        "value": "HT"
    },
    {
        "option": "Honduras",
        "value": "HN"
    },
    {
        "option": "Hungary",
        "value": "HU"
    },
    {
        "option": "Iceland",
        "value": "IS"
    },
    {
        "option": "India",
        "value": "IN"
    },
    {
        "option": "Iraq",
        "value": "IQ"
    },
    {
        "option": "Ireland",
        "value": "IE"
    },
    {
        "option": "Israel",
        "value": "IL"
    },
    {
        "option": "Italy",
        "value": "IT"
    },
    {
        "option": "Jamaica",
        "value": "JM"
    },
    {
        "option": "Japan",
        "value": "JP"
    },
    {
        "option": "Jordan",
        "value": "JO"
    },
    {
        "option": "Kazakhstan",
        "value": "KZ"
    },
    {
        "option": "Kenya",
        "value": "KE"
    },
    {
        "option": "Kiribati",
        "value": "KI"
    },
    {
        "option": "Kuwait",
        "value": "KW"
    },
    {
        "option": "Kyrgyzstan",
        "value": "KG"
    },
    {
        "option": "Lao People's Democratic Republic",
        "value": "LA"
    },
    {
        "option": "Latvia",
        "value": "LV"
    },
    {
        "option": "Lebanon",
        "value": "LB"
    },
    {
        "option": "Lesotho",
        "value": "LS"
    },
    {
        "option": "Liberia",
        "value": "LR"
    },
    {
        "option": "Liechtenstein",
        "value": "LI"
    },
    {
        "option": "Lithuania",
        "value": "LT"
    },
    {
        "option": "Luxembourg",
        "value": "LU"
    },
    {
        "option": "Madagascar",
        "value": "MG"
    },
    {
        "option": "Malawi",
        "value": "MW"
    },
    {
        "option": "Malaysia",
        "value": "MY"
    },
    {
        "option": "Maldives",
        "value": "MV"
    },
    {
        "option": "Mali",
        "value": "ML"
    },
    {
        "option": "Malta",
        "value": "MT"
    },
    {
        "option": "Marshall Islands",
        "value": "MH"
    },
    {
        "option": "Martinique",
        "value": "MQ"
    },
    {
        "option": "Mauritania",
        "value": "MR"
    },
    {
        "option": "Mauritius",
        "value": "MU"
    },
    {
        "option": "Mayotte",
        "value": "YT"
    },
    {
        "option": "Mexico",
        "value": "MX"
    },
    {
        "option": "Monaco",
        "value": "MC"
    },
    {
        "option": "Mongolia",
        "value": "MN"
    },
    {
        "option": "Montserrat",
        "value": "MS"
    },
    {
        "option": "Morocco",
        "value": "MA"
    },
    {
        "option": "Mozambique",
        "value": "MZ"
    },
    {
        "option": "Myanmar",
        "value": "MM"
    },
    {
        "option": "Namibia",
        "value": "NA"
    },
    {
        "option": "Nauru",
        "value": "NR"
    },
    {
        "option": "Nepal",
        "value": "NP"
    },
    {
        "option": "Netherlands",
        "value": "NL"
    },
    {
        "option": "Netherlands Antilles",
        "value": "AN"
    },
    {
        "option": "New Caledonia",
        "value": "NC"
    },
    {
        "option": "New Zealand",
        "value": "NZ"
    },
    {
        "option": "Nicaragua",
        "value": "NI"
    },
    {
        "option": "Niger",
        "value": "NE"
    },
    {
        "option": "Nigeria",
        "value": "NG"
    },
    {
        "option": "Niue",
        "value": "NU"
    },
    {
        "option": "Norfolk Island",
        "value": "NF"
    },
    // {
    //     "option": "North Korea",
    //     "value": "KP"
    // },
    {
        "option": "Northern Mariana Islands",
        "value": "MP"
    },
    {
        "option": "Norway",
        "value": "NO"
    },
    {
        "option": "Oman",
        "value": "OM"
    },
    {
        "option": "Pakistan",
        "value": "PK"
    },
    {
        "option": "Palau",
        "value": "PW"
    },
    {
        "option": "Panama",
        "value": "PA"
    },
    {
        "option": "Papua New Guinea",
        "value": "PG"
    },
    {
        "option": "Paraguay",
        "value": "PY"
    },
    {
        "option": "Peru",
        "value": "PE"
    },
    {
        "option": "Philippines",
        "value": "PH"
    },
    {
        "option": "Poland",
        "value": "PL"
    },
    {
        "option": "Portugal",
        "value": "PT"
    },
    {
        "option": "Puerto Rico",
        "value": "PR"
    },
    {
        "option": "Qatar",
        "value": "QA"
    },
    {
        "option": "Reunion",
        "value": "RE"
    },
    {
        "option": "Romania",
        "value": "RO"
    },
    {
        "option": "Russian Federation",
        "value": "RU"
    },
    {
        "option": "Rwanda",
        "value": "RW"
    },
    {
        "option": "Saint Kitts and Nevis",
        "value": "KN"
    },
    {
        "option": "Saint Lucia",
        "value": "LC"
    },
    {
        "option": "Saint Vincent and the Grenadines",
        "value": "VC"
    },
    {
        "option": "Samoa",
        "value": "WS"
    },
    {
        "option": "San Marino",
        "value": "SM"
    },
    {
        "option": "Sao Tome and Principe",
        "value": "ST"
    },
    {
        "option": "Saudi Arabia",
        "value": "SA"
    },
    {
        "option": "Senegal",
        "value": "SN"
    },
    {
        "option": "Seychelles",
        "value": "SC"
    },
    {
        "option": "Sierra Leone",
        "value": "SL"
    },
    {
        "option": "Singapore",
        "value": "SG"
    },
    {
        "option": "Slovenia",
        "value": "SI"
    },
    {
        "option": "Solomon Islands",
        "value": "SB"
    },
    {
        "option": "Somalia",
        "value": "SO"
    },
    {
        "option": "South Africa",
        "value": "ZA"
    },
    {
        "option": "South Georgia and the South Sandwich Islands",
        "value": "GS"
    },
    {
        "option": "South Korea",
        "value": "KR"
    },
    {
        "option": "Spain",
        "value": "ES"
    },
    {
        "option": "Sri Lanka",
        "value": "LK"
    },
    {
        "option": "Suriname",
        "value": "SR"
    },
    {
        "option": "Swaziland",
        "value": "SZ"
    },
    {
        "option": "Sweden",
        "value": "SE"
    },
    {
        "option": "Switzerland",
        "value": "CH"
    },
    {
        "option": "Tajikistan",
        "value": "TJ"
    },
    {
        "option": "Thailand",
        "value": "TH"
    },
    {
        "option": "Timor-Leste",
        "value": "TL"
    },
    {
        "option": "Togo",
        "value": "TG"
    },
    {
        "option": "Tonga",
        "value": "TO"
    },
    {
        "option": "Trinidad and Tobago",
        "value": "TT"
    },
    {
        "option": "Tunisia",
        "value": "TN"
    },
    {
        "option": "Turkey",
        "value": "TR"
    },
    {
        "option": "Turkmenistan",
        "value": "TM"
    },
    {
        "option": "Tuvalu",
        "value": "TV"
    },
    {
        "option": "Uganda",
        "value": "UG"
    },
    {
        "option": "Ukraine",
        "value": "UA"
    },
    {
        "option": "United Arab Emirates",
        "value": "AE"
    },
    {
        "option": "United Kingdom",
        "value": "GB"
    },
    {
        "option": "United States",
        "value": "US"
    },
    {
        "option": "Uruguay",
        "value": "UY"
    },
    {
        "option": "Uzbekistan",
        "value": "UZ"
    },
    {
        "option": "Vanuatu",
        "value": "VU"
    },
    {
        "option": "Vatican City State (Holy See)",
        "value": "VA"
    },
    {
        "option": "Venezuela",
        "value": "VE"
    },
    {
        "option": "Virgin Islands (British)",
        "value": "VG"
    },
    {
        "option": "Virgin Islands (U.S.)",
        "value": "VI"
    },
    {
        "option": "Wallis And Futuna Islands",
        "value": "WF"
    },
    {
        "option": "Yemen",
        "value": "YE"
    },
    {
        "option": "Zambia",
        "value": "ZM"
    },
    {
        "option": "Zimbabwe",
        "value": "ZW"
    }
]