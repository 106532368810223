/** @format */
import * as React from "react";
import Box from "@mui/material/Box";
import AppHeader from "../AppHeader.react";
import { useState, useEffect, useRef } from "react";
import { Context } from "../Context/Context";
import AppSidebar from "../AppSidebar.react";
import { gql, useQuery } from "@apollo/client";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Divider from "@mui/material/Divider";
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate, useLocation } from "react-router-dom";


const GET_USER_EXTENSION_KEY = gql`
  query getUserExtensionKey {
    getUserExtensionKey 
  }
`;

export default function Setting() {
    const navigate = useNavigate();
    const { state, setState } = React.useContext(Context)
    const { sendLog } = state;
    const [videoControls, setVideoControls] = useState(false);
    const [isPlayVideo, setIsPlayVideo] = useState(false);
    const videoComponent = useRef(null);
    const { loading, data, refetch } = useQuery(GET_USER_EXTENSION_KEY, {
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
    });
    useEffect(() => {
        if (videoComponent != null) {
            videoComponent.current.addEventListener('pause', function () {
                setIsPlayVideo(false);
            });
            videoComponent.current.addEventListener('play', function () {
                setIsPlayVideo(true);
            });
        }
    }, [videoComponent]);


    return (
        <Box>
            <AppHeader />
            <AppSidebar />
            <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
                <Box sx={{ width: "156.7rem", padding: "0rem 3rem 0" }}>
                    <Box sx={{
                        width: "100%",
                        // height: 'auto',
                        height: '67rem',
                        background: "#FFFFFF",
                        boxShadow: "0px 0.4rem 0.4rem rgba(0, 0, 0, 0.25)",
                        borderRadius: "1rem",
                        marginBottom: '5rem',
                        padding: '0 2rem',
                        boxSizing: 'border-box',
                        marginTop: "2rem"
                    }} >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: "5rem"
                        }}>
                            <Box sx={{
                                fontSize: '28px',
                                height: '4rem',
                                lineHeight: '4rem',
                                marginBottom: '4rem',
                                marginTop: '4rem'
                            }}>Import Product with Chrome Extension</Box>
                            <Box>
                                <Box sx={{
                                    fontSize: '28px',
                                    height: '4rem',
                                    lineHeight: '4rem',
                                    marginBottom: '4rem',
                                    textAlign: 'center'
                                }}>How it works:</Box>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5rem',
                                }}>
                                    <Box sx={{
                                        width: '60rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                        borderRadius: '1rem',
                                        boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.25), 0px -1px 6px rgba(0, 0, 0, 0.25)"
                                    }}
                                        onMouseEnter={() => {
                                            setVideoControls(true);
                                        }}
                                        onMouseLeave={() => {
                                            setVideoControls(false);
                                        }}
                                    >
                                        <video loop muted controls={videoControls} /* poster='/img/poster.png'*/ width='100%' style={{ "borderRadius": '1rem' }} ref={videoComponent}>
                                            <source src='/img/tutorial/part2.mp4' />
                                        </video>
                                        {isPlayVideo === false ? <Box sx={{ position: 'absolute', top: '50%', left: '50%', width: '8rem', height: '8rem', marginTop: '-4rem', marginLeft: '-4rem', background: 'url("/img/icon_product_slide_video_player.png") no-repeat 0 0', backgroundSize: 'contain', cursor: 'pointer' }} onClick={() => {
                                            videoComponent.current.play();
                                            setIsPlayVideo(true);
                                        }} /> : <Box />}
                                    </Box>
                                    <Box className="item" sx={{ flex: 1 }}>
                                        <Box sx={{
                                            height: 'auto',
                                            fontSize: '16px',
                                            boxSizing: 'border-box',
                                            color: "#333333",
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '3rem',
                                            "& .workStep": {
                                                minHeight: '3rem',
                                                lineHeight: '3rem'
                                            }
                                        }}>
                                            <Box className="workStep">
                                                <Box>
                                                    Step 1: <Typography variant="span" sx={{ color: "#666666" }}>Click on the install button to get the browser extension.</Typography>
                                                </Box>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: 'center',
                                                    marginLeft: '7rem',
                                                }}>
                                                    <Box sx={{
                                                        width: '35rem',
                                                        height: '4rem',
                                                        borderRadius: '0.5rem',
                                                        backgroundColor: '#FF9711',
                                                        color: '#FFF',
                                                        display: "flex",
                                                        alignItems: 'center',
                                                        marginTop: '1rem',
                                                        cursor: 'pointer',
                                                        justifyContent: 'center',
                                                    }} onClick={() => {
                                                        // handlerDown("./Cartifind_Chrome_Extension 1.4.0.zip", "Cartifind_Chrome_Extension 1.4.0.zip");
                                                        window.open("https://chromewebstore.google.com/detail/cartifind-importer/lnbknopcbaaajalpagleljjonlblliim");
                                                    }}>Install</Box>
                                                </Box>
                                            </Box>
                                            <Box className="workStep">Step 2: <Typography variant="span" sx={{ color: "#666666" }}>Approve the related permission of Cartifind Importer extension.</Typography></Box>
                                            <Box className="workStep">
                                                <Box>
                                                    Step 3: <Typography variant="span" sx={{ color: "#666666" }}>Login with your exclusive key below.</Typography>
                                                </Box>
                                                {loading === false && data != null && data.getUserExtensionKey != null ? <Box sx={{
                                                    display: "flex",
                                                    alignItems: 'center',
                                                    marginLeft: '7rem',
                                                    marginTop: '1rem'
                                                }}>
                                                    <CopyToClipboard text={data.getUserExtensionKey}>
                                                        <Tooltip title={<Box sx={{ fontSize: '14px', padding: '0.5rem 1rem' }}>Click it and copy your exclusive key</Box>} arrow placement="bottom" sx={{ fontSize: '14px' }}>
                                                            <Box sx={{
                                                                width: "35rem",
                                                                cursor: "pointer",
                                                                textAlign: 'center',
                                                                padding: '1rem 0',
                                                                fontSize: '14px',
                                                                border: '1px solid #666666',
                                                                borderRadius: '0.5rem'
                                                            }}>{data.getUserExtensionKey}</Box>
                                                        </Tooltip>
                                                    </CopyToClipboard>
                                                </Box> : null}
                                            </Box>
                                            <Box className="workStep" sx={{
                                                "& .platform": {
                                                    cursor: 'pointer', textDecoration: "underline", color: 'rgba(0, 127, 255)'
                                                }
                                            }}>Step 4: <Typography variant="span" sx={{ color: "#666666" }}>Go to product detail page of <Typography variant="span" className="platform" onClick={() => { window.open("https://www.temu.com/"); }}>Temu</Typography>, <Typography variant="span" className="platform" onClick={() => { window.open("https://www.shein.com/"); }}>Shein</Typography>, <Typography variant="span" className="platform" onClick={() => { window.open("https://www.amazon.com/"); }}>Amazon</Typography>, <Typography variant="span" className="platform" onClick={() => { window.open("https://www.aliexpress.us/"); }}>AliExpress</Typography>, and <Typography variant="span" className="platform" onClick={() => { window.open("https://re.1688.com/"); }}>1688</Typography>.</Typography></Box>
                                            <Box className="workStep">Step 5: <Typography variant="span" sx={{ color: "#666666" }}>Enjoy importing, and view added products in <Typography variant="span" sx={{ cursor: 'pointer', textDecoration: "underline", color: 'rgba(0, 127, 255)' }} onClick={() => { navigate("/myProducts"); }}>My Products</Typography> section.</Typography></Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
const handlerDown = (url, name) => {
    const x = new XMLHttpRequest();
    const resourceUrl = url;
    x.open("GET", resourceUrl, true);
    x.responseType = "blob";
    x.onload = function () {
        const urls = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = urls;
        a.download = name;
        a.click();
    };
    x.send();
};
