import React from 'react';
import AppHeader from '../AppHeader.react';
import { Box } from '@mui/system';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import ReviewCard from './ReviewCard.react';
import { useEffect, useState, useContext, useRef } from 'react';
import Slide from '@mui/material/Slide';
import CardMedia from '@mui/material/CardMedia';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { Context } from "/home/ubuntu/hff/src/Context/Context";

const SAVE_SUBSCRIBE = gql`
  mutation Mutation($email: String) {
    userSubscribe(email: $email)
  }
`;

const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
const reviewList = [{
    name: 'nightydailylashes',
    email: 'Se**@**.com',
    src: '/img/review_default_Avatar1.png',
    reviewContent: "Nice and easy to use. It will be very convenient for the small business to select items!!! I'll definitely recommend this app to everyone!",
    reviewTime: 'Dec 26, 2022',
}, {
    name: 'Shop For Fun',
    email: 'Al**@**.com',
    src: '/img/review_default_Avatar2.png',
    reviewContent: 'Easy to find products and push to Shopify store. Also able to see Shopify orders. Great tool!',
    reviewTime: 'Jan 8, 2023',
}, {
    name: 'Hope',
    email: 'sa**@**.com',
    src: '/img/review_default_Avatar3.png',
    reviewContent: 'Great tool for Aliexpress drop shipping. I can easily manage orders and import products. Definitely will recommend this app to dropshipping store owners. And it is FREE to use!',
    reviewTime: 'Jan 10, 2023',
}];

export default function IntermediatePage() {

    const [currentReveiwIndex, setCurrentReveiwIndex] = useState(0);
    const [videoControls, setVideoControls] = useState(false);
    const [currentScrollTop, setCurrentScrollTop] = useState(0);
    const navigate = useNavigate();
    const [save_subscribe] = useMutation(SAVE_SUBSCRIBE);
    const [userInputEmail, setUserInputEmail] = useState('');
    const { setState, state } = useContext(Context);
    const [isAllowCookies, setIsAllowCookies] = useState(localStorage.getItem("Allow cookies"));
    const videoComponent = useRef(null);
    const [isPlayVideo, setIsPlayVideo] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentReveiwIndex((oldReveiwIndex) => {
                if (oldReveiwIndex === reviewList.length - 2) {
                    return 0;
                }
                return (oldReveiwIndex + 1);
            });
        }, 5000);

        return () => {
            clearInterval(timer);
            setCurrentReveiwIndex(0);
        }
    }, []);
    useEffect(() => {
        if (videoComponent != null) {
            videoComponent.current.addEventListener('pause', function () {
                setIsPlayVideo(false);
            });
            videoComponent.current.addEventListener('play', function () {
                setIsPlayVideo(true);
            });
        }
    }, [videoComponent]);

    return (
        <Box sx={{
            backgroundColor: "#FFFFFF",
            fontFamily: "sans-serif",
            fontWeight: '400',
            fontStyle: "normal",
        }}>
            <AppHeader />
            <Box sx={{
                height: "59.6rem",
                padding: "10rem 6.2rem 7rem",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: 'space-between'
            }}>
                <Box sx={{
                    width: '95.6rem',
                }}>
                    <Box sx={{
                        fontSize: '2.8rem',
                        height: '8.4rem',
                        marginBottom: '4.4rem',
                        color: '#333333',
                        position: 'relative',
                        lineHeight: '180%',
                    }}><Typography variant="span" sx={{ color: '#FF9711', cursor: 'pointer' }} onClick={() => {
                        setCurrentScrollTop(document.documentElement.scrollTop);
                        const timer = setInterval(() => {
                            setCurrentScrollTop((oldScrollTop) => {
                                document.documentElement.scrollTop = oldScrollTop;
                                if (oldScrollTop >= 2200) {
                                    clearInterval(timer);
                                    return oldScrollTop;
                                }
                                return oldScrollTop + 2200 / 10;
                            });
                        }, 20);
                    }}>Dropshipping</Typography> the highest quality products only from the most reliable suppliers from China.
                        <Box sx={{
                            width: '24.8rem',
                            height: '5.5rem',
                            background: 'url("/img/IntermediatePage_chunk1_underline.png") no-repeat -3rem 0',
                            backgroundSize: 'contain',
                            position: 'absolute',
                            top: '4.5rem',
                            left: '0'
                        }} />
                    </Box>
                    <Box sx={{
                        width: '76.4rem',
                        height: '10.6rem',
                        fontSize: '2rem',
                        marginBottom: '1.2rem',
                        color: '#707070',
                    }}>Automate your Shopify store and keep your dropshipping business thriving through AliExpress fulfillment.</Box>
                    <Box sx={{
                        display: 'flex',
                        marginBottom: '1.4rem'
                    }}>
                        <TextField
                            focused
                            placeholder='Email'
                            sx={{
                                paddingRight: "0",
                                width: "57.8rem",
                                height: "7.5rem",
                                backgroundColor: "#fff",
                                borderRadius: "1rem 0 0 1rem",
                                "& .MuiInputBase-root": {
                                    "&.MuiOutlinedInput-root input": {
                                        textIndent: '2rem',
                                        height: "7.5rem",
                                        fontSize: '2rem',
                                        padding: 0
                                    },
                                },
                                "& .MuiOutlinedInput-root": {
                                    border: "0.4rem solid #FF9711",
                                    "&.Mui-focused fieldset": {
                                        border: "none",
                                    },
                                },
                            }}
                            value={userInputEmail}
                            onChange={(e) => {
                                setUserInputEmail(e.target.value);
                            }}
                        />
                        <Box sx={{
                            width: '23.5rem',
                            height: '8.3rem',
                            borderRadius: '1rem',
                            backgroundColor: '#FF9711',
                            textAlign: "center",
                            lineHeight: '8.3rem',
                            fontSize: '2.6rem',
                            color: '#FFF',
                            marginLeft: '1.6rem',
                            cursor: 'pointer'
                        }} onClick={() => {
                            if (!reg.test(userInputEmail)) {
                                setState({
                                    ...state,
                                    openSnackbar: true,
                                    snackbarText: 'Please enter the correct email address !',
                                    snackbarState: 'FAILED',
                                });
                                return;
                            }
                            save_subscribe({
                                variables: {
                                    email: userInputEmail
                                }
                            }).then((result) => {
                                if (result.data != null && result.data.userSubscribe != null && result.data.userSubscribe === true) {
                                    setState({
                                        ...state,
                                        openSnackbar: true,
                                        snackbarText: 'Subscribe successfully !',
                                        snackbarState: 'SUCCESS',
                                    });
                                } else {
                                    setState({
                                        ...state,
                                        openSnackbar: true,
                                        snackbarText: 'Subscription failed !',
                                        snackbarState: 'FAILED',
                                    });
                                }
                                navigate("/app");
                            })
                        }}>FREE INSTALL</Box>
                    </Box>
                    <Box sx={{
                        width: '70.5rem',
                        height: '3rem',
                        color: '#707070',
                        fontSize: '2rem'
                    }}>By entering your email, you agree to receive a free dropshipping guide.</Box>
                </Box>
                <Box sx={{
                    width: '77.3rem',
                    height: '42.2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                }}
                    onMouseEnter={() => {
                        setVideoControls(true);
                    }}
                    onMouseLeave={() => {
                        setVideoControls(false);
                    }}
                >
                    <video loop muted controls={videoControls} poster='/img/poster.png' width='95%' style={{ "borderRadius": '1rem' }} ref={videoComponent}>
                        <source src='/img/cartifind_publicity.mp4' />
                    </video>
                    {isPlayVideo === false ? <Box sx={{ position: 'absolute', top: '50%', left: '50%', width: '8rem', height: '8rem', marginTop: '-4rem', marginLeft: '-4rem', background: 'url("/img/icon_product_slide_video_player.png") no-repeat 0 0', backgroundSize: 'contain', cursor: 'pointer' }} onClick={() => {
                        videoComponent.current.play();
                        setIsPlayVideo(true);
                    }} /> : <Box />}
                </Box>
            </Box>
            <Box sx={{
                height: "87.7rem",
                paddingRight: "6.2rem",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: 'space-between',
                alignItems: 'center',
                background: 'url("/img/IntermediatePage_background_first.png") no-repeat 0 0',
                backgroundSize: 'cover'
            }}>
                <Box sx={{
                    background: 'url("/img/IntermediatePage_chunk2_computer.png") no-repeat -4.3rem 0',
                    width: '95.5rem',
                    height: '69.9rem',
                    backgroundSize: 'contain'
                }} />
                <Box sx={{
                    display: "flex",
                    width: '81.6rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center'
                }}>
                    <Box sx={{ fontSize: '3.6rem', color: '#333333', height: '5.4rem', marginBottom: '5.2rem', fontWeight: '700' }}>High quality products from China</Box>
                    <Box sx={{ fontSize: '2.8rem', color: '#707070', width: '81.6rem', height: '9.6rem', marginBottom: '5.2rem', lineHeight: '170%', }}><Typography variant="span" sx={{ fontWeight: '700' }}>Cartifind</Typography> allows you to select high-quality and low-cost products from manufacturers all over China.</Box>
                    <Box sx={{
                        width: '81.6rem',
                        display: "flex"
                    }}>
                        <Box sx={{
                            background: 'url("/img/IntermediatePage_chunk2_car.png") no-repeat 0 0',
                            width: '20.5rem',
                            height: '14.4rem',
                            backgroundSize: 'contain'
                        }} />
                        <Box sx={{
                            width: '26.4rem',
                            height: '8rem',
                            borderRadius: '1rem',
                            backgroundColor: '#FF9711',
                            color: '#FFF',
                            alignSelf: 'flex-end',
                            marginLeft: '4.7rem',
                            textAlign: "center",
                            lineHeight: '8rem',
                            fontSize: '3.2rem',
                            cursor: 'pointer'
                        }} onClick={() => { navigate("/app"); }}>TRY IT FREE</Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: "79.5rem",
                paddingLeft: "6.2rem",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Box sx={{
                    display: "flex",
                    width: '62.3em',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center'
                }}>
                    <Box sx={{ fontSize: '3.6rem', color: '#333333', height: '5.4rem', marginBottom: '2rem', fontWeight: '700' }}>You just click on the product</Box>
                    <Box sx={{ fontSize: '2.8rem', color: '#707070', width: '62.3rem', height: '19.2rem', marginBottom: '9.5rem', lineHeight: '170%', }}>Add the product to my products list with a few clicks. It is easy  to select products and push commodities to the shopify store to establish a reliable business.</Box>
                    <Box sx={{
                        width: '62.3rem',
                        height: '13rem',
                        display: "flex"
                    }}>
                        <Box sx={{
                            background: 'url("/img/IntermediatePage_chunk3_mouse.png") no-repeat 0 0',
                            width: '9.6rem',
                            height: '9.6rem',
                            backgroundSize: 'contain',
                            alignSelf: 'flex-end',
                        }} />
                        <Box sx={{
                            width: '26.4rem',
                            height: '8rem',
                            borderRadius: '1rem',
                            backgroundColor: '#FF9711',
                            color: '#FFF',
                            marginLeft: '4.9rem',
                            textAlign: "center",
                            lineHeight: '8rem',
                            fontSize: '3.2rem',
                            alignSelf: 'flex-start',
                            cursor: 'pointer'
                        }} onClick={() => { navigate("/app"); }}>TRY IT FREE</Box>
                    </Box>
                </Box>
                <Box sx={{
                    background: 'url("/img/IntermediatePage_chunk3_computer.png") no-repeat 5rem 0',
                    width: '125.7rem',
                    height: '69.8rem',
                    backgroundSize: 'contain'
                }} />
            </Box>
            <Box sx={{
                height: "93.1rem",
                padding: "0 6.2rem",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: 'space-between',
                alignItems: 'center',
                background: 'url("/img/IntermediatePage_background_second.png") no-repeat 0 0',
                backgroundSize: 'cover',
            }}>
                <Box sx={{
                    background: 'url("/img/IntermediatePage_chunk4_computer.png") no-repeat 0 0',
                    width: '93.6rem',
                    height: '77rem',
                    backgroundSize: 'contain'
                }} />
                <Box sx={{
                    display: "flex",
                    width: '75.6rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center'
                }}>
                    <Box sx={{ fontSize: '3.6rem', color: '#333333', height: '5.4rem', marginBottom: '5.1rem', fontWeight: '700' }}>Connect your Shopify store with ease</Box>
                    <Box sx={{ fontSize: '2.8rem', color: '#707070', width: '75.6rem', height: '19.2rem', marginBottom: '9.6rem', lineHeight: '170%', }}>One click to complete the order. <Typography variant="span" sx={{ fontWeight: '700' }}>Cartifind</Typography> can easily synchronize with your Shopify store, and all orders will be automatically displayed in your my orders page.</Box>
                    <Box sx={{
                        width: '75.6rem',
                        height: '13rem',
                        display: "flex"
                    }}>
                        <Box sx={{
                            background: 'url("/img/IntermediatePage_chunk4_watch.png") no-repeat 0 0',
                            width: '12rem',
                            height: '12rem',
                            alignSelf: 'flex-end',
                            marginLeft: '4.1rem',
                            backgroundSize: 'contain'
                        }} />
                        <Box sx={{
                            width: '26.4rem',
                            height: '8rem',
                            borderRadius: '1rem',
                            backgroundColor: '#FF9711',
                            color: '#FFF',
                            marginLeft: '7.1rem',
                            textAlign: "center",
                            lineHeight: '8rem',
                            fontSize: '3.2rem',
                            cursor: 'pointer'
                        }} onClick={() => { navigate("/app"); }}>TRY IT FREE</Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: "102.2rem",
                padding: "19.3rem 6.5rem 0rem",
                boxSizing: "border-box",
                textAlign: 'center'
            }}>
                <Box sx={{
                    fontSize: '3.6rem',
                    fontWeight: '700',
                    color: '#333333',
                    marginBottom: '3.3rem'
                }}>What is Dropshipping?</Box>
                <Box sx={{
                    fontSize: '2.6rem',
                    color: '#707070',
                    marginBottom: '7.5rem'
                }}>Dropshipping is a form of retail fulfillment in which stores do not retain inventory of products they sell.</Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Box>
                        <Box sx={{
                            background: 'url("/img/IntermediatePage_chunk5_image1.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                            width: '24.5rem',
                            height: '24.5rem',
                            margin: '0 auto'
                        }} />
                        <Box sx={{
                            width: '18.3rem',
                            height: '5.5rem',
                            borderRadius: '5.2rem',
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            backgroundColor: '#FFF',
                            color: '#FF9711',
                            fontSize: '2rem',
                            lineHeight: '5.5rem',
                            textAlign: 'center',
                            position: 'relative',
                            top: '-1.5rem',
                            margin: '0 auto 3.5rem'
                        }}>Sell Price : $125</Box>
                        <Box sx={{
                            width: '42.8rem',
                            height: '9rem',
                            fontSize: '3rem',
                            color: '#333333'
                        }}>Customers place orders from your Shopify store</Box>
                    </Box>
                    <Box sx={{
                        background: 'url("/img/IntermediatePage_chunk5_icon_left.png") no-repeat 0 0',
                        backgroundSize: 'cover',
                        width: '5.48rem',
                        height: '5.48rem'
                    }} />
                    <Box>
                        <Box sx={{
                            background: 'url("/img/IntermediatePage_chunk5_image2.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                            width: '24.5rem',
                            height: '24.5rem',
                            margin: '0 auto'
                        }} />
                        <Box sx={{
                            width: '18.3rem',
                            height: '5.5rem',
                            borderRadius: '5.2rem',
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            backgroundColor: '#FFF',
                            color: '#FF9711',
                            fontSize: '2rem',
                            lineHeight: '5.5rem',
                            textAlign: 'center',
                            position: 'relative',
                            top: '-1.5rem',
                            margin: '0 auto 3.5rem'
                        }}>Cost Price : $75</Box>
                        <Box sx={{
                            width: '36.3rem',
                            height: '9rem',
                            fontSize: '3rem',
                            color: '#333333'
                        }}>Their order goes directly to your suppliers</Box>
                    </Box>
                    <Box sx={{
                        background: 'url("/img/IntermediatePage_chunk5_icon_left.png") no-repeat 0 0',
                        backgroundSize: 'cover',
                        width: '5.48rem',
                        height: '5.48rem'
                    }} />
                    <Box>
                        <Box sx={{
                            background: 'url("/img/IntermediatePage_chunk5_image3.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                            width: '24.5rem',
                            height: '24.5rem',
                            margin: '0 auto'
                        }} />
                        <Box sx={{
                            width: '18.3rem',
                            height: '5.5rem',
                            borderRadius: '5.2rem',
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            backgroundColor: '#FFF',
                            color: '#FF9711',
                            fontSize: '2rem',
                            lineHeight: '5.5rem',
                            textAlign: 'center',
                            position: 'relative',
                            top: '-1.5rem',
                            margin: '0 auto 3.5rem'
                        }}>Ship From</Box>
                        <Box sx={{
                            width: '37.3rem',
                            height: '9rem',
                            fontSize: '3rem',
                            color: '#333333'
                        }}>Your suppliers ship the product to your customer</Box>
                    </Box>
                    <Box sx={{
                        background: 'url("/img/IntermediatePage_chunk5_icon_left.png") no-repeat 0 0',
                        backgroundSize: 'cover',
                        width: '5.48rem',
                        height: '5.48rem'
                    }} />
                    <Box>
                        <Box sx={{
                            background: 'url("/img/IntermediatePage_chunk5_image4.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                            width: '24.5rem',
                            height: '24.5rem',
                            margin: '0 auto'
                        }} />
                        <Box sx={{
                            width: '18.3rem',
                            height: '5.5rem',
                            borderRadius: '5.2rem',
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            backgroundColor: '#FFF',
                            color: '#FF9711',
                            fontSize: '2rem',
                            lineHeight: '5.5rem',
                            textAlign: 'center',
                            position: 'relative',
                            top: '-1.5rem',
                            margin: '0 auto 3.5rem'
                        }}>Profit : $50</Box>
                        <Box sx={{
                            width: '29rem',
                            height: '9rem',
                            fontSize: '3rem',
                            color: '#333333',
                        }}>You keep the profit from the retail price</Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: "104.1rem",
                paddingTop: "12.0rem",
                boxSizing: "border-box",
                background: 'url("/img/IntermediatePage_background_third.png") no-repeat 0 0',
                backgroundSize: 'contain',
                textAlign: 'center',
                "& .items": {
                    display: 'flex',
                    flex: '1',
                    alignItems: 'center',
                    justifyContent: 'center'
                }
            }}>
                <Box sx={{ position: 'relative', marginBottom: '4.2rem', }}>
                    <Box sx={{
                        fontSize: '3.6rem',
                        color: '#333333',
                        fontWeight: '700',
                        marginBottom: '3.3rem'
                    }}>Available Integrations</Box>
                    <Box sx={{
                        fontSize: "2.6rem",
                        color: '#707070'
                    }}>Cartifind integrates with your favorite e-commerce platform. Start shipping now!</Box>
                    <Box sx={{
                        position: 'absolute',
                        background: 'url("/img/IntermediatePage_chunk6_bag.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                        width: '15.8rem',
                        height: '18.7rem',
                        top: '-3.1rem',
                        right: '16.9rem'
                    }} />
                </Box>
                <Box sx={{
                    height: '31.8rem',
                    borderBottom: '0.4rem solid rgba(112, 112, 112, 0.1)',
                    display: 'flex',
                }}>
                    <Box sx={{
                        borderRight: '0.4rem solid rgba(112, 112, 112, 0.1)'
                    }} className="items">
                        <Box sx={{
                            background: 'url("/img/IntermediatePage_chunk6_image1.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                            width: '50rem',
                            height: '17.1rem',
                            cursor: 'pointer'
                        }} onClick={() => { window.open('https://apps.shopify.com/hff-products'); }} />
                    </Box>
                    <Box className="items">
                        <Box sx={{
                            background: 'url("/img/IntermediatePage_chunk6_image2.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                            width: '50rem',
                            height: '17.1rem'
                        }} />
                    </Box>
                </Box>
                <Box sx={{
                    height: '31.8rem',
                    display: 'flex',
                }}>
                    <Box sx={{
                        borderRight: '0.4rem solid rgba(112, 112, 112, 0.1)'
                    }} className="items">
                        <Box sx={{
                            background: 'url("/img/IntermediatePage_chunk6_image3.png") no-repeat 0 0',
                            backgroundSize: 'contain',

                            width: '50rem',
                            height: '17.1rem'
                        }} />
                    </Box>
                    <Box className="items">
                        <Box sx={{
                            background: 'url("/img/IntermediatePage_chunk6_image4.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                            width: '50rem',
                            height: '17.1rem'
                        }} />
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: "90.9rem",
                paddingTop: "5.3rem",
                boxSizing: "border-box",
            }}>
                <Box sx={{ height: '5.4rem', fontSize: '3.6rem', fontWeight: '700', color: '#333333', textAlign: 'center', marginBottom: '7rem' }}>5 - Star Rating from Shopify App Store Review</Box>
                <Box sx={{
                    height: '85.5rem',
                    overflow: 'hidden',
                    position: 'relative',
                    paddingTop: '2rem'
                }}>
                    <Box sx={{
                        position: 'absolute',
                        display: 'flex',
                        transition: "left 0.5s",
                        paddingLeft: '5rem',
                        left: `-${currentReveiwIndex * 94}rem`
                    }}>
                        {
                            reviewList.map((item, index) => {
                                return <ReviewCard
                                    src={item.src}
                                    name={item.name}
                                    email={item.email}
                                    reviewContent={item.reviewContent}
                                    reviewTime={item.reviewTime}
                                    key={index}
                                />
                            })
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: '114.8rem',
                paddingTop: "25.7rem",
                boxSizing: "border-box",
                background: 'url("/img/IntermediatePage_background_fourth.png") no-repeat 0 0',
                backgroundSize: 'contain',
                position: 'relative',
                zIndex: '10'
            }}>
                <Box sx={{
                    width: '108rem',
                    height: '10.8rem',
                    borderRadius: '1rem',
                    backgroundColor: '#FFF',
                    textAlign: 'center',
                    lineHeight: '10.8rem',
                    color: '#FF9711',
                    fontSize: '4rem',
                    margin: '0 auto 7.9rem'
                }}>Grow your dropshipping business today for FREE!</Box>
                <Box sx={{
                    width: '26.4rem',
                    height: '8rem',
                    borderRadius: '1rem',
                    color: '#FFF',
                    textAlign: 'center',
                    lineHeight: '8rem',
                    backgroundColor: '#FF9711',
                    fontSize: '4rem',
                    margin: '0 auto 4.6rem'
                }}>Start Now</Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Box sx={{
                        background: 'url("/img/IntermediatePage_chunk8_image1.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                        width: '26.5rem',
                        height: '26.5rem',
                        marginRight: '23rem'
                    }} />
                    <Box sx={{
                        background: 'url("/img/IntermediatePage_chunk8_image2.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                        width: '26.5rem',
                        height: '26.5rem',
                        marginRight: '29rem',
                        position: 'relative',
                        top: '8.3rem'
                    }} />
                    <Box sx={{
                        background: 'url("/img/IntermediatePage_chunk8_image3.png") no-repeat 0 0',
                        backgroundSize: 'contain',
                        width: '26.5rem',
                        height: '26.5rem'
                    }} />
                </Box>
            </Box>
            <Box sx={{
                height: '123.9rem',
                padding: '41.4rem 9rem 0 0',
                boxSizing: "border-box",
                background: 'url("/img/IntermediatePage_background_fifth.png") no-repeat 0 0',
                backgroundSize: 'contain',
                marginTop: '-32.8rem',
                position: 'relative',
                zIndex: '1',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <Box sx={{
                    fontSize: '3.6rem',
                    color: '#333333',
                    fontWeight: '700',
                    textAlign: 'center',
                    height: '5.4rem',
                    marginBottom: '10.9rem'
                }}>Got Any Questions with Cartifind?</Box>
                <Box sx={{
                    background: 'url("/img/IntermediatePage_chunk9_phone.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                    width: '83rem',
                    height: '83rem',
                    position: 'absolute',
                    top: '37.9rem',
                    left: '-3.2rem'
                }} />
                <Box sx={{
                    alignSelf: 'flex-end',
                    color: '#666666',
                    fontSize: '3.6rem',
                    '& a': {
                        color: '#333333',
                        textDecoration: 'none'
                    },
                    '& a>div,&>div': {
                        width: '73.4rem',
                        height: '18.5rem',
                        borderRadius: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingLeft: '3.7rem',
                        paddingRight: '7.2rem',
                        boxSizing: "border-box",
                    }
                }}><a href='mailto:'>
                        <Box sx={{
                            backgroundColor: '#BEF1FF',
                            marginBottom: '10rem',
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <Box sx={{
                                    width: '13.7rem',
                                    height: '11.6rem',
                                    background: 'url("/img/IntermediatePage_chunk9_email.png") no-repeat 0 0',
                                    backgroundSize: 'contain',
                                    marginRight: '1rem'
                                }} />
                                <Box>Contact by Email</Box>
                            </Box>
                            <ArrowForwardIosRoundedIcon sx={{ fontSize: '5rem' }} />
                        </Box>
                    </a>
                    <Box sx={{
                        backgroundColor: '#FFD5BA',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Box sx={{
                                width: '13.7rem',
                                height: '11.6rem',
                                background: 'url("/img/IntermediatePage_chunk9_messenger.png") no-repeat 0 0',
                                backgroundSize: 'contain',
                                marginRight: '1rem'
                            }} />
                            <Box onClick={() => {
                                const chatbox = document.getElementById("fb-customer-chat");
                                chatbox.click();
                            }}>Contact by Messenger</Box>
                        </Box>
                        <ArrowForwardIosRoundedIcon sx={{ fontSize: '5rem' }} />
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: '57.5rem',
                paddingTop: '5.2rem',
                boxSizing: "border-box",
            }}>
                <Box sx={{
                    fontSize: '3.6rem',
                    color: '#000000',
                    fontWeight: '700',
                    textAlign: 'center',
                    marginBottom: '10.4rem'
                }}>Featured on</Box>
                <Box sx={{
                    background: 'url("/img/IntermediatePage_chunk10_top_logo.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                    width: 'calc(100% - 10rem)',
                    height: '10.4rem',
                    margin: '0 5rem',
                }} />
                <Box sx={{
                    width: '100%',
                    height: '0.2rem',
                    backgroundColor: '#C4C4C4',
                    marginBottom: '5.3rem'
                }} />
                <Box sx={{
                    display: 'flex',
                    '&>div': {
                        flex: 1,
                        height: '19rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        fontSize: '3.6rem',
                        color: '#333333',
                        fontWeight: '700',
                        borderRight: '0.2rem solid #C4C4C4',
                        '& .logo': {
                            backgroundSize: 'contain',
                            width: '10rem',
                            height: '7.9rem',
                            marginBottom: '5.6rem',
                        }
                    }
                }}>
                    <Box>
                        <Box sx={{ background: 'url("/img/IntermediatePage_chunk10_facebook.png") no-repeat 0 0' }} className='logo' />Facebook
                    </Box>
                    <Box>
                        <Box sx={{ background: 'url("/img/IntermediatePage_chunk10_twitter.png") no-repeat 0 0' }} className='logo' />Twitter
                    </Box>
                    <Box>
                        <Box sx={{ background: 'url("/img/IntermediatePage_chunk10_producthunt.png") no-repeat 0 0' }} className='logo' />Producthunt
                    </Box>
                    <Box sx={{ border: 'none!important' }}>
                        <Box sx={{ background: 'url("/img/IntermediatePage_chunk10_instagram.png") no-repeat 0 0' }} className='logo' />Instagram
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: '16.5rem',
                paddingBottom: '4rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.6rem',
                fontWeight: '700',
                color: '#707070',
                '& .line': {
                    height: '1.5rem',
                    width: '0.1rem',
                    background: 'url("/img/IntermediatePage_chunk11_line.png") no-repeat 0 0',
                    margin: '0 1rem'
                },
                '& .link': {
                    cursor: 'pointer',
                }
            }}>
                <Box>Cartifind © 2023</Box>
                <Box className='line' />
                <Box className='link' onClick={() => {
                    navigate('/faq')
                }}>FAQ</Box>
                <Box className='line' />
                <Box className='link' onClick={() => {
                    navigate('/tutorial')
                }}>TUTORIAL</Box>
                <Box className='line' />
                <Box className='link' onClick={() => {
                    navigate('/blog')
                }}>BLOG</Box>
                <Box className='line' />
                <Box className='link'>COMMUNITY</Box>
                <Box className='line' />
                <Box className='link'>CONTACT</Box>
            </Box>
            <Box sx={{
                width: '100%',
                height: '8rem',
                backgroundColor: '#474658',
                position: 'fixed',
                bottom: '0',
                left: '0',
                zIndex: '9999',
                display: isAllowCookies === 'Allow' ? 'none' : 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Box sx={{
                    fontSize: '2rem',
                    color: "#FFF",
                    marginRight: '2.5rem'
                }}>This website makes use of cookies to enhance browsing experience and provide additional functionality.</Box>
                <Box sx={{
                    color: '#FF9711',
                    fontSize: '2.4rem',
                    marginRight: '2.5rem',
                    cursor: 'pointer'
                }} onClick={() => { window.open('/privacy'); }}>Privacy policy</Box>
                <Box sx={{
                    width: '18.3rem',
                    height: '4.5rem',
                    borderRadius: '1rem',
                    backgroundColor: '#FF9711',
                    color: '#FFF',
                    textAlign: 'center',
                    lineHeight: '4.5rem',
                    fontSize: '2.4rem',
                    cursor: 'pointer'
                }} onClick={() => { localStorage.setItem("Allow cookies", 'Allow'); setIsAllowCookies('Allow') }}>Allow cookies</Box>
            </Box>
        </Box >
    )
}