import React, { useState, useEffect } from "react";
import { Context } from "../Context/Context";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { gql, useMutation } from "@apollo/client";
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import Confetti from 'react-confetti';
import Typography from "@mui/material/Typography";


const APP_SUBSCRIPTION_CREATE = gql`
mutation appSubscriptionCreate($timeType: String, $domain: String) {
    appSubscriptionCreate(timeType: $timeType, domain: $domain){
        status
        confirmationUrl
        message
    }
}
`;

const PlanSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: '8rem',
    height: '4rem',
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: '0.5rem',
        transitionDuration: '300ms',
        backgroundColor: "#FF9711",
        '& + .MuiSwitch-track': {
            backgroundColor: "#FF9711",
            opacity: 1,
            border: 0,
        },
        '&.Mui-checked': {
            transform: 'translateX(4rem)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: "#FF9711",
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: "3rem",
        height: "3rem",
    },
    '& .MuiSwitch-track': {
        borderRadius: "2rem",
        backgroundColor: 'rgba(112, 112, 112, 0.7)',
        opacity: 1,
        transition: "background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
}));

const getHolidayTheme = (holiday, closeFun) => {
    switch (holiday) {
        case "Halloween":
            return <Box sx={{
                backgroundColor: "#FF9711",
                width: "208px",
                height: "36px",
                padding: '6px 16px',
                position: "fixed",
                left: 0,
                bottom: 0,
                zIndex: '99',
                display: "flex",
                alignItems: 'center',
                justifyContent: "space-between",
                borderRadius: "4px",
                cursor: "pointer"
            }} id="UpgradePlanDialog">
                <Box sx={{
                    position: "absolute",
                    top: "-44px",
                    right: '0',
                    width: "57px",
                    height: '44px'
                }}>
                    <img src="./img/black_cat.png" width="57px" height="44px" />
                </Box>
                <Box sx={{
                    width: '33px',
                    height: '33px'
                }}>
                    <img src="./img/black_pumpkin.png" width="33px" height="33px" />
                </Box>
                <Box sx={{
                    color: '#231F20',
                    fontFamily: 'Arial',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '143%',
                    letterSpacing: ' 0.15px',
                }}>Halloween Savings</Box>
                <CloseIcon sx={{ fontSize: '20px' }} onClick={closeFun} />
            </Box>;
        case "Christmas":
            return <Box sx={{
                backgroundColor: "rgba(191, 30, 46, 1)",
                width: "208px",
                height: "36px",
                padding: '6px 16px',
                position: "fixed",
                left: 0,
                bottom: 0,
                zIndex: '99',
                display: "flex",
                alignItems: 'center',
                justifyContent: "space-between",
                borderRadius: "4px",
                cursor: "pointer"
            }} id="UpgradePlanDialog">
                <Box sx={{
                    position: "absolute",
                    top: "-28px",
                    right: '0',
                    display: "flex",
                    alignItems: 'flex-end',
                    justifyContent: "center"
                }}>
                    <Box sx={{
                        width: "26px",
                        height: '30px',
                        marginBottom: "-1px"
                    }}>
                        <img src="./img/christmas_second.png" width="26px" height="30px" />
                    </Box>
                    <Box sx={{
                        right: '0',
                        width: "18px",
                        height: '22px',
                        marginLeft: "-2px"
                    }}>
                        <img src="./img/christmas_third.png" width="18px" height="22px" />
                    </Box>
                </Box>
                <Box sx={{
                    width: '35px',
                    height: '34px'
                }}>
                    <img src="./img/christmas_first.png" width="35px" height="34px" />
                </Box>
                <Box sx={{
                    color: '#FFF',
                    fontFamily: 'Arial',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '143%',
                    letterSpacing: ' 0.15px',
                    width: "125px",
                }}>Holiday Savings</Box>
                <CloseIcon sx={{ fontSize: '20px', color: '#FFF' }} onClick={closeFun} />
            </Box>;
        case "special offer":
            return <Box sx={{
                backgroundColor: "#61C454",
                width: "208px",
                height: "36px",
                padding: '6px 16px',
                position: "fixed",
                left: 0,
                bottom: 0,
                zIndex: '99',
                display: "flex",
                alignItems: 'center',
                justifyContent: "space-between",
                borderRadius: "4px",
                cursor: "pointer"
            }} id="UpgradePlanDialog">
                <Box sx={{
                    width: '35px',
                    height: '34px'
                }}>
                    <img src="./img/special_offer_icon.png" width="35px" height="34px" />
                </Box>
                <Box sx={{
                    color: '#FFF',
                    fontFamily: 'Arial',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '20px',
                    letterSpacing: ' 0.15px',
                    width: "125px",
                }}>Special Offer</Box>
                <CloseIcon sx={{ fontSize: '20px', color: '#FFF' }} onClick={closeFun} />
            </Box>;
        default:
            return <Box sx={{
                backgroundColor: "#FF9711",
                width: "208px",
                height: "36px",
                padding: '6px 16px',
                position: "fixed",
                left: 0,
                bottom: 0,
                zIndex: '99',
                display: "flex",
                alignItems: 'center',
                justifyContent: "space-between",
                borderRadius: "4px",
                cursor: "pointer"
            }} id="UpgradePlanDialog">
                <Box sx={{
                    position: "absolute",
                    top: "-44px",
                    right: '0',
                    width: "57px",
                    height: '44px'
                }}>
                    <img src="./img/black_cat.png" width="57px" height="44px" />
                </Box>
                <Box sx={{
                    width: '33px',
                    height: '33px'
                }}>
                    <img src="./img/black_pumpkin.png" width="33px" height="33px" />
                </Box>
                <Box sx={{
                    color: '#231F20',
                    fontFamily: 'Arial',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '143%',
                    letterSpacing: ' 0.15px',
                }}>Halloween Savings</Box>
                <CloseIcon sx={{ fontSize: '20px' }} onClick={closeFun} />
            </Box>;
    }
}


export default function UpgradePlanDialog() {
    const [app_subscription_create] = useMutation(APP_SUBSCRIPTION_CREATE);
    const { state, setState } = React.useContext(Context);
    const { isOpenSelectPlanDialog, firstName, shopName, sendLog } = state;
    const isShowUpgradePlanDialog = localStorage.getItem("isShowUpgradePlanDialog");
    const [isClose, setIsClose] = useState(false);
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isShowConfetti, setIsShowConfetti] = useState(true);

    useEffect(() => {
        setIsShowConfetti(isShowDialog);
    }, [isShowDialog])
    return (
        <>
            <Dialog
                open={isShowDialog}
                onClose={() => {
                    setIsShowDialog(false);
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{
                    "&.MuiDialog-root": {
                        zIndex: '99999',
                    },
                    "& .MuiPaper-root": {
                        width: "602px",
                        height: "620px",
                        maxWidth: "602px",
                        maxHeight: "620px",
                        borderRadius: '4px',
                        boxShadow: "0px 11px 15px -7px rgba(0, 0, 0, 0.20), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
                        transform: "scale(0.8)"
                    },
                }}
            >
                <DialogContent sx={{ padding: '0px' }}>
                    {/* {isShowConfetti ? <ConfettiExplosion
                        force="0.5"
                        duration="3000"
                        particleCount="250"
                        width="602"
                        height={"620px"}
                        zIndex={999}
                    /> : null} */}
                    <Confetti
                        width={"602px"}
                        height={"620px"}
                        run={isShowConfetti}
                        onConfettiComplete={() => {
                            console.log(113);
                            setIsShowConfetti(false)
                        }}
                        numberOfPieces={400}
                        recycle={false}
                        gravity={0.05}
                    />
                    <Box sx={{
                        width: "570px",
                        height: '78px',
                        padding: '0px 16px',
                        backgroundColor: "#FF9711",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "center",
                        color: '#FFF',
                        fontFamily: 'Arial',
                        fontSize: '30px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '160%',
                        letterSpacing: ' 0.15px',
                    }}>Loyal Customer SAVINGS</Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "center",
                    }}>
                        <img src="./img/upgrade_dialog_content_images.png" width="423px" height="273px" />
                    </Box>
                    <Box sx={{
                        width: "570px",
                        height: '111px',
                        padding: '0px 16px',
                        fontFamily: 'Arial',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        letterSpacing: ' 0.15px',
                        display: "flex",
                        flexDirection: "column",
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            color: '#61C454',
                            fontFamily: 'Arial',
                            fontSize: '48px',
                            fontWeight: '700',
                        }}>$5.83<Typography variant="span" sx={{ fontSize: '16px' }}>/Month</Typography></Box>
                        <Box sx={{
                            color: '#707070',
                            fontFamily: 'Arial',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '160%',
                        }}>Original $9.95/ Month</Box>
                        <Box sx={{
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontFamily: 'Arial',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '160%',
                            textDecorationLine: "strikethrough"
                        }}>Only <Typography variant="span" sx={{ fontWeight: '700' }}>$69.99</Typography> for <Typography variant="span" sx={{ fontWeight: '600' }}>A YEAR</Typography></Box>
                    </Box>
                    <Box sx={{
                        width: "570px",
                        height: '126px',
                        padding: '8px 16px',
                        fontFamily: 'Arial',
                        fontStyle: 'normal',
                        display: "flex",
                        flexDirection: "column",
                        alignItems: 'center',
                        justifyContent: "space-between",
                        "& div": {
                            width: "307px",
                            height: '43px',
                            padding: '6px 16px',
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: "center",
                            borderRadius: "10px",
                            backgroundColor: "#61C454",
                            boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                            color: '#FFF',
                            fontFamily: 'Arial',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '24px',
                            letterSpacing: ' 0.4px',
                            textTransform: "uppercase",
                            cursor: 'pointer'
                        }
                    }}>
                        <Box onClick={() => {
                            if (firstName == null) {
                                setState({
                                    ...state,
                                    isAccountPopUpDialogOpen: true,
                                    currentComponentName: "SIGN_UP",
                                });
                                return;
                            }
                            if (shopName == null) {
                                setState({
                                    ...state,
                                    isAccountPopUpDialogOpen: true,
                                    currentComponentName: "CONNECT_SHOPIFY",
                                });
                                return;
                            }
                            app_subscription_create({
                                variables: {
                                    timeType: "yearly",
                                    domain: window.location.hostname, /* 可能是www.cartifind.com 或者 cartifind.com 或者cloudfront url */
                                },
                            }).then(res => {
                                if (res.data != null && res.data.appSubscriptionCreate != null) {
                                    if (res.data.appSubscriptionCreate.confirmationUrl !== '') {
                                        window.location.href = res.data.appSubscriptionCreate.confirmationUrl;
                                    }
                                }
                            })
                        }}>Upgrade to yearly plan</Box>
                        <Box sx={{ backgroundColor: 'rgba(112, 112, 112, 0.70)!important' }} onClick={() => { setIsShowDialog(false); }}>Give up savings</Box>
                    </Box>
                </DialogContent>
            </Dialog >
            <Box sx={{
                display: isShowUpgradePlanDialog == "false" || isClose ? 'none' : 'flex',
            }} onClick={() => { setIsShowDialog(true) }}>
                {getHolidayTheme("special offer", (e) => {
                    e.stopPropagation();
                    localStorage.setItem("isShowUpgradePlanDialog", "false");
                    setIsClose(true);
                })}

            </Box>
        </>

    );
}