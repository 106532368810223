// @flow
import React, { useContext } from "react";
import { gql, useQuery } from '@apollo/client';
import { Context } from "/home/ubuntu/hff/src/Context/Context";
import { setCookie } from "doc-cookie";

const queryParams = new URLSearchParams(window.location.search);
const code = queryParams.get('code');
const hmac = queryParams.get('hmac');
const shop = queryParams.get('shop'); //testhffproducts.myshopify.com

const SAVE_TOKEN = gql`
query Query($email: String, $shopName: String, $code: String, $useCodeForEmail: Boolean, $domain:String) {
    saveShopifyToken(email: $email, shopName: $shopName, code: $code, useCodeForEmail: $useCodeForEmail, domain: $domain) {
        firstName
        lastName
        password
        email
        shopifyToken
        shopName
        token
        subscriptionURL
        shipToCountry
        adjustedEmail
    }
  }
  
`;

export default function ShopifyAuth() {
    const email = localStorage.getItem("email");
    const [redirectLink, setRedirectLink] = React.useState(null);
    React.useEffect(() => {
        if (redirectLink != null) {
            window.location = redirectLink;
        }
    }, [redirectLink]);

    if (code != null && hmac != null && shop != null && email != null && email.length > 0) {
        const variables = {
            shopName: String(shop),
            code: String(code),
            email: String(email),
            useCodeForEmail: false,
            domain: window.location.hostname,
        };
        const { data } = useQuery(SAVE_TOKEN, {
            variables,
            // notifyOnNetworkStatusChange: true,
        });

        if (data != null && data.saveShopifyToken !== null) {
            // Success saved shopify token, thus login to account
            const { setState } = useContext(Context);
            const { firstName, shopifyToken, email, token, subscriptionURL, shipToCountry, adjustedEmail } = data.saveShopifyToken;
            localStorage.setItem("firstName", firstName);
            localStorage.setItem("shopifyToken", shopifyToken);
            localStorage.setItem("email", email);
            localStorage.setItem("shopName", String(shop));
            localStorage.setItem("storePlatform", "shopify");
            localStorage.setItem("shipToCountry", shipToCountry);
            // adjustedEmail: shopify account email
            localStorage.setItem("adjustedEmail", adjustedEmail);
            localStorage.setItem("token", token);
            setState({
                firstName,
                shopifyToken,
                email,
                shopName: String(shop),
                storePlatform: "shopify",
                shipToCountry,
                adjustedEmail
            });
            setCookie({
                key: "token",
                value: token,
                "Max-Age": 604800 * 4, // seconds
            });

            // Check enviroment to prevent infinite reloading in shopify mobile app.
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                if (subscriptionURL != null && redirectLink == null) {
                    setRedirectLink(subscriptionURL);
                } else if (redirectLink == null) {
                    setRedirectLink('/app?subscriptionStatus=1');
                }
            } else {
                if (subscriptionURL != null && redirectLink == null) {
                    setRedirectLink(subscriptionURL);
                } else if (redirectLink == null) {
                    setRedirectLink('/app?subscriptionStatus=1');
                }
            }
        }
        // Write shopName
        // localStorage.setItem("shopName", String(shop));

        // window.location.href = 'http://3.84.25.106/app';
        return;
    };

    return <div />;

};