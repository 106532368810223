/** @format */
import * as React from "react";
import { lazy, useImperativeHandle } from 'react';
import Box from "@mui/material/Box";
import AppHeader from "../AppHeader.react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { Context } from "../Context/Context";
import AppSidebar from "../AppSidebar.react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Divider from "@mui/material/Divider";

import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CircularProgress from "@mui/material/CircularProgress";
import SettingBlankPage from "./SettingBlankPage.react";
import { currencyList } from "../utils/CurrencyList.js";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { LanguageList } from "../utils/LanguageList.js";
import { useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPlan from "./TabPlan.react";
import TabAccount from "./TabAccount.react";
import TabProduct from "./TabProduct.react";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { isInExperiment } from "../utils/HelperFunction.js";


const GET_SETTING_CONTENT = gql`
  query getSettingContent {
    getSettingContent {
      currency
      priceCalculate
      timesValue
      plusValue
      isTaxable
      pushProductInformationValue
      pushProductDescriptionValue
      language
      inventoryAutomaticUpdate
      email
      isOpenAssignCents
      AssignCents
      vendor
      optimizeValue
      currentPlanShowDate
      subscriptionType
      userID
      isShowCancel
      customPricingRule
      defaultFulfillmentOption
      productPricingRuleComposition
    }
  }
`;



export default function Setting() {
  const [tabIndex, setTabIndex] = React.useState(0);
  const { state, setState } = React.useContext(Context)
  const { shopName, firstName, sendLog, email, userID } = state;
  const [isOpenUnSave, setIsOpenUnSave] = useState(false);
  const [currentUnSaveIndex, setCurrentUnSaveIndex] = useState('-1');
  const [nextTabIndex, setNextTabIndex] = useState(0);
  const AccountRef = React.useRef();
  const ProductRef = React.useRef();
  const [initialize, setinitialize] = React.useState(true);

  const { loading, data, refetch } = useQuery(GET_SETTING_CONTENT, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });


  useEffect(() => {
    if (loading === false) {
      if (shopName == null || firstName == null) {
        sendLog("SETTING_LOAD_BLANK_PAGE");
      } else if (data != null && data.getSettingContent != null) {
        sendLog("SETTING_LOAD_SETTING_PAGE");
        setinitialize(false);
      }
    }
  }, [loading]);

  if (shopName == null || firstName == null) {
    return (
      <>
        <AppHeader />
        <AppSidebar />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
          <Box sx={{ width: "156.7rem", padding: "0rem 2rem 0" }}>
            <SettingBlankPage />
          </Box>
        </Box>
      </>
    );
  }
  return (
    <>
      <Dialog
        open={isOpenUnSave}
        onClose={() => {
          setIsOpenUnSave(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            width: "57.4rem",
            height: "27.3rem",
            borderRadius: "1rem",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: '15rem',
            fontFamily: "Arial",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            letterSpacing: "0.015rem",
            color: "#666666",
            position: "relative",
            paddingTop: '3rem',
          }}
        >
          <Box sx={{ fontSize: '18px', color: "#333333", fontWeight: "500", }}>Unsaved changes</Box>
          <Box>The information you modified has not been saved yet. If you want to save, please click "Save", if you don't want to save your modifications, click "Discard".</Box>
        </DialogTitle>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: '0 3rem',
            "& .button": {
              width: '12rem',
              height: '5rem',
              padding: "0rem",
              borderRadius: "1rem",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "150%",
              letterSpacing: "0.015rem",
              color: "#707070B2",
              backgroundColor: "#e4e4e4",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#e4e4e4",
              },
            },
            "& .delete-button": {
              color: "#FFF",
              backgroundColor: "#FF9711",
              "&:hover": {
                color: "#FFF",
                backgroundColor: "#FF9711",
              },
            },
          }}
        >
          <Button
            className="button"
            onClick={() => {
              if (currentUnSaveIndex === '1') {
                AccountRef.current.handleDiscardAccount();
              }
              if (currentUnSaveIndex === '2') {
                ProductRef.current.handleDiscardProduct();
              }
              setIsOpenUnSave(false);
            }}
          >
            Discard
          </Button>
          <Button
            className="button delete-button"
            onClick={async () => {
              if (currentUnSaveIndex === '1') {
                await AccountRef.current.handleSaveAccount();
              } else if (currentUnSaveIndex === '2') {
                await ProductRef.current.handleSaveProduct();
              }

            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog >
      <Box>
        <AppHeader />
        <AppSidebar />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
          <Box sx={{ width: "156.7rem", padding: "0rem 3rem 0" }}>
            {(!initialize || loading === false) && data != null && data.getSettingContent != null ?
              <Box>
                <Box sx={{ borderBottom: "0.1rem solid rgba(112, 112, 112, 0.5)", marginBottom: '1rem' }} >
                  <Tabs
                    value={tabIndex}
                    onChange={(event, newValue) => {
                      if (currentUnSaveIndex !== '-1') {
                        setNextTabIndex(newValue);
                        setIsOpenUnSave(true);
                        return;
                      }
                      setTabIndex(newValue);
                    }}
                    aria-label="setting"
                    TabIndicatorProps={{
                      style: {
                        height: "0.5rem",
                        backgroundColor: "#FF9711",
                      },
                    }}
                    sx={{
                      "& .MuiTab-root": {
                        fontSize: "18px",
                        color: "#707070",
                        lineHeight: "150%",
                        fontFamily: "Arial",
                        letterSpacing: "0.015rem",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        margin: '0 2rem',
                        padding: '0 2rem',
                        minWidth: '0',
                        "&.Mui-selected": {
                          color: "#FF9711",
                        },
                      },
                    }}
                  >
                    <Tab label={"Product"} {...a11yProps(0)} />
                    {isInExperiment(data.getSettingContent.userID) ? <Tab label={"Plan"} {...a11yProps(1)} /> : null}
                    <Tab label={"Account"} {...a11yProps(2)} />
                  </Tabs>
                </Box>

                <TabPanel value={tabIndex} index={0}>
                  <TabProduct
                    initialCurrency={data.getSettingContent.currency}
                    initialSettingContent={data.getSettingContent}
                    customPricingRule={data.getSettingContent.customPricingRule != null && data.getSettingContent.customPricingRule != "null" ? JSON.parse(data.getSettingContent.customPricingRule) : [{
                      id: 0,
                      costRangeStart: "0.00",
                      costRangeEnd: "10.00",
                      operativeSymbol: "plus",
                      operativeValue: "0.00",
                    }, {
                      id: 1,
                      costRangeStart: "10.01",
                      costRangeEnd: "",
                      operativeSymbol: "plus",
                      operativeValue: "0.00",
                    }, {
                      id: "Rest of the ranges",
                      costRangeStart: "",
                      costRangeEnd: "",
                      operativeSymbol: "plus",
                      operativeValue: "0.00",
                    }]}
                    shopName={shopName}
                    ProductRef={ProductRef}
                    setCurrentUnSaveIndex={setCurrentUnSaveIndex}
                    refetch={refetch}
                    setIsOpenUnSave={setIsOpenUnSave}
                    fulfillmentOption={data.getSettingContent.defaultFulfillmentOption}
                    productPricingRuleComposition={data.getSettingContent.productPricingRuleComposition}
                  />
                </TabPanel>
                {isInExperiment(data.getSettingContent.userID) ? <TabPanel value={tabIndex} index={1}>
                  <TabPlan currentPlanShowDate={data.getSettingContent.currentPlanShowDate} subscriptionType={data.getSettingContent.subscriptionType} refetch={refetch} isShowCancel={data.getSettingContent.isShowCancel} />
                </TabPanel> : null}
                <TabPanel value={tabIndex} index={isInExperiment(data.getSettingContent.userID) ? 2 : 1}>
                  <TabAccount
                    userName={data.getSettingContent.email}
                    initialLanguage={data.getSettingContent.language}
                    setCurrentUnSaveIndex={setCurrentUnSaveIndex}
                    AccountRef={AccountRef}
                    refetch={refetch}
                    setIsOpenUnSave={setIsOpenUnSave}
                  />
                </TabPanel>
              </Box> : <Box
                sx={{
                  height: "40rem",
                  marginTop: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>}
          </Box>
        </Box>
      </Box>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, onClick } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      onClick={onClick}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

