export const LanguageList = [
    {
        option: "English",
        value: "English",
    },
    // {
    //     option: "French",
    //     value: "French",
    // },
    // {
    //     option: "German",
    //     value: "German",
    // },
    // {
    //     option: "中文（简体）",
    //     value: "Chinese",
    // },
]