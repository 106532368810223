export const categoryList2 = [
  {
    option: 'All Categories',
    children: [{
      option: 'All Categories',
      value: 'all'
    }]
  },
  {
    option: 'Women Clothing',
    children: [
      {
        option: 'Women Swimwear',
        value: 'women_swimwear'
      }, {
        option: 'Women Muslim',
        value: 'women_muslim'
      }, {
        option: 'Bottom',
        value: 'bottom'
      }, {
        option: 'Underwear',
        value: 'underwear'
      }, {
        option: 'Women Accessories',
        value: 'women_accessories'
      }
    ]
  }, {
    option: 'Men Clothing',
    children: [{
      option: 'Men Pants',
      value: 'men_pants'
    }, {
      option: 'Men Coats',
      value: 'men_coats'
    }
      , {
      option: 'Men Novelty Special',
      value: 'men_novelty_special'
    }]
  }, {
    option: 'Cell Phone',
    children: [{
      option: 'Cell Phone Accessories',
      value: 'cell_phone_accessories'
    }, {
      option: 'Phone Cases',
      value: 'phone_cases'
    }, {
      option: 'Phone Accessories',
      value: 'phone_accessories'
    }, {
      option: 'Phone Parts',
      value: 'phone_parts'
    }]
  }, {
    option: 'Computer Office & Security',
    children: [{
      option: 'Laptops',
      value: 'laptops'
    }, {
      option: 'NetWorking',
      value: 'networking'
    }, {
      option: 'Office Electronics',
      value: 'office_electronics'
    },
    {
      option: 'Security Protection',
      value: 'security_protection'
    }]
  }, {
    option: 'Consumer Eletronics',
    children: [{
      option: 'Accessories Parts',
      value: 'accessories_parts',
    }, {
      option: 'Home Audio Video',
      value: 'home_audio_video'
    }, {
      option: 'Camera',
      value: 'camera'
    }, {
      option: 'Portable Audio Video',
      value: 'portable_audio_video'
    }, {
      option: 'Video Game',
      value: "video_game",
    }, {
      option: 'Smart Electronics',
      value: 'smart_electronics'
    }]
  }, {
    option: 'Jewelry & Watches',
    children: [{
      option: 'Jewelry Accessories',
      value: "jewelry_accessories"
    }, {
      option: 'Fine Jewelry',
      value: "fine_jewlery"
    }, {
      option: 'Wedding Engagement Jewelry',
      value: 'wedding_engagement_jewelry'
    }, {
      option: 'Beads Jewelry',
      value: 'beads_jewelry'
    }, {
      option: 'Wedding',
      value: 'wedding'
    }, {
      option: 'Men Watches',
      value: 'men_watches'
    }, {
      option: 'Women Watches',
      value: 'women_watches'
    }]
  }, {
    option: 'Home & Garden',
    children: [{
      option: 'Art Crafts Sewing',
      value: 'art_crafts_sewing'
    }, {
      option: 'Home Textile',
      value: 'home_textile'
    }, {
      option: 'Home Decor',
      value: 'home_decor'
    }, {
      option: 'Festive Party Supplies',
      value: "festive_party_supplies",
    }, {
      option: 'Home Storage Organization',
      value: 'home_storage_organization'
    }, {
      option: 'Household Merchandlises',
      value: "household-merchandises",
    }, {
      option: 'Kitchen Dining Bar',
      value: 'kitchen_dining_bar'
    }, {
      option: 'Garden Supplies',
      value: 'garden_supplies'
    }, {
      option: 'Smart Home Switch',
      value: "smart_home_switch",
    }, {
      option: 'Furniture',
      value: 'furniture'
    }]
  }, {
    option: 'Bags & Shoes',
    children: [{
      option: 'Luggage Bags',
      value: 'luggage_bags'
    }, {
      option: 'Women Bags',
      value: 'women_bags'
    }, {
      option: 'Men Bags',
      value: 'men_bags'
    }, {
      option: 'Women Shoes',
      value: 'women_shoes'
    }, {
      option: 'Men Shoes',
      value: 'men_shoes'
    }]
  }, {
    option: 'Toys & Kids',
    children: [{
      option: 'Building Construction Toys',
      value: "building_construction_toys",
    }, {
      option: 'Remote Control Toys',
      value: "remote_control_toys",
    }, {
      option: 'Girl Kids Clothings',
      value: "girl_kids_clothings",
    }, {
      option: 'Boy Kids Clothings',
      value: "boy_kids_clothings",
    }, {
      option: 'Girl Kids',
      value: "girl_kids",
    }, {
      option: 'Mother Kids',
      value: "mother_kids"
    }]
  }, {
    option: 'Sports Entertainment',
    value: null,
    children: [{
      option: 'Swimming',
      value: "swimming",
    }, {
      option: 'Cycling',
      value: "cycling",
    }, {
      option: 'Fishing',
      value: "fishing",
    }, {
      option: 'Sneakers',
      value: "sneakers",
    }, {
      option: 'Sports Clothings',
      value: "sports_clothings",
    }]
  }, {
    option: 'Beauty & Health & Hair',
    children: [{
      option: 'Skin Care',
      value: "skin_care",
    }, {
      option: 'Health Care',
      value: "health_care",
    }, {
      option: 'Make Up',
      value: "makeup",
    }, {
      option: 'Nail Art Tools',
      value: "nail_art_tools",
    }, {
      option: 'Hair Care Styling',
      value: "hair_care_styling",
    }, {
      option: 'Massage Relaxation',
      value: "massage_relaxation",
    },
    {
      option: 'Human Hair Weaves',
      value: "human_hair_weaves",
    }, {
      option: 'Lace Wigs',
      value: "lace_wigs",
    }, {
      option: 'Synthetic Wigs',
      value: "synthetic_wigs",
    }]
  }, {
    option: 'Automobiles & Motorcycles',
    children: [{
      option: 'Exterior Accessories',
      value: "exterior_accessories"
    }, {
      option: 'Car Electronic',
      value: "car_electronic"
    }, {
      option: 'Motorcycle Accessories',
      value: "motorcycle_accessories"
    }, {
      option: 'Home Interior Accessories',
      value: "home_interior_accessories"
    }]
  }, {
    option: 'Tools & Home Improvement',
    children: [{
      option: 'Tools',
      value: "tools",
    },
    {
      option: 'Home Improvement',
      value: "home_improvement",
    }, {
      option: 'Outdoor Light',
      value: "outdoor_light",
    }, {
      option: 'Ceiling Lights Fans',
      value: "ceiling_lights_fans",
    }]
  }
];

export const categoryList = [
  {
    option: 'All Categories',
    children: [{
      option: 'All Categories',
      value: 'all'
    }]
  },
  {
    "option": "Cellphones & Telecommunications",
    "id": 509,
    "children": [
      {
        "option": "Cellphones",
        "value": "5090301"
      },
      {
        "option": "Half-wrapped Cases",
        "value": "200217991"
      },
      {
        "option": "Phone Screen Protectors",
        "value": "200003089"
      },
      {
        "option": "Mobile Phone Cables",
        "value": "100003569"
      },
      {
        "option": "Wallet Cases",
        "value": "200216972"
      },
      {
        "option": "Mobile Phone LCD Screens",
        "value": "200005298"
      },
      {
        "option": "Mobile Phone Chargers",
        "value": "100003571"
      },
      {
        "option": "Wireless Chargers",
        "value": "200217751"
      },
      {
        "option": "Phone Accessory Bundles & Sets",
        "value": "200003169"
      },
      {
        "option": "Phone Holders & Stands",
        "value": "5093004"
      }
    ]
  },
  {
    "option": "Consumer Electronics",
    "id": 44,
    "children": [
      {
        "option": "Earphones & Headphones",
        "value": "63705"
      },
      {
        "option": "Phone Earphones & Headphones",
        "value": "200216654"
      },
      {
        "option": "Bluetooth Earphones & Headphones",
        "value": "200216593"
      },
      {
        "option": "Earphones",
        "value": "200216686"
      }
    ]
  },
  {
    "option": "Security & Protection",
    "id": 30,
    "children": [
      {
        "option": "Security & Protection",
        "value": "30"
      }
    ]
  },
  {
    "option": "Tools",
    "id": 1420,
    "children": [
      {
        "option": "Tools",
        "value": "1420"
      }
    ]
  },
  {
    "option": "Computer & Office",
    "id": 7,
    "children": [
      {
        "option": "Computer & Office",
        "value": "7"
      }
    ]
  },
  {
    "option": "Electronic Components & Supplies",
    "id": 502,
    "children": [
      {
        "option": "Electronic Components & Supplies",
        "value": "502"
      }
    ]
  },
  {
    "option": "Automobiles & Motorcycles",
    "id": 34,
    "children": [
      {
        "option": "Automobiles & Motorcycles",
        "value": "34"
      }
    ]
  },
  {
    "option": "Home Improvement",
    "id": 13,
    "children": [
      {
        "option": "Home Improvement",
        "value": "13"
      }
    ]
  },
  {
    "option": "Home & Garden",
    "id": 15,
    "children": [
      {
        "option": "Home & Garden",
        "value": "15"
      }
    ]
  },
  {
    "option": "Sports & Entertainment",
    "id": 18,
    "children": [
      {
        "option": "Sports & Entertainment",
        "value": "18"
      }
    ]
  },
  {
    "option": "Lights & Lighting",
    "id": 39,
    "children": [
      {
        "option": "Lights & Lighting",
        "value": "39"
      }
    ]
  },
  {
    "option": "Toys & Hobbies",
    "id": 26,
    "children": [
      {
        "option": "Toys & Hobbies",
        "value": "26"
      }
    ]
  },
  {
    "option": "Men's Clothing",
    "id": 100003070,
    "children": [
      {
        "option": "Men's Clothing",
        "value": "100003070"
      }
    ]
  },
  {
    "option": "Beauty & Health",
    "id": 66,
    "children": [
      {
        "option": "Beauty & Health",
        "value": "66"
      }
    ]
  },
  {
    "option": "Education & Office Supplies",
    "id": 21,
    "children": [
      {
        "option": "Education & Office Supplies",
        "value": "21"
      }
    ]
  },
  {
    "option": "Jewelry & Accessories",
    "id": 1509,
    "children": [
      {
        "option": "Jewelry & Accessories",
        "value": "1509"
      }
    ]
  },
  {
    "option": "Home Appliances",
    "id": 6,
    "children": [
      {
        "option": "Home Appliances",
        "value": "6"
      }
    ]
  },
  {
    "option": "Luggage & Bags",
    "id": 1524,
    "children": [
      {
        "option": "Luggage & Bags",
        "value": "1524"
      }
    ]
  },
  {
    "option": "Mother & Kids",
    "id": 1501,
    "children": [
      {
        "option": "Mother & Kids",
        "value": "1501"
      }
    ]
  },
  {
    "option": "Women's Clothing",
    "id": 100003109,
    "children": [
      {
        "option": "Women's Clothing",
        "value": "100003109"
      }
    ]
  },
  {
    "option": "Apparel Accessories",
    "id": 205776616,
    "children": [
      {
        "option": "Apparel Accessories",
        "value": "205776616"
      }
    ]
  },
  {
    "option": "Underwear & Sleepwears",
    "id": 205779615,
    "children": [
      {
        "option": "Underwear & Sleepwears",
        "value": "205779615"
      }
    ]
  }
]

export const gradeSelectedArray = [{
  option: '4.0',
  value: '4.0'
}, {
  option: '4.1',
  value: '4.1'
}, {
  option: '4.2',
  value: '4.2'
}, {
  option: '4.3',
  value: '4.3'
}, {
  option: '4.4',
  value: '4.4'
}, {
  option: '4.5',
  value: '4.5'
}, {
  option: '4.6',
  value: '4.6'
}, {
  option: '4.7',
  value: '4.7'
}, {
  option: '4.8',
  value: '4.8'
}, {
  option: '4.9',
  value: '4.9'
}, {
  option: '5.0',
  value: '5.0'
}];

export const percentageSelectedArray = [{
  option: '90%',
  value: '90'
}, {
  option: '91%',
  value: '91'
}, {
  option: '92%',
  value: '92'
}, {
  option: '93%',
  value: '93'
}, {
  option: '94%',
  value: '94'
}, {
  option: '95%',
  value: '95'
}, {
  option: '96%',
  value: '96'
}, {
  option: '97%',
  value: '97'
}, {
  option: '98%',
  value: '98'
}, {
  option: '99%',
  value: '99'
}, {
  option: '100%',
  value: '100'
}];

export const sortByList = [
  {
    option: "Relevance",
    value: "relevance",
  },
  {
    option: "Cartifind Score",
    value: "cartifind",
  },
  // {
  //   option: "Number of Orders",
  //   value: "numberSold",
  // },
  {
    option: "Price High to Low",
    value: "price",
  },
  {
    option: "Price Low to High",
    value: "price asc",
  }];