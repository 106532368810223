import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Context } from "../Context/Context";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Checkbox } from "@mui/material";
import CustomSelected from "../CommonComponents/CustomSelected.react";
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';

const SAVE_AI_TEMPLATE_LIST = gql`
  mutation saveAITemplateList($AITemplateListID: Int, $AITemplateListName: String, $AITemplateListPrompt: String) {
    saveAITemplateList(AITemplateListID: $AITemplateListID, AITemplateListName: $AITemplateListName, AITemplateListPrompt: $AITemplateListPrompt) {
      status
      message
    }
  }
`;

const GET_AI_TEMPLATE_LIST = gql`
  query getAITemplateList {
    getAITemplateList {
      id
      name
      prompt
    }
  }
`;

const GET_OPENAI_CHATGPT_TOKEN = gql`
  query getOpenAIChatGPTToken {
    getOpenAIChatGPTToken 
  }
`;

const SAVE_OPENAI_CHATGPT_TOKEN = gql`
mutation mutation($OpenAIChatGPTToken: String) {
  saveOpenAIChatGPTToken(OpenAIChatGPTToken: $OpenAIChatGPTToken){
    status
    message
  }
  }
`;

const GET_PRODUCT_COLLECTION = gql`
mutation getProductCollection {
  getProductCollection {
    collectionOptionTitleList
  }
}
`;
const AITemplateList = [{
  name: "Template 1",
  prompt: "Template 1"
}]
const AITemplateSelectList = [{
  option: "Template 1",
  value: "Template 1"
}]

let AITemplateOptionsMap = new Map();
let AITemplateOptions = [{
  option: "Template 1",
  value: 0,
}]
let currentDefaultOption = "Template 1";
let timeID = null;
export default function ProductContent(props) {
  const {

  } = props;
  const { state, setState } = React.useContext(Context);
  const { sendLog, tabValue } = state;
  const [token, setToken] = useState("");
  const [currentAITemplate, setCurrentAITemplate] = useState(0);
  const [currentTemplateName, setcurrentTemplateName] = useState("Template 1");
  const [currentPromptInput, setCurrentPromptInput] = useState("");
  const [initial, setInitial] = useState(true);
  const { loading, data, refetch } = useQuery(GET_AI_TEMPLATE_LIST, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const { loading: tokenLoading, data: tokenData, refetch: tokenRefetch } = useQuery(GET_OPENAI_CHATGPT_TOKEN, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const [save_ai_template_list] = useMutation(SAVE_AI_TEMPLATE_LIST);
  const [save_openai_chatgpt_token] = useMutation(SAVE_OPENAI_CHATGPT_TOKEN);
  const setTemplateEffect = (value) => {
    setCurrentAITemplate(value);
    setcurrentTemplateName(AITemplateOptionsMap.get(value).name);
    setCurrentPromptInput(AITemplateOptionsMap.get(value).prompt);
  }
  const handleSaveTemplate = (currentTemplateName, currentPromptInput) => {
    clearTimeout(timeID);
    timeID = setTimeout(() => {
      save_ai_template_list({
        variables: {
          AITemplateListID: currentAITemplate,
          AITemplateListName: currentTemplateName,
          AITemplateListPrompt: currentPromptInput
        },
      }).then(result => {
        refetch().then(res => {
          if (res != null && res.data != null && res.data.getAITemplateList != null) {
            AITemplateOptions = res.data.getAITemplateList.map(item => {
              AITemplateOptionsMap.set(
                item.id,
                item,
              );
              return {
                option: item.name,
                value: item.id,
              }
            });
          }
          currentDefaultOption = currentTemplateName;
        });
      });
    }, 1000);

  }

  useEffect(() => {
    if (data != null && data.getAITemplateList != null) {
      AITemplateOptions = data.getAITemplateList.map(item => {
        AITemplateOptionsMap.set(
          item.id,
          item,
        );
        return {
          option: item.name,
          value: item.id,
        }
      });
      if (initial) {
        currentDefaultOption = AITemplateOptions[0].option;
        setCurrentAITemplate(AITemplateOptions[0].value);
        setcurrentTemplateName(AITemplateOptions[0].option);
        setCurrentPromptInput(data.getAITemplateList[0].prompt);
        setInitial(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (tokenData != null && tokenData.getOpenAIChatGPTToken != null) {
      setToken(tokenData.getOpenAIChatGPTToken)
    }
  }, [tokenLoading]);
  useEffect(() => {
    tokenRefetch();
  }, [tabValue]);

  return (
    <Box sx={{
      maxWidth: "600px",
      margin: "0px auto"
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0px'
      }}>
        <Box>Open AI ChatGPT Token:</Box>
        <TextField
          sx={{
            width: "300px",
            height: "35px",
            margin: '0px 20px',
            "& .MuiInputBase-input": {
              fontSize: "14px",
              lineHeight: "150%",
            },
            "&>.MuiInputBase-root.MuiOutlinedInput-root": {
              padding: "0 5px!important",
              height: "35px",
            },
            "& .MuiOutlinedInput-root.Mui-focused fieldset": {
              borderColor: "#c4c4c4",
              borderWidth: '1px',
              "&.Mui-focused": {
                borderColor: "#c4c4c4!important",
                borderWidth: '1px',
              },
            },
          }}
          value={token}
          onChange={(e) => {
            setToken(e.target.value);
          }}
          onBlur={() => {
            save_openai_chatgpt_token({
              variables: {
                OpenAIChatGPTToken: token
              },
            })
          }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', textDecoration: 'underline', cursor: "pointer" }} onClick={() => { window.open("https://platform.openai.com/account/api-keys") }}>where to find
          <Box sx={{
            width: '10px',
            height: '10px',
            background: 'url("img/myProduct/myProduct_icon_skip.png") no-repeat 0 0',
            backgroundSize: 'contain',
            marginLeft: "3px",
            cursor: 'pointer'
          }} /></Box>
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0px'
      }}>

        <Box sx={{ fontSize: '16px', lineHeight: "150%" }}>
          <CustomSelected
            width="400px"
            height="40px"
            selectedWidth="400px"
            border="1px solid #999999"
            selectedItems={AITemplateOptions}
            defaultOption={currentDefaultOption}
            selectedValue={currentAITemplate}
            setSelectValue={setTemplateEffect}
          />
        </Box>
        <AddCircleOutlineSharpIcon sx={{ fontSize: '32px', color: "#666666", marginLeft: '10px', cursor: "pointer" }} onClick={() => {
          save_ai_template_list({
            variables: {
              AITemplateListID: Number(AITemplateOptions[AITemplateOptions.length - 1].value) + 1,
              AITemplateListName: "Template " + (Number(AITemplateOptions[AITemplateOptions.length - 1].value) + 2),
              AITemplateListPrompt: ""
            },
          }).then(result => {
            refetch().then(res => {
              if (result != null && result.data != null && result.data.saveAITemplateList != null && result.data.saveAITemplateList.status) {
                setCurrentAITemplate(Number(AITemplateOptions[AITemplateOptions.length - 1].value) + 1);
                setcurrentTemplateName("Template " + (Number(AITemplateOptions[AITemplateOptions.length - 1].value) + 2));
                setCurrentPromptInput("");
              }
              if (res != null && res.data != null && res.data.getAITemplateList != null) {
                AITemplateOptions = res.data.getAITemplateList.map(item => {
                  AITemplateOptionsMap.set(
                    item.id,
                    item,
                  );
                  return {
                    option: item.name,
                    value: item.id,
                  }
                });
              }
            });
          });
        }} />
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0px'
      }}>
        <Box>Template name:</Box>
        <TextField
          onChange={(e) => {
            setcurrentTemplateName(e.target.value);
            handleSaveTemplate(e.target.value, currentPromptInput);
          }}
          value={currentTemplateName}
          sx={{
            width: "300px",
            height: "35px",
            margin: '0px 20px',
            "& .MuiInputBase-input": {
              fontSize: "14px",
              lineHeight: "150%",
            },
            "&>.MuiInputBase-root.MuiOutlinedInput-root": {
              padding: "0 5px!important",
              height: "35px",
            },
            "& .MuiOutlinedInput-root.Mui-focused fieldset": {
              borderColor: "#c4c4c4",
              borderWidth: '1px',
              "&.Mui-focused": {
                borderColor: "#c4c4c4!important",
                borderWidth: '1px',
              },
            },
          }}
        // sx={{
        //   paddingRight: "0",
        //   width: "300px",
        //   height: "35px",
        //   backgroundColor: "#FFF",
        //   borderRadius: "10px",
        //   "& .MuiInputBase-root": {
        //     "&.MuiOutlinedInput-root input": {
        //       textIndent: '20px',
        //       height: '35px',
        //       fontSize: '14px',
        //       padding: 0
        //     },
        //   },
        //   "& .MuiOutlinedInput-root": {
        //     border: "none",
        //     "&.Mui-focused fieldset": {
        //       borderRadius: "10px",
        //       border: "none",
        //     },
        //   },
        // }}
        />
      </Box>
      <Box sx={{
        margin: '20px 0px'
      }}>
        <Box sx={{ marginBottom: '10px' }}>Template prompt:</Box>
        <Box sx={{
          width: '600px',
          border: '1px solid #c4c4c4',
          borderRadius: '5px',
        }}>
          <TextField
            sx={{
              width: '100%',
              paddingRight: "0",
              backgroundColor: "#fff",
              border: 'none',
              fontSize: "14px",
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: "24px",
              letterSpacing: '0.15px',
              color: "rgba(0, 0, 0, 0.6)",
              borderColor: "transparent",
              borderRadius: '10px',
              "& fieldset": {
                borderColor: "transparent!important",
                borderWidth: '1px',
                "&.Mui-focused": {
                  borderColor: "transparent!important",
                  borderWidth: '1px',
                },
              },
              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                padding: "8px 12px",
                fontSize: '14px'
              }
            }}
            id="outlined-multiline-static"
            multiline
            rows={6}
            placeholder="Enter your template prompt"
            value={currentPromptInput}
            onChange={(e) => {
              setCurrentPromptInput(e.target.value);
              handleSaveTemplate(currentTemplateName, e.target.value);
            }}
          />
        </Box>

      </Box>
    </Box >
  );
}

