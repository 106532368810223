
import React, { useEffect, useState } from "react";
import AppHeader from "../AppHeader.react";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import TablePagination from '@mui/material/TablePagination';
import Drawer from '@mui/material/Drawer';
import Fade from '@mui/material/Fade';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';


import { Checkbox } from "@mui/material";
import { Context } from "../Context/Context";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import AppSidebar from "../AppSidebar.react";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MyProductBlankPage from "./MyProductBlankPage.react";
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import ProductEditDialog from "../CommonComponents/ProductEditDialog.react";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import CustomSelected from "../CommonComponents/CustomSelected.react";
import InputAdornment from '@mui/material/InputAdornment';


const GET_USER_PRODUCT_LIST = gql`
query Product($pageIndex: Int, $pageSize: Int, $searchWords: String, $sort: String, $status: String, $collections: [String], $types: [String], $tags: [String], $suppliers: [String]) {
  getUserProductList(pageIndex: $pageIndex, pageSize: $pageSize, searchWords: $searchWords, sort: $sort, status: $status, collections: $collections, types: $types, tags: $tags, suppliers: $suppliers) {
      message
      isFilterProductList
      pageSize
      viewMode
      currentProductCount
      result {
        id
        title
        price
        priceCurrency
        multipliedPrice
        images
        thumbnailSource
        currency
        crawlerID
        productID
        shopifyProductID
        productSource
        productSourceURL
        available
        shippingFee
        inventory
        tagsList
        collectionList
        fulfillmentOption
      }
    }
  }
`;

const REMOVE_PRODUCTS_FROM_USER = gql`	
  mutation RemoveProductsFromUser($IDs: [String]) {	
    removeProductsFromUser(IDs: $IDs) {	
      status	
      message	
    }	
  }	
`;

const PUSH_SELECTED_PRODUCTS = gql`
  mutation Mutation($IDList: [String]) {
    pushSelectedProduct(IDList: $IDList) {
      status
      result {
        failedProductSchemaIDList
        successProductSchemaIDList
        successShopifyIDList
        failedReasonList
      }
      isShowUpgrade
      canBeEvaluated
      message
    }
  }
`;

const PUBLISH_SELECTED_PRODUCTS = gql`
  mutation Mutation($IDList: [String]) {
    publishSelectedProduct(IDList: $IDList) {
      status
      result {
        failedProductSchemaIDList
        successProductSchemaIDList
        successShopifyIDList
        failedReasonList
      }
      message
    }
  }
`;

const RESET_SELECTED_USER_PRODUCT_DETAIL = gql`
  mutation Mutation($IDList: [String]) {
    resetSelectedUserProductDetail(IDList: $IDList) {
        status,
        message
    }
  }
`;

const OPTIMIZE_SELECTED_PRODUCT = gql`
  mutation Mutation($IDList: [String]) {
    optimizeSelectedProduct(IDList: $IDList) {
        status
        result {
          failedProductSchemaIDList
          successProductSchemaIDList
          failedReasonList
        }
        message
    }
  }
`;

const GET_SUBSCRIPTION_STATUS = gql`
  query getSubscriptionStatus {
    getSubscriptionStatus
  }
`;

const GET_PRODUCT_COLLECTION = gql`
mutation getProductCollection {
  getProductCollection {
    collectionOptionTitleList
  }
}
`;

const SAVE_MYPRODUCTS_PAGE_SETTING = gql`
mutation saveMyProductsSetting($pageSize: Int, $viewMode: String) {
  saveMyProductsSetting(pageSize: $pageSize, viewMode: $viewMode) {
    status,
    message
  }
}
`;

const GET_IS_RATING_SHOWN = gql`
  query getIsRatingShown {
    getIsRatingShown
  }
`;

const SET_IS_RATING_SHOWN_TRUE = gql`
mutation setIsRatingShownTrue {
  setIsRatingShownTrue {
    status
    message
  }
}
`;

const SET_SELECT_PRODUCT_PRICE = gql`
mutation setSelectedProductPrice($productIDList: [String], $method: String, $inputValue: String) {
  setSelectedProductPrice(productIDList:$productIDList, method: $method, inputValue: $inputValue) {
    status
    message
  }
}
`;

const SET_SELECT_PRODUCT_COLLECTION_AND_TAG = gql`
mutation setSelectedProductCollectionAndTag($productIDList: [String], $collectionList: [String], $tagList: [String], $productType: String) {
  setSelectedProductCollectionAndTag(productIDList:$productIDList, collectionList: $collectionList, tagList: $tagList, productType: $productType) {
    status
    message
  }
}
`;


const supplierList = [
  'AliExpress',
  'Amazon',
  'Temu',
  // 'Etsy',
  '1688',
  'Shein',
  'Banggood',
];

const typeList = [
  'Men',
  'Women',
  'Pet',
  'Youth',
]

const tagList = [
  'Spring',
  'Summer',
  'Autumn',
  'Winter',
]

const ratingLabels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

const editPricePercentageList = [
  {
    option: '0%',
    value: '0'
  },
  {
    option: '25%',
    value: '25'
  },
  {
    option: '50%',
    value: '50'
  },
  {
    option: '75%',
    value: '75'
  },
  {
    option: '100%',
    value: '100'
  },
  {
    option: '125%',
    value: '125'
  },
  {
    option: '150%',
    value: '150'
  },
  {
    option: '175%',
    value: '175'
  },
  {
    option: '200%',
    value: '200'
  },
  {
    option: '225%',
    value: '225'
  },
  {
    option: '250%',
    value: '250'
  },
  {
    option: '275%',
    value: '275'
  },
  {
    option: '300%',
    value: '300'
  },
]
function getRatingLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${ratingLabels[value]}`;
}

function getStyles(collection, collectionSelectedList, theme) {
  return {
    fontWeight:
      collectionSelectedList.indexOf(collection) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const DetailError = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFEDED",
    fontSize: "20px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFEDED",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: '280px',
    fontSize: "12px",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 1px rgb(0 0 0 / 12%)",
  },
}));

const ButtonTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement={placement ? placement : "bottom"} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
    fontSize: "20px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    color: "#FFF",
    maxWidth: '260px',
    fontSize: "12px",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 1px rgb(0 0 0 / 12%)",
  },
}));

const getButtonTooltipLayout = (title) => {
  return <React.Fragment>
    <Box sx={{
      color: '#FFF',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '150%'
    }}>
      {title}
    </Box>
  </React.Fragment>
}

function ProductCard(props) {
  const {
    productSelectedNum,
    currentViewMode,
    id,
    index,
    title,
    price,
    currency,
    multipliedPrice,
    thumbnailSource,
    shopifyProductID,
    productID,
    isPushingAll,
    pushNum,
    available,
    setIsOpenEdit,
    crawlerID,
    productSource,
    productSourceURL,
    selectedIDList,
    setSelectedIDList,
    setCheckedAll,
    productList,
    handleRefreshPage,
    undateCurrentProductIDList,
    isPushFailed,
    pushReason,
    setPushSuccessIDList,
    setPushFailedIDList,
    setPushFailedReasonList,
    setCurrentFailedCount,
    setPushStatus,
    pushFailedIDList,
    pushFailedReasonList,
    setPushIDList,
    setOpenDeleteAlert,
    setDeleteDisabledProduct,
    shippingFee,
    inventory,
    tagsList,
    collectionList,
    fulfillmentOption,
    setStatus,
    setTagValue,
    setSearchImmediate,
    handleRefetchMyProductList,
    setPageIndex,
    collectionSelectedList,
    setCollectionSelectedList,
    setOpenRatingAlert,
    optimizeFailedReason,
    isOptimizeFailed,
  } = props;

  const { setState, state } = React.useContext(Context);
  const { sendLog, isPushingToShopify, shopName, } = state;
  const currentClickProduct = sessionStorage.getItem("currentClickProduct") != null ? sessionStorage.getItem("currentClickProduct") : `{}`;
  const { id: currentClickID } = JSON.parse(currentClickProduct);
  const navigate = useNavigate();
  const [isPushing, setIsPushing] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [push_selected_products] = useMutation(PUSH_SELECTED_PRODUCTS);
  const [refetchPageNum, setRefetchPageNum] = React.useState(0);
  const [isShowFailedReason, setIsShowFailedReason] = React.useState(false);
  const storePlatform = localStorage.getItem("storePlatform");
  const wixSiteID = localStorage.getItem("wixSiteID");
  const storePlatformCapitalize = storePlatform != null && storePlatform.length > 0 ? storePlatform.slice(0, 1).toUpperCase() + storePlatform.slice(1, storePlatform.length) : "Shopify";
  useEffect(() => {
    setIsShowFailedReason(isPushFailed || isOptimizeFailed);
  }, [isPushFailed, isOptimizeFailed])


  useEffect(() => {
    let timer;
    if (isPushingAll) {
      setIsPushing(true)
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 98) {
            return 98;
          }
          const diff = Math.random() * (pushNum ? Number(pushNum) / 2 : 5);
          return Math.min(oldProgress + diff, 98);
        });
      }, 500);
    }
    return () => {
      setIsPushing(false);
      clearInterval(timer);
      setProgress(0);
    }
  }, [isPushingAll]);

  useEffect(() => {
    let timer;
    if (currentClickID === id) {
      if (isPushingToShopify) {
        setIsPushing(true);
        timer = setInterval(() => {
          setProgress((oldProgress) => {
            if (oldProgress === 98) {
              return 98;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 98);
          });
        }, 500);
      } else {
        setIsPushing(false);
        clearInterval(timer);
      }
    }
    return () => {
      if (currentClickID === id && isPushingToShopify === false) {
        setIsPushing(false);
        clearInterval(timer);
        setProgress(0);
      }
    }
  }, [isPushingToShopify, currentClickID]);

  return (
    <Box sx={{
      background: selectedIDList.includes(id) ? "rgba(0, 0, 0, 0.06)" : "#FFF",
      "&:hover": {
        background: "rgba(0, 0, 0, 0.06)",
      }
    }} onClick={(e) => {
      const Arr = selectedIDList;
      if (selectedIDList.includes(id)) {
        Arr.splice(selectedIDList.indexOf(id), 1);
      } else {
        Arr.splice(0, 0, id);
      }
      setSelectedIDList(Arr);
      let absoluteQuantity = 0;
      productList.map(item => {
        if (item.available) {
          absoluteQuantity++;
        }
      });
      if (Arr.length === absoluteQuantity) {
        setCheckedAll(true);
      } else {
        setCheckedAll(false);
      }
      handleRefreshPage();
    }}>
      {
        currentViewMode === "concise" ?
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            height: '84px',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            "&>div": {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: '0',
              gap: '4px',
            }
          }}>
            <Box sx={{
              width: '130px'
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0px 10px 0px 0px'
              }}>
                <Checkbox
                  checked={selectedIDList.includes(id)}
                  onChange={(e) => {
                    const Arr = selectedIDList;
                    if (selectedIDList.includes(id)) {
                      Arr.splice(selectedIDList.indexOf(id), 1);
                    } else {
                      Arr.splice(0, 0, id);
                    }
                    setSelectedIDList(Arr);
                    let absoluteQuantity = 0;
                    productList.map(item => {
                      if (item.available) {
                        absoluteQuantity++;
                      }
                    });
                    if (Arr.length === absoluteQuantity) {
                      setCheckedAll(true);
                    } else {
                      setCheckedAll(false);
                    }
                    handleRefreshPage();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                  icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                  </Box>}
                  checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                  </Box>}
                />
                <Box sx={{
                  width: '79px',
                  height: '40px',
                  borderRaidus: '4px',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  display: 'flex',
                  "&>div": {
                    width: '39px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }
                }}>
                  <ButtonTooltip title={getButtonTooltipLayout("Edit product details")} arrow>
                    <Box sx={{
                      width: '39px',
                      height: '40px',
                      borderRight: '1px solid rgba(0, 0, 0, 0.12)'
                    }} onClick={(e) => {
                      e.stopPropagation();
                      setIsOpenEdit(true);
                      setState({
                        ...state,
                        isOpenImportProductDialog: true,
                        tabValue: 0,
                        currentClickProduct: { id, title, thumbnailSource, shopifyProductID },
                        setPushSuccessIDList,
                        setPushFailedIDList,
                        setPushFailedReasonList,
                        setCurrentFailedCount,
                        setPushStatus,
                        pushFailedIDList,
                        pushFailedReasonList,
                        setPushIDList
                      });
                      sessionStorage.setItem("isEditing", 'true');
                      sessionStorage.setItem("currentClickProduct", JSON.stringify({ id, title, thumbnailSource, shopifyProductID }));
                    }}>
                      <img src="img/myProduct/myProduct_icon_edit.png" width="19px" height="18px" />
                    </Box>
                  </ButtonTooltip>
                  <ButtonTooltip title={getButtonTooltipLayout(`Push to ${storePlatformCapitalize}`)} arrow>
                    <Box onClick={(e) => {
                      e.stopPropagation();
                      if (isPushing) {
                        return;
                      }
                      sendLog("MY_PRODUCTS_CARD_PUSH_TO_SHOPIFY_CLICK");
                      if (shopName == null) {
                        setState({
                          ...state,
                          isAccountPopUpDialogOpen: true,
                          currentComponentName: 'CONNECT_SHOPIFY',
                        });
                        return;
                      }

                      setIsPushing(true);
                      const timer = setInterval(() => {
                        setProgress((oldProgress) => {
                          if (oldProgress === 98) {
                            return 98;
                          }
                          const diff = Math.random() * 5;
                          return Math.min(oldProgress + diff, 98);
                        });
                      }, 500);
                      push_selected_products({
                        variables: {
                          IDList: [String(id)],
                        },
                      }).then((result) => {
                        const isEditing = sessionStorage.getItem("isEditing");
                        if (
                          result.data != null &&
                          result.data.pushSelectedProduct != null &&
                          result.data.pushSelectedProduct.result != null
                        ) {
                          if (result.data.pushSelectedProduct.status &&
                            result.data.pushSelectedProduct.result != null &&
                            result.data.pushSelectedProduct.result.successShopifyIDList != null) {
                            setState({
                              ...state,
                              openSnackbar: true,
                              snackbarText: 'Success! Your selected products have been pushed successfully.',
                              snackbarState: 'SUCCESS',
                              isOpenImportProductDialog: isEditing === 'true',
                            });
                            //Open Upgrade Dailog
                            if (result.data.pushSelectedProduct.isShowUpgrade) {
                              document.getElementById("UpgradePlanDialog").click();
                            }
                            //Open Rating Dailog
                            if (localStorage.getItem("isRatingShown") == "false" && result.data.pushSelectedProduct.canBeEvaluated) {
                              setOpenRatingAlert(true);
                            }
                          } else {
                            if (result.data.pushSelectedProduct.message === 'PLEASE_UPGRADE_PLAN') {
                              setState({
                                ...state,
                                isOpenSelectPlanDialog: true,
                                isOpenImportProductDialog: isEditing === 'true',
                              });
                              setIsPushing(false);
                              clearInterval(timer);
                              setProgress(0);
                              return;
                            } else {
                              setState({
                                ...state,
                                openSnackbar: true,
                                snackbarText: result.data.pushSelectedProduct.result.failedReasonList[0],
                                snackbarState: 'FAILED',
                                isOpenImportProductDialog: isEditing === 'true',
                              });
                            }
                          }
                        } else {
                          setState({
                            ...state,
                            openSnackbar: true,
                            snackbarText: `Item fails to push to ${storePlatformCapitalize}, Each variant must have an unique name.`,
                            snackbarState: 'FAILED',
                            isOpenImportProductDialog: isEditing === 'true',
                          });
                        }
                        if (
                          result.data != null &&
                          result.data.pushSelectedProduct != null &&
                          result.data.pushSelectedProduct.result != null &&
                          result.data.pushSelectedProduct.result.successProductSchemaIDList != null &&
                          result.data.pushSelectedProduct.result.failedProductSchemaIDList != null &&
                          result.data.pushSelectedProduct.result.successShopifyIDList != null
                        ) {
                          const cacheFailedIDList = pushFailedIDList;
                          const cacheFailedReasonList = pushFailedReasonList;
                          if (result.data.pushSelectedProduct.result.failedProductSchemaIDList.length > 0) {
                            if (cacheFailedIDList.includes(String(id))) {
                              cacheFailedReasonList[cacheFailedIDList.indexOf(String(id))] = result.data.pushSelectedProduct.result.failedReasonList[0];
                            } else {
                              cacheFailedIDList.push(result.data.pushSelectedProduct.result.failedProductSchemaIDList[0]);
                              cacheFailedReasonList.push(result.data.pushSelectedProduct.result.failedReasonList[0]);
                            }
                          } else if (cacheFailedIDList.includes(String(id))) {
                            cacheFailedIDList.splice(cacheFailedIDList.indexOf(String(id)), 1);
                            cacheFailedReasonList.splice(cacheFailedIDList.indexOf(String(id)), 1);
                          }
                          setPushSuccessIDList(result.data.pushSelectedProduct.result.successProductSchemaIDList);
                          setPushFailedIDList(cacheFailedIDList);
                          setPushFailedReasonList(cacheFailedReasonList);
                          undateCurrentProductIDList(result.data.pushSelectedProduct.result.successShopifyIDList[0]);
                        }
                        setIsPushing(false);
                        clearInterval(timer);
                        setProgress(0);
                      });
                    }}>
                      {storePlatform != "wix" ? <img src="img/myProduct/myProduct_icon_push.png" width="24px" height="24px" /> :
                        <img src="img/wix_logo.png" width="24px" height="24px" />}
                    </Box>
                  </ButtonTooltip>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: '80px', display: 'flex', alignItems: "center", justifyContent: 'center', "& img": { cursor: 'pointer' } }}>
              <img src={thumbnailSource} /*width="54px"*/ height="54px" onClick={(e) => {
                e.stopPropagation();
                setIsOpenEdit(true);
                setState({
                  ...state,
                  isOpenImportProductDialog: true,
                  tabValue: 0,
                  currentClickProduct: { id, title, thumbnailSource, shopifyProductID },
                  setPushSuccessIDList,
                  setPushFailedIDList,
                  setPushFailedReasonList,
                  setCurrentFailedCount,
                  setPushStatus,
                  pushFailedIDList,
                  pushFailedReasonList,
                  setPushIDList
                });
                sessionStorage.setItem("isEditing", 'true');
                sessionStorage.setItem("currentClickProduct", JSON.stringify({ id, title, thumbnailSource, shopifyProductID }));
              }} />
            </Box>
            <Box sx={{
              width: '180px'
            }} >
              <ButtonTooltip title={getButtonTooltipLayout(title)} arrow>
                <Box sx={{
                  width: '180px',
                  lineHeight: '20px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 2,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  cursor: 'pointer'
                }} onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenEdit(true);
                  setState({
                    ...state,
                    isOpenImportProductDialog: true,
                    tabValue: 0,
                    currentClickProduct: { id, title, thumbnailSource, shopifyProductID },
                    setPushSuccessIDList,
                    setPushFailedIDList,
                    setPushFailedReasonList,
                    setCurrentFailedCount,
                    setPushStatus,
                    pushFailedIDList,
                    pushFailedReasonList,
                    setPushIDList
                  });
                  sessionStorage.setItem("isEditing", 'true');
                  sessionStorage.setItem("currentClickProduct", JSON.stringify({ id, title, thumbnailSource, shopifyProductID }));
                }} >
                  {title}
                </Box>
              </ButtonTooltip>
            </Box>
            <Box sx={{ width: '150px' }}>
              <Box>{price + " " + currency}</Box>
            </Box>
            <Box sx={{ width: '150px' }}>
              <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "flex-start" }}>
                <Box>{shippingFee + " " + currency}</Box>
                {fulfillmentOption ? <ButtonTooltip title={getButtonTooltipLayout(`This is Fulfilled by Cartifind shipping cost.`)} arrow>
                  <Box sx={{
                    width: '13px',
                    height: '13px',
                    background: 'url("img/myProduct/product_fulfilled_by_cartifind.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                    marginLeft: "3px",
                    marginTop: "-3px",
                  }} />
                </ButtonTooltip> : <ButtonTooltip title={getButtonTooltipLayout(`This is Fulfilled by You shipping cost.`)} arrow>
                  <Box sx={{
                    width: '13px',
                    height: '13px',
                    background: 'url("img/myProduct/product_fulfilled_by_you.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                    marginLeft: "3px",
                    marginTop: "-3px",
                  }} />
                </ButtonTooltip>}
              </Box>
            </Box>
            <Box sx={{ width: '150px' }}>
              <Box>{multipliedPrice + " " + currency}</Box>
            </Box>
            <Box sx={{ width: '150px' }}>
              <Box>{(multipliedPrice - price - Number(shippingFee)).toFixed(2) + " " + currency}</Box>
              <Box sx={{ color: (price + Number(shippingFee) === 0 ? "0" : (100 * parseFloat((multipliedPrice - price - Number(shippingFee)) / (price + Number(shippingFee)))).toFixed(2) < 0) ? "rgba(255, 51, 51, 1)" : "rgba(97, 196, 84, 1)" }}>({price + Number(shippingFee) === 0 ? "0" : (100 * parseFloat((multipliedPrice - price - Number(shippingFee)) / (price + Number(shippingFee)))).toFixed(2)}%)</Box>
            </Box>
            {/* <Box sx={{ width: '100px' }}>
              <Box>{"Collection"}</Box>
              <Box>{"type"}</Box>
            </Box> */}
            <Box sx={{ width: "130px", }}>

              <ButtonTooltip title={getButtonTooltipLayout(`Show if product is pushed to ${storePlatformCapitalize}`)} arrow>
                <Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "500",
                  lineHeight: "150%",
                  letterSpacing: "0.15px",
                  fontStyle: "normal",
                  color: "#707070",
                  '& div': {
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "16px",
                    padding: '0px 10px',
                    cursor: 'pointer'
                  }
                }}>

                  {available ? (isPushFailed ? <Box sx={{
                    border: '3px solid #e94343',
                    backgroundColor: "#FFF",
                    color: 'rgba(0, 0, 0, 0.87)',
                  }} onClick={() => { setStatus("2") }}>
                    Not Imported
                  </Box> : shopifyProductID !== '0' ? (
                    <Box sx={{
                      backgroundColor: "rgba(198, 238, 179, 0.68)",
                      color: "rgba(0, 0, 0, 0.87)"
                    }} onClick={() => { setStatus("1") }}>
                      Imported
                    </Box>
                  ) : <Box sx={{
                    backgroundColor: "#D3D3D3AD",
                    color: 'rgba(0, 0, 0, 0.87)',
                  }} onClick={() => { setStatus("2") }}>
                    Not Imported
                  </Box>) : <Box sx={{
                    backgroundColor: "#D3D3D3AD",
                    color: 'rgba(0, 0, 0, 0.87)',
                  }} onClick={() => { setStatus("3") }}>
                    Sold Out
                  </Box>}
                </Box>
              </ButtonTooltip>
              {isPushFailed || isOptimizeFailed ?
                <ClickAwayListener onClickAway={() => { setIsShowFailedReason(false) }}>
                  <Box>
                    <ButtonTooltip
                      title={
                        <React.Fragment>
                          <Box sx={{
                            color: '#FFF',
                            fontSize: '12px',
                            fontWeight: '400',
                            lineHeight: '150%'
                          }}>
                            {/* The store has too many products to generate new variants. For details, see: */}
                            {pushReason != null ? <Box>push failed Reason: {pushReason.includes('https') ? pushReason.slice(0, pushReason.indexOf('https')) : pushReason}
                              {pushReason.includes('https') ? <Typography variant="span" sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { window.open(pushReason.slice(pushReason.indexOf('https'), pushReason.length)); }}>{pushReason.slice(pushReason.indexOf('https'), pushReason.length)}</Typography> : null}</Box> : null}
                            {optimizeFailedReason != null ? <Box>optimize failed Reason: {optimizeFailedReason}</Box> : null}
                          </Box>
                        </React.Fragment>
                      }
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      open={isShowFailedReason}
                      onClose={() => { setIsShowFailedReason(false); }}
                      onOpen={() => {
                        sendLog("MY_PRODUCTS_CARD_PUSH_TO_SHOPIFY_WRONG_ICON_HOVER");
                      }}>
                      <Box sx={{
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: '10px'
                      }}>
                        <Box sx={{
                          width: '20px',
                          height: '20px',
                          background: 'url("img/push_to_shopify_error_detail_icon.png") no-repeat 0 0',
                          backgroundSize: 'contain',
                        }} onClick={() => { setIsShowFailedReason(true) }}></Box>
                      </Box>
                    </ButtonTooltip>
                  </Box>
                </ClickAwayListener> : (shopifyProductID != null && shopifyProductID != "0" && <Box sx={{
                  display: "flex",
                  width: 'auto',
                  alignItems: 'center',
                  justifyContent: "center",
                  fontSize: '12px',
                  marginTop: '10px'
                }}>
                  {storePlatform != "wix" ? <ButtonTooltip title={getButtonTooltipLayout(`Click to visit Shopify link`)} arrow>
                    <Box sx={{ cursor: 'pointer', textDecoration: "underline" }} onClick={(e) => {
                      e.stopPropagation();
                      const beginIndex = shopifyProductID.indexOf('/Product/');
                      window.open(`https://admin.shopify.com/store/${shopName.replace('.myshopify.com', '')}/products/${shopifyProductID.slice(beginIndex + 9, shopifyProductID.length)}`)
                    }}>Shopify</Box>
                  </ButtonTooltip> : <ButtonTooltip title={getButtonTooltipLayout("Click to visit Wix link")} arrow>
                    <Box sx={{ cursor: 'pointer', textDecoration: "underline" }} onClick={(e) => {
                      e.stopPropagation();
                      window.open(`https://manage.wix.com/dashboard/${wixSiteID}/store/products/product/${shopifyProductID}`)
                    }}>Wix</Box>
                  </ButtonTooltip>}
                  <OpenInNewOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.87)", width: '10px', marginLeft: "3px", cursor: 'pointer' }} />
                </Box>)}
            </Box>
          </Box>
          :
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            height: '84px',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            "&>div": {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: '0',
              gap: '4px',
            }
          }}>
            <Box sx={{
              width: '130px'
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0px 10px 0px 0px'
              }}>
                <Checkbox
                  checked={selectedIDList.includes(id)}
                  onChange={(e) => {
                    const Arr = selectedIDList;
                    if (selectedIDList.includes(id)) {
                      Arr.splice(selectedIDList.indexOf(id), 1);
                    } else {
                      Arr.splice(0, 0, id);
                    }
                    setSelectedIDList(Arr);
                    let absoluteQuantity = 0;
                    productList.map(item => {
                      if (item.available) {
                        absoluteQuantity++;
                      }
                    });
                    if (Arr.length === absoluteQuantity) {
                      setCheckedAll(true);
                    } else {
                      setCheckedAll(false);
                    }
                    handleRefreshPage();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                  icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                  </Box>}
                  checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                  </Box>}
                />
                <Box sx={{
                  width: '79px',
                  height: '40px',
                  borderRaidus: '4px',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  display: 'flex',
                  "&>div": {
                    width: '39px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }
                }}>
                  <ButtonTooltip title={getButtonTooltipLayout("Edit product details")} arrow>
                    <Box sx={{
                      width: '39px',
                      height: '40px',
                      borderRight: '1px solid rgba(0, 0, 0, 0.12)'
                    }} onClick={(e) => {
                      e.stopPropagation();
                      setIsOpenEdit(true);
                      setState({
                        ...state,
                        isOpenImportProductDialog: true,
                        tabValue: 0,
                        currentClickProduct: { id, title, thumbnailSource, shopifyProductID },
                        setPushSuccessIDList,
                        setPushFailedIDList,
                        setPushFailedReasonList,
                        setCurrentFailedCount,
                        setPushStatus,
                        pushFailedIDList,
                        pushFailedReasonList,
                        setPushIDList
                      });
                      sessionStorage.setItem("isEditing", 'true');
                      sessionStorage.setItem("currentClickProduct", JSON.stringify({ id, title, thumbnailSource, shopifyProductID }));
                    }}>
                      <img src="img/myProduct/myProduct_icon_edit.png" width="19px" height="18px" />
                    </Box>
                  </ButtonTooltip>
                  <ButtonTooltip title={getButtonTooltipLayout(`Push to ${storePlatformCapitalize}`)} arrow>
                    <Box onClick={(e) => {
                      e.stopPropagation();
                      if (isPushing) {
                        return;
                      }
                      sendLog("MY_PRODUCTS_CARD_PUSH_TO_SHOPIFY_CLICK");
                      if (shopName == null) {
                        setState({
                          ...state,
                          isAccountPopUpDialogOpen: true,
                          currentComponentName: 'CONNECT_SHOPIFY',
                        });
                        return;
                      }

                      setIsPushing(true);
                      const timer = setInterval(() => {
                        setProgress((oldProgress) => {
                          if (oldProgress === 98) {
                            return 98;
                          }
                          const diff = Math.random() * 5;
                          return Math.min(oldProgress + diff, 98);
                        });
                      }, 500);
                      push_selected_products({
                        variables: {
                          IDList: [String(id)],
                        },
                      }).then((result) => {
                        const isEditing = sessionStorage.getItem("isEditing");
                        if (
                          result.data != null &&
                          result.data.pushSelectedProduct != null &&
                          result.data.pushSelectedProduct.result != null
                        ) {
                          if (result.data.pushSelectedProduct.status &&
                            result.data.pushSelectedProduct.result != null &&
                            result.data.pushSelectedProduct.result.successShopifyIDList != null) {
                            setState({
                              ...state,
                              openSnackbar: true,
                              snackbarText: 'Success! Your selected products have been pushed successfully.',
                              snackbarState: 'SUCCESS',
                              isOpenImportProductDialog: isEditing === 'true',
                            });
                            //Open Upgrade Dailog
                            if (result.data.pushSelectedProduct.isShowUpgrade) {
                              document.getElementById("UpgradePlanDialog").click();
                            }
                            //Open Rating Dailog
                            if (localStorage.getItem("isRatingShown") == "false" && result.data.pushSelectedProduct.canBeEvaluated) {
                              setOpenRatingAlert(true);
                            }
                          } else {
                            if (result.data.pushSelectedProduct.message === 'PLEASE_UPGRADE_PLAN') {
                              setState({
                                ...state,
                                isOpenSelectPlanDialog: true,
                                isOpenImportProductDialog: isEditing === 'true',
                              });
                              setIsPushing(false);
                              clearInterval(timer);
                              setProgress(0);
                              return;
                            } else {
                              setState({
                                ...state,
                                openSnackbar: true,
                                snackbarText: result.data.pushSelectedProduct.result.failedReasonList[0],
                                snackbarState: 'FAILED',
                                isOpenImportProductDialog: isEditing === 'true',
                              });
                            }
                          }
                        } else {
                          setState({
                            ...state,
                            openSnackbar: true,
                            snackbarText: `Item fails to push to ${storePlatformCapitalize}, Each variant must have an unique name.`,
                            snackbarState: 'FAILED',
                            isOpenImportProductDialog: isEditing === 'true',
                          });
                        }
                        if (
                          result.data != null &&
                          result.data.pushSelectedProduct != null &&
                          result.data.pushSelectedProduct.result != null &&
                          result.data.pushSelectedProduct.result.successProductSchemaIDList != null &&
                          result.data.pushSelectedProduct.result.failedProductSchemaIDList != null &&
                          result.data.pushSelectedProduct.result.successShopifyIDList != null
                        ) {
                          const cacheFailedIDList = pushFailedIDList;
                          const cacheFailedReasonList = pushFailedReasonList;
                          if (result.data.pushSelectedProduct.result.failedProductSchemaIDList.length > 0) {
                            if (cacheFailedIDList.includes(String(id))) {
                              cacheFailedReasonList[cacheFailedIDList.indexOf(String(id))] = result.data.pushSelectedProduct.result.failedReasonList[0];
                            } else {
                              cacheFailedIDList.push(result.data.pushSelectedProduct.result.failedProductSchemaIDList[0]);
                              cacheFailedReasonList.push(result.data.pushSelectedProduct.result.failedReasonList[0]);
                            }
                          } else if (cacheFailedIDList.includes(String(id))) {
                            cacheFailedIDList.splice(cacheFailedIDList.indexOf(String(id)), 1);
                            cacheFailedReasonList.splice(cacheFailedIDList.indexOf(String(id)), 1);
                          }
                          setPushSuccessIDList(result.data.pushSelectedProduct.result.successProductSchemaIDList);
                          setPushFailedIDList(cacheFailedIDList);
                          setPushFailedReasonList(cacheFailedReasonList);
                          undateCurrentProductIDList(result.data.pushSelectedProduct.result.successShopifyIDList[0]);
                        }
                        setIsPushing(false);
                        clearInterval(timer);
                        setProgress(0);
                      });
                    }}>
                      {storePlatform != "wix" ? <img src="img/myProduct/myProduct_icon_push.png" width="24px" height="24px" /> :
                        <img src="img/wix_logo.png" width="24px" height="24px" />}
                    </Box>
                  </ButtonTooltip>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: '80px', display: 'flex', alignItems: "center", justifyContent: 'center', "& img": { cursor: 'pointer' } }}>
              <img src={thumbnailSource} /*width="54px"*/ height="54px" onClick={(e) => {
                e.stopPropagation();
                setIsOpenEdit(true);
                setState({
                  ...state,
                  isOpenImportProductDialog: true,
                  tabValue: 0,
                  currentClickProduct: { id, title, thumbnailSource, shopifyProductID },
                  setPushSuccessIDList,
                  setPushFailedIDList,
                  setPushFailedReasonList,
                  setCurrentFailedCount,
                  setPushStatus,
                  pushFailedIDList,
                  pushFailedReasonList,
                  setPushIDList
                });
                sessionStorage.setItem("isEditing", 'true');
                sessionStorage.setItem("currentClickProduct", JSON.stringify({ id, title, thumbnailSource, shopifyProductID }));
              }} />
            </Box>
            <Box sx={{
              width: '180px'
            }} >
              <ButtonTooltip title={getButtonTooltipLayout(title)} arrow>
                <Box sx={{
                  width: '180px',
                  lineHeight: '20px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 2,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  cursor: 'pointer'
                }} onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenEdit(true);
                  setState({
                    ...state,
                    isOpenImportProductDialog: true,
                    tabValue: 0,
                    currentClickProduct: { id, title, thumbnailSource, shopifyProductID },
                    setPushSuccessIDList,
                    setPushFailedIDList,
                    setPushFailedReasonList,
                    setCurrentFailedCount,
                    setPushStatus,
                    pushFailedIDList,
                    pushFailedReasonList,
                    setPushIDList
                  });
                  sessionStorage.setItem("isEditing", 'true');
                  sessionStorage.setItem("currentClickProduct", JSON.stringify({ id, title, thumbnailSource, shopifyProductID }));
                }}>
                  {title}
                </Box>
              </ButtonTooltip>
            </Box>
            <Box sx={{ width: '100px' }}>
              <Box>{price + " " + currency}</Box>
            </Box>
            <Box sx={{ width: '100px' }}>
              <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "flex-start" }}>
                <Box>{shippingFee + " " + currency}</Box>
                {fulfillmentOption ? <ButtonTooltip title={getButtonTooltipLayout(`This is Fulfilled by Cartifind shipping cost.`)} arrow>
                  <Box sx={{
                    width: '13px',
                    height: '13px',
                    background: 'url("img/myProduct/product_fulfilled_by_cartifind.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                    marginLeft: "3px",
                    marginTop: "-3px",
                  }} />
                </ButtonTooltip> : <ButtonTooltip title={getButtonTooltipLayout(`This is Fulfilled by You shipping cost.`)} arrow>
                  <Box sx={{
                    width: '13px',
                    height: '13px',
                    background: 'url("img/myProduct/product_fulfilled_by_you.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                    marginLeft: "3px",
                    marginTop: "-3px",
                  }} />
                </ButtonTooltip>}
              </Box>
            </Box>
            <Box sx={{ width: '100px' }}>
              <Box>{multipliedPrice + " " + currency}</Box>
              <Box>{multipliedPrice + " " + currency}</Box>
            </Box>
            <Box sx={{ width: '100px' }}>
              <Box>{(multipliedPrice - price - Number(shippingFee)).toFixed(2) + " " + currency}</Box>
              <Box sx={{ color: (price + Number(shippingFee) === 0 ? "0" : (100 * parseFloat((multipliedPrice - price - Number(shippingFee)) / (price + Number(shippingFee)))).toFixed(2) < 0) ? "rgba(255, 51, 51, 1)" : "rgba(97, 196, 84, 1)" }}>({price + Number(shippingFee) === 0 ? "0" : (100 * parseFloat((multipliedPrice - price - Number(shippingFee)) / (price + Number(shippingFee)))).toFixed(2)}%)</Box>
            </Box>
            {/* <Box sx={{ width: '100px' }}>
              <Box>{price}</Box>
              <Box>{multipliedPrice}</Box>
            </Box> */}
            <Box sx={{ width: '105px' }}>

              <ButtonTooltip title={getButtonTooltipLayout("Click to visit source link")} arrow>
                <Box sx={{
                  display: "flex",
                  width: 'auto',
                  alignItems: 'center'
                }}>
                  <Box sx={{ cursor: 'pointer', textDecoration: "underline", textTransform: 'capitalize' }} onClick={(e) => {
                    e.stopPropagation();
                    let url = "";
                    if (productSource === "aliexpress") {
                      url = `https://www.aliexpress.com/item/${productID}.html`;
                    } else if (productSource === "amazon") {
                      url = `https://amazon.com/dp/${productID}`;
                    } else if (productSource === "temu") {
                      url = `https://www.temu.com/g-${productID}.html`;
                    } else if (productSource === "etsy") {
                      url = `https://www.etsy.com/`;
                    } else if (productSource === "1688") {
                      url = `https://detail.1688.com/offer/${productID}.html`;
                    } else if (productSource === "shein") {
                      url = `https://www.shein.com/shein-p-${productID}.html`;
                    } else if (productSource === "banggood") {
                      url = `https://www.banggood.com/banggood-p-${productID}.html`;
                    } else {
                      url = `https://www.aliexpress.com/item/${productID}.html`;
                    }
                    window.open(productSourceURL != null && productSourceURL.length > 0 ? productSourceURL : url);
                  }}>{productSource === "aliexpress" ? "AliExpress" : productSource}</Box>
                  <Box sx={{
                    width: '10px',
                    height: '10px',
                    background: 'url("img/myProduct/myProduct_icon_skip.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                    marginLeft: "3px",
                    cursor: 'pointer'
                  }} />
                </Box>
              </ButtonTooltip>
              <Box>{inventory}</Box>
            </Box>
            <Box sx={{
              width: '100px',
              "&>div": {
                width: '100px',
                maxWidth: '100px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center'
              }
            }}>
              {collectionList.length > 0 ? <ButtonTooltip title={getButtonTooltipLayout("Collection: " + collectionList.join(","))} arrow>
                <Box sx={{
                  height: '32px',
                  lineHeight: '32px',
                  lineHeight: '24px',
                  color: "#00000099",
                }}>
                  <Box sx={{
                    display: 'flex', flexWrap: 'no-wrap', alignItems: "center", maxWidth: '90px', overflow: 'hidden', gap: 0.5
                  }}>
                    {collectionList.map((value, index) => (
                      <Chip key={value} label={value} sx={{ fontSize: '14px', color: "rgba(0, 0, 0, 0.87)", backgroundColor: "rgba(0, 0, 0, 0.26)" }} onClick={() => {
                        const Arr = collectionSelectedList;
                        if (collectionSelectedList.includes(value)) {
                          Arr.splice(collectionSelectedList.indexOf(value), 1);
                        } else {
                          Arr.push(value);
                        }
                        setCollectionSelectedList(Arr);
                      }} />
                    ))}
                  </Box>
                </Box>
              </ButtonTooltip> : null}
              {tagsList.length > 0 ? <ButtonTooltip title={getButtonTooltipLayout("Tags: " + tagsList.join(","))} arrow>
                <Box sx={{
                  height: '32px',
                  lineHeight: '32px',
                  lineHeight: '24px',
                  color: "#00000099",
                }}>
                  <Box sx={{
                    display: 'flex', flexWrap: 'no-wrap', alignItems: "center", maxWidth: '90px', overflow: 'hidden', gap: 0.5
                  }}>
                    {tagsList.map((value, index) => (
                      <Chip key={value} label={value} sx={{ fontSize: '14px', color: "rgba(0, 0, 0, 0.87)", backgroundColor: "rgba(0, 0, 0, 0.08)" }} onClick={() => {
                        setTagValue(value);
                        setSearchImmediate(value);
                      }} />
                    ))}
                  </Box>
                </Box>
              </ButtonTooltip> : null}
            </Box>
            <Box sx={{ width: "130px", }}>

              <ButtonTooltip title={getButtonTooltipLayout(`Show if product is pushed to ${storePlatformCapitalize}`)} arrow>
                <Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "500",
                  lineHeight: "150%",
                  letterSpacing: "0.15px",
                  fontStyle: "normal",
                  color: "#707070",
                  '& div': {
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "16px",
                    padding: '0px 10px',
                    cursor: 'pointer'
                  }
                }}>

                  {available ? (isPushFailed ? <Box sx={{
                    border: '3px solid #e94343',
                    backgroundColor: "#FFF",
                    color: 'rgba(0, 0, 0, 0.87)',
                  }} onClick={() => { setStatus("2") }}>
                    Not Imported
                  </Box> : shopifyProductID !== '0' ? (
                    <Box sx={{
                      backgroundColor: "rgba(198, 238, 179, 0.68)",
                      color: "rgba(0, 0, 0, 0.87)"
                    }} onClick={() => { setStatus("1") }}>
                      Imported
                    </Box>
                  ) : <Box sx={{
                    backgroundColor: "#D3D3D3AD",
                    color: 'rgba(0, 0, 0, 0.87)',
                  }} onClick={() => { setStatus("2") }}>
                    Not Imported
                  </Box>) : <Box sx={{
                    backgroundColor: "#D3D3D3AD",
                    color: 'rgba(0, 0, 0, 0.87)',
                  }} onClick={() => { setStatus("3") }}>
                    Sold Out
                  </Box>}
                </Box>
              </ButtonTooltip>
              {isPushFailed || isOptimizeFailed ?
                <ClickAwayListener onClickAway={() => { setIsShowFailedReason(false) }}>
                  <Box>
                    <ButtonTooltip
                      title={
                        <React.Fragment>
                          <Box sx={{
                            color: '#FFF',
                            fontSize: '12px',
                            fontWeight: '400',
                            lineHeight: '150%'
                          }}>
                            {/* The store has too many products to generate new variants. For details, see: */}
                            {/* {pushReason.includes('https') ? pushReason.slice(0, pushReason.indexOf('https')) : pushReason} */}
                            {pushReason != null ? <Box>push failed Reason: {pushReason.includes('https') ? pushReason.slice(0, pushReason.indexOf('https')) : pushReason}
                              {pushReason.includes('https') ? <Typography variant="span" sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { window.open(pushReason.slice(pushReason.indexOf('https'), pushReason.length)); }}>{pushReason.slice(pushReason.indexOf('https'), pushReason.length)}</Typography> : null}</Box> : null}
                            {optimizeFailedReason != null ? <Box>optimize failed Reason: {optimizeFailedReason}</Box> : null}
                          </Box>
                        </React.Fragment>
                      }
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      open={isShowFailedReason}
                      onClose={() => { setIsShowFailedReason(false); }}
                      onOpen={() => {
                        sendLog("MY_PRODUCTS_CARD_PUSH_TO_SHOPIFY_WRONG_ICON_HOVER");
                      }}>
                      <Box sx={{
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: '10px'
                      }}>
                        <Box sx={{
                          width: '20px',
                          height: '20px',
                          background: 'url("img/push_to_shopify_error_detail_icon.png") no-repeat 0 0',
                          backgroundSize: 'contain',
                        }} onClick={() => { setIsShowFailedReason(true); }}></Box>
                      </Box>
                    </ButtonTooltip>
                  </Box>
                </ClickAwayListener> : (shopifyProductID != null && shopifyProductID != "0" && <Box sx={{
                  display: "flex",
                  width: 'auto',
                  alignItems: 'center',
                  justifyContent: "center",
                  fontSize: '12px',
                  marginTop: '10px'
                }}>
                  {storePlatform != "wix" ? <ButtonTooltip title={getButtonTooltipLayout(`Click to visit Shopify link`)} arrow>
                    <Box sx={{ cursor: 'pointer', textDecoration: "underline" }} onClick={(e) => {
                      e.stopPropagation();
                      const beginIndex = shopifyProductID.indexOf('/Product/');
                      window.open(`https://admin.shopify.com/store/${shopName.replace('.myshopify.com', '')}/products/${shopifyProductID.slice(beginIndex + 9, shopifyProductID.length)}`)
                    }}>Shopify</Box>
                  </ButtonTooltip> : <ButtonTooltip title={getButtonTooltipLayout("Click to visit Wix link")} arrow>
                    <Box sx={{ cursor: 'pointer', textDecoration: "underline" }} onClick={(e) => {
                      e.stopPropagation();
                      window.open(`https://manage.wix.com/dashboard/${wixSiteID}/store/products/product/${shopifyProductID}`)
                    }}>Wix</Box>
                  </ButtonTooltip>}
                  <Box sx={{
                    width: '10px',
                    height: '10px',
                    background: 'url("img/myProduct/myProduct_icon_skip.png") no-repeat 0 0',
                    backgroundSize: 'contain',
                    marginLeft: "3px",
                    cursor: 'pointer'
                  }} />
                </Box>)}
            </Box>
          </Box>
      }
      <Box sx={{ position: 'relative', top: '0px', height: '4px' }}>
        {isPushing ?
          <LinearProgress variant="determinate" value={progress} color="success" sx={{
            borderRadius: '2px', "& .MuiLinearProgress-bar": {
              backgroundColor: '#67B239'
            }
          }} /> : <div />
        }
      </Box>
    </Box >
  );
}

export default function MyProducts() {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const { setState, state } = React.useContext(Context);
  const { shopName, sendLog, handleRefetchMyProductsCount, myProductListCount, isOpenRatingDialog } = state;
  const [pageIndex, setPageIndex] = React.useState(queryParams.get("pageIndex") != null ? Number(queryParams.get("pageIndex")) : 0);
  const [initialize, setinitialize] = React.useState(true);
  const [isOpenEdit, setIsOpenEdit] = React.useState(false);
  const [status, setStatus] = React.useState('');
  const [sort, setSort] = React.useState('');
  const [productSelectedNum, setProductSelectedNum] = React.useState(0);
  const theme = useTheme();
  const [collectionSelectedList, setCollectionSelectedList] = React.useState([]);
  const [collectionOptionList, setCollectionOptionList] = React.useState([]);

  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [selectedIDList, setSelectedIDList] = React.useState([]);
  const [checkedAll, setCheckedAll] = React.useState(false);
  const [isPushingAll, setIsPushingAll] = React.useState(false);
  const [isShowOptimizeTips, setIsShowOptimizeTips] = React.useState(false);
  const [isShowOptimizeTipsChecked, setIsShowOptimizeTipsChecked] = React.useState(false);
  const [refreshPage, setRefreshPage] = React.useState(0);
  const [pushStatus, setPushStatus] = React.useState('none');
  const [pushIDList, setPushIDList] = React.useState([]);
  const [pushSuccessIDList, setPushSuccessIDList] = React.useState(['1']);
  const [pushFailedIDList, setPushFailedIDList] = React.useState([]);
  const [optimizeFailedIDList, setOptimizeFailedIDList] = React.useState([]);
  const [optimizeFailedReasonList, setOptimizeFailedReasonList] = React.useState([]);
  const [pushFailedReasonList, setPushFailedReasonList] = React.useState([]);
  const [currentProductIDList, setCurrentProductIDList] = React.useState([]);
  const [currentFailedCount, setCurrentFailedCount] = React.useState(0);
  const [currentSuccessCount, setCurrentSuccessCount] = React.useState(0);
  const [deleteDisabledProduct, setDeleteDisabledProduct] = React.useState(null);
  const [optimizeStatus, setOptimizeStatus] = React.useState('none');
  const [optimizeNum, setOptimizeNum] = React.useState(0);
  const [optimizeCompleteNum, setOptimizeCompleteNum] = React.useState(0);
  const [optimizeSuccessNum, setOptimizeSuccessNum] = React.useState(0);
  const [myProductSearchValue, setMyProductSearchValue] = React.useState("");
  const [isOpenFilter, setIsOpenFilter] = React.useState(false);
  const [supplierSelectedList, setSupplierSelectedList] = React.useState([]);
  const [typeSelectedList, setTypeSelectedList] = React.useState([]);
  const [tagSelectedList, setTagSelectedList] = React.useState([]);
  const [typeValue, setTypeValue] = React.useState("");
  const [tagValue, setTagValue] = React.useState("");
  const [refetchPageNum, setRefetchPageNum] = React.useState(0);
  const [openMoreActionMenu, setOpenMoreActionMenu] = React.useState(false);
  const [moreActionAnchorEl, setMoreActionAnchorEl] = React.useState(null);
  const [filterAttestation, setFilterAttestation] = React.useState(false);
  const [searchImmediate, setSearchImmediate] = React.useState("");
  const [openRatingAlert, setOpenRatingAlert] = React.useState(false);
  const [ratingValue, setRatingValue] = React.useState(0);
  const [ratingHover, setRatingHover] = React.useState(-1);
  const storePlatform = localStorage.getItem("storePlatform");
  const storePlatformCapitalize = storePlatform != null && storePlatform.length > 0 ? storePlatform.slice(0, 1).toUpperCase() + storePlatform.slice(1, storePlatform.length) : "Shopify";
  const [openEditCollectionAndTagAlert, setOpenEditCollectionAndTagAlert] = React.useState(false);
  const [editCollectionAndTagType, setEditCollectionAndTagType] = React.useState("collection");
  const [openEditPriceAlert, setOpenEditPriceAlert] = React.useState(false);
  const [editPriceType, setEditPriceType] = React.useState("percentage");
  const [editPricePercentage, setEditPricePercentage] = React.useState("150");
  const [percentageBoxSwitch, setPercentageBoxSwitch] = React.useState(false);
  const [editPriceFixed, setEditPriceFixed] = React.useState("10");
  const [editCollection, setEditCollection] = React.useState([]);
  const [collectionInput, setCollectionInput] = useState("");
  const [editTag, setEditTag] = React.useState([]);
  const [tagOptionList, setTagOptionList] = useState([]);
  const [tagsInput, setTagsInput] = useState("");
  const [productTypeInput, setProductTypeInput] = useState("");
  const [isOpenTagsSelelct, setIsOpenTagsSelelct] = useState(false);
  const [isOpenCollectionsSelelct, setIsOpenCollectionsSelelct] = useState(false);

  const handleChangeCollection = (event) => {
    const {
      target: { value },
    } = event;
    setCollectionSelectedList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleChangeEditCollection = (event) => {
    const {
      target: { value },
    } = event;
    setEditCollection(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const [get_product_collection] = useMutation(GET_PRODUCT_COLLECTION);
  const [save_myproducts_page_setting] = useMutation(SAVE_MYPRODUCTS_PAGE_SETTING);
  const [set_is_rating_shown_true] = useMutation(SET_IS_RATING_SHOWN_TRUE);
  const [set_select_product_price] = useMutation(SET_SELECT_PRODUCT_PRICE);
  const [set_select_product_collection_and_tag] = useMutation(SET_SELECT_PRODUCT_COLLECTION_AND_TAG);
  const [remove_products_from_user] = useMutation(REMOVE_PRODUCTS_FROM_USER);
  const [reset_selected_user_product_detail] = useMutation(RESET_SELECTED_USER_PRODUCT_DETAIL);
  const [optimize_selected_product] = useMutation(OPTIMIZE_SELECTED_PRODUCT);
  const [push_selected_products] = useMutation(PUSH_SELECTED_PRODUCTS);
  const [publish_selected_products] = useMutation(PUBLISH_SELECTED_PRODUCTS);
  const { loading, data, refetch } = useQuery(GET_USER_PRODUCT_LIST, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [currentViewMode, setCurrentViewMode] = React.useState("compact");//concise or compact

  const { loading: statusLoading, data: statusData, refetch: statusRefetch } = useQuery(GET_SUBSCRIPTION_STATUS);
  const { loading: isRatingShownLoading, data: isRatingShownData } = useQuery(GET_IS_RATING_SHOWN, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const { pathname } = useLocation();

  const handleRefetchMyProductList = (pageIndex, newRowsPerPage) => {
    setSelectedIDList([]);
    refetch({
      pageIndex,
      pageSize: newRowsPerPage != null ? newRowsPerPage : rowsPerPage,
      searchWords: myProductSearchValue.length === 0 ? null : myProductSearchValue,
      sort: sort.length === 0 ? null : sort,
      status: status.length === 0 ? null : status,
      collections: collectionSelectedList.length === 0 ? null : collectionSelectedList,
      types: typeValue.length === 0 ? null : [typeValue],
      tags: tagValue.length === 0 ? null : [tagValue],
      suppliers: supplierSelectedList.length === 0 ? null : supplierSelectedList,
    });
  }

  const handleGenerateContent = async () => {
    setOptimizeCompleteNum(0);
    let completeList = [];
    let successList = [];
    for (let i = selectedIDList.length - 1; i >= 0; i--) {
      await new Promise(async (resolve, reject) => {
        optimize_selected_product({
          variables: {
            IDList: [selectedIDList[i]],
          },
        }).then((result) => {
          if (
            result.data != null &&
            result.data.optimizeSelectedProduct != null &&
            result.data.optimizeSelectedProduct.status === true
          ) {
            if (result.data.optimizeSelectedProduct.result != null &&
              result.data.optimizeSelectedProduct.result.successProductSchemaIDList != null) {
              const resultFailedIDList = [];
              const resultFailedReasonList = [];
              // 比较当前错误ID列表和返回的成功的ID列表，如果当前错误的ID，不是成功的ID,就把这个ID,和对应的错误信息添加到结果列表
              optimizeFailedIDList.map((id, index) => {
                if (!result.data.optimizeSelectedProduct.result.successProductSchemaIDList.some(cacheId => cacheId === id)) {
                  resultFailedIDList.push(id);
                  resultFailedReasonList.push(optimizeFailedReasonList[index]);
                }
              })
              successList.push(selectedIDList[i]);
              setOptimizeFailedIDList(resultFailedIDList);
              setOptimizeFailedReasonList(resultFailedReasonList);
            }
          } else if (result.data.optimizeSelectedProduct != null) {
            if (result.data.optimizeSelectedProduct.message === 'PLEASE_UPGRADE_PLAN') {
              setState({
                ...state,
                isOpenSelectPlanDialog: true,
                openSnackbar: false,
              });
              return;
            } else {
              if (result.data.optimizeSelectedProduct.result != null &&
                result.data.optimizeSelectedProduct.result.failedProductSchemaIDList != null &&
                result.data.optimizeSelectedProduct.result.successProductSchemaIDList != null &&
                result.data.optimizeSelectedProduct.result.failedReasonList != null) {
                const cacheFailedIDList = optimizeFailedIDList;
                const cacheFailedReasonList = optimizeFailedReasonList;
                //如果第二次push，这件产品依然报错，把这条报错信息替换成最新返回的信息
                result.data.optimizeSelectedProduct.result.failedProductSchemaIDList.map((id, index) => {
                  optimizeFailedIDList.map((cacheId, cacheIndex) => {
                    if (cacheId === id) {
                      cacheFailedReasonList[cacheIndex] = result.data.optimizeSelectedProduct.result.failedReasonList[index];
                    }
                  })
                })
                // 比较之前的错误列表和本次返回的错误列表，如果存在不同的，就把这个不同的ID和报错信息存放在当前列表
                result.data.optimizeSelectedProduct.result.failedProductSchemaIDList.map((id, index) => {
                  if (!pushFailedIDList.some(cacheId => cacheId === id)) {
                    cacheFailedIDList.push(id);
                    cacheFailedReasonList.push(result.data.optimizeSelectedProduct.result.failedReasonList[index]);
                  }
                })
                const resultFailedIDList = [];
                const resultFailedReasonList = [];
                // 比较当前错误ID列表和返回的成功的ID列表，如果当前错误的ID，不是成功的ID,就把这个ID,和对应的错误信息添加到结果列表
                cacheFailedIDList.map((id, index) => {
                  if (!result.data.optimizeSelectedProduct.result.successProductSchemaIDList.some(cacheId => cacheId === id)) {
                    resultFailedIDList.push(id);
                    resultFailedReasonList.push(cacheFailedReasonList[index]);
                  }
                })
                setOptimizeFailedIDList(resultFailedIDList);
                setOptimizeFailedReasonList(resultFailedReasonList);
              }
            }
          }
          completeList.push(selectedIDList[i]);
          setOptimizeCompleteNum(completeList.length);
          resolve();
        }).catch(e => {
          console.log("time-out period")
          completeList.push(selectedIDList[i]);
          setOptimizeCompleteNum(completeList.length);
          const cacheFailedIDList = optimizeFailedIDList;
          const cacheFailedReasonList = optimizeFailedReasonList;
          let hasFailedID = false;
          cacheFailedIDList.map((cacheId, cacheIndex) => {
            if (cacheId === selectedIDList[i]) {
              hasFailedID = true;
              cacheFailedReasonList[cacheIndex] = "ChatGPT timeout: consider shorter your description prompt.";
            }
          })
          if (!hasFailedID) {
            cacheFailedIDList.push(selectedIDList[i]);
            cacheFailedReasonList.push("ChatGPT timeout: consider shorter your description prompt.");
          }
          setOptimizeFailedIDList(cacheFailedIDList);
          setOptimizeFailedReasonList(cacheFailedReasonList)
          resolve();
        });
      })
    }
    setOptimizeStatus("finish");
    setOptimizeSuccessNum(successList.length);
    handleRefetchMyProductList(pageIndex);
  }

  useEffect(() => {
    if (loading == false && data != null && data.getUserProductList != null) {
      setRowsPerPage(data.getUserProductList.pageSize != null ? data.getUserProductList.pageSize : 25);
      setCurrentViewMode(data.getUserProductList.viewMode != null ? data.getUserProductList.viewMode : "compact");
    }
  }, [loading]);


  useEffect(() => {
    if (isRatingShownLoading == false && isRatingShownData != null) {
      if (isRatingShownData.getIsRatingShown == false) {
        localStorage.setItem("isRatingShown", "false");
      } else {
        localStorage.setItem("isRatingShown", "true");
      }
    }
  }, [isRatingShownLoading]);

  useEffect(() => {
    if (loading === false) {
      handleRefetchMyProductList(0);
      setPageIndex(0);
    }
  }, [collectionSelectedList.length, sort, status, supplierSelectedList.length, searchImmediate]);


  useEffect(() => {
    if (loading === false && data != null && data.getUserProductList != null) {
      if (data.getUserProductList.result == null || data.getUserProductList.result.length === 0) {
        sendLog("MY_PRODUCTS_LOAD_BLANK_PAGE");
      } else {
        setState({
          ...state,
          handleRefetchProductList: refetch,
          productListAfterEdit: data.getUserProductList.result
        });
        sendLog("MY_PRODUCTS_LOAD_PRODUCTS_PAGE");
        setinitialize(false);
        setFilterAttestation(true);

        setCurrentProductIDList(data.getUserProductList.result.map((item) => {
          return {
            productSchemaID: item.id,
            shopifyProductID: item.shopifyProductID
          }
        }));
      }
    }
  }, [loading, pageIndex]);

  return (
    <>
      <Dialog
        open={openDeleteAlert}
        onClose={() => {
          setOpenDeleteAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            width: "500px",
            height: "240px",
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: '130px',
            fontFamily: "Arial",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "150%",
            letterSpacing: "0.15px",
            color: "#666666",
            position: "relative",
            paddingTop: '20px',
          }}
        >
          <Box sx={{ fontSize: '18px', color: "#333333", fontWeight: "500", }}>Delete Product</Box>
          <Box>Are you sure you want to delete the product{selectedIDList.length > 1 && deleteDisabledProduct != null ? 's' : ''}? It will be deleted from the import list but will not be deleted from your {storePlatformCapitalize} store.</Box>
        </DialogTitle>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: '0 20px',
            "& .button": {
              width: '10px',
              height: '40px',
              padding: "0px",
              borderRadius: "10px",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "150%",
              letterSpacing: "0.15px",
              color: "#707070B2",
              backgroundColor: "#e4e4e4",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#e4e4e4",
              },
            },
            "& .delete-button": {
              color: "#FFF",
              backgroundColor: "#FF9711",
              "&:hover": {
                color: "#FFF",
                backgroundColor: "#FF9711",
              },
            },
          }}
        >
          <Button
            className="button"
            onClick={() => {
              sendLog("MY_PRODUCTS_DELETE_DAILOG_CANCEL_CLICK");
              setOpenDeleteAlert(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="button delete-button"
            onClick={() => {
              setOpenDeleteAlert(false);
              remove_products_from_user({
                variables: {
                  IDs: deleteDisabledProduct ? deleteDisabledProduct : selectedIDList,
                },
              }).then((result) => {
                if (
                  result.data != null &&
                  result.data.removeProductsFromUser != null &&
                  result.data.removeProductsFromUser.status === true
                ) {
                  handleRefetchMyProductList(pageIndex);
                  if (handleRefetchMyProductsCount != null) {
                    handleRefetchMyProductsCount();
                  }
                  setState({
                    ...state,
                    openSnackbar: true,
                    snackbarText: 'Delete products successfully !',
                    snackbarState: 'SUCCESS',
                  });
                  setDeleteDisabledProduct(null);
                  setSelectedIDList([]);
                  setCheckedAll(false);
                }
              });
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRatingAlert || (isOpenRatingDialog != null ? isOpenRatingDialog : false)}
        onClose={() => {
          setOpenRatingAlert(false);
          setState({
            ...state,
            isOpenRatingDialog: false
          });
          localStorage.setItem("isRatingShown", "true");
          set_is_rating_shown_true();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            width: "396px",
            height: "250px",
            padding: "38px 48px",
            borderRadius: "2px",
            marginLeft: "240px"
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: '96px',
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "26px",
            lineHeight: "32px",
            letterSpacing: "0.15px",
            color: "#666666",
            position: "relative",
            padding: '0px',
            textAlign: 'center'
          }}
        >
          Great job! Your products are pushed. Please support us and leave us a review!
        </DialogTitle>
        <DialogContent sx={{
          padding: '0px'
        }}>
          <Box
            sx={{
              minWidth: "200px",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: "20px 0px"
            }}
          >
            <Rating
              name="hover-feedback"
              value={ratingValue}
              precision={0.5}
              getLabelText={getRatingLabelText}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setRatingHover(newHover);
              }}
              emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize: '60px' }} />}
              sx={{
                ".MuiRating-decimal": {
                  fontSize: '60px'
                }
              }}
            />
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Box sx={{
              width: '313px',
              height: "36px",
              padding: '6px 16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              background: '#61C454',
              boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              color: "#FFF",
              textTransform: "uppercase",
              cursor: "pointer"
            }} onClick={() => {
              set_is_rating_shown_true();
              if (ratingValue === 5) {
                window.open("https://apps.shopify.com/hff-products#modal-show=WriteReviewModal")
              } else {
                window.open("https://dw0xjnsym34.typeform.com/cartifind")
              }
              localStorage.setItem("isRatingShown", "true");
              setOpenRatingAlert(false);
              setState({
                ...state,
                isOpenRatingDialog: false
              });
            }}>Submit ratings</Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openEditCollectionAndTagAlert}
        onClose={() => {
          setOpenEditCollectionAndTagAlert(false);
          setIsOpenTagsSelelct(false);
          setIsOpenCollectionsSelelct(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            width: "600px",
            height: "390px",
            borderRadius: "4px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: '26px',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "160%",
            letterSpacing: "0.15px",
            color: "rgba(0, 0, 0, 0.87)",
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          onClick={() => { setIsOpenTagsSelelct(false); setIsOpenCollectionsSelelct(false); }}
        >
          <Box>Add Collections,Tags and Type</Box>
        </DialogTitle>
        <DialogContent sx={{
          padding: '0px'
        }}
          onClick={() => { setIsOpenTagsSelelct(false); setIsOpenCollectionsSelelct(false); }}>
          <Box
            sx={{
              height: "264px",
              padding: '8px 16px',
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              letterSpacing: "0.15px",
              color: "rgba(0, 0, 0, 0.87)",
              "& .radio_label_wrap": {
                height: '88px',
                display: 'flex',
                alignItems: 'center',
                fontSize: "16px"
              },
              "& .MuiInputLabel-root": {
                fontSize: '16px',
                lineHeight: '16px',
                color: "rgba(0, 0, 0, 0.6)!important",
                "&.Mui-focused": {
                  fontSize: '16px',
                  lineHeight: '16px',
                  color: "rgba(0, 0, 0, 0.6)!important",
                },
              },
              "& .MuiSvgIcon-root": {
                fontSize: '28px',
              },
              "& fieldset": {
                fontSize: '14px',
                borderColor: "rgba(0, 0, 0, 0.23)!important",
                "&.Mui-focused": {
                  borderColor: "rgba(0, 0, 0, 0.23)!important",
                },
              },
              "& .MuiChip-root": {
                maxWidth: "150px"
              },
              "& #demo-multiple-chip-label": collectionSelectedList.length > 0 ? {
                transform: "translate(14px, -9px) scale(0.75)",
              } : {},
              "& legend": collectionSelectedList.length > 0 ? {
                maxWidth: '100%'
              } : {}
            }}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }} className="radio_label_wrap">
              <Box sx={{ marginRight: "10px", width: '120px' }}>Add Collections: </Box>
              <Box sx={{ position: 'relative' }}>
                <Box sx={{
                  flex: 1,
                  boxSizing: "border-box",
                  border: '1px solid #999999',
                  borderRadius: '4px',
                  width: "390px",
                  display: 'flex',
                  alignItems: "center",
                }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: "center",
                    flexWrap: 'no-wrap',
                    maxWidth: '340px',
                    height: "32px",
                    overflow: 'hidden',
                    gap: 0.5,
                    paddingLeft: '10px'
                  }}>
                    {
                      editCollection.map((item, index) => {
                        return <Chip key={item} label={item} sx={{ fontSize: '14px' }} onDelete={(e) => {
                          e.stopPropagation();
                          let midArr = [...editCollection];
                          midArr.splice(index, 1);
                          setEditCollection(midArr);
                          setRefreshPage(refreshPage + 1);
                        }} />
                      })
                    }
                  </Box>
                  <TextField
                    value={collectionInput}
                    onChange={(e) => {
                      setCollectionInput(e.target.value);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (collectionOptionList.length === 0) {
                        get_product_collection().then(res => {
                          if (res != null && res.data != null && res.data.getProductCollection) {
                            setCollectionOptionList(res.data.getProductCollection.collectionOptionTitleList);
                          }
                        });
                      }
                      setIsOpenTagsSelelct(false);
                      setIsOpenCollectionsSelelct(true);
                    }}
                    sx={{
                      flex: 1,
                      cursor: "pointer",
                      "& .MuiInputBase-input": {
                        padding: '0 0 0 10px',
                        height: '40px',
                        fontSize: '14px'
                      },
                      "& .MuiInputBase-root": {
                        fontSize: '16px',
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Box>
                <HtmlSelect sx={isOpenCollectionsSelelct && collectionOptionList != null && collectionOptionList.length > 0 ? {
                  position: 'absolute',
                  top: '40px',
                  left: '0.5%',
                  width: '99%',
                  maxHeight: '160px',
                  height: 'auto',
                  overflow: 'auto',
                  backgroundColor: "#FFF",
                  borderRadius: '10px',
                  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25), 0px -1px 6px rgba(0, 0, 0, 0.25)',
                  zIndex: '9'
                } : { with: 0, height: 0 }} onClick={(e) => {
                  e.stopPropagation();
                }}
                  selectedItems={editCollection}
                  selectedOptions={collectionOptionList}
                  open={isOpenCollectionsSelelct}
                  userInput={collectionInput}
                  handleClickItem={(value) => {
                    if (editCollection.includes(value)) {
                      let midArr = editCollection;
                      midArr.splice(editCollection.indexOf(value), 1);
                      setEditCollection(midArr);
                    } else {
                      setEditCollection([...editCollection, value]);
                    }
                    setRefreshPage(refreshPage + 1);
                  }}
                />
              </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }} className="radio_label_wrap">
              <Box sx={{ marginRight: "10px", width: '120px' }}>Add Tags: </Box>
              <Box sx={{ position: 'relative' }}>
                <Box sx={{
                  flex: 1,
                  boxSizing: "border-box",
                  border: '1px solid #999999',
                  borderRadius: '4px',
                  width: "390px",
                  display: 'flex',
                  alignItems: "center",
                }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: "center",
                    flexWrap: 'no-wrap',
                    maxWidth: '340px',
                    height: "32px",
                    overflow: 'hidden',
                    gap: 0.5,
                    paddingLeft: "10px"
                  }}>
                    {
                      editTag.map((item, index) => {
                        return <Chip key={item} label={item} sx={{ fontSize: '14px' }} onDelete={(e) => {
                          e.stopPropagation();
                          let midArr = [...editTag];
                          midArr.splice(index, 1);
                          setEditTag(midArr);
                          setRefreshPage(refreshPage + 1);
                        }} />
                      })
                    }
                  </Box>
                  <TextField
                    value={tagsInput}
                    onChange={(e) => {
                      setTagsInput(e.target.value);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpenTagsSelelct(true);
                      setIsOpenCollectionsSelelct(false);
                    }}
                    sx={{
                      flex: 1,
                      cursor: "pointer",
                      "& .MuiInputBase-input": {
                        padding: '0 0 0 10px',
                        height: '40px',
                        fontSize: '14px'
                      },
                      "& .MuiInputBase-root": {
                        fontSize: '16px',
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Box>
                <HtmlSelect sx={isOpenTagsSelelct ? {
                  position: 'absolute',
                  top: '40px',
                  left: '0.5%',
                  width: '99%',
                  maxHeight: '160px',
                  height: 'auto',
                  overflow: 'auto',
                  backgroundColor: "#FFF",
                  borderRadius: '10px',
                  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25), 0px -1px 6px rgba(0, 0, 0, 0.25)',
                  zIndex: '9'
                } : { with: 0, height: 0 }} onClick={(e) => {
                  e.stopPropagation();
                }}
                  selectedItems={editTag}
                  selectedOptions={tagOptionList}
                  open={isOpenTagsSelelct}
                  userInput={tagsInput}
                  isAllowAdd={true}
                  handleClickItem={(value) => {
                    if (editTag.includes(value)) {
                      let midArr = editTag;
                      midArr.splice(editTag.indexOf(value), 1);
                      setEditTag(midArr);
                    } else {
                      setEditTag([...editTag, value]);
                    }
                    setRefreshPage(refreshPage + 1);
                  }}
                  handleAddOption={(value) => {
                    let newTagList = [...editTag];
                    let newTagOptionList = [...tagOptionList];
                    newTagList.push(value);
                    newTagOptionList.push(value);
                    setEditTag(newTagList);
                    setTagOptionList(newTagOptionList);
                    setTagsInput("");
                    setRefreshPage(refreshPage + 1);
                  }}
                />
              </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }} className="radio_label_wrap">
              <Box sx={{ marginRight: "10px", width: '120px' }}>Edit Type: </Box>
              <Box sx={{ position: 'relative' }}>
                <Box sx={{
                  flex: 1,
                  boxSizing: "border-box",
                  border: '1px solid #999999',
                  borderRadius: '4px',
                  width: "390px",
                  display: 'flex',
                  alignItems: "center",
                }}>
                  <TextField
                    value={productTypeInput}
                    onChange={(e) => {
                      setProductTypeInput(e.target.value);
                    }}
                    sx={{
                      flex: 1,
                      cursor: "pointer",
                      "& .MuiInputBase-input": {
                        padding: '0 0 0 20px',
                        height: '40px',
                        fontSize: '14px'
                      },
                      "& .MuiInputBase-root": {
                        fontSize: '16px',
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: "space-between",
            gap: "24px",
            height: "36px",
            padding: '8px 16px',
            color: '#707070B2',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.4px",
            textTransform: "uppercase",

          }}>
            <Box sx={{ cursor: "pointer" }} onClick={() => { setOpenEditCollectionAndTagAlert(false); }}>cancel</Box>
            <Box sx={{ color: "#FF9711", cursor: "pointer" }} onClick={() => {
              set_select_product_collection_and_tag({
                variables: {
                  productIDList: selectedIDList,
                  collectionList: editCollection,
                  tagList: editTag,
                  productType: productTypeInput,
                },
              }).then((result) => {
                if (
                  result.data != null &&
                  result.data.setSelectedProductCollectionAndTag != null &&
                  result.data.setSelectedProductCollectionAndTag.status === true
                ) {
                  handleRefetchMyProductList(pageIndex);
                  setState({
                    ...state,
                    openSnackbar: true,
                    snackbarText: `Edit ${editCollectionAndTagType} successfully !`,
                    snackbarState: 'SUCCESS',
                  });
                  setOpenEditCollectionAndTagAlert(false);
                  setEditCollection([]);
                  setCollectionInput("");
                  setEditTag([]);
                  setProductTypeInput("");
                  setTagOptionList([]);
                  setTagsInput("");
                } else {
                  setState({
                    ...state,
                    openSnackbar: true,
                    snackbarText: `Edit ${editCollectionAndTagType} failed !`,
                    snackbarState: 'FAILED',
                  });
                }
              })
            }}>apply</Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openEditPriceAlert}
        onClose={() => {
          setOpenEditPriceAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            width: "474px",
            height: "290px",
            borderRadius: "4px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: '26px',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "160%",
            letterSpacing: "0.15px",
            color: "rgba(0, 0, 0, 0.87)",
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>Edit Profit Markup</Box>
        </DialogTitle>
        <DialogContent sx={{
          padding: '0px'
        }}>
          <Box
            sx={{
              height: "164px",
              padding: '8px 16px',
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              letterSpacing: "0.15px",
              color: "rgba(0, 0, 0, 0.87)",
              "& .radio_label_wrap": {
                height: '88px',
                display: 'flex',
                alignItems: 'center',
                fontSize: "16px"
              }
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={editPriceType}
                onChange={(e) => { setEditPriceType(e.target.value) }}
              >
                <FormControlLabel value="percentage" control={<Radio icon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonUncheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} checkedIcon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonCheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} />} label={<Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                }} className="radio_label_wrap">
                  <Box sx={{ marginRight: "10px" }}>By Percentage:</Box>
                  <Box sx={{ position: 'relative' }}>
                    <Box sx={{
                      border: "1px solid #999999",
                      width: "140px",
                      height: "40px",
                      borderRadius: '10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                      boxSizing: 'border-box',
                      fontSize: '16px',
                      position: 'relative',
                      zIndex: 999999,
                    }} onClick={() => { setPercentageBoxSwitch(true); }}>
                      <TextField
                        InputProps={{
                          endAdornment: <InputAdornment position="end" sx={{ "& .MuiTypography-root.MuiTypography-body1 ": { fontSize: "16px" } }}>%</InputAdornment>,
                        }}
                        id="edit_price_percentage"
                        sx={{
                          width: '140px',
                          fontSize: '16px',
                          border: "none",
                          "& .MuiInputBase-input": {
                            border: "none",
                            padding: '0px',
                            paddingLeft: '10px',
                            height: '40px',
                            fontSize: '14px'
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "none",
                          },
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                        placeholder="Percentage value"
                        value={editPricePercentage}
                        onChange={(e) => {
                          setEditPricePercentage(e.target.value);
                        }}
                      />
                    </Box>
                    {percentageBoxSwitch ?
                      <Box sx={{
                        width: "140px",
                        height: '120px',
                        boxShadow: '0rem 0.5rem 0.5rem -0.3rem rgb(0 0 0 / 20%), 0rem 0.8rem 1rem 0.1rem rgb(0 0 0 / 14%), 0rem 0.3rem 1.4rem 0.2rem rgb(0 0 0 / 12%)',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        padding: '10px 0',
                        position: 'absolute',
                        left: 0,
                        top: "40px",
                        zIndex: 99999,
                        cursor: 'pointer',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                      }}>
                        {editPricePercentageList.map((item, index) => {
                          return (
                            <Box sx={{
                              padding: '0.4rem 1rem',
                              backgroundColor: item.value === editPricePercentage ? '#e0e0e0' : '#FFF',
                              '&:hover': {
                                backgroundColor: '#f5f5f5'
                              }
                            }} onClick={() => {
                              setEditPricePercentage(item.value);
                              setPercentageBoxSwitch(false);
                            }} key={index}>{item.option}</Box>
                          )
                        })}

                      </Box>
                      : null
                    }
                    {percentageBoxSwitch ?
                      <Box sx={{ width: '192rem', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: '99998' }} onClick={() => { setPercentageBoxSwitch(false); }}>
                      </Box>
                      : null
                    }
                  </Box>
                </Box>} />
                <FormControlLabel value="fixed" control={<Radio icon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonUncheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} checkedIcon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonCheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} />} label={<Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                }} className="radio_label_wrap">
                  <Box sx={{ marginRight: "10px" }}>By Fixed Value: </Box>
                  <Box sx={{
                    width: '140px',
                    height: '40px',
                    border: "1px solid #999999",
                    borderRadius: "10px"
                  }}>
                    <TextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start" sx={{ "& .MuiTypography-root.MuiTypography-body1 ": { fontSize: "16px" } }}>$</InputAdornment>,
                      }}
                      id="edit_price_fixed"
                      sx={{
                        width: '140px',
                        fontSize: '16px',
                        border: "none",
                        "& .MuiInputBase-input": {
                          border: "none",
                          padding: '0px',
                          paddingLeft: '10px',
                          height: '40px',
                          fontSize: '14px'
                        },
                        "& .MuiOutlinedInput-root": {
                          border: "none",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      placeholder="fixed value"
                      value={editPriceFixed}
                      onChange={(e) => {
                        setEditPriceFixed(e.target.value);
                      }}
                    />
                  </Box>
                </Box>} />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: "space-between",
            gap: "24px",
            height: "36px",
            padding: '8px 16px',
            color: '#707070B2',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.4px",
            textTransform: "uppercase",

          }}>
            <Box sx={{ cursor: "pointer" }} onClick={() => { setOpenEditPriceAlert(false); }}>cancel</Box>
            <Box sx={{ color: "#FF9711", cursor: "pointer" }} onClick={() => {
              set_select_product_price({
                variables: {
                  productIDList: selectedIDList,
                  method: editPriceType,
                  inputValue: editPriceType == "percentage" ? editPricePercentage : editPriceFixed,
                },
              }).then((result) => {
                if (
                  result.data != null &&
                  result.data.setSelectedProductPrice != null &&
                  result.data.setSelectedProductPrice.status === true
                ) {
                  handleRefetchMyProductList(pageIndex);
                  setState({
                    ...state,
                    openSnackbar: true,
                    snackbarText: 'Edit profit markup successfully !',
                    snackbarState: 'SUCCESS',
                  });
                  setOpenEditPriceAlert(false);
                  setEditPriceType("percentage");
                  setEditPricePercentage("150");
                  setEditPriceFixed("5");
                } else {
                  setState({
                    ...state,
                    openSnackbar: true,
                    snackbarText: "Edit profit markup failed !",
                    snackbarState: 'FAILED',
                  });
                }
              })
            }}>apply</Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Box sx={{ fontSize: '14px', minWidth: '1440px' }}>
        <AppHeader />
        <Box sx={{ display: "flex" }}>
          <Drawer
            anchor="right"
            open={isOpenFilter}
            onClose={() => {
              setIsOpenFilter(false);
            }}
          >
            <Box sx={{
              width: '272px',
              padding: '28px 72px 28px 24px',
              height: '100%',
              "& .MuiInputLabel-root": {
                fontSize: '16px',
                height: '20px',
                lineHeight: '16px',
                color: "rgba(0, 0, 0, 0.6)!important",
                "&.Mui-focused": {
                  fontSize: '16px',
                  lineHeight: '16px',
                  color: "rgba(0, 0, 0, 0.6)!important",
                },
              },
              "& .MuiSvgIcon-root": {
                fontSize: '28px',
              },
              "& fieldset": {
                fontSize: '14px',
                borderColor: "rgba(0, 0, 0, 0.23)!important",
                "&.Mui-focused": {
                  borderColor: "rgba(0, 0, 0, 0.23)!important",
                },
              }
            }}>
              <Box sx={{ width: 'auto', display: 'flex', alignItems: 'center', fontSize: '14px', letterSpacing: '0.46px', color: '#FF9711', cursor: 'pointer' }} onClick={() => {
                setIsOpenFilter(false);
              }}>
                <Box sx={{ transform: 'rotate(-45deg)', fontSize: "30px", fontWeight: '200', marginRight: '10px' }}>+</Box>
                <Box>CLOSE</Box>
              </Box>
              <Box sx={{ width: '272px', position: 'relative', marginTop: '16px' }}>
                <FormControl sx={{
                  width: "272px",
                  "& .MuiInputLabel-root": {
                    fontSize: '16px',
                    lineHeight: '16px',
                    color: "rgba(0, 0, 0, 0.6)!important",
                    "&.Mui-focused": {
                      fontSize: '16px',
                      lineHeight: '16px',
                      color: "rgba(0, 0, 0, 0.6)!important",
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: '28px',
                  },
                  "& fieldset": {
                    fontSize: '14px',
                    borderColor: "rgba(0, 0, 0, 0.23)!important",
                    "&.Mui-focused": {
                      borderColor: "rgba(0, 0, 0, 0.23)!important",
                    },
                  },
                }}>
                  <InputLabel id="demo-multiple-chip-label">Supplier</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    sx={{
                      "& .MuiSelect-select.MuiInputBase-input": {
                        padding: '0px',
                        paddingLeft: '10px',
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                        overflow: "hidden",
                        fontSize: '14px'
                      },
                    }}
                    multiple
                    value={supplierSelectedList}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      setSupplierSelectedList(
                        // On autofill we get a stringified value.
                        typeof value === 'string' ? value.split(',') : value,
                      );
                    }}
                    input={<OutlinedInput id="select-multiple-chip" label="Supplier" sx={{ fontSize: "14px" }} />}
                    renderValue={(selected) => (
                      <Box sx={{
                        display: 'flex', flexWrap: 'no-wrap', alignItems: "center", maxWidth: '220px', overflow: 'hidden', gap: 0.5, "& .MuiChip-label": {
                          height: '20px',
                          lineHeight: '20px'
                        }
                      }}>
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "200px",
                          width: "272px"
                        }
                      }
                    }}
                  >
                    {supplierList.map((collection) => (
                      <MenuItem
                        key={collection}
                        value={collection}
                        style={getStyles(collection, supplierSelectedList, theme)}
                        sx={{
                          "&.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root": {
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            fontSize: '14px',
                            "&.Mui-selected": {
                              background: "rgba(0, 0, 0, 0.06)",
                              "&:hover": {
                                background: "rgba(0, 0, 0, 0.06)",
                              }
                            }
                          }
                        }}
                      >
                        <Checkbox checked={supplierSelectedList.indexOf(collection) > -1}
                          icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                          </Box>}
                          checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_gray_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                          </Box>} />
                        {collection}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box sx={{
                  position: 'absolute',
                  height: '32px',
                  lineHeight: '32px',
                  top: '8px',
                  left: '10px',
                  lineHeight: '24px',
                  color: "#00000099"
                }}>
                  <Box sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: "center", maxWidth: '220px', overflow: 'hidden', gap: 0.5 }}>
                    {supplierSelectedList.map((value, index) => (
                      <Chip key={value} label={value} sx={{ fontSize: '14px' }} onDelete={() => {
                        let Arr = supplierSelectedList;
                        Arr.splice(index, 1);
                        setSupplierSelectedList(Arr);
                        setRefetchPageNum(refetchPageNum + 1)
                      }} />
                    ))}
                  </Box>
                </Box>
                {supplierSelectedList.length > 0 && <Box sx={{
                  position: 'absolute',
                  height: '24px',
                  bottom: '-24px',
                  left: '10px',
                  lineHeight: '24px',
                  color: "#00000099"
                }}>{supplierSelectedList.length} Selected</Box>}
              </Box>
              {/* <Box sx={{
                fontSize: '16px',
                fontWeight: '400',
                letterSpacing: '0.15px',
                color: "rgba(0, 0, 0, 0.87)"
              }}>
                <Box sx={{ height: '48px', lineHeight: '48px', marginTop: '20px', color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px', fontWeight: '500', paddingLeft: '9px' }}>Type</Box>
                {typeList.map((type, index) => {
                  return <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }} key={index}>
                    <Checkbox
                      checked={typeSelectedList.includes(type)}
                      onChange={() => {
                        let Arr = typeSelectedList;
                        if (typeSelectedList.includes(type)) {
                          Arr.splice(typeSelectedList.indexOf(type), 1);
                        } else {
                          Arr.splice(0, 0, type);
                        }
                        setTypeSelectedList(Arr);
                        setRefetchPageNum(refetchPageNum + 1);
                      }}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                      icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                      </Box>}
                      checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                      </Box>}
                    />
                    <Box sx={{ cursor: "pointer" }} onClick={() => {
                      let Arr = typeSelectedList;
                      if (typeSelectedList.includes(type)) {
                        Arr.splice(typeSelectedList.indexOf(type), 1);
                      } else {
                        Arr.splice(0, 0, type);
                      }
                      setTypeSelectedList(Arr);
                      setRefetchPageNum(refetchPageNum + 1);
                    }}>{type}</Box>
                  </Box>
                })}
              </Box>
              <Box sx={{
                fontSize: '16px',
                fontWeight: '400',
                letterSpacing: '0.15px',
                color: "rgba(0, 0, 0, 0.87)"
              }}>
                <Box sx={{ height: '48px', lineHeight: '48px', marginTop: '20px', color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px', fontWeight: '500', paddingLeft: '9px' }}>Tags</Box>
                {tagList.map((tag, index) => {
                  return <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '40px'
                  }} key={index}>
                    <Checkbox
                      checked={tagSelectedList.includes(tag)}
                      onChange={() => {
                        let Arr = tagSelectedList;
                        if (tagSelectedList.includes(tag)) {
                          Arr.splice(tagSelectedList.indexOf(tag), 1);
                        } else {
                          Arr.splice(0, 0, tag);
                        }
                        setTagSelectedList(Arr);
                        setRefetchPageNum(refetchPageNum + 1);
                      }}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                      icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                      </Box>}
                      checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                      </Box>}
                    />
                    <Box sx={{ cursor: "pointer" }} onClick={() => {
                      let Arr = tagSelectedList;
                      if (tagSelectedList.includes(tag)) {
                        Arr.splice(tagSelectedList.indexOf(tag), 1);
                      } else {
                        Arr.splice(0, 0, tag);
                      }
                      setTagSelectedList(Arr);
                      setRefetchPageNum(refetchPageNum + 1);
                    }}>{tag}</Box>
                  </Box>
                })}
              </Box> */}
              <Box sx={{
                fontSize: '16px',
                fontWeight: '400',
                letterSpacing: '0.15px',
                color: "rgba(0, 0, 0, 0.87)"
              }}>
                <Box sx={{
                  width: '272px',
                  height: '48px',
                  marginTop: '30px'
                }}>
                  <TextField
                    id="myproduct_search"
                    label="Type"
                    sx={{
                      width: '272px',
                      fontSize: '16px',
                      "& .MuiInputBase-input": {
                        padding: '0px',
                        paddingLeft: '10px',
                        height: '48px',
                        fontSize: '14px'
                      }
                    }}
                    placeholder="type"
                    value={typeValue}
                    onChange={(e) => {
                      setTypeValue(e.target.value);
                    }}
                    onBlur={() => {
                      handleRefetchMyProductList(0);
                      setPageIndex(0);
                    }}
                  />
                </Box>
              </Box>
              {storePlatform != "wix" ? <Box sx={{
                fontSize: '16px',
                fontWeight: '400',
                letterSpacing: '0.15px',
                color: "rgba(0, 0, 0, 0.87)"
              }}>
                <Box sx={{
                  width: '272px',
                  height: '48px',
                  marginTop: '30px'
                }}>
                  <TextField
                    id="myproduct_search"
                    label="Tag"
                    sx={{
                      width: '272px',
                      fontSize: '16px',
                      "& .MuiInputBase-input": {
                        padding: '0px',
                        paddingLeft: '10px',
                        height: '48px',
                        fontSize: '14px'
                      }
                    }}
                    placeholder="tag"
                    value={tagValue}
                    onChange={(e) => {
                      setTagValue(e.target.value);
                    }}
                    onBlur={() => {
                      handleRefetchMyProductList(0);
                      setPageIndex(0);
                    }}
                  />
                </Box>
              </Box> : null}
              {/* <Box sx={{ height: '40px', color: 'rgba(33, 150, 243, 1)', fontSize: '16px', fontWeight: '400', letterSpacing: '0.15px', textDecoration: 'underline', cursor: 'pointer' }}>Show more</Box> */}
            </Box>
          </Drawer>
          <Box sx={{ minWidth: "240px", flexShrink: "0" }} ><AppSidebar /> </Box> {/* App SiderBar */}
          <Box sx={{ display: "flex", width: "100%", justifyContent: 'center', position: 'relative' }}>
            {!isOpenEdit ? <Box sx={{ minWidth: "100px", maxWidth: "1200px", padding: "16px 16px 20px 20px" }}>

              {optimizeStatus === 'wait' ? <Box sx={{
                minWidth: "100px",
                maxWidth: "1200px",
                height: '70px',
                margin: '0px auto 10px',
                backgroundColor: '#EEF7FF',
                border: '1px solid #2888DD',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '20px',
                fontWeight: '500',
                color: '#006FD1',
                zIndex: '999',
                position: 'sticky',
                top: '9.8rem',
                "@keyframes load": {
                  from: {
                    transform: "rotate(0deg)",
                  },
                  to: {
                    transform: "rotate(360deg)",
                  }
                },
              }}>
                <Box sx={{ margin: '0 30px' }}>
                  <Box sx={{ background: 'url("img/push_wait.png") no-repeat 0 0', width: '30px', height: '30px', backgroundSize: 'contain', animation: 'load 1.5s linear infinite ', }} />
                </Box>
                Optimizing {optimizeCompleteNum}/{optimizeNum} products, don't close this window ...
              </Box> : null}
              {optimizeStatus === 'finish' ? <Box sx={{
                minWidth: "100px",
                maxWidth: "1200px",
                height: '70px',
                margin: '0px auto 10px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '20px',
                fontWeight: '500',
                backgroundColor: optimizeSuccessNum === 0 ? '#FFE6E6' : '#E6FFE7',
                border: optimizeSuccessNum === 0 ? '1px solid #E62E2E' : '1px solid #4CDD28',
                color: optimizeSuccessNum === 0 ? '#E62E2E' : '#03A527',
                position: 'sticky',
                top: '9.8rem',
                zIndex: '999',
              }}>
                <Box sx={{ margin: '0 30px' }}>
                  <Box sx={{ background: optimizeSuccessNum === 0 ? 'url("img/push_failed.png") no-repeat 0 0' : 'url("img/push_success.png") no-repeat 0 0', width: '30px', height: '30px', backgroundSize: 'contain', }} />
                </Box>
                {optimizeSuccessNum === 0 ? <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: '1',
                }}>
                  Failed to optimize   {optimizeNum}
                </Box> : <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: '1',
                  paddingRight: '50px'
                }}>
                  <Box>Successfully optimize  {optimizeSuccessNum}</Box>
                  {optimizeNum > optimizeSuccessNum ? <Box sx={{ color: '#E62E2EE5', width: "207px", textAlign: 'center' }}>Unsuccessful {optimizeNum - optimizeSuccessNum}</Box> : null}
                </Box>}
                <Box sx={{ background: 'url("img/icon_close.png") no-repeat 0 0', backgroundSize: 'contain', width: '30px', height: '30px', position: 'absolute', top: '7px', right: '6px', cursor: 'pointer' }} onClick={() => { setPushStatus('none'); }} />
              </Box> : null}
              {pushStatus === 'wait' ? <Box sx={{
                minWidth: "100px",
                maxWidth: "1200px",
                height: '70px',
                margin: '0px auto 10px',
                backgroundColor: '#EEF7FF',
                border: '1px solid #2888DD',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '20px',
                fontWeight: '500',
                color: '#006FD1',
                zIndex: '999',
                position: 'sticky',
                top: '9.8rem',
                "@keyframes load": {
                  from: {
                    transform: "rotate(0deg)",
                  },
                  to: {
                    transform: "rotate(360deg)",
                  }
                },
              }}>
                <Box sx={{ margin: '0 30px' }}>
                  <Box sx={{ background: 'url("img/push_wait.png") no-repeat 0 0', width: '30px', height: '30px', backgroundSize: 'contain', animation: 'load 1.5s linear infinite ', }} />
                </Box>
                {pushIDList.length}  products being pushed
              </Box> : null}
              {pushStatus === 'finish' ? <Box sx={{
                minWidth: "100px",
                maxWidth: "1200px",
                height: '70px',
                margin: '0px auto 10px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '20px',
                fontWeight: '500',
                backgroundColor: currentSuccessCount === 0 ? '#FFE6E6' : '#E6FFE7',
                border: currentSuccessCount === 0 ? '1px solid #E62E2E' : '1px solid #4CDD28',
                color: currentSuccessCount === 0 ? '#E62E2E' : '#03A527',
                position: 'sticky',
                top: '9.8rem',
                zIndex: '999',
              }}>
                <Box sx={{ margin: '0 30px' }}>
                  <Box sx={{ background: currentSuccessCount === 0 ? 'url("img/push_failed.png") no-repeat 0 0' : 'url("img/push_success.png") no-repeat 0 0', width: '30px', height: '30px', backgroundSize: 'contain', }} />
                </Box>
                {currentSuccessCount === 0 ? <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: '1',
                }}>
                  Failed to push   {currentFailedCount}
                </Box> : <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: '1',
                  paddingRight: '50px'
                }}>
                  <Box>Successfully pushed  {currentSuccessCount}</Box>
                  {currentFailedCount > 0 ? <Box sx={{ color: '#E62E2EE5', width: "207px", textAlign: 'center' }}>Unsuccessful {currentFailedCount}</Box> : null}
                </Box>}
                <Box sx={{ background: 'url("img/icon_close.png") no-repeat 0 0', backgroundSize: 'contain', width: '30px', height: '30px', position: 'absolute', top: '7px', right: '6px', cursor: 'pointer' }} onClick={() => { setPushStatus('none'); }} />
              </Box> : null}
              {
                filterAttestation ?
                  <Box>
                    <Box sx={{
                      width: '1146px',
                      minHeight: "300px",
                      backgroundColor: "#FFF",
                      borderRadius: '4px',
                      boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                      "& .MuiInputLabel-root": {
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: "rgba(0, 0, 0, 0.6)!important",
                        "&.Mui-focused": {
                          fontSize: '16px',
                          lineHeight: '16px',
                          color: "rgba(0, 0, 0, 0.6)!important",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: '28px',
                      },
                      "& fieldset": {
                        fontSize: '14px',
                        borderColor: "rgba(0, 0, 0, 0.23)!important",
                        "&.Mui-focused": {
                          borderColor: "rgba(0, 0, 0, 0.23)!important",
                        },
                      },
                      "& #demo-multiple-chip-label": collectionSelectedList.length > 0 ? {
                        transform: "translate(14px, -9px) scale(0.75)",
                      } : {},
                      "& legend": collectionSelectedList.length > 0 ? {
                        maxWidth: '100%'
                      } : {}
                    }}>
                      <Box sx={{
                        padding: '10px 10px 20px',
                        height: '64px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        "&>div": {
                          height: '48px'
                        }
                      }}>
                        <Box sx={{
                          width: '262px',
                          height: '48px'
                        }}>
                          <TextField
                            id="myproduct_search"
                            label="Search"
                            sx={{
                              width: '262px',
                              fontSize: '16px',
                              "& .MuiInputBase-input": {
                                padding: '0px',
                                paddingLeft: '10px',
                                height: '48px',
                                fontSize: '14px'
                              }
                            }}
                            placeholder="Product, type, supplier, etc.."
                            value={myProductSearchValue}
                            onChange={(e) => {
                              setMyProductSearchValue(e.target.value);
                            }}
                            onBlur={() => {
                              handleRefetchMyProductList(0);
                              setPageIndex(0);
                            }}
                          />
                        </Box>
                        <Box sx={{ width: '135px' }}></Box>
                        {storePlatform != "wix" ? null : <Box sx={{ width: '160px', position: 'relative' }}></Box>}
                        <Box sx={{ width: '160px' }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              sx={{
                                "& .MuiSelect-select.MuiInputBase-input": {
                                  padding: '0px',
                                  paddingLeft: '10px',
                                  height: '48px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  overflow: "hidden",
                                  fontSize: '14px'
                                }
                              }}
                              value={status}
                              label="Status"
                              onChange={(e) => {
                                setStatus(e.target.value);
                              }}
                            >
                              <MenuItem value="0"/*all*/ sx={{ fontSize: '14px' }}>All</MenuItem>
                              <MenuItem value='1'/*imported*/ sx={{ fontSize: '14px' }}>Imported</MenuItem>
                              <MenuItem value="2"/*not_imported*/ sx={{ fontSize: '14px' }}>Not Imported</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        {storePlatform != "wix" ? <Box sx={{ width: '160px', position: 'relative' }}>
                          <FormControl sx={{
                            width: "160px",
                          }}>
                            <InputLabel id="demo-multiple-chip-label">Collection</InputLabel>
                            <Select
                              labelId="demo-multiple-chip-label"
                              id="demo-multiple-chip"
                              sx={{
                                "& .MuiSelect-select.MuiInputBase-input": {
                                  padding: '0px',
                                  paddingLeft: '10px',
                                  height: '48px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  overflow: "hidden",
                                  fontSize: '14px'
                                },
                              }}
                              multiple
                              value={collectionSelectedList}
                              onChange={handleChangeCollection}
                              onOpen={() => {
                                if (collectionOptionList.length === 0) {
                                  get_product_collection().then(res => {
                                    if (res != null && res.data != null && res.data.getProductCollection) {
                                      setCollectionOptionList(res.data.getProductCollection.collectionOptionTitleList);
                                    }
                                  });
                                }
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Collection" sx={{ fontSize: "14px" }} />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: "center", maxWidth: '120px', overflow: 'hidden', gap: 0.5 }}>
                                </Box>
                              )}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: "200px",
                                    width: "160px"
                                  }
                                }
                              }}
                            >
                              {collectionOptionList.length > 0 ? collectionOptionList.map((collection) => (
                                <MenuItem
                                  key={collection}
                                  value={collection}
                                  style={getStyles(collection, collectionSelectedList, theme)}
                                  sx={{
                                    "&.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root": {
                                      paddingLeft: '0px',
                                      paddingRight: '0px',
                                      fontSize: '14px',
                                      "&.Mui-selected": {
                                        background: "rgba(0, 0, 0, 0.06)",
                                        "&:hover": {
                                          background: "rgba(0, 0, 0, 0.06)",
                                        }
                                      }
                                    }
                                  }}
                                >
                                  <Checkbox checked={collectionSelectedList.indexOf(collection) > -1}
                                    icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                    </Box>}
                                    checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_gray_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                    </Box>} />

                                  <ButtonTooltip title={getButtonTooltipLayout(collection)} arrow>
                                    <Box sx={{ width: '160px' }}>{collection}</Box>
                                  </ButtonTooltip>

                                </MenuItem>
                              )) :
                                <Box
                                  sx={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: 'center'
                                  }}
                                >
                                  <CircularProgress />
                                </Box>}
                            </Select>
                          </FormControl>
                          <Box sx={{
                            position: 'absolute',
                            height: '32px',
                            lineHeight: '32px',
                            top: '8px',
                            left: '10px',
                            lineHeight: '24px',
                            color: "#00000099"
                          }}>
                            <Box sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: "center", maxWidth: '120px', overflow: 'hidden', gap: 0.5 }}>
                              {collectionSelectedList.map((value, index) => (
                                <Chip key={value} label={value} sx={{ fontSize: '14px' }} onDelete={() => {
                                  let Arr = collectionSelectedList;
                                  Arr.splice(index, 1);
                                  setCollectionSelectedList(Arr);
                                  setRefetchPageNum(refetchPageNum + 1)
                                }} />
                              ))}
                            </Box>
                          </Box>
                          {collectionSelectedList.length > 0 && <Box sx={{
                            position: 'absolute',
                            height: '24px',
                            bottom: '-24px',
                            left: '10px',
                            lineHeight: '24px',
                            color: "#00000099"
                          }}>{collectionSelectedList.length} Selected</Box>}
                        </Box> : null}
                        <Box sx={{ width: '160px' }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              sx={{
                                "& .MuiSelect-select.MuiInputBase-input": {
                                  padding: '0px',
                                  paddingLeft: '10px',
                                  height: '48px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  overflow: "hidden",
                                  fontSize: '14px'
                                }
                              }}
                              value={sort}
                              label="Sort"
                              onChange={(e) => {
                                setSort(e.target.value);
                              }}
                            >
                              <MenuItem value='latest' sx={{ fontSize: '14px' }}>Latest</MenuItem>
                              <MenuItem value="oldest" sx={{ fontSize: '14px' }}>Oldest</MenuItem>
                              <MenuItem value="price_low_to_high" sx={{ fontSize: '14px' }}>Price: Low to High</MenuItem>
                              <MenuItem value="price_high_to_low" sx={{ fontSize: '14px' }}>Price: High to Low</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <ButtonTooltip title={getButtonTooltipLayout("Switch to compact viewing mode")} arrow>
                          <Box sx={{ width: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{ width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: currentViewMode === "concise" ? "0px solid rgba(0, 0, 0, 0.23)" : "1px solid rgba(0, 0, 0, 0.23)", borderRadius: '4px', background: currentViewMode === "concise" ? "#FFF" : "rgba(0, 0, 0, 0.08)", cursor: 'pointer' }} onClick={() => {
                              setCurrentViewMode(currentViewMode === "concise" ? "compact" : "concise");
                              save_myproducts_page_setting({
                                variables: {
                                  viewMode: currentViewMode === "concise" ? "compact" : "concise",
                                },
                              })
                            }} >
                              <ListAltOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '28px', cursor: 'pointer' }} />
                            </Box>
                          </Box>
                        </ButtonTooltip>
                        {/* <ButtonTooltip title={getButtonTooltipLayout("Customize columns")} arrow>
                      <Box sx={{ width: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <SettingsOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '28px', cursor: 'pointer' }} />
                      </Box>
                    </ButtonTooltip> */}
                        <ButtonTooltip title={getButtonTooltipLayout("More filters")} arrow>
                          <Box sx={{ width: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {
                            setIsOpenFilter(true);
                          }}>
                            <TuneOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '28px', cursor: 'pointer' }} />
                          </Box>
                        </ButtonTooltip>
                      </Box>
                      <Box>
                        <Box>
                          {
                            currentViewMode === "concise" ?
                              <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                "&>div": {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }
                              }}>
                                <Box sx={{
                                  width: '130px',
                                  alignItems: 'flex-start!important',
                                }}>
                                  <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0px 10px 0px 0px'
                                  }}>

                                    <ButtonTooltip title={getButtonTooltipLayout("Select all products")} arrow>
                                      <Checkbox
                                        checked={checkedAll}
                                        onChange={(e) => {
                                          if (statusLoading == true || statusData == null || statusData.getSubscriptionStatus === "Free") {
                                            setState({
                                              ...state,
                                              isOpenSelectPlanDialog: true
                                            });
                                            return;
                                          }
                                          let Arr = [];
                                          if (e.target.checked) {
                                            data != null && data.getUserProductList.result.map(({ id, available }) => {
                                              if (available) {
                                                Arr.push(id);
                                              }
                                            });
                                            setSelectedIDList(Arr);
                                            setCheckedAll(true);
                                          } else {
                                            setSelectedIDList([]);
                                            setCheckedAll(false);
                                          }
                                        }}
                                        inputProps={{
                                          'aria-label': 'select all desserts',
                                        }}
                                        icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                        </Box>}
                                        checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                        </Box>}
                                      />
                                    </ButtonTooltip>

                                  </Box>
                                </Box>
                                <Box sx={{ width: '80px', alignItems: 'center' }}>Picture</Box>
                                <Box sx={{ width: '180px', alignItems: 'center' }}>Title</Box>
                                <Box sx={{ width: '150px' }}>Cost</Box>
                                <ButtonTooltip title={getButtonTooltipLayout(`Shipping fee will be calculated based on the methods you choose to fulfill the product. Please continue to product detail editing for more details.`)} arrow>
                                  <Box sx={{ width: '150px' }}>Shipping Fee</Box>
                                </ButtonTooltip>
                                <Box sx={{ width: '150px' }}>Store Price</Box>
                                <Box sx={{ width: '150px' }}>Profit Markup</Box>
                                {/* <Box sx={{ width: '100px' }}>
                            <Box>Collection</Box>
                            <Box>Type</Box>
                          </Box> */}
                                <Box sx={{ width: "130px", alignItems: 'center' }}>Status</Box>
                              </Box>
                              :
                              <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                "&>div": {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }
                              }}>
                                <Box sx={{
                                  width: '130px',
                                  alignItems: 'flex-start!important',
                                }}>
                                  <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0px 10px 0px 0px'
                                  }}>
                                    <ButtonTooltip title={getButtonTooltipLayout("Select all products")} arrow>
                                      <Checkbox
                                        checked={checkedAll}
                                        onChange={(e) => {
                                          if (statusLoading == true || statusData == null || statusData.getSubscriptionStatus === "Free") {
                                            setState({
                                              ...state,
                                              isOpenSelectPlanDialog: true
                                            });
                                            return;
                                          }
                                          let Arr = [];
                                          if (e.target.checked) {
                                            data != null && data.getUserProductList.result.map(({ id, available }) => {
                                              if (available) {
                                                Arr.push(id);
                                              }
                                            });
                                            setSelectedIDList(Arr);
                                            setCheckedAll(true);
                                          } else {
                                            setSelectedIDList([]);
                                            setCheckedAll(false);
                                          }
                                        }}
                                        inputProps={{
                                          'aria-label': 'select all desserts',
                                        }}
                                        icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                        </Box>}
                                        checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                        </Box>}
                                      />
                                    </ButtonTooltip>
                                  </Box>
                                </Box>
                                <Box sx={{ width: '80px', alignItems: 'center' }}>Picture</Box>
                                <Box sx={{ width: '180px', alignItems: 'center' }}>Title</Box>
                                <Box sx={{ width: '100px' }}>
                                  <Box>Cost</Box>
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                  <ButtonTooltip title={getButtonTooltipLayout(`Shipping fee will be calculated based on the methods you choose to fulfill the product. Please continue to product detail editing for more details.`)} arrow>
                                    <Box>Shipping Fee</Box>
                                  </ButtonTooltip>
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                  <Box>Store Price</Box>
                                  <Box>Compare Price</Box>
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                  <Box>Profit </Box>
                                  <Box>Markup</Box>
                                </Box>
                                {/* <Box sx={{ width: '100px' }}>
                              <Box>30D Sales</Box>
                              <Box>Stars 4+</Box>
                            </Box> */}
                                <Box sx={{ width: '105px' }}>
                                  <Box>Default Supplier</Box>
                                  <Box>Inventory</Box>
                                </Box>
                                <Box sx={{ width: '100px' }}>
                                  <Box>Collection</Box>
                                  <Box>Tags</Box>
                                </Box>
                                <Box sx={{ width: "130px", alignItems: 'center' }}>Status</Box>
                              </Box>
                          }
                        </Box>
                        <Box sx={{
                          fontSize: "14px",
                          overflow: "auto"
                        }}>
                          {(initialize || loading === true) && (data == null || data.getUserProductList == null) ? (<Box
                            sx={{
                              height: "400px",
                              marginTop: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>) : data != null && data.getUserProductList.result.length === 0 ?
                            <Box sx={{ height: '124px', fontSize: '18px', color: "#666", fontWeight: '600', lineHeight: '124px', textAlign: 'center' }}>There are currently no products that match the filter criteria</Box> :
                            data != null && data.getUserProductList.result.map(({ id, title, price, currency, multipliedPrice, thumbnailSource, productID, available, shopifyProductID, crawlerID, productSource, productSourceURL, shippingFee, inventory, tagsList, collectionList, fulfillmentOption }, index) => {
                              return <ProductCard
                                key={index}
                                productSelectedNum={productSelectedNum}
                                currentViewMode={currentViewMode}
                                id={id}
                                index={index}
                                title={title}
                                price={price}
                                currency={currency}
                                multipliedPrice={multipliedPrice}
                                thumbnailSource={thumbnailSource}
                                shopifyProductID={currentProductIDList[index] != null ? currentProductIDList[index].shopifyProductID : '0'}
                                productID={productID}
                                available={available}
                                setIsOpenEdit={setIsOpenEdit}
                                crawlerID={crawlerID}
                                productSource={productSource}
                                productSourceURL={productSourceURL}
                                isPushingAll={pushIDList.includes(id) && isPushingAll}
                                pushNum={selectedIDList.length !== 0 ? 12 - (Math.ceil(selectedIDList.length / 5) * 2) : 10}
                                selectedIDList={selectedIDList}
                                setSelectedIDList={setSelectedIDList}
                                setCheckedAll={setCheckedAll}
                                productList={data != null && data.getUserProductList.result}
                                handleRefreshPage={() => { setRefreshPage(refreshPage + 1) }}
                                undateCurrentProductIDList={(shopifyProductID) => {
                                  let midArr = currentProductIDList;
                                  midArr[index].shopifyProductID = shopifyProductID;
                                  setCurrentProductIDList(midArr);
                                }}
                                isPushFailed={pushFailedIDList.includes(id)}
                                pushReason={pushFailedIDList.includes(id) ? pushFailedReasonList[pushFailedIDList.indexOf(id)] : null}
                                setPushSuccessIDList={setPushSuccessIDList}
                                setPushFailedIDList={setPushFailedIDList}
                                setPushFailedReasonList={setPushFailedReasonList}
                                setCurrentFailedCount={setCurrentFailedCount}
                                setPushStatus={setPushStatus}
                                pushFailedIDList={pushFailedIDList}
                                pushFailedReasonList={pushFailedReasonList}
                                setPushIDList={setPushIDList}
                                setOpenDeleteAlert={setOpenDeleteAlert}
                                setDeleteDisabledProduct={setDeleteDisabledProduct}
                                shippingFee={shippingFee}
                                inventory={inventory}
                                tagsList={tagsList}
                                collectionList={collectionList}
                                fulfillmentOption={fulfillmentOption}
                                setStatus={setStatus}
                                setTagValue={setTagValue}
                                setSearchImmediate={setSearchImmediate}
                                handleRefetchMyProductList={handleRefetchMyProductList}
                                setPageIndex={setPageIndex}
                                collectionSelectedList={collectionSelectedList}
                                setCollectionSelectedList={setCollectionSelectedList}
                                setOpenRatingAlert={setOpenRatingAlert}
                                optimizeFailedReason={optimizeFailedIDList.includes(id) ? optimizeFailedReasonList[optimizeFailedIDList.indexOf(id)] : null}
                                isOptimizeFailed={optimizeFailedIDList.includes(id)}
                              />
                            })
                          }
                          <Box sx={{ width: '100%', height: '10px', background: "#f2f2f2" }}></Box>
                        </Box>
                        <TablePagination
                          sx={{
                            fontSize: '14px',
                            "& .MuiTablePagination-displayedRows": {
                              fontSize: '14px'
                            },
                            "& .MuiTablePagination-selectLabel": {
                              fontSize: '14px'
                            },
                            "& .MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input": {
                              fontSize: '14px',
                              lineHeight: '150%'
                            },
                            "& .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root": {
                              fontSize: '14px',
                            },
                          }}
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={loading === false && data != null && data.getUserProductList != null ? data.getUserProductList.currentProductCount : 0}
                          rowsPerPage={rowsPerPage}
                          page={pageIndex}
                          onPageChange={(event, newPage) => {
                            setPageIndex(newPage);
                            setSelectedIDList([]);
                            setCheckedAll(false);
                            handleRefetchMyProductList(newPage);
                          }}
                          onRowsPerPageChange={(event) => {
                            setRowsPerPage(+event.target.value);
                            setPageIndex(0);
                            handleRefetchMyProductList(0, +event.target.value);
                            save_myproducts_page_setting({
                              variables: {
                                pageSize: (+event.target.value),
                              },
                            })
                          }}
                        />
                      </Box>
                      <Box sx={{
                        position: "fixed",
                        bottom: '0',
                        left: '240px',
                        width: 'calc(100% - 240px)',
                        height: "64px",
                        background: '#FFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0px 100px 0px 16px',
                        userSelect: 'none',
                        boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                        boxSizing: "border-box"
                      }}>
                        <Box sx={{
                          fontSize: '14px',
                          color: 'rgba(0, 0, 0, 0.87)',
                          fontWeight: '600'
                        }}>{selectedIDList.length} items selected</Box>
                        <Box sx={{
                          width: '400px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          "&>div": {
                            flex: '1',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }
                        }}>
                          <Box sx={{
                            borderRight: "3px solid #e0e0e0"
                          }}>
                            <Box sx={{
                              width: "156px",
                              height: "36px",
                              backgroundColor: false ? "rgba(112, 112, 112, 0.5)" : "#FF9711",
                              color: "#FFF",
                              fontFamily: 'Roboto',
                              fontStyle: "normal",
                              fontWeight: "600",
                              fontSize: "14px",
                              letterSpacing: "0.4px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              boxSizing: 'border-box',
                              cursor: false ? "not-allowed" : "pointer",
                              boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
                            }}
                              onClick={async () => {
                                sendLog("MY_PRODUCTS_ALL_PUSH_TO_SHOPIFY_CLICK");
                                if (isPushingAll) {
                                  return;
                                }
                                if (shopName == null) {
                                  setState({
                                    ...state,
                                    isAccountPopUpDialogOpen: true,
                                    currentComponentName: 'CONNECT_SHOPIFY',
                                  });
                                  return;
                                }

                                if (selectedIDList == null || selectedIDList.length === 0) {
                                  return;
                                }
                                if (selectedIDList != null && selectedIDList.length > 25) {
                                  setState({
                                    ...state,
                                    openSnackbar: true,
                                    snackbarText: 'Push job is received, and running in the background. Refresh page later.',
                                    snackbarState: 'SUCCESS',
                                  });
                                  push_selected_products({
                                    variables: {
                                      IDList: selectedIDList,
                                    },
                                  })
                                  return;
                                } else {
                                  setIsPushingAll(true);
                                  setPushStatus('wait');
                                  const Arr = selectedIDList.map(item => {
                                    return item;
                                  })
                                  setPushIDList(Arr);
                                  push_selected_products({
                                    variables: {
                                      IDList: selectedIDList,
                                    },
                                  }).then(async (result) => {
                                    localStorage.setItem("LastTimePushSelectedProduct", new Date().getTime());
                                    const isEditing = sessionStorage.getItem("isEditing");
                                    if (selectedIDList != null && selectedIDList.length > 25) {
                                      // Add a message to indicate the progress is going in the background
                                    }

                                    if (
                                      result.data != null &&
                                      result.data.pushSelectedProduct != null &&
                                      result.data.pushSelectedProduct.result != null &&
                                      result.data.pushSelectedProduct.result.successProductSchemaIDList != null &&
                                      result.data.pushSelectedProduct.result.failedProductSchemaIDList != null &&
                                      result.data.pushSelectedProduct.result.successShopifyIDList != null
                                    ) {
                                      if (result.data.pushSelectedProduct.message === 'PLEASE_UPGRADE_PLAN') {
                                        setState({
                                          ...state,
                                          isOpenSelectPlanDialog: true,
                                          isOpenImportProductDialog: isEditing === 'true',
                                        });
                                        setPushStatus('none');
                                        setIsPushingAll(false);
                                        return;
                                      }
                                      setPushStatus('finish');
                                      const cacheFailedIDList = pushFailedIDList;
                                      const cacheFailedReasonList = pushFailedReasonList;
                                      //如果第二次push，这件产品依然报错，把这条报错信息替换成最新返回的信息
                                      result.data.pushSelectedProduct.result.failedProductSchemaIDList.map((id, index) => {
                                        pushFailedIDList.map((cacheId, cacheIndex) => {
                                          if (cacheId === id) {
                                            cacheFailedReasonList[cacheIndex] = result.data.pushSelectedProduct.result.failedReasonList[index];
                                          }
                                        })
                                      })
                                      // 比较之前的错误列表和本次返回的错误列表，如果存在不同的，就把这个不同的ID和报错信息存放在当前列表
                                      result.data.pushSelectedProduct.result.failedProductSchemaIDList.map((id, index) => {
                                        if (!pushFailedIDList.some(cacheId => cacheId === id)) {
                                          cacheFailedIDList.push(id);
                                          cacheFailedReasonList.push(result.data.pushSelectedProduct.result.failedReasonList[index]);
                                        }
                                      })
                                      const resultFailedIDList = [];
                                      const resultFailedReasonList = [];
                                      // 比较当前错误ID列表和返回的成功的ID列表，如果当前错误的ID，不是成功的ID,就把这个ID,和对应的错误信息添加到结果列表
                                      cacheFailedIDList.map((id, index) => {
                                        if (!result.data.pushSelectedProduct.result.successProductSchemaIDList.some(cacheId => cacheId === id)) {
                                          resultFailedIDList.push(id);
                                          resultFailedReasonList.push(cacheFailedReasonList[index]);
                                        }
                                      })
                                      setPushSuccessIDList(result.data.pushSelectedProduct.result.successProductSchemaIDList);
                                      setPushFailedIDList(resultFailedIDList);
                                      setPushFailedReasonList(resultFailedReasonList);
                                      setCurrentFailedCount(result.data.pushSelectedProduct.result.failedProductSchemaIDList.length);
                                      setCurrentSuccessCount(result.data.pushSelectedProduct.result.successProductSchemaIDList.length);
                                      let midArr = currentProductIDList;
                                      result.data.pushSelectedProduct.result.successProductSchemaIDList.map((productSchemaID, index) => {
                                        currentProductIDList.map((item, ind) => {
                                          if (productSchemaID === item.productSchemaID) {
                                            midArr[ind].shopifyProductID = result.data.pushSelectedProduct.result.successShopifyIDList[index];
                                          }
                                        });
                                      })
                                      setCurrentProductIDList(midArr);
                                    }
                                    setIsPushingAll(false);
                                    //Open Upgrade Dailog
                                    if (result.data.pushSelectedProduct.isShowUpgrade) {
                                      document.getElementById("UpgradePlanDialog").click();
                                    }
                                    //Open Rating Dailog
                                    if (result.data != null && result.data.pushSelectedProduct != null && result.data.pushSelectedProduct.status && localStorage.getItem("isRatingShown") == "false" && result.data.pushSelectedProduct.canBeEvaluated) {
                                      setOpenRatingAlert(true);
                                    }
                                  });
                                }
                              }}>
                              {storePlatform != "wix" ? "PUSH TO SHOPIFY" : "PUSH TO WIX"}
                            </Box>
                          </Box>
                          <Box sx={{
                            color: '#FF9711',
                            fontWeight: "600",
                            fontSize: "14px",
                            letterSpacing: "0.46px",
                            cursor: 'pointer',
                            position: 'relative'
                          }}
                            aria-controls={openMoreActionMenu ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMoreActionMenu ? 'true' : undefined}
                            onClick={(e) => {
                              setOpenMoreActionMenu(true);
                              setMoreActionAnchorEl(e.currentTarget)
                            }}>
                            <Box sx={{
                              fontWeight: "200",
                              fontSize: '30px',
                              marginRight: '20px'
                            }}>
                              +
                            </Box>
                            <Box>MORE ACTION</Box>
                            <Menu
                              id="fade-menu"
                              MenuListProps={{
                                'aria-labelledby': 'fade-button',
                              }}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              anchorEl={moreActionAnchorEl}
                              open={openMoreActionMenu}
                              onClose={(e) => {
                                e.stopPropagation();
                                setMoreActionAnchorEl(null)
                                setOpenMoreActionMenu(false);
                              }}
                              TransitionComponent={Fade}
                            >
                              <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                // sendLog("MY_PRODUCTS_DELETE_CLICK");
                                if (selectedIDList.length !== 0) {
                                  setOpenEditPriceAlert(true);
                                }
                                setMoreActionAnchorEl(null);
                                setOpenMoreActionMenu(false);
                              }} sx={{ fontSize: '14px' }}>Edit Profit Markup</MenuItem>
                              <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                // sendLog("MY_PRODUCTS_DELETE_CLICK");
                                if (selectedIDList.length !== 0) {
                                  setOpenEditCollectionAndTagAlert(true);
                                }
                                setMoreActionAnchorEl(null);
                                setOpenMoreActionMenu(false);
                              }} sx={{ fontSize: '14px' }}>Add Collections,Tags and Type</MenuItem>
                              <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                setMoreActionAnchorEl(null);
                                setOpenMoreActionMenu(false);
                                if (selectedIDList.length === 0) {
                                  return;
                                }
                                if (selectedIDList.length > 1 && (statusLoading == true || statusData == null || statusData.getSubscriptionStatus === "Free")) {
                                  setState({
                                    ...state,
                                    isOpenSelectPlanDialog: true,
                                    openSnackbar: false,
                                  });
                                }
                                setOptimizeStatus("wait");
                                setOptimizeNum(selectedIDList.length);
                                handleGenerateContent();
                              }} sx={{ fontSize: '14px' }}>Generate Content by ChatGPT</MenuItem>
                              {storePlatform != "wix" ? <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                setMoreActionAnchorEl(null);
                                setOpenMoreActionMenu(false);
                                sendLog("MY_PRODUCTS_PUBLISH_TO_ONLINE_STORE_CLICK");
                                if (isPushingAll) {
                                  return;
                                }
                                if (shopName == null) {
                                  setState({
                                    ...state,
                                    isAccountPopUpDialogOpen: true,
                                    currentComponentName: 'CONNECT_SHOPIFY',
                                  });
                                  return;
                                }

                                if (selectedIDList == null || selectedIDList.length === 0) {
                                  return;
                                }
                                if (selectedIDList != null && selectedIDList.length > 25) {
                                  setState({
                                    ...state,
                                    openSnackbar: true,
                                    snackbarText: 'Push job is received, and running in the background. Refresh page later.',
                                    snackbarState: 'SUCCESS',
                                  });
                                  publish_selected_products({
                                    variables: {
                                      IDList: selectedIDList,
                                    },
                                  })
                                  return;
                                } else {
                                  setIsPushingAll(true);
                                  setPushStatus('wait');
                                  const Arr = selectedIDList.map(item => {
                                    return item;
                                  })
                                  setPushIDList(Arr);
                                  publish_selected_products({
                                    variables: {
                                      IDList: selectedIDList,
                                    },
                                  }).then(async (result) => {
                                    localStorage.setItem("LastTimePushSelectedProduct", new Date().getTime());
                                    const isEditing = sessionStorage.getItem("isEditing");
                                    if (selectedIDList != null && selectedIDList.length > 25) {
                                      // Add a message to indicate the progress is going in the background
                                    }

                                    if (
                                      result.data != null &&
                                      result.data.publishSelectedProduct != null &&
                                      result.data.publishSelectedProduct.result != null &&
                                      result.data.publishSelectedProduct.result.successProductSchemaIDList != null &&
                                      result.data.publishSelectedProduct.result.failedProductSchemaIDList != null &&
                                      result.data.publishSelectedProduct.result.successShopifyIDList != null
                                    ) {
                                      if (result.data.publishSelectedProduct.message === 'PLEASE_UPGRADE_PLAN') {
                                        setState({
                                          ...state,
                                          isOpenSelectPlanDialog: true,
                                          isOpenImportProductDialog: isEditing === 'true',
                                        });
                                        setPushStatus('none');
                                        setIsPushingAll(false);
                                        return;
                                      }
                                      setPushStatus('finish');
                                      const cacheFailedIDList = pushFailedIDList;
                                      const cacheFailedReasonList = pushFailedReasonList;
                                      //如果第二次push，这件产品依然报错，把这条报错信息替换成最新返回的信息
                                      result.data.publishSelectedProduct.result.failedProductSchemaIDList.map((id, index) => {
                                        pushFailedIDList.map((cacheId, cacheIndex) => {
                                          if (cacheId === id) {
                                            cacheFailedReasonList[cacheIndex] = result.data.publishSelectedProduct.result.failedReasonList[index];
                                          }
                                        })
                                      })
                                      // 比较之前的错误列表和本次返回的错误列表，如果存在不同的，就把这个不同的ID和报错信息存放在当前列表
                                      result.data.publishSelectedProduct.result.failedProductSchemaIDList.map((id, index) => {
                                        if (!pushFailedIDList.some(cacheId => cacheId === id)) {
                                          cacheFailedIDList.push(id);
                                          cacheFailedReasonList.push(result.data.publishSelectedProduct.result.failedReasonList[index]);
                                        }
                                      })
                                      const resultFailedIDList = [];
                                      const resultFailedReasonList = [];
                                      // 比较当前错误ID列表和返回的成功的ID列表，如果当前错误的ID，不是成功的ID,就把这个ID,和对应的错误信息添加到结果列表
                                      cacheFailedIDList.map((id, index) => {
                                        if (!result.data.publishSelectedProduct.result.successProductSchemaIDList.some(cacheId => cacheId === id)) {
                                          resultFailedIDList.push(id);
                                          resultFailedReasonList.push(cacheFailedReasonList[index]);
                                        }
                                      })
                                      setPushSuccessIDList(result.data.publishSelectedProduct.result.successProductSchemaIDList);
                                      setPushFailedIDList(resultFailedIDList);
                                      setPushFailedReasonList(resultFailedReasonList);
                                      setCurrentFailedCount(result.data.publishSelectedProduct.result.failedProductSchemaIDList.length);
                                      setCurrentSuccessCount(result.data.publishSelectedProduct.result.successProductSchemaIDList.length);
                                      let midArr = currentProductIDList;
                                      result.data.publishSelectedProduct.result.successProductSchemaIDList.map((productSchemaID, index) => {
                                        currentProductIDList.map((item, ind) => {
                                          if (productSchemaID === item.productSchemaID) {
                                            midArr[ind].shopifyProductID = result.data.publishSelectedProduct.result.successShopifyIDList[index];
                                          }
                                        });
                                      })
                                      setCurrentProductIDList(midArr);
                                    }
                                    setIsPushingAll(false);
                                  });
                                }
                              }} sx={{ fontSize: '14px' }}>Publish to Online Store</MenuItem> : null}
                              <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                sendLog("MY_PRODUCTS_DELETE_CLICK");
                                if (selectedIDList.length !== 0) {
                                  setDeleteDisabledProduct(null);
                                  setOpenDeleteAlert(true);
                                }
                                setMoreActionAnchorEl(null);
                                setOpenMoreActionMenu(false);
                              }} sx={{ fontSize: '14px' }}>Delete</MenuItem>
                            </Menu>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: '1px', height: '84px' }} />
                  </Box>
                  :
                  (
                    (initialize || loading === true) && (data == null || data.getUserProductList == null) ?
                      (<Box
                        sx={{
                          height: "400px",
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>) : (data != null && data.getUserProductList.result == null ||
                        data.getUserProductList.result.length === 0) && (data.getUserProductList.isFilterProductList == null || data.getUserProductList.isFilterProductList === false) ? (
                        <MyProductBlankPage />
                      ) : <Box>
                        <Box sx={{
                          width: '1146px',
                          minHeight: "300px",
                          backgroundColor: "#FFF",
                          borderRadius: '4px',
                          boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                          "& .MuiInputLabel-root": {
                            fontSize: '16px',
                            lineHeight: '16px',
                            color: "rgba(0, 0, 0, 0.6)!important",
                            "&.Mui-focused": {
                              fontSize: '16px',
                              lineHeight: '16px',
                              color: "rgba(0, 0, 0, 0.6)!important",
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: '28px',
                          },
                          "& fieldset": {
                            fontSize: '14px',
                            borderColor: "rgba(0, 0, 0, 0.23)!important",
                            "&.Mui-focused": {
                              borderColor: "rgba(0, 0, 0, 0.23)!important",
                            },
                          },
                          "& #demo-multiple-chip-label": collectionSelectedList.length > 0 ? {
                            transform: "translate(14px, -9px) scale(0.75)",
                          } : {},
                          "& legend": collectionSelectedList.length > 0 ? {
                            maxWidth: '100%'
                          } : {}
                        }}>
                          <Box sx={{
                            padding: '10px 10px 20px',
                            height: '64px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            "&>div": {
                              height: '48px'
                            }
                          }}>
                            <Box sx={{
                              width: '262px',
                              height: '48px'
                            }}>
                              <TextField
                                id="myproduct_search"
                                label="Search"
                                sx={{
                                  width: '262px',
                                  fontSize: '16px',
                                  "& .MuiInputBase-input": {
                                    padding: '0px',
                                    paddingLeft: '10px',
                                    height: '48px',
                                    fontSize: '14px'
                                  }
                                }}
                                placeholder="Product, type, supplier, etc.."
                                value={myProductSearchValue}
                                onChange={(e) => {
                                  setMyProductSearchValue(e.target.value);
                                }}
                                onBlur={() => {
                                  handleRefetchMyProductList(0);
                                  setPageIndex(0);
                                }}
                              />
                            </Box>
                            <Box sx={{ width: '135px' }}></Box>
                            {storePlatform != "wix" ? null : <Box sx={{ width: '160px', position: 'relative' }}></Box>}
                            <Box sx={{ width: '160px' }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  sx={{
                                    "& .MuiSelect-select.MuiInputBase-input": {
                                      padding: '0px',
                                      paddingLeft: '10px',
                                      height: '48px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      overflow: "hidden",
                                      fontSize: '14px'
                                    }
                                  }}
                                  value={status}
                                  label="Status"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <MenuItem value="0"/*all*/ sx={{ fontSize: '14px' }}>All</MenuItem>
                                  <MenuItem value='1'/*imported*/ sx={{ fontSize: '14px' }}>Imported</MenuItem>
                                  <MenuItem value="2"/*not_imported*/ sx={{ fontSize: '14px' }}>Not Imported</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                            {storePlatform != "wix" ? <Box sx={{ width: '160px', position: 'relative' }}>
                              <FormControl sx={{
                                width: "160px",
                              }}>
                                <InputLabel id="demo-multiple-chip-label">Collection</InputLabel>
                                <Select
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  sx={{
                                    "& .MuiSelect-select.MuiInputBase-input": {
                                      padding: '0px',
                                      paddingLeft: '10px',
                                      height: '48px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      overflow: "hidden",
                                      fontSize: '14px'
                                    },
                                  }}
                                  multiple
                                  value={collectionSelectedList}
                                  onChange={handleChangeCollection}
                                  onOpen={() => {
                                    if (collectionOptionList.length === 0) {
                                      get_product_collection().then(res => {
                                        if (res != null && res.data != null && res.data.getProductCollection) {
                                          setCollectionOptionList(res.data.getProductCollection.collectionOptionTitleList);
                                        }
                                      });
                                    }
                                  }}
                                  input={<OutlinedInput id="select-multiple-chip" label="Collection" sx={{ fontSize: "14px" }} />}
                                  renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: "center", maxWidth: '120px', overflow: 'hidden', gap: 0.5 }}>
                                    </Box>
                                  )}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: "200px",
                                        width: "160px"
                                      }
                                    }
                                  }}
                                >
                                  {collectionOptionList.length > 0 ? collectionOptionList.map((collection) => (
                                    <MenuItem
                                      key={collection}
                                      value={collection}
                                      style={getStyles(collection, collectionSelectedList, theme)}
                                      sx={{
                                        "&.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root": {
                                          paddingLeft: '0px',
                                          paddingRight: '0px',
                                          fontSize: '14px',
                                          "&.Mui-selected": {
                                            background: "rgba(0, 0, 0, 0.06)",
                                            "&:hover": {
                                              background: "rgba(0, 0, 0, 0.06)",
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      <Checkbox checked={collectionSelectedList.indexOf(collection) > -1}
                                        icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                        </Box>}
                                        checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_gray_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                        </Box>} />

                                      <ButtonTooltip title={getButtonTooltipLayout(collection)} arrow>
                                        <Box sx={{ width: '160px' }}>{collection}</Box>
                                      </ButtonTooltip>

                                    </MenuItem>
                                  )) :
                                    <Box
                                      sx={{
                                        height: "100px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: 'center'
                                      }}
                                    >
                                      <CircularProgress />
                                    </Box>}
                                </Select>
                              </FormControl>
                              <Box sx={{
                                position: 'absolute',
                                height: '32px',
                                lineHeight: '32px',
                                top: '8px',
                                left: '10px',
                                lineHeight: '24px',
                                color: "#00000099"
                              }}>
                                <Box sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: "center", maxWidth: '120px', overflow: 'hidden', gap: 0.5 }}>
                                  {collectionSelectedList.map((value, index) => (
                                    <Chip key={value} label={value} sx={{ fontSize: '14px' }} onDelete={() => {
                                      let Arr = collectionSelectedList;
                                      Arr.splice(index, 1);
                                      setCollectionSelectedList(Arr);
                                      setRefetchPageNum(refetchPageNum + 1);
                                    }} />
                                  ))}
                                </Box>
                              </Box>
                              {collectionSelectedList.length > 0 && <Box sx={{
                                position: 'absolute',
                                height: '24px',
                                bottom: '-24px',
                                left: '10px',
                                lineHeight: '24px',
                                color: "#00000099"
                              }}>{collectionSelectedList.length} Selected</Box>}
                            </Box> : null}
                            <Box sx={{ width: '160px' }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  sx={{
                                    "& .MuiSelect-select.MuiInputBase-input": {
                                      padding: '0px',
                                      paddingLeft: '10px',
                                      height: '48px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      overflow: "hidden",
                                      fontSize: '14px'
                                    }
                                  }}
                                  value={sort}
                                  label="Sort"
                                  onChange={(e) => {
                                    setSort(e.target.value);
                                  }}
                                >
                                  <MenuItem value='latest' sx={{ fontSize: '14px' }}>Latest</MenuItem>
                                  <MenuItem value="oldest" sx={{ fontSize: '14px' }}>Oldest</MenuItem>
                                  <MenuItem value="price_low_to_high" sx={{ fontSize: '14px' }}>Price: Low to High</MenuItem>
                                  <MenuItem value="price_high_to_low" sx={{ fontSize: '14px' }}>Price: High to Low</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                            <ButtonTooltip title={getButtonTooltipLayout("Switch to compact viewing mode")} arrow>
                              <Box sx={{ width: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box sx={{ width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: currentViewMode === "concise" ? "0px solid rgba(0, 0, 0, 0.23)" : "1px solid rgba(0, 0, 0, 0.23)", borderRadius: '4px', background: currentViewMode === "concise" ? "#FFF" : "rgba(0, 0, 0, 0.08)", cursor: 'pointer' }} onClick={() => {
                                  setCurrentViewMode(currentViewMode === "concise" ? "compact" : "concise");
                                  save_myproducts_page_setting({
                                    variables: {
                                      viewMode: currentViewMode === "concise" ? "compact" : "concise",
                                    },
                                  })
                                }} >
                                  <ListAltOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '28px', cursor: 'pointer' }} />
                                </Box>
                              </Box>
                            </ButtonTooltip>
                            {/* <ButtonTooltip title={getButtonTooltipLayout("Customize columns")} arrow>
                              <Box sx={{ width: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <SettingsOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '28px', cursor: 'pointer' }} />
                              </Box>
                            </ButtonTooltip> */}
                            <ButtonTooltip title={getButtonTooltipLayout("More filters")} arrow>
                              <Box sx={{ width: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {
                                setIsOpenFilter(true);
                              }}>
                                <TuneOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', width: '28px', cursor: 'pointer' }} />
                              </Box>
                            </ButtonTooltip>
                          </Box>
                          <Box>
                            <Box>
                              {
                                currentViewMode === "concise" ?
                                  <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    "&>div": {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }
                                  }}>
                                    <Box sx={{
                                      width: '130px',
                                      alignItems: 'flex-start!important',
                                    }}>
                                      <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '0px 10px 0px 0px'
                                      }}>

                                        <ButtonTooltip title={getButtonTooltipLayout("Select all products")} arrow>
                                          <Checkbox
                                            checked={checkedAll}
                                            onChange={(e) => {
                                              if (statusLoading == true || statusData == null || statusData.getSubscriptionStatus === "Free") {
                                                setState({
                                                  ...state,
                                                  isOpenSelectPlanDialog: true
                                                });
                                                return;
                                              }
                                              let Arr = [];
                                              if (e.target.checked) {
                                                data != null && data.getUserProductList.result.map(({ id, available }) => {
                                                  if (available) {
                                                    Arr.push(id);
                                                  }
                                                });
                                                setSelectedIDList(Arr);
                                                setCheckedAll(true);
                                              } else {
                                                setSelectedIDList([]);
                                                setCheckedAll(false);
                                              }
                                            }}
                                            inputProps={{
                                              'aria-label': 'select all desserts',
                                            }}
                                            icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                            </Box>}
                                            checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                            </Box>}
                                          />
                                        </ButtonTooltip>

                                      </Box>
                                    </Box>
                                    <Box sx={{ width: '80px', alignItems: 'center' }}>Picture</Box>
                                    <Box sx={{ width: '180px', alignItems: 'center' }}>Title</Box>
                                    <Box sx={{ width: '150px' }}>Cost</Box>
                                    <ButtonTooltip title={getButtonTooltipLayout(`Shipping fee will be calculated based on the methods you choose to fulfill the product. Please continue to product detail editing for more details.`)} arrow>
                                      <Box sx={{ width: '150px' }}>Shipping Fee</Box>
                                    </ButtonTooltip>
                                    <Box sx={{ width: '150px' }}>Store Price</Box>
                                    <Box sx={{ width: '150px' }}>Profit Markup</Box>
                                    {/* <Box sx={{ width: '100px' }}>
                                    <Box>Collection</Box>
                                    <Box>Type</Box>
                                  </Box> */}
                                    <Box sx={{ width: "130px", alignItems: 'center' }}>Status</Box>
                                  </Box>
                                  :
                                  <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    "&>div": {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }
                                  }}>
                                    <Box sx={{
                                      width: '130px',
                                      alignItems: 'flex-start!important',
                                    }}>
                                      <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '0px 10px 0px 0px'
                                      }}>
                                        <ButtonTooltip title={getButtonTooltipLayout("Select all products")} arrow>
                                          <Checkbox
                                            checked={checkedAll}
                                            onChange={(e) => {
                                              if (statusLoading == true || statusData == null || statusData.getSubscriptionStatus === "Free") {
                                                setState({
                                                  ...state,
                                                  isOpenSelectPlanDialog: true
                                                });
                                                return;
                                              }
                                              let Arr = [];
                                              if (e.target.checked) {
                                                data != null && data.getUserProductList.result.map(({ id, available }) => {
                                                  if (available) {
                                                    Arr.push(id);
                                                  }
                                                });
                                                setSelectedIDList(Arr);
                                                setCheckedAll(true);
                                              } else {
                                                setSelectedIDList([]);
                                                setCheckedAll(false);
                                              }
                                            }}
                                            inputProps={{
                                              'aria-label': 'select all desserts',
                                            }}
                                            icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                            </Box>}
                                            checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                                            </Box>}
                                          />
                                        </ButtonTooltip>
                                      </Box>
                                    </Box>
                                    <Box sx={{ width: '80px', alignItems: 'center' }}>Picture</Box>
                                    <Box sx={{ width: '180px', alignItems: 'center' }}>Title</Box>
                                    <Box sx={{ width: '100px' }}>
                                      <Box>Cost</Box>
                                    </Box>
                                    <Box sx={{ width: '100px' }}>
                                      <ButtonTooltip title={getButtonTooltipLayout(`Shipping fee will be calculated based on the methods you choose to fulfill the product. Please continue to product detail editing for more details.`)} arrow>
                                        <Box>Shipping Fee</Box>
                                      </ButtonTooltip>
                                    </Box>
                                    <Box sx={{ width: '100px' }}>
                                      <Box>Store Price</Box>
                                      <Box>Compare Price</Box>
                                    </Box>
                                    <Box sx={{ width: '100px' }}>
                                      <Box>Profit </Box>
                                      <Box>Markup</Box>
                                    </Box>
                                    {/* <Box sx={{ width: '100px' }}>
                                      <Box>30D Sales</Box>
                                      <Box>Stars 4+</Box>
                                    </Box> */}
                                    <Box sx={{ width: '105px' }}>
                                      <Box>Default Supplier</Box>
                                      <Box>Inventory</Box>
                                    </Box>
                                    <Box sx={{ width: '100px' }}>
                                      <Box>Collection</Box>
                                      <Box>Tags</Box>
                                    </Box>
                                    <Box sx={{ width: "130px", alignItems: 'center' }}>Status</Box>
                                  </Box>
                              }
                            </Box>
                            <Box sx={{
                              fontSize: "14px",
                              overflow: "auto"
                            }}>
                              {data != null && data.getUserProductList.result.length === 0 ?
                                <Box sx={{ height: '124px', fontSize: '18px', color: "#666", fontWeight: '600', lineHeight: '124px', textAlign: 'center' }}>There are currently no products that match the filter criteria</Box> :
                                data != null && data.getUserProductList.result.map(({ id, title, price, currency, multipliedPrice, thumbnailSource, productID, available, shopifyProductID, crawlerID, productSource, shippingFee, inventory, tagsList, collectionList, fulfillmentOption }, index) => {
                                  return <ProductCard
                                    key={index}
                                    productSelectedNum={productSelectedNum}
                                    currentViewMode={currentViewMode}
                                    id={id}
                                    index={index}
                                    title={title}
                                    price={price}
                                    currency={currency}
                                    multipliedPrice={multipliedPrice}
                                    thumbnailSource={thumbnailSource}
                                    shopifyProductID={currentProductIDList[index] != null ? currentProductIDList[index].shopifyProductID : '0'}
                                    productID={productID}
                                    available={available}
                                    setIsOpenEdit={setIsOpenEdit}
                                    crawlerID={crawlerID}
                                    productSource={productSource}

                                    isPushingAll={pushIDList.includes(id) && isPushingAll}
                                    pushNum={selectedIDList.length !== 0 ? 12 - (Math.ceil(selectedIDList.length / 5) * 2) : 10}
                                    selectedIDList={selectedIDList}
                                    setSelectedIDList={setSelectedIDList}
                                    setCheckedAll={setCheckedAll}
                                    productList={data != null && data.getUserProductList.result}
                                    handleRefreshPage={() => { setRefreshPage(refreshPage + 1) }}
                                    undateCurrentProductIDList={(shopifyProductID) => {
                                      let midArr = currentProductIDList;
                                      midArr[index].shopifyProductID = shopifyProductID;
                                      setCurrentProductIDList(midArr);
                                    }}
                                    isPushFailed={pushFailedIDList.includes(id)}
                                    pushReason={pushFailedIDList.includes(id) ? pushFailedReasonList[pushFailedIDList.indexOf(id)] : null}
                                    setPushSuccessIDList={setPushSuccessIDList}
                                    setPushFailedIDList={setPushFailedIDList}
                                    setPushFailedReasonList={setPushFailedReasonList}
                                    setCurrentFailedCount={setCurrentFailedCount}
                                    setPushStatus={setPushStatus}
                                    pushFailedIDList={pushFailedIDList}
                                    pushFailedReasonList={pushFailedReasonList}
                                    setPushIDList={setPushIDList}
                                    setOpenDeleteAlert={setOpenDeleteAlert}
                                    setDeleteDisabledProduct={setDeleteDisabledProduct}
                                    shippingFee={shippingFee}
                                    inventory={inventory}
                                    tagsList={tagsList}
                                    collectionList={collectionList}
                                    fulfillmentOption={fulfillmentOption}
                                    setStatus={setStatus}
                                    setTagValue={setTagValue}
                                    setSearchImmediate={setSearchImmediate}
                                    handleRefetchMyProductList={handleRefetchMyProductList}
                                    setPageIndex={setPageIndex}
                                    collectionSelectedList={collectionSelectedList}
                                    setCollectionSelectedList={setCollectionSelectedList}
                                    setOpenRatingAlert={setOpenRatingAlert}
                                    optimizeFailedReason={optimizeFailedIDList.includes(id) ? optimizeFailedReasonList[optimizeFailedIDList.indexOf(id)] : null}
                                    isOptimizeFailed={optimizeFailedIDList.includes(id)}
                                  />
                                })
                              }
                              <Box sx={{ width: '100%', height: '10px', background: "#f2f2f2" }}></Box>
                            </Box>
                            <TablePagination
                              sx={{
                                fontSize: '14px',
                                "& .MuiTablePagination-displayedRows": {
                                  fontSize: '14px'
                                },
                                "& .MuiTablePagination-selectLabel": {
                                  fontSize: '14px'
                                },
                                "& .MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input": {
                                  fontSize: '14px',
                                  lineHeight: '150%'
                                },
                                "& .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root": {
                                  fontSize: '14px',
                                },
                              }}
                              rowsPerPageOptions={[10, 25, 100]}
                              component="div"
                              count={loading === false && data != null && data.getUserProductList != null ? data.getUserProductList.currentProductCount : 0}
                              rowsPerPage={rowsPerPage}
                              page={pageIndex}
                              onPageChange={(event, newPage) => {
                                setPageIndex(newPage);
                                setSelectedIDList([]);
                                setCheckedAll(false);
                                handleRefetchMyProductList(newPage);
                              }}
                              onRowsPerPageChange={(event) => {
                                setRowsPerPage(+event.target.value);
                                setPageIndex(0);
                                handleRefetchMyProductList(0, +event.target.value)
                                save_myproducts_page_setting({
                                  variables: {
                                    pageSize: (+event.target.value),
                                  },
                                })
                              }}
                            />
                          </Box>
                          <Box sx={{
                            position: "fixed",
                            bottom: '0',
                            left: '240px',
                            width: 'calc(100% - 240px)',
                            height: "64px",
                            background: '#FFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '0px 100px 0px 16px',
                            userSelect: 'none',
                            boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                            boxSizing: "border-box"
                          }}>
                            <Box sx={{
                              fontSize: '14px',
                              color: 'rgba(0, 0, 0, 0.87)',
                              fontWeight: '600'
                            }}>{selectedIDList.length} items selected</Box>
                            <Box sx={{
                              width: '400px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              "&>div": {
                                flex: '1',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }
                            }}>
                              <Box sx={{
                                borderRight: "3px solid #e0e0e0"
                              }}>
                                <Box sx={{
                                  width: "156px",
                                  height: "36px",
                                  backgroundColor: false ? "rgba(112, 112, 112, 0.5)" : "#FF9711",
                                  color: "#FFF",
                                  fontFamily: 'Roboto',
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                  letterSpacing: "0.4px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "4px",
                                  boxSizing: 'border-box',
                                  cursor: false ? "not-allowed" : "pointer",
                                  boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
                                }}
                                  onClick={async () => {
                                    sendLog("MY_PRODUCTS_ALL_PUSH_TO_SHOPIFY_CLICK");
                                    if (isPushingAll) {
                                      return;
                                    }
                                    if (shopName == null) {
                                      setState({
                                        ...state,
                                        isAccountPopUpDialogOpen: true,
                                        currentComponentName: 'CONNECT_SHOPIFY',
                                      });
                                      return;
                                    }

                                    if (selectedIDList == null || selectedIDList.length === 0) {
                                      return;
                                    }
                                    if (selectedIDList != null && selectedIDList.length > 25) {
                                      setState({
                                        ...state,
                                        openSnackbar: true,
                                        snackbarText: 'Push job is received, and running in the background. Refresh page later.',
                                        snackbarState: 'SUCCESS',
                                      });
                                      push_selected_products({
                                        variables: {
                                          IDList: selectedIDList,
                                        },
                                      })
                                      return;
                                    } else {
                                      setIsPushingAll(true);
                                      setPushStatus('wait');
                                      const Arr = selectedIDList.map(item => {
                                        return item;
                                      })
                                      setPushIDList(Arr);
                                      push_selected_products({
                                        variables: {
                                          IDList: selectedIDList,
                                        },
                                      }).then(async (result) => {
                                        if (selectedIDList != null && selectedIDList.length > 25) {
                                          // Add a message to indicate the progress is going in the background
                                        }
                                        localStorage.setItem("LastTimePushSelectedProduct", new Date().getTime());
                                        const isEditing = sessionStorage.getItem("isEditing");
                                        if (
                                          result.data != null &&
                                          result.data.pushSelectedProduct != null &&
                                          result.data.pushSelectedProduct.result != null &&
                                          result.data.pushSelectedProduct.result.successProductSchemaIDList != null &&
                                          result.data.pushSelectedProduct.result.failedProductSchemaIDList != null &&
                                          result.data.pushSelectedProduct.result.successShopifyIDList != null
                                        ) {
                                          if (result.data.pushSelectedProduct.message === 'PLEASE_UPGRADE_PLAN') {
                                            setState({
                                              ...state,
                                              isOpenSelectPlanDialog: true,
                                              isOpenImportProductDialog: isEditing === 'true',
                                            });
                                            setPushStatus('none');
                                            setIsPushingAll(false);
                                            return;
                                          }
                                          setPushStatus('finish');
                                          const cacheFailedIDList = pushFailedIDList;
                                          const cacheFailedReasonList = pushFailedReasonList;
                                          //如果第二次push，这件产品依然报错，把这条报错信息替换成最新返回的信息
                                          result.data.pushSelectedProduct.result.failedProductSchemaIDList.map((id, index) => {
                                            pushFailedIDList.map((cacheId, cacheIndex) => {
                                              if (cacheId === id) {
                                                cacheFailedReasonList[cacheIndex] = result.data.pushSelectedProduct.result.failedReasonList[index];
                                              }
                                            })
                                          })
                                          // 比较之前的错误列表和本次返回的错误列表，如果存在不同的，就把这个不同的ID和报错信息存放在当前列表
                                          result.data.pushSelectedProduct.result.failedProductSchemaIDList.map((id, index) => {
                                            if (!pushFailedIDList.some(cacheId => cacheId === id)) {
                                              cacheFailedIDList.push(id);
                                              cacheFailedReasonList.push(result.data.pushSelectedProduct.result.failedReasonList[index]);
                                            }
                                          })
                                          const resultFailedIDList = [];
                                          const resultFailedReasonList = [];
                                          // 比较当前错误ID列表和返回的成功的ID列表，如果当前错误的ID，不是成功的ID,就把这个ID,和对应的错误信息添加到结果列表
                                          cacheFailedIDList.map((id, index) => {
                                            if (!result.data.pushSelectedProduct.result.successProductSchemaIDList.some(cacheId => cacheId === id)) {
                                              resultFailedIDList.push(id);
                                              resultFailedReasonList.push(cacheFailedReasonList[index]);
                                            }
                                          })
                                          setPushSuccessIDList(result.data.pushSelectedProduct.result.successProductSchemaIDList);
                                          setPushFailedIDList(resultFailedIDList);
                                          setPushFailedReasonList(resultFailedReasonList);
                                          setCurrentFailedCount(result.data.pushSelectedProduct.result.failedProductSchemaIDList.length);
                                          setCurrentSuccessCount(result.data.pushSelectedProduct.result.successProductSchemaIDList.length);
                                          let midArr = currentProductIDList;
                                          result.data.pushSelectedProduct.result.successProductSchemaIDList.map((productSchemaID, index) => {
                                            currentProductIDList.map((item, ind) => {
                                              if (productSchemaID === item.productSchemaID) {
                                                midArr[ind].shopifyProductID = result.data.pushSelectedProduct.result.successShopifyIDList[index];
                                              }
                                            });
                                          })
                                          setCurrentProductIDList(midArr);
                                        }
                                        setIsPushingAll(false);
                                        //Open Upgrade Dailog
                                        if (result.data.pushSelectedProduct.isShowUpgrade) {
                                          document.getElementById("UpgradePlanDialog").click();
                                        }
                                        //Open Rating Dailog
                                        if (localStorage.getItem("isRatingShown") == "false" && result.data.pushSelectedProduct.canBeEvaluated) {
                                          setOpenRatingAlert(true);
                                        }
                                      });
                                    }
                                  }}>
                                  {storePlatform != "wix" ? "PUSH TO SHOPIFY" : "PUSH TO WIX"}
                                </Box>
                              </Box>
                              <Box sx={{
                                color: '#FF9711',
                                fontWeight: "600",
                                fontSize: "14px",
                                letterSpacing: "0.46px",
                                cursor: 'pointer',
                                position: 'relative'
                              }}
                                aria-controls={openMoreActionMenu ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMoreActionMenu ? 'true' : undefined}
                                onClick={(e) => {
                                  setOpenMoreActionMenu(true);
                                  setMoreActionAnchorEl(e.currentTarget)
                                }}>
                                <Box sx={{
                                  fontWeight: "200",
                                  fontSize: '30px',
                                  marginRight: '20px'
                                }}>
                                  +
                                </Box>
                                <Box>MORE ACTION</Box>
                                <Menu
                                  id="fade-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  anchorEl={moreActionAnchorEl}
                                  open={openMoreActionMenu}
                                  onClose={(e) => {
                                    e.stopPropagation();
                                    setMoreActionAnchorEl(null)
                                    setOpenMoreActionMenu(false);
                                  }}
                                  TransitionComponent={Fade}
                                >
                                  <MenuItem onClick={(e) => {
                                    e.stopPropagation();
                                    // sendLog("MY_PRODUCTS_DELETE_CLICK");
                                    if (selectedIDList.length !== 0) {
                                      setOpenEditPriceAlert(true);
                                    }
                                    setMoreActionAnchorEl(null);
                                    setOpenMoreActionMenu(false);
                                  }} sx={{ fontSize: '14px' }}>Edit Profit Markup</MenuItem>
                                  <MenuItem onClick={(e) => {
                                    e.stopPropagation();
                                    // sendLog("MY_PRODUCTS_DELETE_CLICK");
                                    if (selectedIDList.length !== 0) {
                                      setOpenEditCollectionAndTagAlert(true);
                                    }
                                    setMoreActionAnchorEl(null);
                                    setOpenMoreActionMenu(false);
                                  }} sx={{ fontSize: '14px' }}>Add Collections,Tags and Type</MenuItem>
                                  <MenuItem onClick={(e) => {
                                    e.stopPropagation();
                                    setMoreActionAnchorEl(null);
                                    setOpenMoreActionMenu(false);
                                    if (selectedIDList.length === 0) {
                                      return;
                                    }
                                    if (selectedIDList.length > 1 && (statusLoading == true || statusData == null || statusData.getSubscriptionStatus === "Free")) {
                                      setState({
                                        ...state,
                                        isOpenSelectPlanDialog: true,
                                        openSnackbar: false,
                                      });
                                    }
                                    setOptimizeStatus("wait");
                                    setOptimizeNum(selectedIDList.length);
                                    handleGenerateContent();
                                  }} sx={{ fontSize: '14px' }}>Generate Content by ChatGPT</MenuItem>
                                  {storePlatform != "wix" ? <MenuItem onClick={(e) => {
                                    e.stopPropagation();
                                    setMoreActionAnchorEl(null);
                                    setOpenMoreActionMenu(false);
                                    sendLog("MY_PRODUCTS_PUBLISH_TO_ONLINE_STORE_CLICK");
                                    if (isPushingAll) {
                                      return;
                                    }
                                    if (shopName == null) {
                                      setState({
                                        ...state,
                                        isAccountPopUpDialogOpen: true,
                                        currentComponentName: 'CONNECT_SHOPIFY',
                                      });
                                      return;
                                    }

                                    if (selectedIDList == null || selectedIDList.length === 0) {
                                      return;
                                    }
                                    if (selectedIDList != null && selectedIDList.length > 25) {
                                      setState({
                                        ...state,
                                        openSnackbar: true,
                                        snackbarText: 'Push job is received, and running in the background. Refresh page later.',
                                        snackbarState: 'SUCCESS',
                                      });
                                      publish_selected_products({
                                        variables: {
                                          IDList: selectedIDList,
                                        },
                                      })
                                      return;
                                    } else {
                                      setIsPushingAll(true);
                                      setPushStatus('wait');
                                      const Arr = selectedIDList.map(item => {
                                        return item;
                                      })
                                      setPushIDList(Arr);
                                      publish_selected_products({
                                        variables: {
                                          IDList: selectedIDList,
                                        },
                                      }).then(async (result) => {
                                        localStorage.setItem("LastTimePushSelectedProduct", new Date().getTime());
                                        const isEditing = sessionStorage.getItem("isEditing");
                                        if (selectedIDList != null && selectedIDList.length > 25) {
                                          // Add a message to indicate the progress is going in the background
                                        }

                                        if (
                                          result.data != null &&
                                          result.data.publishSelectedProduct != null &&
                                          result.data.publishSelectedProduct.result != null &&
                                          result.data.publishSelectedProduct.result.successProductSchemaIDList != null &&
                                          result.data.publishSelectedProduct.result.failedProductSchemaIDList != null &&
                                          result.data.publishSelectedProduct.result.successShopifyIDList != null
                                        ) {
                                          if (result.data.publishSelectedProduct.message === 'PLEASE_UPGRADE_PLAN') {
                                            setState({
                                              ...state,
                                              isOpenSelectPlanDialog: true,
                                              isOpenImportProductDialog: isEditing === 'true',
                                            });
                                            setPushStatus('none');
                                            setIsPushingAll(false);
                                            return;
                                          }
                                          setPushStatus('finish');
                                          const cacheFailedIDList = pushFailedIDList;
                                          const cacheFailedReasonList = pushFailedReasonList;
                                          //如果第二次push，这件产品依然报错，把这条报错信息替换成最新返回的信息
                                          result.data.publishSelectedProduct.result.failedProductSchemaIDList.map((id, index) => {
                                            pushFailedIDList.map((cacheId, cacheIndex) => {
                                              if (cacheId === id) {
                                                cacheFailedReasonList[cacheIndex] = result.data.publishSelectedProduct.result.failedReasonList[index];
                                              }
                                            })
                                          })
                                          // 比较之前的错误列表和本次返回的错误列表，如果存在不同的，就把这个不同的ID和报错信息存放在当前列表
                                          result.data.publishSelectedProduct.result.failedProductSchemaIDList.map((id, index) => {
                                            if (!pushFailedIDList.some(cacheId => cacheId === id)) {
                                              cacheFailedIDList.push(id);
                                              cacheFailedReasonList.push(result.data.publishSelectedProduct.result.failedReasonList[index]);
                                            }
                                          })
                                          const resultFailedIDList = [];
                                          const resultFailedReasonList = [];
                                          // 比较当前错误ID列表和返回的成功的ID列表，如果当前错误的ID，不是成功的ID,就把这个ID,和对应的错误信息添加到结果列表
                                          cacheFailedIDList.map((id, index) => {
                                            if (!result.data.publishSelectedProduct.result.successProductSchemaIDList.some(cacheId => cacheId === id)) {
                                              resultFailedIDList.push(id);
                                              resultFailedReasonList.push(cacheFailedReasonList[index]);
                                            }
                                          })
                                          setPushSuccessIDList(result.data.publishSelectedProduct.result.successProductSchemaIDList);
                                          setPushFailedIDList(resultFailedIDList);
                                          setPushFailedReasonList(resultFailedReasonList);
                                          setCurrentFailedCount(result.data.publishSelectedProduct.result.failedProductSchemaIDList.length);
                                          setCurrentSuccessCount(result.data.publishSelectedProduct.result.successProductSchemaIDList.length);
                                          let midArr = currentProductIDList;
                                          result.data.publishSelectedProduct.result.successProductSchemaIDList.map((productSchemaID, index) => {
                                            currentProductIDList.map((item, ind) => {
                                              if (productSchemaID === item.productSchemaID) {
                                                midArr[ind].shopifyProductID = result.data.publishSelectedProduct.result.successShopifyIDList[index];
                                              }
                                            });
                                          })
                                          setCurrentProductIDList(midArr);
                                        }
                                        setIsPushingAll(false);
                                      });
                                    }
                                  }} sx={{ fontSize: '14px' }}>Publish to Online Store</MenuItem> : null}
                                  <MenuItem onClick={(e) => {
                                    e.stopPropagation();
                                    sendLog("MY_PRODUCTS_DELETE_CLICK");
                                    if (selectedIDList.length !== 0) {
                                      setDeleteDisabledProduct(null);
                                      setOpenDeleteAlert(true);
                                    }
                                    setMoreActionAnchorEl(null);
                                    setOpenMoreActionMenu(false);
                                  }} sx={{ fontSize: '14px' }}>Delete</MenuItem>
                                </Menu>
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box sx={{ width: '1px', height: '84px' }} />
                      </Box>
                  )
              }
            </Box> : <ProductEditDialog setIsOpenEdit={setIsOpenEdit} />}
          </Box>
        </Box>
      </Box >
    </>
  );
}

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function HtmlSelect(props) {
  const {
    sx,
    onClick,
    open,
    selectedItems,
    selectedOptions,
    userInput,
    handleClickItem,
    isAllowAdd,
    handleAddOption
  } = props;
  const [isShowAddItem, setIsShowAddItem] = useState(false);
  useEffect(() => {
    let timer;
    if (userInput != null && userInput.length > 0 && !selectedOptions.includes(userInput)) {
      timer = setTimeout(() => {
        setIsShowAddItem(true);
      }, 200)
    } else {
      setIsShowAddItem(false);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [userInput]);

  return <>{
    open ? <Box sx={sx} onClick={onClick}>
      {isShowAddItem && isAllowAdd ? <Box sx={{
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        cursor: "pointer",
        backgroundColor: "#f2edfd",
      }} onClick={() => {
        if (handleAddOption) {
          setIsShowAddItem(false);
          handleAddOption(userInput);
        }
      }}>
        <Box sx={{ width: '24px', height: '24px', background: 'url("img/icon_product_add_tag.png") no-repeat 0 0', backgroundSize: 'contain', margin: '0 10px' }}></Box>
        <Box><Typography variant="span" sx={{ color: '#333333', fontWeight: '600' }}>Add</Typography> {userInput}</Box>
      </Box> : null}
      {
        selectedOptions != null ? selectedOptions.map((item, index) => {
          if (item.slice(0, userInput.length).includes(userInput)) {
            return (
              <Box sx={{
                height: '36px',
                display: 'flex',
                alignItems: 'center',
                cursor: "pointer",
                backgroundColor: false ? '#e0e0e0' : '#FFF',
                '&:hover': {
                  backgroundColor: '#f5f5f5'
                }
              }} onClick={() => {
                handleClickItem(item);
              }} key={index}>
                <Checkbox sx={{
                  color: "#FF9711",
                  padding: '0',
                  "&.Mui-checked": {
                    color: "#FF9711",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "40px",
                  },
                  margin: '0 10px'
                }}
                  checked={selectedItems.includes(item)}
                  onChange={(e) => {

                  }}
                  inputProps={{
                    "aria-label": `Checked item${0}`,
                  }}
                  icon={<Box sx={{ width: '24px', height: '24px', background: 'url("img/checkbox_icon.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                  </Box>}
                  checkedIcon={<Box sx={{ width: '24px', height: '24px', background: 'url("img/checkbox_icon_checked.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                  </Box>} />
                <Box sx={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>{item}</Box>
              </Box>
            )
          }
        }) : null
      }
    </Box> : null
  }</>
}
