import React, { useEffect } from "react";
import { Context } from "../Context/Context";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ImportProduct from "../ProductsTabComponents/ImportProduct.react";
import Button from "@mui/material/Button";


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProductEditDialog(props) {
  const { setIsOpenEdit } = props;
  const saveShopifyInputRef = React.useRef();
  const { state, setState } = React.useContext(Context);
  const { isOpenImportProductDialog, tabValue, shopName, sendLog, isEditShopifyInput, variantsIsWrong, imagesIsWrong } = state;

  const handleSaveShopifyInput = (val) => {
    saveShopifyInputRef.current.handleSaveShopifyInput();
  };
  const handleSaveAndPushShopifyInput = (val) => {
    if (shopName == null) {
      setState({
        ...state,
        isAccountPopUpDialogOpen: true,
        currentComponentName: 'CONNECT_SHOPIFY',
      });
      return;
    }
    saveShopifyInputRef.current.handleSaveAndPushShopifyInput();
  };
  const [openConfirmAlert, setOpenConfirmAlert] = React.useState(false);

  return (
    <>
      <Box sx={{
        minWidth: "100px",
        maxWidth: "1200px",
        height: 'auto',
        backgroundColor: '#FFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        overflow: 'auto',
        padding: "0px 16px 20px 20px",
        margin: "16px 16px 20px 20px",
      }}>
        <Box
          onClick={() => {
            if (isEditShopifyInput) {
              setOpenConfirmAlert(true);
            } else {
              setIsOpenEdit(false);
              sessionStorage.setItem("isEditing", 'false');
            }
          }}
          sx={{
            width: "30px",
            height: "30px",
            cursor: "pointer",
            marginTop: "5px",
            float: "Right",
            background: 'url("/img/icon_close.png") no-repeat 0 0',
            backgroundSize: "contain",
            zIndex: '999999999999999999999999999'
          }}
        />
        <Box sx={{
          height: '54px',
          borderBottom: '2px solid #D9D9D9',
        }}>
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => {
              switch (newValue) {
                case 0:
                  sendLog("MY_PRODUCTS_CARD_DIALOG_PRODUCT_TAB_CLICK");
                  break;
                case 1:
                  sendLog("MY_PRODUCTS_CARD_DIALOG_DESCRIPTION_TAB_CLICK");
                  break;
                case 2:
                  sendLog("MY_PRODUCTS_CARD_DIALOG_AITEMPLATE_TAB_CLICK");
                  break;
                case 3:
                  sendLog("MY_PRODUCTS_CARD_DIALOG_VARIANTS_TAB_CLICK");
                  break;
                case 4:
                  sendLog("MY_PRODUCTS_CARD_DIALOG_MEDIA_TAB_CLICK");
                  break;
              }
              setState({
                ...state,
                tabValue: newValue
              });
            }}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                height: "5px",
                backgroundColor: "#FF9711",
              },
            }}
            sx={{
              "& .MuiTab-root": {
                fontSize: '18px',
                color: '#000000',
                fontWeight: 'bold',
                lineHeight: "150%",
                fontFamily: "Arial",
                letterSpacing: "0.15px",
                fontStyle: "normal",
                fontWeight: "500",
                textTransform: "capitalize",
                margin: '0 20px',
                padding: '0 50px',
                minWidth: '0',
                minHeight: '54px',
                "&.Mui-selected": {
                  //color: "#FF9711",
                  fontSize: '18px',
                  color: '#000000',
                  fontWeight: 'bold',
                },
              },
            }}
          >
            <Tab label={"Product"} {...a11yProps(0)} />
            <Tab label="Description" {...a11yProps(1)} />
            <Tab label="GPT Templates" {...a11yProps(2)} />
            <Tab label={
              <Box sx={{
                position: 'relative',
              }}>
                Variants
                {variantsIsWrong ? <Box sx={{
                  width: '7.66px',
                  height: '7.66px',
                  borderRadius: '7.66px',
                  backgroundColor: '#E84343',
                  position: 'absolute',
                  right: '-7.66px',
                  top: '0'
                }} /> : <Box />}
              </Box>
            } {...a11yProps(3)} />
            <Tab label={
              <Box sx={{
                position: 'relative',
              }}>
                Media
                {imagesIsWrong ? <Box sx={{
                  width: '7.66px',
                  height: '7.66px',
                  borderRadius: '7.66px',
                  backgroundColor: '#E84343',
                  position: 'absolute',
                  right: '-7.66px',
                  top: '0'
                }} /> : <Box />}
              </Box>
            } {...a11yProps(4)} />
          </Tabs>
        </Box>
        <Box sx={{
          padding: '10px',
          //overflow: 'auto',
        }}>
          <ImportProduct saveShopifyInputRef={saveShopifyInputRef} setIsOpenEdit={setIsOpenEdit} sx={{ padding: '10px' }} />
        </Box>
        <Box sx={{
          height: '84px',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box
            sx={{
              width: "calc( 100% - 48px)",
              padding: "0 24px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              justifyContent: "space-between",
              "& .button": {
                padding: "10px 25px",
                borderRadius: "7.66px",
                fontFamily: "Arial",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                lineHeight: "150%",
                letterSpacing: "0.15px",
                color: "rgba(112, 112, 112, 0.7)",
                backgroundColor: "#e4e4e4",
              },
              "& .importButton": {
                marginLeft: "49px",
                color: "#FFF",
                backgroundColor: "#FF9711",
              },
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Box className="button" onClick={() => {
                sendLog("MY_PRODUCTS_CARD_DIALOG_CANCEL");
                if (isEditShopifyInput) {
                  setOpenConfirmAlert(true);
                } else {
                  setIsOpenEdit(false);
                  sessionStorage.setItem("isEditing", 'false');
                }
              }} >
                Cancel
              </Box>
              <Box className="button" onClick={() => {
                sendLog("MY_PRODUCTS_CARD_DIALOG_RESET");
                saveShopifyInputRef.current.handleResetShopifyInput();
              }} sx={{
                marginLeft: '46px',
              }}>
                Reset
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box className="button" onClick={() => {
                sendLog("MY_PRODUCTS_CARD_DIALOG_SAVE");
                handleSaveShopifyInput();
              }}>
                Save
              </Box>
              <Box className="button importButton" onClick={() => {
                sendLog("MY_PRODUCTS_CARD_DIALOG_SAVE_IMPORT");
                handleSaveAndPushShopifyInput();
              }}>
                Save & Import
              </Box>
            </Box>
          </Box>
        </Box>
      </Box >
      <Dialog
        open={openConfirmAlert}
        onClose={() => {
          setOpenConfirmAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            width: "440px",
            height: "209px",
            borderRadius: "7.66px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: '115px',
            fontFamily: "Arial",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "150%",
            letterSpacing: "0.15px",
            color: "#666666",
            position: "relative",
            paddingTop: '23px',
          }}
        >
          <Box sx={{ fontSize: '20px', color: "#333333", }}>Unsaved changes</Box>
          <Box>The information you modified has not been saved yet. If you want to save, please click "Save", if you don't want to save your modifications, click "Discard".</Box>
        </DialogTitle>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: '0 23px',
            "& .button": {
              width: '92px',
              height: '38px',
              padding: "0",
              borderRadius: "7.66px",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "150%",
              letterSpacing: "0.15px",
              color: "#707070B2",
              backgroundColor: "#e4e4e4",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#e4e4e4",
              },
            },
            "& .save-button": {
              color: "#FFF",
              backgroundColor: "#FF9711",
              "&:hover": {
                color: "#FFF",
                backgroundColor: "#FF9711",
              },
            },
          }}
        >
          <Button
            className="button"
            onClick={() => {
              setOpenConfirmAlert(false);
              setIsOpenEdit(false);
              setState({
                ...state,
                isOpenImportProductDialog: false,
                variantsIsWrong: false
              });
              sessionStorage.setItem("isEditing", 'false');
            }}
          >
            Discard
          </Button>
          <Button
            className="button save-button"
            onClick={() => {
              setOpenConfirmAlert(false);
              handleSaveShopifyInput();
              setIsOpenEdit(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );
}