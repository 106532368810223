import * as React from "react";
import { lazy, useImperativeHandle } from 'react';
import Box from "@mui/material/Box";
import AppHeader from "../AppHeader.react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { Context } from "../Context/Context";
import AppSidebar from "../AppSidebar.react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Divider from "@mui/material/Divider";

import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CircularProgress from "@mui/material/CircularProgress";
import SettingBlankPage from "./SettingBlankPage.react";
import { currencyList } from "../utils/CurrencyList.js";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { LanguageList } from "../utils/LanguageList.js";
import { useLocation } from "react-router-dom";

const CustomSelected = lazy(() =>
  import("../CommonComponents/CustomSelected.react")
);

const SAVE_SETTING_ACCOUNT = gql`
  mutation saveSettingAccount(
    $language: String, 
    $newPassword: String, 
  ) {
    saveSettingAccount(
      language: $language, 
      newPassword: $newPassword, 
    ) {
      status
      message
    }
  }
`;

export default function TabAccount(prop) {
  const {
    userName,
    initialLanguage,
    setCurrentUnSaveIndex,
    AccountRef,
    refetch,
    setIsOpenUnSave
  } = prop;
  const { state, setState } = React.useContext(Context);
  const [language, setLanguage] = useState(initialLanguage);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  useImperativeHandle(AccountRef, () => ({
    handleSaveAccount,
    handleDiscardAccount,
  }));
  const [save_setting_account] = useMutation(SAVE_SETTING_ACCOUNT);

  useEffect(() => {
    if ((language !== initialLanguage && language != null) || newPassword !== '') {
      setCurrentUnSaveIndex('1')
    } else {
      setCurrentUnSaveIndex('-1')
    }
  }, [newPassword, language]);

  const handleSaveAccount = () => {
    save_setting_account({
      variables: {
        language: String(language),
        newPassword: String(newPassword),
      },
    }).then((result) => {
      if (result != null && result.data != null && result.data.saveSettingAccount != null) {
        if (result.data.saveSettingAccount.status) {
          setState({
            ...state,
            openSnackbar: true,
            snackbarText: 'Save Setting Success !',
            snackbarState: 'SUCCESS',
          });
          setCurrentUnSaveIndex('-1');
          refetch();
        } else {
          setState({
            ...state,
            openSnackbar: true,
            snackbarText: 'Save Setting failed !',
            snackbarState: 'FAILED',
          });
        }
      } else {
        setState({
          ...state,
          openSnackbar: true,
          snackbarText: 'Save Setting failed !',
          snackbarState: 'FAILED',
        });
      }
      setIsOpenUnSave(false);
    });
  }

  const handleDiscardAccount = () => {
    setLanguage(initialLanguage);
    setNewPassword('');
    setConfirmPassword('');
  }

  return (
    <Box sx={{
      width: "100%",
      height: 'auto',
      background: "#FFFFFF",
      boxShadow: "0px 0.4rem 0.4rem rgba(0, 0, 0, 0.25)",
      borderRadius: "1rem",
      marginBottom: '5rem',
    }} >
      <Box>
        <Box sx={{
          height: '5.3rem',
          display: 'flex',
          alignItems: 'center',
          boxSizing: 'border-box',
          fontSize: '18px',
          fontWeight: '500',
          paddingLeft: '4rem',
          margin: '0rem 0 1rem',
          paddingTop: '2rem',
          color: '#333333'
        }}>User Name</Box>
        <Box sx={{
          paddingLeft: '7rem',
          fontSize: '16px',
          fontWeight: '500',
          marginBottom: '2rem',
          color: '#333333'
        }}>{userName}</Box>
      </Box>
      <Box  >
        <Divider sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }} />
        <Box sx={{ paddingLeft: '4rem', marginTop: '2rem' }}>
          <Box sx={{ fontSize: "18px", fontWeight: '500', color: '#333333', height: '3.3rem', lineheight: '3.3rem' }}>Password</Box>
          <Box sx={{ fontSize: "14px", fontWeight: '400', color: '#999999', height: '2.7rem', lineheight: '2.7rem' }}>This section can be used to update andreset your account password.</Box>
        </Box>
        <Box sx={{
          paddingLeft: '7rem',
          margin: '1rem 0 2rem',
          fontSize: '16px',
          color: '#666666',
          "& .password-input": {
            width: '50rem',
            height: '5rem',
            "& .MuiInputBase-root": {
              borderRadius: '1rem',
              "&.MuiOutlinedInput-root input": {
                textIndent: '2rem',
                height: '5rem',
                fontSize: '16px',
                padding: 0
              },
            },
            "& .MuiOutlinedInput-root": {
              borderSize: "0.1rem",
              borderColor: '#999999',
              "&.Mui-focused fieldset": {
                borderSize: "0.1rem",
                borderColor: '#999999'
              },
              "&:hover fieldset": {
                borderSize: "0.1rem",
                borderColor: '#999999'
              },
            },
          }
        }}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: '1.2rem'
          }}>
            <Box sx={{ width: '16.5rem' }}>New Password:</Box>
            <TextField
              type={showNewPassword ? "text" : "password"}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Password"
                      onClick={() => {
                        setShowNewPassword(!showNewPassword);
                      }}
                      edge="end"
                    >
                      {showNewPassword ? (
                        <Visibility sx={{ fontSize: "2.8rem" }} />
                      ) : (
                        <VisibilityOff sx={{ fontSize: "2.8rem" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              className="password-input"
              placeholder="New Password"
              value={newPassword}
              sx={{
                width: '62.1rem!important'
              }}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </Box>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: '1.2rem'
          }}>
            <Box sx={{ width: '28.3rem' }}>Confirm The New Password:</Box>
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Password"
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <Visibility sx={{ fontSize: "2.8rem" }} />
                      ) : (
                        <VisibilityOff sx={{ fontSize: "2.8rem" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              className="password-input"
              placeholder="Confirm The New Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Divider sx={{ borderColor: "#7070704D", borderBottomWidth: "0.2rem" }} />
        <Box sx={{ paddingLeft: '4rem', marginTop: '2.3rem' }}>
          <Box sx={{ fontSize: "18px", fontWeight: '500', color: '#333333', height: '3.3rem', lineheight: '3.3rem' }}>Language Preference</Box>
          <Box sx={{ fontSize: "14px", fontWeight: '400', color: '#999999', height: '2.7rem', lineheight: '2.7rem' }}>This section can be used to update the language for your Account.</Box>
        </Box>
        <Box sx={{
          marginLeft: '4rem', paddingBottom: '3rem', marginTop: '1rem', fontSize: '16px', "& .custom-selected-item": {
            fontSize: '16px'
          }
        }}>
          <CustomSelected
            width="50rem"
            height="4.8rem"
            selectedWidth="54rem"
            border="0.1rem solid #999999"
            selectedItems={LanguageList}
            defaultOption={initialLanguage}
            selectedValue={language}
            setSelectValue={setLanguage}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: 'fixed',
          right: '25.1rem',
          bottom: '5rem',
          "& .button": {
            padding: "1.4rem 3.3rem",
            borderRadius: "1rem",
            fontFamily: "Arial",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "150%",
            letterSpacing: "0.015rem",
            color: "rgba(112, 112, 112, 0.7)",
            backgroundColor: "#e4e4e4",
            cursor: "pointer",
          },
          "& .saveButton": {
            marginLeft: "6.4rem",
            color: "#FFF",
            backgroundColor: "#FF9711",
            boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.25)',
          },
        }}
      >
        <Box className="saveButton button" onClick={() => {
          if (newPassword !== '' || confirmPassword !== '') {
            if (newPassword !== confirmPassword) {
              setState({
                ...state,
                openSnackbar: true,
                snackbarText: 'Ensure that the two passwords are the same.',
                snackbarState: 'FAILED',
              });
              return;
            }
          }
          handleSaveAccount();
        }}>
          Save
        </Box>
      </Box>
    </Box>
  );
}