/** @format */

import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const CHECK_ORDER_STATUS = gql`
  query checkOrderTransactionStatus($shopifyOrderID: String, $orderName: String) {
    checkOrderTransactionStatus(shopifyOrderID: $shopifyOrderID, orderName: $orderName)
  }
`;

export default function ShopifyOneTimePurchaseRedirect() {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const shopifyOrderID = queryParams.get('shopifyOrderID');
    const orderName = queryParams.get('orderName');

    const { loading, data } = useQuery(CHECK_ORDER_STATUS, {
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        variables: {
            shopifyOrderID,
            orderName
        },
    });
    if (loading === false && data != null && data.checkOrderTransactionStatus != null) {
        if (data.checkOrderTransactionStatus != null) {
            navigate(`/orderDetail?id=${shopifyOrderID}&success=1`);
            return;
        } else {
            navigate(`/orderDetail?id=${shopifyOrderID}&success=0`);
            return;
        }
    }

    return (
        <div>
            <Box sx={{
                height: "40rem",
                marginTop: 10,
                display: "flex",
                justifyContent: "center",
            }}
            >
                <CircularProgress />
            </Box>
        </div>
    );
}
