/** @format */
import * as React from "react";
import { lazy, useImperativeHandle } from 'react';
import Box from "@mui/material/Box";
import AppHeader from "./AppHeader.react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { Context } from "./Context/Context";
import AppSidebar from "./AppSidebar.react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Divider from "@mui/material/Divider";

import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CircularProgress from "@mui/material/CircularProgress";
import { currencyList } from "./utils/CurrencyList.js";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { LanguageList } from "./utils/LanguageList.js";
import { useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { isInExperiment } from "./utils/HelperFunction.js";
import Typography from '@mui/material/Typography';
import { ShopifyAppKey, AuthScopes } from "./ShopifyConstants";
import { CopyToClipboard } from "react-copy-to-clipboard";


const GET_REFERRAL_EARNINGS = gql`
  query getReferralEarnings {
    getReferralEarnings {
      successReferrerList
      pendingReferrerList
      inviteEarnings
      invitePendingEarnings
      allowedWithdrawalEarnings
    }
  }
`;

const USER_REQUEST_WITHDRAWN = gql`
  mutation userRequestWithdrawn($allowedWithdrawalEarnings: String) {
    userRequestWithdrawn(allowedWithdrawalEarnings: $allowedWithdrawalEarnings) {
      status
      message
    }
  }
`;



const getInviteProgressWidthText = (inviteNumber) => {
  let inviteProgressWidthText = "0px"
  switch (true) {
    case inviteNumber > 6:
      inviteProgressWidthText = "100%";
      break;
    case inviteNumber == 6:
      inviteProgressWidthText = "calc(85.71% - 50px)";
      break;
    case inviteNumber == 5:
      inviteProgressWidthText = "calc(71.42% - 50px)";
      break;
    case inviteNumber == 4:
      inviteProgressWidthText = "calc(57.14% - 50px)";
      break;
    case inviteNumber == 3:
      inviteProgressWidthText = "calc(42.85% - 50px)";
      break;
    case inviteNumber == 2:
      inviteProgressWidthText = "calc(28.57% - 50px)";
      break;
    case inviteNumber == 1:
      inviteProgressWidthText = "calc(14.28% - 50px)";
      break;
    default:
      inviteProgressWidthText = "0px";
  }
  return inviteProgressWidthText;
}
export default function Referral() {
  const [tabIndex, setTabIndex] = React.useState(0);
  const { state, setState } = React.useContext(Context)
  const { shopName, firstName, sendLog, email, userID } = state;
  const [isOpenUnSave, setIsOpenUnSave] = useState(false);
  const [currentUnSaveIndex, setCurrentUnSaveIndex] = useState('-1');
  const [nextTabIndex, setNextTabIndex] = useState(0);
  const AccountRef = React.useRef();
  const ProductRef = React.useRef();
  const [initialize, setinitialize] = React.useState(true);
  const [inviteNumber, setInviteNumber] = useState(0);
  const [invitePendingNumber, setInvitePendingNumber] = useState(0);
  const [earnings, setEarnings] = useState(0);
  const [pendingEarnings, setPendingEarnings] = useState(0);
  const [allowedWithdrawalEarnings, setAllowedWithdrawalEarnings] = useState(0);
  const [inviteProgressWidthText, setInviteProgressWidthText] = useState("0px");
  const [user_request_withdrawn] = useMutation(USER_REQUEST_WITHDRAWN);

  const { loading, data, refetch } = useQuery(GET_REFERRAL_EARNINGS, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (loading == false && data != null && data.getReferralEarnings != null) {
      const curentInviteNumber = data.getReferralEarnings.successReferrerList != null ? data.getReferralEarnings.successReferrerList.length : 0;
      const inviteProgressWidthText = getInviteProgressWidthText(curentInviteNumber);
      console.log(96, inviteProgressWidthText, curentInviteNumber)
      setInviteNumber(curentInviteNumber);
      setEarnings(data.getReferralEarnings.inviteEarnings != null ? data.getReferralEarnings.inviteEarnings : 0);
      setPendingEarnings(data.getReferralEarnings.invitePendingEarnings != null ? data.getReferralEarnings.invitePendingEarnings : 0);
      setAllowedWithdrawalEarnings(data.getReferralEarnings.allowedWithdrawalEarnings != null ? data.getReferralEarnings.allowedWithdrawalEarnings : 0);
      setInviteProgressWidthText(inviteProgressWidthText);
    }
  }, [loading])
  useEffect(() => {
    if (sendLog != null) {
      sendLog("LOAD_REFERRAL_PAGE");
    }
  }, []);


  return (
    <>
      <Box>
        <AppHeader />
        <AppSidebar />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
          <Box sx={{ display: "flex", width: "calc(100% - 240px)", justifyContent: 'center', position: 'relative' }}>
            <Box sx={{
              paddingTop: "40px",
              " .white-wrap": {
                width: "1111px",
                height: "auto",
                minHeight: "100px",
                background: "#FFF",
                borderRadius: "4px",
                padding: "16px",
                marginBottom: "20px"
              },
              "& .title": {
                display: 'flex',
                alignItems: 'center',
                boxSizing: 'border-box',
                fontSize: '18px',
                fontWeight: '500',
                marginTop: '10px',
                color: '#333333',
                borderBottom: "1px solid #333"
              },
            }}>
              <Box className="white-wrap">

                <Box>
                  <Box sx={{
                    marginBottom: '20px',
                    fontSize: '18px'
                  }} className="title">
                    <Box>Referral Earnings</Box>
                  </Box>
                </Box>
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: "24px",
                  letterSpacing: "0.15px"
                }}>
                  <Box>Current Earnings: </Box>
                  <Box sx={{ fontWeight: "600" }}>{`${earnings}`}</Box>
                  <Box>{`(+${pendingEarnings} pending)`}</Box>
                </Box>
                <Box sx={{
                  width: "750px",
                  padding: '16px 24px',
                  fontSize: "18px",
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: "160%",
                  letterSpacing: '0.15px',
                }}><Box>
                    <Box sx={{
                      width: '100%',
                      height: '4px',
                      borderRadius: '10px',
                      backgroundColor: "#eceef0",
                      margin: "20px 0px 20px",
                      position: "relative",
                    }}>
                      <Box sx={{
                        width: inviteProgressWidthText,
                        height: '4px',
                        borderRadius: '10px',
                        backgroundColor: "#FF9711",
                        transition: "width 1s ease",
                      }} />
                      <Box sx={{
                        position: "absolute",
                        top: "-3px",
                        left: inviteProgressWidthText,
                        width: "8px",
                        height: "8px",
                        backgroundColor: "#FFF",
                        borderRadius: "8px",
                        border: "1px solid #FF9711",
                      }} />
                    </Box>
                    <Box sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontSize: "12px",
                      fontWeight: "400",
                      height: "17px",
                      letterSpacing: '0.17px',
                      "& .reach": {
                        color: "#FF9711"
                      },
                      "& div": {
                        flex: "1"
                      }
                    }}>
                      <Box></Box>
                      <Box className={inviteNumber >= 2 ? "reach" : "normal"}>Invite 2: get $9.95</Box>
                      <Box></Box>
                      <Box className={inviteNumber >= 4 ? "reach" : "normal"}>Invite 4: get $19.90</Box>
                      <Box></Box>
                      <Box className={inviteNumber >= 6 ? "reach" : "normal"}>Invite 6: get $39.90</Box>
                      <Box></Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {allowedWithdrawalEarnings != null && allowedWithdrawalEarnings.length > 0 && allowedWithdrawalEarnings != "$0" ? <Box sx={{
                    padding: "6px 16px",
                    boxShadow: "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
                    background: "#FF9711",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    color: "#FFF",
                    borderRadius: "4px",
                    marginTop: "20px",
                    cursor: "pointer",
                  }} onClick={() => {
                    // requestWithdrawn
                    // 用户点击此按钮后, 设置 requestWithdrawn 为 true, 并弹出提示，此提现将在三个工作日内到账
                    // 后端airflow根据 requestWithdrawn == true 来筛选需要提现的用户,存在表格中发到cartifind群中,由英华来给用户提现
                    // 此处如果需要加快提现速度，可以省略存表过程，直接发在群中
                    // 英华提现完成后, 更新 requestWithdrawn 为 false
                    // 比如在管理员模式下，输入已打款的用户id
                    if (sendLog != null) {
                      sendLog("CLICK_WITHDRAWN_BUTTON");
                    }
                    user_request_withdrawn({
                      variables: {
                        allowedWithdrawalEarnings
                      }
                    }).then((result) => {
                      if (result != null && result.data != null && result.data.userRequestWithdrawn != null && result.data.userRequestWithdrawn.status) {

                        setState({
                          ...state,
                          openSnackbar: true,
                          snackbarText: 'withdrawn success! the withdrawal amount will be received within 3 working days!',
                          snackbarState: 'SUCCESS',
                        });
                      } else {
                        setState({
                          ...state,
                          openSnackbar: true,
                          snackbarText: 'something wrong! Please try again later.',
                          snackbarState: 'FAILED',
                        });
                      }
                    });
                    console.log("请求提现")
                  }}>{allowedWithdrawalEarnings} Withdraw Requested</Box> : <Box sx={{
                    padding: "6px 16px",
                    boxShadow: "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
                    background: "#dcdcdc",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    color: "rgba(0, 0, 0, 0.8)",
                    borderRadius: "4px",
                    marginTop: "20px",
                    cursor: "pointer",
                  }} onClick={() => {
                    if (sendLog != null) {
                      sendLog("CLICK_WITHDRAWN_DISABLED_BUTTON");
                    }
                  }}>$0 Withdraw Requested</Box>}
                </Box>
              </Box>
              <Box className="white-wrap">

                <Box>
                  <Box sx={{
                    marginBottom: '20px',
                    fontSize: '18px'
                  }} className="title">
                    <Box>Referral Invite </Box>
                  </Box>
                </Box>
                <Box sx={{
                  width: "764px",
                  border: "1px solid #CCC",
                  padding: "16px 12px",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#000000DE",
                  lineHeight: "24px",
                  letterSpacing: "0.15px"
                }}>Hi, I am currently using Ipopify, a one-step Google sign up tool that boosts registration rate for Shopify store!
                  Use my link to sign up and you will get 14-day free trial! Sign up here:  <Typography variant="span" sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: "#2f76dc",
                    cursor: "pointer",
                    textDecoration: "underline"
                  }} onClick={() => {
                    if (sendLog != null) {
                      sendLog("CLICK_INVITE_LINK");
                    }
                    localStorage.setItem("email", email);
                    // const URL = `https://${"testhffproducts"}.myshopify.com/admin/oauth/authorize?client_id=${ShopifyAppKey}&scope=${AuthScopes}&redirect_uri=https://cartifind.com/shopifyAuth&state=43758342758d&referrerID=${userID}`;
                    // &grant_options%5B%5D=per-user
                    // window.open(URL);
                    const URL = `https://cartifind.com?currentComponentName=SIGN_UP&referrerID=${userID}`
                    window.open(URL);
                  }}>https://cartifind.com?currentComponentName=SIGN_UP&referrerID={userID}</Typography></Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CopyToClipboard text={`https://cartifind.com?currentComponentName=SIGN_UP&referrerID=${userID}`} onCopy={(_, result) => {
                    if (result) {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: 'Copy success!',
                        snackbarState: 'SUCCESS',
                      });
                    } else {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: 'Copy failed, please try again later.',
                        snackbarState: 'FAILED',
                      });
                    }
                  }}>
                    <Box sx={{
                      padding: "6px 16px",
                      boxShadow: "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
                      background: "#FF9711",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      letterSpacing: "0.4px",
                      color: "#FFF",
                      borderRadius: "4px",
                      marginTop: "20px",
                      cursor: "pointer",
                    }} onClick={() => {
                      if (sendLog != null) {
                        sendLog("CLICK_COPY_INVITE_BUTTON");
                      }
                    }}>Copy Invite</Box>
                  </CopyToClipboard>
                </Box>
              </Box>
              <Box></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

