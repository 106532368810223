// @flow
import React from 'react'
import AppHeader from './AppHeader.react';
import Box from '@mui/material/Box';
import AppFooter from "./AppFooter.react";
import { useEffect } from 'react';
import AppSidebar from "./AppSidebar.react";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<Box sx={{ width: '9px', height: '9px', background: 'url("img/common/triangle_orange.png") no-repeat 0 0', backgroundSize: 'contain' }}>
    </Box>}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: "8px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0px 16px 16px",
  borderTop: '0px solid #0000001F',
}));

const FAQList = [
  {
    title: "Account and Subscription",
    FAQ: [{
      question: "How do I cancel my plan and account, and where is the uninstall button?",
      answer: "You can uninstall the app, which will cancel your subscription automatically. You can also find the uninstall button in settings, plan, and cancel subscription.",
    }, {
      question: "I have cancelled my subscription, so why do you keep charging me?",
      answer: "Shopify's design for subscription payments charges one month late. When you cancel the subscription, future bills are halted, but charges for the previous month's services may still occur.",
    }, {
      question: "Why was I charged a monthly fee after paying for a yearly subscription?",
      answer: "The system automatically charged you a monthly fee because the upgrade to a yearly plan occurred a day after the free trial ended. The fee collected was for the services used before the yearly plan purchase. No further charges as the monthly subscription was cancelled.",
    }],
  },
  // {
  //   title: "Billing Issues",
  //   FAQ: [{
  //     question: "Why was I charged a monthly fee after paying for a yearly subscription?",
  //     answer: "The system automatically charged you a monthly fee because the upgrade to a yearly plan occurred a day after the free trial ended. The fee collected was for the services used before the yearly plan purchase. No further charges as the monthly subscription was cancelled.",
  //   }],
  // },
  {
    title: "Product Import",
    FAQ: [{
      question: "Can I import products from TEMU to my store using a Chrome Extension?",
      answer: "Yes, we highly suggest users adopting Chrome Extension to import products for its reliability, and fast response. ",
    }, {
      question: "How can i ensure an accurate shipping cost in my store?",
      answer: "To ensure accurate shipping costs in your store, it's necessary to manually adjust the weight of your products. This can be done by accessing the product detail editor and entering an estimated weight. Since exact weights are often not listed on platforms like Temu or AliExpress, using your best guess is advisable.",
    }, {
      question: "Is there a limit on the number of products I can upload?",
      answer: "There is no limit from our side, but Shopify's plan may restrict the number of products you can have.",
    }, {
      question: "What should I do if I fail to push products to shopify?",
      answer: "If you encounter issues pushing products to Shopify, look for a question mark icon that appears at the end of the product row. Hovering over this icon will display a popup message with further instructions or explanations for the failure. It's likely that your Shopify account has reached the max limit of the products for the day.",
    }, {
      question: "Do you offer options for bulk uploads?",
      answer: "Bulk pushes to Shopify are supported, but bulk additions to Cartifind.com are not yet available.",
    }, {
      question: "Why don't products show on the front end even if they are active on the Shopify backend?",
      answer: <Box>You need to publish them to your online store via Cartifind by selecting the product and publishing it to your desired sales channel.<br /> {"You can do this in Cartifind by visiting Cartifind.com/myProducts, and select the pushed product that you want to publish, and click on -> more actions -> publish to online store. "}</Box>,
    }, {
      question: "How can I find out the shipping times?",
      answer: "Shipping times can be finded in product detail editor variants tab, and in my order tabs, determined by creating a test order on the Cartifind platform. This order will appear in cartifnd.com/myOrders , where you can view the available shipping methods and their respective timelines.",
    }, {
      question: "Are there issues with the ChatGPT templates or language options?",
      answer: "If the GPT API limit is reached, using your own API key is recommended. For language-specific requests, instructions can be added to generate responses in the desired language.",
    }, {
      question: "Does Cartifind sync with price and quantity changes from suppliers like Temu?",
      answer: "We monitor inventory from all suppliers except Temu due to technical constraints but search for alternative suppliers if items are out of stock. Price sync is planned for future implementation.",
    }, {
      question: "I think the Chatgpt templates is not working properly.",
      answer: "It's possible that we hit the GPT API too many calls limit, and to avoid this, you may be able to use your own API key instead. You can input this key in the product edit -> AI templates -> API Key.",
    }, {
      question: "How can I collect VAT/Tax?",
      answer: "To collect VAT/Tax for your products sold through Shopify, you can enable a specific setting that designates all your products as taxable. This feature can be activated within your Shopify settings. For detailed instructions and further assistance, contacting Shopify support is recommended.",
    }, {
      question: "Why does my product price not update when I change the currency ?",
      answer: "You must re-add the product after changing the currency to ensure the correct amount is displayed. Editing the currency does not update any existing products.",
    }, {
      question: "What are 'Fulfilled By You' and 'Fulfilled By Cartfind' ?",
      answer: <Box sx={{
        "& .item": {
          display: "flex",
          alignItems: "center",
          gap: '10px',
        },
      }}>
        <Box>
          <Box sx={{ color: "#000000DE", marginBottom: "5px" }}>Note you have two options to fulfill orders:</Box>
          <Box sx={{ marginLeft: "10px" }}>
            <Box>{`1. Fulfilled By Youself: You directly on Temu.com by placing an order there, and paying Temu price. However, this item may arrive with Temu package, and price tag. You also need to pass the 30 dollar minimum checkout threshold. You can visit the product page by clicking on the "source" link.(Free shipping)`}</Box>
            <Box sx={{ marginTop: "5px" }}>{`2. Fulfilled By Cartfind: You can choose to fulfill from us, and our product price is similar or lower to Temu's price. But you need to pay us an additional VIP shipping fee to ship the item to your customer without any Temu package, and rely on us to fulfill it when the item is out of stock on Temu.`}</Box>
          </Box>
        </Box>
        <Box sx={{ color: "#000000DE", marginBottom: "5px" }}>{"You can choose this in product edit page -> variant -> fulfillment options."}</Box>
      </Box>
    }],
  },
  {
    title: "Order Fulfillment Issues",
    FAQ: [{
      question: "Is the fulfillment process by Cartifind automated?",
      answer: "The fulfillment process offered by Cartifind is not entirely automated. You need to pay it at cartifind.com/MyOrders This is because each order requires a manual payment confirmation to ensure that you agree with the pricing before proceeding.",
    }, {
      question: "Why am I being asked for additional payments after order fulfillment?",
      answer: "Our system leverages advanced AI technology to estimate the weight and cost of products before finalizing your order. Occasionally, the actual weight or cost may exceed our initial predictions, leading to additional expenses. In such cases, we may reach out to request an extra payment to cover these unforeseen costs. Please note, if the additional payment is not completed within 24 hours, we assure you that your initial payment will be fully refunded.",
    }, {
      question: "Why doesn't my order's product have a source link?",
      answer: "The absence of a source link for the product in your order is due to the fact that it was not sourced by Cartifind directly. As a result, we are unable to provide the original source link for these items.",
    }, {
      question: "Why can't you fulfill my Temu product order even though it's still available on Temu?",
      answer: "Products need to be stocked in the China market for package replacement. If a product is out of stock in China but available in the USA, we can ship directly from TEMU with their packaging if you enable this option. Otherwise, a refund is provided.",
    }, {
      question: "Can you manage orders from Amazon or other platforms like 1688?",
      answer: "Yes, we support purchasing and order fulfillment for Amazon and can facilitate dropshipping with 1688 by using our Chrome extension and Shopify integration.",
    }, {
      question: "How do I manage dropshipping with 1688 when they don't accept USD or other currency?",
      answer: "For dropshipping with 1688, despite the platform not accepting USD or AUD, you can manage the process through Cartifind. Simply install the Cartifind Chrome extension, use it to import products from 1688, push these products to Shopify via the Cartifind platform, and fulfill orders as they arrive. This process circumvents the currency issue, allowing you to conduct business smoothly.",
    }, {
      question: "Why do shipping addresses, or payments sometimes cause errors during Shopify store checkout?",
      answer: "This may be a setting issue within your Shopify store. It's advised to check Shopify's settings or contact their customer support for assistance.",
    }, {
      question: "What items cannot be shipped by cartifind?",
      answer: "Cartifind is unable to ship items that are excessively large or heavy. Typically, the maximum weight limit for packages is under 10KG.",
    }, {
      question: "Can I use customer payments to pay for orders on Cartifind?",
      answer: "  Payments from customers cannot be directly used for order fulfillment. Orders will only be processed once payment is received through the Shopify payment system.",
    }, {
      question: "How are returns and refunds handled?",
      answer: <Box>Due to the substantial cost of international shipping, we do not accommodate exchanges or returns. Refunds are only provided for orders where the incorrect items are sent.<br /> Additionally, ensure the accuracy and accessibility of your shipping address. We cannot issue refunds for orders undelivered by local shipping services due to incorrect or unreachable addresses provided by the customer.</Box>,
    }],
  },
  {
    title: "Shipping Inquiries",
    FAQ: [{
      question: "Can you specify the shipping carriers used for deliveries?",
      answer: "We partner with 4px.com, which coordinates with your local shipping companies for last-mile delivery.",
    }, {
      question: "What happens if there's a discrepancy in shipping fees listed and charged?",
      answer: "Shipping fee discrepancies occur due to Temu's minimum purchase threshold, requiring a product cost markup for orders below this threshold. This markup is not collected if your order meets the minimum requirement.",
    }, {
      question: "Delivery Times and Charges:",
      answer: <Box sx={{
        "& .item": {
          display: "flex",
          alignItems: "center",
          gap: '10px',
        },
        "& .country": {
          width: "60px",
          fontWeight: "500"
        }
      }}>
        <Box className='item'>
          <Box sx={{ color: "#000000DE", marginBottom: "5px" }}>Our delivery charges and times vary by destination:</Box>
        </Box>
        <Box className='item'>
          <Box className='country'>Australia:</Box>
          <Box>$16 USD for delivery within 7-15 business days, with additional 3-5 business days for order processing and domestic delivery within China.</Box>
        </Box>
        <Box className='item'>
          <Box className='country'>USA:</Box>
          <Box>$20 USD for delivery within 7-12 business days, with additional 3-5 business days for order processing and domestic delivery within China.</Box>
        </Box>
        <Box className='item'>
          <Box className='country'>UK:</Box>
          <Box>$15 USD for delivery within 6-8 business days, with additional 3-5 business days for order processing and domestic delivery within China.</Box>
        </Box>
        <Box className='item'>
          <Box className='country'>Canada:</Box>
          <Box>$25 USD for delivery within 10-15 business days, with additional 3-5 business days for order processing and domestic delivery within China.</Box>
        </Box>
        <Box className='item'>
          <Box className='country'>Europe:</Box>
          <Box> $20 USD for delivery within 6-8 business days, with with additional 3-5 business days for order processing and domestic delivery within China.</Box>
        </Box>
      </Box>
    }, {
      question: "Can my company name be put on the boxes?",
      answer: "Customizing packages is not possible as it would significantly delay shipping times.",
    }, {
      question: "What packaging do you use if you remove the TEMU packaging?",
      answer: "Orders are shipped in a plain box, which may contain some Chinese labels due to shipping requirements.",
    }],
  },
];
export default function FAQ() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <AppHeader />
      <AppSidebar />
      {/* <Box>
                <Box sx={{ fontSize: '3.6rem', fontWeight: '700', color: "#333333", textAlign: 'center', marginTop: '2rem', marginBottom: '2rem' }}>FAQ</Box>

            </Box> */}
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
        <Box sx={{ display: "flex", width: "calc(100% - 240px)", justifyContent: 'center', alignItems: "center", position: 'relative' }}>
          <Box sx={{
            width: "100%",
            padding: '0px 18px',
            boxSizing: "border-box",
            "& .white_wrap": {
              borderRadius: "4px",
              boxShadow: "0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F",
              background: "#FFF",
              widthL: "100%",
              height: "auto",
              marginTop: "20px",
              "& .MuiPaper-root.MuiPaper-elevation.MuiAccordion-root": {
                backgroundColor: 'transparent',
              }
            },
            "& .title": {
              fontSize: "18px",
              lineHeight: "32px",
              fontWeight: "500",
              color: "#000000DE"
            },
            "& .question": {
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "400",
              letterSpacing: "0.17px",
              color: "#000000DE"
            },
            "& .answer": {
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "400",
              letterSpacing: "0.17px",
              color: "#00000099",
              paddingLeft: "17px"
            }
          }}>
            <Box className='white_wrap'>
              <Box sx={{
                padding: "12px 16px"
              }}>
                <Box sx={{
                  fontSize: "24px",
                  lineHeight: "32px",
                  fontWeight: "600",
                  color: "#000000DE"
                }}>FAQs</Box>
                <Box sx={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "400",
                  letterSpacing: "0.17px",
                  color: "#00000099"
                }}>Solve the most common issues you may encounter while using Cartifind</Box>
              </Box>
            </Box>
            {FAQList.map((item, index) => {
              return <Box className='white_wrap' key={index}>
                <Box sx={{
                  fontSize: "18px",
                  lineHeight: "32px",
                  fontWeight: "500",
                  color: "#000000DE",
                  padding: "12px 16px 0px"
                }}>{item.title}</Box>
                <Box>
                  {
                    item.FAQ != null ? item.FAQ.map((faq, faqIndex) => {
                      return <Accordion key={"Accordion" + faqIndex}>
                        <AccordionSummary sx={{ borderRadius: "4px" }}>
                          <Box className='question'>{faq.question}</Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box className='answer'>{faq.answer}</Box>
                        </AccordionDetails>
                      </Accordion>
                    }) : null
                  }
                </Box>
              </Box>
            })}
            <Box sx={{ width: "1px", height: "200px" }}></Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}