/*
自定义下拉选择
*/
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { categoryList } from "./utils/CategoryList.js";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CatogerySelector(props) {
    const { setSelectedValue, option, setSelectedOption } = props;
    const [selectedTypeName, setSelectedTypeName] = useState(categoryList[0].option);
    const [selectedType, setSelectedType] = useState(categoryList[0].children);
    const [boxSwitch, setBoxSwitch] = useState(false);
    const [currentOption, setCurrentOption] = useState(categoryList[0]["children"][0].option);
    useEffect(() => {
        if (option != null) {
            setCurrentOption(option);
        }
    }, [option]);
    return (
        <Box sx={{
            display: "flex",
        }}>
            <Box sx={{
                display: "flex",
                width: "280px",
                height: "40px",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "10px",
                cursor: "poiner"
            }} onClick={() => {
                setBoxSwitch(!boxSwitch);
            }}>
                <div>{currentOption}</div>
                <div>{boxSwitch ? <ExpandLessIcon sx={{ fontSize: '28px' }} /> : <ExpandMoreIcon sx={{ fontSize: '28px' }} />}</div>
            </Box>
            {boxSwitch ?
                <div style={{
                    position: "absolute",
                    width: "350px",
                    height: "300px",
                    background: "#FFFFFF",
                    borderRadius: "10px",
                    top: "40px",
                    zIndex: '99999',
                    boxShadow: "0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F",
                }}>
                    <>
                        <Box sx={{
                            position: "absolute",
                            width: "150px",
                            backgroundColor: "#ffe0b8",
                            borderRadius: "1rem 0px 0px 1rem",
                            height: "auto",
                            maxHeight: '300px',
                            backgroundColor: "#ffe0b8",
                            left: "0",
                            zIndex: 99999,
                            fontFamily: 'Roboto',
                            cursor: 'pointer',
                            overflowY: "scroll",
                            overflowX: "hidden",
                            "&::-webkit-scrollbar": {
                                display: 'none'
                            }
                        }}>
                            {categoryList.map((item, index) => {
                                return (
                                    <Box
                                        sx={{
                                            transition: "color 0.5s",
                                            color: item.option === selectedTypeName ? "#333333" : "#707070",
                                            fontWeight: item.option === selectedTypeName ? "700" : "500",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            padding: "4px 10px",
                                            marginTop: index === 0 ? "10px" : "unset",
                                            backgroundColor: item.option === selectedTypeName ? "#fff" : "#ffe0b8",
                                        }} title={item.option} onMouseEnter={() => {
                                            setSelectedTypeName(item.option);
                                            setSelectedType(item.children);
                                        }} key={index}>{item.option}</Box>
                                )
                            })}

                        </Box>
                        <Box sx={{
                            position: "absolute",
                            width: "200px",
                            background: "#fff",
                            color: '#707070',
                            borderRadius: "10px",
                            height: '100%',
                            maxHeight: '300px',
                            backgroundColor: '#fff',
                            right: '0',
                            zIndex: "99999",
                            cursor: 'pointer',
                            fontSize: '14px',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            }
                        }}>
                            {selectedType.map((item, index) => {
                                return (
                                    <Box sx={{
                                        padding: "4px 10px",
                                        fontSize: "14px",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        color: "#707070",
                                        fontFamily: 'Roboto',
                                        backgroundColor: "#fff",
                                        "&:hover": {
                                            color: '#fd9711',
                                            textDecoration: 'underline',
                                            transition: "all 0.5s"
                                        }
                                    }} title={item.option} onClick={() => {
                                        setSelectedValue(item.value);
                                        setSelectedOption(item.option);
                                        setBoxSwitch(false);
                                        setCurrentOption(item.option);
                                        setSelectedTypeName(categoryList[0].option);
                                        setSelectedType(categoryList[0].children);
                                    }} key={index}>{item.option}</Box>
                                )
                            })}
                        </Box>
                    </>
                </div>
                : null
            }
            {boxSwitch ?
                <Box sx={{ width: '1920px', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: '99998' }} onClick={() => { setBoxSwitch(false); }}>
                </Box>
                : null
            }
        </Box>
    );
}